import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { getParameterByName } from '../../../utils/toSearchParams'
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { parseDate } from '../../../utils/react';
import Slider from '../../../components/Slider/index'
import gsap from 'gsap';
import SelectList from "../../../components/Sidebar/SelectList";
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { Button, EButtonType } from "../../../components/Button";

const translatableStrings = defineMessages({
    whatBruttolohn: {
        id: 'pageBruttolohn.whatBruttolohn',
        defaultMessage: 'Der Bruttolohn nach Teilpensionierung muss um mindestens 20% des <b>Vollzeitlohns</b> vor der Teilzeitpensionierung reduziert werden. Der maximale Bruttolohn wird untenstehend angezeigt.',
        values: {
            b: (child) => <b>{child}</b>
        }
    },
    bruttolohn: {
        id: 'general.bruttolohn',
        defaultMessage: 'Bruttolohn'
    },
    cancel: {
        id: 'button.cancel',
        defaultMessage: 'ABBRECHEN',
    },
})

interface State {
    bruttolohn: number;
}

interface Props {
    actions: any;
    history: any;
    setUserDataBruttolohn: any;
    userDataWI: any;
    intl: any;
}

class Bruttolohn extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        console.log('arbeitpensum constructor');
        if (!this.props.userDataWI.partialPensionLimits.possibleRetirementStepList) {
            this.props.history.push({
                pathname: `/geburtsdatum`
            });
            this.state = {
                bruttolohn: 0
            }
        } else {
            this.state = {
                bruttolohn: this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
                    this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumGrossSalary :
                    this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumGrossSalary
            };
        }
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        setTimeout(() => {
            (document.querySelector('.lohnsumme__button button') as any) && (document.querySelector('.lohnsumme__button button') as any).focus();
        }, 1200);
    }

    async setUserDataBruttolohn(data, idx) {
        await this.props.setUserDataBruttolohn({ data, idx });
    }

    async next(e) {
        await this.setUserDataBruttolohn(e, getParameterByName("no"));

        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            // document.querySelector('.routeContainer').classList.add('overflowHidden');
            this.timeline
                // .to(document.querySelector('.currentNavItem'), { color: '#80994D', duration: 0.3 })
                // .to(document.querySelector('.routeContainer'), {overflow: 'hidden', delay: 0.0} )
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {
            this.props.history.push({
                pathname: this.props.userDataWI.partialPensionLimits.isDerivedFromActivityReduction ? `/arbeitspensum` : `/rentenanteil`,
                search: `no=${getParameterByName("no")}`
            })
        }, 900)
    }

    cancel(e) {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            // document.querySelector('.routeContainer').classList.add('overflowHidden');
            this.timeline
                // .to(document.querySelector('.currentNavItem'), { color: '#80994D', duration: 0.3 })
                // .to(document.querySelector('.routeContainer'), {overflow: 'hidden', delay: 0.0} )
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {

            this.props.history.push({
                pathname: `/chart`
            })
        }, 800)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e,
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id,
                search: `no=${getParameterByName("no")}`
            })
        }
    }

    setPercent = (e) => {
        this.setState({
            bruttolohn: e || this.state.bruttolohn,
        });
        this.next(e || this.state.bruttolohn);
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    getLimitsIdx = () => {
        const chosenDate = this.props.userDataWI.RetirementStepsUnsorted[this.props.userDataWI.RetirementStepsUnsorted.length - 1];
        let index = 0;
        console.log('getLimitsIdx', this.props.userDataWI);
        this.props.userDataWI.partialPensionLimits.possibleRetirementStepList?.forEach((el, idx) => {
            console.log('inside for', el.startDate, el.endDate, chosenDate, parseDate(chosenDate, "dateObject"), this.props.userDataWI.RetirementSteps);
            if (parseDate(chosenDate, "dateObject") > parseDate(el.startDate, "dateObject") &&
                parseDate(chosenDate, "dateObject") < parseDate(el.endDate, "dateObject")) {
                index = idx;
            }
        })
        return index;
    }

    render() {
        const minVal = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
            this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumGrossSalary :
            this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumGrossSalary;
        const maxVal = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
            this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].maximumGrossSalary :
            this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].maximumGrossSalary;

        const defaultVal = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
            this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].defaultGrossSalary :
            this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].defaultGrossSalary;

        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                    <div className='flex-grow-1'></div>
                    <div className='selects' style={{ flexGrow: 1.53 }}>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />

                        </div>
                    </div>
                </div>
                <div className="main rentenant-main">
                    <FormattedMessageCustom id={translatableStrings.whatBruttolohn.id} text={this.props.intl.formatMessage(translatableStrings.whatBruttolohn)}>
                        <div className='ich-mochte stagger'></div>
                    </FormattedMessageCustom>
                    <div className='rentenanteil stagger'>
                        <Slider
                            controlled={false}
                            showButton
                            label={this.props.intl.formatMessage(translatableStrings.bruttolohn)}
                            // stepSize={5}
                            // stepSize={this.props.userDataWI.partialPensionLimits.minimumActivityRateStepSize}
                            initialValue={defaultVal.toFixed(0) || this.state.bruttolohn}
                            setPercent={this.setPercent}
                            maxVal={maxVal}
                            minVal={minVal}
                            domain={[minVal, maxVal]}
                            intl={this.props.intl}
                        />
                    </div>
                    <Button
                        className="stagger"
                        buttonType={EButtonType.BasicLink}
                        onClick={(e) => this.cancel(e)}>
                        <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                    </Button>
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataBruttolohn: (data) => dispatch(actions.setUserDataBruttolohn(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Bruttolohn);
