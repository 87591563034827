import React from 'react';
import Draggable from 'react-draggable';
import classNames from 'classnames';
import { ReactComponent as IconTrash } from '../../../assets/icons/trash.svg';
import './Grid.scss';
import Handle from '../Handle';
import { ChartSettings } from '../Chart/chartSettings';
import { months } from '../../../constants';
import { defineMessages } from 'react-intl';
import { checkIfArrayIsUnique } from '../../../utils/array';

const translatableStrings = defineMessages({
    partialPension: {
        id: 'pageChart.partialPension',
        defaultMessage: 'Teilpension'
    },
    purpose: {
        id: 'pageChart.purpose',
        defaultMessage: 'Verwendung',
    },
    list: {
        id: 'pageChart.list',
        defaultMessage: 'Liste',
    },
    close: {
        id: 'pageChart.close',
        defaultMessage: 'SCHLIESSEN',
    }
});

interface State {
    currentHandleIdx: number;
    dragging: boolean;
}

interface Props {
    ages: Array<number>;
    blockWidths: Array<any>;
    containerWidthPx: number;
    currentAge: Array<number>;
    currentMonths: Array<number>;
    currentYears: Array<number>;
    handleDrag: any;
    isEarlyPensionPresent: boolean;
    handleWrapperWidth: number;
    handlesXPos: Array<number>;
    handlesLimits: Array<any>;
    initialHandleDate: Array<any>;
    nominalRetirementDate: any;
    months: Array<number>;
    removeHandle: any;
    rerender: any;
    intl: any;
    lang: string;
    retirementPercentages: any;
    retirementSteps: any;
    startDragging: any;
    updateOnResize: any;
    yearMax: number;
    yearMin: number;
    yearRatio: number;
    yearWidths: Array<number>;
    yearWidthPx: number;
    yearWidthPxWhenNotDragging: number;
    years: Array<number>;
    descendingStepsToEnteringIndex: any;
}

export class Grid extends React.Component<Props, State> {
    private mouseStartClientX = [null, null, null, null];
    private monthStart = [null, null, null, null];
    // private initialYearFraction = [null, null, null, null];
    private toId: any;
    private firstStepYearObj: any = {};
    private nominalStepYearObj: any = {};

    constructor(props: any) {
        super(props);
        // const yearsArray1 = [];

        this.state = {
            dragging: false,
            currentHandleIdx: 0
        }
    }
    private initialWindowWidth = window.innerWidth;
    private initialWindowHeight = window.innerHeight;

    // shouldComponentUpdate(nextProps, nextState) {
    //     // const handlesNumberChanged = nextProps.handlesXPos.length !== this.props.handlesXPos.length;
    //     const handleUnchanged = nextProps.handlesXPos === this.props.handlesXPos;
    //     // const animateCounterChanged = nextProps.animateCounter > this.props.animateCounter;
    //     console.log('shouldComponentUpdate Blocksalary widthChanged', handleUnchanged);
    //     // console.log('shouldComponentUpdate Blocksalary widthChanged', widthChanged, 'animateCounterCHanged: ', this.props.animateCounter, nextProps.animateCounter, animateCounterChanged);
    //     if ( !handleUnchanged ) {
    //         console.log('shouldComponentUpdate TRUE');
    //         return true;
    //     } else {
    //         console.log('shouldComponentUpdate FALSE');
    //         return false
    //     }
    // }

    componentDidMount() {
        this.firstStepYearObj = {
            year: new Date(this.props.retirementSteps[0]).getFullYear(),
            month: new Date(this.props.retirementSteps[0]).getMonth(),
        }
        this.nominalStepYearObj = {
            year: new Date(this.props.nominalRetirementDate).getFullYear(),
            month: new Date(this.props.nominalRetirementDate).getMonth(),
        }
        console.log('Grid :: did mount', this.props, this.state, document.querySelector('.chartRight .handleWrapper'), 'firstStepYearObj: ', this.firstStepYearObj);
        window.addEventListener('resize', this.windowResized);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResized);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("Grid :: componenentDidUpdate");
        // Object.entries(this.props).forEach(([key, val]) =>
        // prevProps[key] !== val && console.log(`Prop Chartcontainer'${key}' changed from ${prevProps[key]} to ${val}`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //     prevState[key] !== val && console.log(`State Chartcontainer'${key}' changed from ${prevState[key]} to ${val}`)
        //     );
        // }
        // if (prevProps.ages !== this.props.ages) {
        // console.log('will dispatch animationfinished in grid did update, commented out');
        // (window as any).dispatchEvent((window as any).animationFinishedEvent);
        // }

        this.firstStepYearObj = {
            year: new Date(this.props.retirementSteps[0]).getFullYear(),
            month: new Date(this.props.retirementSteps[0]).getMonth(),
        }
    }

    windowResized = (e) => {
        console.log('resized', this.toId, e,
            'initialWindowWidth', this.initialWindowWidth, window.innerWidth);

        if (this.initialWindowWidth !== window.innerWidth || this.initialWindowHeight !== window.innerHeight) {
            clearTimeout(this.toId);
            this.initialWindowWidth = window.innerWidth;
            this.initialWindowHeight = window.innerHeight;
            document.body.classList.add('dragStarted');

            this.toId = setTimeout(() => {
                console.log('window resized');
                this.triggerRerendering(0, 'resize', null);
            }, 1000);
        }
    }

    handleStart = (e, dragData, idx) => {
        // prepare mobile device for vibrating, probably energy saving feature makes it not work on first invoke
        // window.navigator.vibrate(100);
        this.mouseStartClientX[idx] = dragData.x;
        this.monthStart[idx] = this.props.months[idx];
        // const birthMonth = new Date(this.props.initialHandleDate[0] as any).getMonth() + 1;
        // const initialHandleMonth = this.monthStart[idx] + 1;
        // Retirement steps cannot start earlier than at the end of the month. 
        // WHen given initial handle month we assume it is always middle of the month for visualisation purposes.
        // Hence a correction by 0.5
        // this.initialYearFraction[idx] = (initialHandleMonth - 0.5 * 0) / 12;
        document.body.classList.add('dragStarted');

        console.log(
            'offsetX: ', e.offsetX,
            'clientX', e.clientX, e.movementX, e.layerX,
            'handleStart mouseStartClientX: ', this.mouseStartClientX,
            'this.monthStart', this.monthStart,
            // 'this.initialYearFraction', this.initialYearFraction,
            'yearWidth', this.props.yearWidthPx,
            'yearWidthWhenNotdragging', this.props.yearWidthPxWhenNotDragging
        );
        this.props.startDragging(idx);
        // window.navigator.vibrate(50);

        this.setState({
            currentHandleIdx: idx,
            dragging: true
        })
    }

    handleDrag = (e, dragData, idx, isStop?) => {
        // setTimeout(() => {
        // window.navigator.vibrate(40);
        // }, 150)
        this.props.handleDrag(dragData.x, idx, isStop || false);
    }

    handleStop = (e, dragData, idx) => {
        console.log('handlestop: offsetX: ', e.offsetX, 'clientX', e.clientX, e.movementX, e.layerX, idx, 'X: ', dragData.x, this.props.ages);
        // this.mouseStartClientX[idx] = 0;
        // gsap.fromTo(this.barMain, { maxWidth: 0, width: 0 }, { maxWidth: newWidth , width: newWidth, ease: 'sine.out', duration: 0.5 });

        // const yearDelta = this.determineYearDelta(dragData.x, this.mouseStartClientX[idx], this.initialYearFraction[idx]);
        // const newMonth = this.determineMonth(dragData.x, this.mouseStartClientX[idx], this.initialYearFraction[idx]);
        this.handleDrag(e, dragData, idx, true);
        // this.props.handleDrag(dragData.x, idx, yearDelta, this.initialYearFraction, newMonth);

        this.setState({
            // currentHandleIdx: 0,
            dragging: false
        });

        setTimeout(() => {
            const deltaX = Math.abs(this.mouseStartClientX[idx] - dragData.x);

            if (
                // Math.abs(this.mouseStartClientX[idx] - dragData.x) >= this.props.yearWidthPx / 12 || 
                this.props.months[idx] !== this.props.currentMonths[idx] || 
                this.props.years[idx] !== this.props.currentYears[idx] || 
                deltaX > this.props.yearWidthPx / 11 
                || !checkIfArrayIsUnique(this.props.currentAge)
            ) {
                this.triggerRerendering(idx, 'stop', dragData.x);
            }
        }, 1500)
    }

    getMinimumBlockWidth = (xPos) => {
        const minimumBlockWidth = ChartSettings.minWidths.other;
        console.log('minimunblockwidth', xPos, minimumBlockWidth, this.props.yearWidthPx, Math.max(this.props.yearWidthPx, minimumBlockWidth, xPos));
        // return Math.max(this.props.yearWidthPx, minimumBlockWidth, xPos);
        return xPos;
    }

    triggerRerendering = (idx, source, x) => {
        console.log('rerender grid: ', idx, source, "X:", x);
        // if (source === "resize") {
        // correctedYearPos = yearPos * this.props.yearRatio;
        // this.props.rerender(correctedYearPos, idx, age, this.props.months[idx]);
        // } else {
        this.props.rerender(x, idx, source === "resize")
        //  age, this.props.months[idx]);
        // }
    }

    getYearSegments = () => {
        const array1 = [];
        const array2 = [];
        const yearAt65 = new Date(this.props.retirementSteps[0]).getFullYear();
        const yearObj0 = {
            age: this.props.ages[0],
            year: yearAt65,
            month: new Date(this.props.retirementSteps[0]).getMonth(),
        }

        for (let index = 1; index <= (this.props.ages[0] - this.props.yearMin); index++) {
            array1.unshift(
                {
                    year: yearAt65 - index,
                    age: this.props.ages[0] - index,
                    month: new Date(this.props.retirementSteps[this.props.years.indexOf(yearAt65 - index)]).getMonth()
                }
            );
        }

        for (let index = 1; index <= (this.props.yearMax - this.props.ages[0]); index++) {
            array2.push(
                {
                    year: yearAt65 + index,
                    age: this.props.ages[0] + index,
                    month: new Date(this.props.retirementSteps[this.props.years.indexOf(yearAt65 + index)]).getMonth()
                }
            );
        }
        return [...array1, yearObj0, ...array2];
    }

    render() {
        const yearSegments = this.getYearSegments();
        // console.log('nominal', this.nominalStepYearObj.month, yearSegments);
        return (
            <div className='grid'>
                <div className={classNames("gridYears", {
                    "currentYear4": this.props.handlesXPos.length === 1,
                    "currentYear2p5": this.props.handlesXPos.length === 2,
                    "currentYear2": this.props.handlesXPos.length === 3,
                    "currentYear1p5": this.props.handlesXPos.length === 4,
                    "currentYear1": this.props.handlesXPos.length === 5,
                    "earlyPension": this.props.isEarlyPensionPresent
                })}>
                    {yearSegments.map((yearObj, idx) => {
                        const flexBasis = this.props.yearWidths[idx];
                        // const flexBasis = this.getBlockWidth(yearObj.age);
                        // console.log('yearObj', yearObj, 'nominal', this.nominalStepYearObj.month);
                        return <div className={classNames('gridYear', {
                            "first": idx === 0,
                            "equals1stStep": this.firstStepYearObj.year === yearObj.year,
                            "current": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age,
                            "currentNext": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age - 1,
                            "currentNext2": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age - 2,
                            "currentNext3": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age - 3,
                            "nominalPrev": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age + 1 &&
                                this.nominalStepYearObj.year === yearObj.year + 1 &&
                                (yearSegments[idx + 1].month && this.nominalStepYearObj.month === yearSegments[idx + 1].month),
                            // "isNominal": this.nominalStepYearObj.year === yearObj.year && (yearObj.month && this.nominalStepYearObj.month === yearObj.month),
                            "isNominal": (yearObj.month && this.nominalStepYearObj.month === yearObj.month),
                            "isBirthday": (yearObj.month && this.nominalStepYearObj.month === yearObj.month + 1),
                            // "isBirthdayMonth": this.nominalStepYearObj.year === yearObj.year && (yearObj.month && this.nominalStepYearObj.month === yearObj.month),
                            "currentPrev": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age + 1,
                            "currentPrev2": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age + 2,
                            "currentPrev3": this.props.currentAge[this.state.currentHandleIdx] === yearObj.age + 3,
                            // "last": yearSegmentsLength === (this.state.currentHandleIdx + 1),
                            // "lastPrev": (this.props.currentAge[this.state.currentHandleIdx] === 69),
                            "hasHandle": this.props.currentAge.filter(age => age === yearObj.age).length === 1,
                            "has2Handles": this.props.currentAge.filter(age => age === yearObj.age).length === 2,
                            "has3Handles": this.props.currentAge.filter(age => age === yearObj.age).length === 3,
                            // "at10": this.nominalStepYearObj.month === yearObj.month - 2,
                            "at11": this.nominalStepYearObj.month === yearObj.month - 1,
                            "at12": this.nominalStepYearObj.month === yearObj.month,
                            "at1": this.nominalStepYearObj.month === yearObj.month + 1,
                            "at2": this.nominalStepYearObj.month === yearObj.month + 2,
                            // "at3": this.nominalStepYearObj.month === yearObj.month + 3,
                            // "hinted": flexBasis > 0
                        }, 
                        this.props.ages.length === 5 ? {
                            "has4Handles": this.props.currentAge.filter(age => age === yearObj.age).length === 4,
                            "has4HandlesNext1": this.props.currentAge.filter((age) => { return yearSegments[idx - 1] && age === yearSegments[idx - 1].age }).length === 4,
                            "has4HandlesNext2": this.props.currentAge.filter((age) => { return yearSegments[idx - 2] && age === yearSegments[idx - 2].age }).length === 4,
                            "has4HandlesNext3": this.props.currentAge.filter((age) => { return yearSegments[idx - 3] && age === yearSegments[idx - 3].age }).length === 4,
                        } : {}
                        )} key={`seg1${idx}`} data-age={yearObj.age} data-year={yearObj.year} data-width={flexBasis}
                        >
                            <div className="valueDot">
                                <div className="value">{yearObj.age}</div>
                                <div className={classNames('dotWrapper', {
                                })}>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                    <div className="dot"></div>
                                </div>
                                {/* <div className={classNames('dotWrapper', {
                                })}><div className="dot"></div>
                                </div>
                                <div className={classNames('dotWrapper', {
                                })}><div className="dot"></div>
                                </div> */}
                                {/* <div style={{ visibility: "hidden" }} className={classNames('dotWrapper', {
                                })}><div className="dot"></div>
                                </div> */}
                            </div>
                            <div className="tick">
                                |
                            </div>
                        </div>
                    })}
                </div>
                {this.props.handlesXPos.map((xPos, idx) => {
                        return <Draggable
                            key={`draggable${idx}`}
                            axis="x"
                            // bounds=".chartRight"
                            // disabled={this.props.draggingDisabled}
                            // bounds="parent"
                            bounds={{ left: this.props.handlesLimits[idx][0], right: this.props.handlesLimits[idx][1], top: 0, bottom: 0 }}
                            position={{ x: (xPos), y: 0 }}
                            // defaultPosition={{ x: (xPos), y: 0 }}
                            grid={[0.5, 0]}
                            scale={1}
                            onStart={(e, dragData) => this.handleStart(e, dragData, idx)}
                            onDrag={(e, dragData) => this.handleDrag(e, dragData, idx)}
                            onStop={(e, dragData) => this.handleStop(e, dragData, idx)}
                        >
                            <div
                                data-age={this.props.ages[idx]}
                                // onMouseDown={(e) => this.startManipulating(e, idx)}
                                // onMouseUp={(e) => this.stopManipulating(e)}
                                className={classNames(
                                    'handleWrapper', `handle${idx}`, {
                                    'current': idx === this.state.currentHandleIdx
                                }
                                )}
                            >
                                <Handle
                                    year={this.props.currentAge[idx] || this.props.ages[idx]}
                                />
                                <div className="text-center monthLabel font-500">
                                    {(this.props.currentMonths[idx] !== null) ? months(this.props.lang)[this.props.currentMonths[idx]] : null}
                                    {" "}
                                    {(this.props.currentYears[idx] !== null) ? this.props.currentYears[idx] : null}
                                    <span className="font-300 mt-1 d-none d-md-block">
                                        {idx > 0 ? (this.props.intl.formatMessage(translatableStrings.partialPension) + " " + this.props.retirementPercentages[this.props.descendingStepsToEnteringIndex[idx]] + "%") : "Pensionsstart"}
                                    </span>
                                </div>
                                <div className="remove text-center" onTouchStart={(e) => {
                                    e.stopPropagation();
                                    console.log('removehandle', this.props.retirementSteps, this.props.retirementSteps[idx - 1]);
                                    this.props.removeHandle(this.props.retirementSteps[idx], idx);
                                }} onMouseDown={(e) => {
                                    e.stopPropagation();
                                    console.log('removehandle', this.props.retirementSteps, this.props.retirementSteps[idx - 1]);
                                    this.props.removeHandle(this.props.retirementSteps[idx], idx);
                                }}>
                                    <IconTrash />
                                </div>
                            </div>
                            {/* </div> */}
                        </Draggable>
                    })
                }
                {/* <Button
                    className="button ml-auto mt-0 w-auto zuruckSetzenButton invisibleSoft"
                    onClick={() => this.props.resetControls()}
                    buttonType={EButtonType.BasicLink}
                >
                    Zurücksetzen
                        </Button> */}
            </div>
        );
    }
}