import React from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../../../reducers/index';
import './BlockPartialPension.scss';
import { ChartSettings } from '../chartSettings';
import classNames from 'classnames';
import gsap from 'gsap';
import { formatNumber } from '../../../../utils/react';
import { animationConfig } from '../../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    partialRetirement: {
        id: 'pageChart.partialRetirement',
        defaultMessage: 'Teilpensionierung'
    },
    salary: {
        id: 'pageChart.salary',
        defaultMessage: 'Lohn'
    },
    altersRente: {
        id: 'pageChart.altersRente',
        defaultMessage: 'Altersrente'
    },
});
interface State {
    animateCounter: number;
}

interface Props {
    // activateSegment: any;
    // activeSegment: any;
    animateCounter: number;
    basicPension: number;
    intl: any;
    isEarlyPensionPresent: boolean;
    maxVal: number;
    partialPensionsNo: number;
    salary: number;
    segment: number;
    totalIncome: number;
    totalPension: number;
    width: number;
    wrapInTooltip: any;
}

export class BlockPartialPension extends React.Component<Props, State> {
    private barPartialPensionLabel: any;
    private barPartialPension: any;
    private partialTimeline1 = null;

    constructor(props: any) {
        super(props);
        console.log("BlockPartialPension constructor called");

        this.state = {
            animateCounter: 0
        };
    }

    componentDidMount() {
        console.log('BlockPartialPension app did mount', this.props);
        this.preparePartialPensionTimeline();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("BlockPartialPension componenentDidUpdate", prevProps, prevState, snapshot);
        if (this.props.segment - 1 === this.props.partialPensionsNo && this.props.animateCounter > 1) {
            console.log('will dispatch lastPartialPensionAnimated in partial pension on update');
            setTimeout(() => {
                (window as any).dispatchEvent((window as any).lastPartialPensionAnimated);
            }, 300);
        }
        // this.animateIn();
    }

    preparePartialPensionTimeline = () => {
        console.log('partialPension prepare available height');
        let startSec = (animationConfig().startSec + 1) * animationConfig().scale;
        this.partialTimeline1 = gsap.timeline({ paused: true });
        this.partialTimeline1 && this.partialTimeline1.pause();

        this.partialTimeline1
            .fromTo([
                document.querySelector(`.blockPartialPension.partial${this.props.segment}`),
                this.barPartialPension
            ], { autoAlpha: 0, duration: 0 }, { autoAlpha: 1 }, startSec)
            .from([
                this.barPartialPension
            ], { maxHeight: 0, ease: animationConfig().easing }, startSec + 1)
            .fromTo([
                document.querySelector(`.blockPartialPension.partial${this.props.segment} #barPartialPension .blockTitle`),
                document.querySelector(`.blockPartialPension.partial${this.props.segment} #barPartialPension .blockValue`)
            ], { autoAlpha: 0, y: -40 }, { autoAlpha: 1, y: 0 }, startSec + 1.5)
            .from([
                document.querySelector(`.blockPartialPension.partial${this.props.segment} #barPartialPensionSalaryWrapper`),
            ], { maxHeight: 0, ease: animationConfig().easing }, startSec + 1.8)
            .fromTo([
                document.querySelector(`.blockPartialPension.partial${this.props.segment} #barPartialPensionSalary .blockTitle`),
                document.querySelector(`.blockPartialPension.partial${this.props.segment} #barPartialPensionSalary .blockValue`)
            ], { autoAlpha: 0, y: -40 }, { autoAlpha: 1, y: 0 }, startSec + 2.1)
            .fromTo([document.querySelector(`.blockPartialPension.partial${this.props.segment} .blockLabel`)],
                { y: -40, autoAlpha: 0 }, { autoAlpha: 1, y: 0 }, startSec + 1.4)

        this.partialTimeline1.play();


        this.partialTimeline1.eventCallback("onStart", () => {
            console.log('oncomplete partialTimeline1 animateCounter', this.props.animateCounter, this.props.partialPensionsNo, this.props.segment);
            if (this.props.segment - 1 === this.props.partialPensionsNo) {
                console.log('will dispatch lastPartialPensionAnimated in partial pension on complete');
                setTimeout(() => {
                    (window as any).dispatchEvent((window as any).lastPartialPensionAnimated);
                }, 1500 * animationConfig().scale);
            }
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const widthChanged = nextProps.width !== this.props.width;
        const animateCounterIncreased = nextProps.animateCounter > this.props.animateCounter;
        const maxValChanged = nextProps.maxVal !== this.props.maxVal;
        const basicPensionChanged = nextProps.basicPension !== this.props.basicPension;
        const totalIncomeChanged = nextProps.totalIncome !== this.props.totalIncome;
        // const animateCounterChanged = nextProps.animateCounter > this.props.animateCounter;
        console.log('shouldComponentUpdate Blocksalary widthChanged', this.props.basicPension, this.props.totalIncome);
        // console.log('shouldComponentUpdate Blocksalary widthChanged', widthChanged, 'animateCounterCHanged: ', this.props.animateCounter, nextProps.animateCounter, animateCounterChanged);
        if (animateCounterIncreased || maxValChanged || basicPensionChanged || totalIncomeChanged) {
            // console.log('shouldComponentUpdate TRUE');
            // this.animateIn(this.props.width, nextProps.width);
            if (nextProps.segment - 1 === nextProps.partialPensionsNo && this.props.animateCounter < 1) {
                console.log('will dispatch lastPartialPensionAnimated in blockpartialpension shouldcomponentupdate');
                setTimeout(() => {
                    (window as any).dispatchEvent((window as any).lastPartialPensionAnimated);
                }, 150)
            }
            return true;
        } else {
            console.log('shouldComponentUpdate FALSE BlockPartialPension');
            return false
        }
    }

    tooltip = (val, realValueToPxRatio) => {
        // console.log('tooltip partial perc: ', perc);
        if (val * realValueToPxRatio < 55 || this.props.width < ChartSettings.minBlockWidth1) {
            return true;
        } else {
            return false;
        }
    }

    render() {
        const availableHeight = document.querySelector('.chartRightTop').clientHeight - 75;
        const realValueToPxRatio = availableHeight / this.props.maxVal;
        console.log('render partialpension availableheig', availableHeight, realValueToPxRatio);
        const enoughHeightFor1RowSalary = this.props.salary * realValueToPxRatio >= 30;
        const enoughHeightFor1RowPension = this.props.basicPension * realValueToPxRatio >= 30;
        return (
            <div className={classNames("blockPartialPension blockPadding blockFullHeight", 'partial' + this.props.segment, {
            })}
                style={{
                    maxWidth: this.props.width,
                    opacity: 0
                }}
            // onClick={() => this.props.activateSegment(this.props.segment - 1)}
            >
                {/* removed blockElasticHeight class, any regressions? */}
                <div id="barPartialPensionLabelWrapper"
                    className="blockLabelWrapper"
                    ref={c => this.barPartialPensionLabel = c}
                    style={{
                        height: this.props.maxVal - this.props.basicPension - this.props.salary,
                    }}>

                    <div id="barPartialPensionLabel"
                        className="blockLabel"
                        ref={c => this.barPartialPensionLabel = c}
                        style={{
                            height: this.props.maxVal - this.props.salary - this.props.basicPension
                        }}>
                        <FormattedMessageCustom id={translatableStrings.partialRetirement.id} text={this.props.intl.formatMessage(translatableStrings.partialRetirement)}>
                            <p
                                className={classNames("blockTitle", {
                                    "invisible": this.props.segment > 2
                                })}
                            ></p>
                        </FormattedMessageCustom>
                        <p className="blockValue">CHF {formatNumber(this.props.salary + this.props.basicPension)}</p>
                    </div>
                </div>

                {this.props.wrapInTooltip(<div
                    id="barPartialPensionSalaryWrapper"
                    className={classNames(
                        "blockWrapper blockStyleRounded", {
                        "inTooltip": this.tooltip(this.props.salary, realValueToPxRatio),
                        "enoughHeightFor1Row": enoughHeightFor1RowSalary,
                    }
                    )}
                    style={{
                        height: this.props.salary,
                        maxHeight: this.props.salary * realValueToPxRatio
                    }}>
                    <div id="barPartialPensionSalary"
                        className="blockElasticHeight blockStyleRounded"
                        style={{
                            height: '100%',
                            maxHeight: ChartSettings.maxBlockHeight * 6,
                        }}>
                        <div className="blockLabels"
                            style={{
                                // visibility: 'hidden'
                            }}
                        >
                            <FormattedMessageCustom id={translatableStrings.salary.id} text={this.props.intl.formatMessage(translatableStrings.salary)}>
                                <p className="blockTitle">
                                </p>
                            </FormattedMessageCustom>
                            <p className="blockValue">CHF {formatNumber(this.props.salary)}</p>
                        </div>
                    </div>
                </div>,
                    [
                        <FormattedMessageCustom id={translatableStrings.salary.id} text={this.props.intl.formatMessage(translatableStrings.salary)}> <p className="blockTitle"> </p> </FormattedMessageCustom>,
                        !enoughHeightFor1RowSalary &&  <p className="blockValue">CHF {formatNumber(this.props.salary)}</p>
                    ],
                    this.tooltip(this.props.salary, realValueToPxRatio)
                )}

                {this.props.wrapInTooltip(<div id="barPartialPension" className={classNames(
                    "blockElasticHeight", {
                    "enoughHeightFor1Row": enoughHeightFor1RowPension,
                    "pointer": this.props.basicPension < 850,
                    "inTooltip": this.tooltip(this.props.basicPension, realValueToPxRatio)
                }
                )}
                    ref={c => this.barPartialPension = c} style={{
                        height: this.props.basicPension,
                        maxHeight: this.props.basicPension * realValueToPxRatio,
                        opacity: this.props.animateCounter < 1 ? 0 : 1,
                    }}>
                    <FormattedMessageCustom id={translatableStrings.altersRente.id} text={this.props.intl.formatMessage(translatableStrings.altersRente)}>
                            <p className="blockTitle"></p>
                        </FormattedMessageCustom>
                    <p className="blockValue">CHF {formatNumber(this.props.basicPension)}</p>
                </div>,
                    [
                        <FormattedMessageCustom id={translatableStrings.altersRente.id} text={this.props.intl.formatMessage(translatableStrings.altersRente)}>
                            <p className="blockTitle"></p>
                        </FormattedMessageCustom>,
                        !enoughHeightFor1RowPension && <p className="blockValue">CHF {formatNumber(this.props.basicPension)}</p>
                    ],
                    this.tooltip(this.props.basicPension, realValueToPxRatio)
                )
                }
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockPartialPension);