import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import gsap from 'gsap';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../Anliegen/Wunschberechnung.scss';
import SelectList from "../../../components/Sidebar/SelectList";
// import HelpIcon from '../../../assets/icons/Help Icon.svg';

interface State {
    // login: string;
    // password: string;
    // passwordType: string;
    // rememberMe: boolean;
    // dropdownOpened: boolean;
}

interface Props {
    actions: any;
    history: any;
    userDataWI: any;
    intl: any;
    setUserDataDetaillierung: any;
    // clearData: any;
}

class Wunschberechnung extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        // this.state = {
        //     dropdownOpened: true
        // }
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    async setUserDataDetaillierung(data) {
        await this.props.setUserDataDetaillierung(data);
    }

    componentDidMount() {
        document.body.classList.add('detaillierung');
        document.querySelector('.routeContainer').classList.remove('overflowHidden');
        // document.body.classList.add('animationFinished');
    }

    componentWillUnmount() {
        document.body.classList.remove('detaillierung')
    }

    next = (e) => {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();


        this.setUserDataDetaillierung(e.currentTarget.id);
        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .to(document.querySelector('#logo1 ~ .dropdown'), { maxHeight: 50, duration: 0, delay: 0 })
                .fromTo(document.querySelectorAll('.stagger'),
                    { autoAlpha: 1 },
                    {
                        // autoAlpha: 0,
                        y: -900,
                        delay: 0,
                        duration: 1,
                        stagger: {
                            each: .1,
                            ease: 'linear'
                        }
                    })
        }

        this.timeline.play();

        setTimeout(() => {

            this.props.history.push({
                pathname: `/geburtsdatum`
            })
        }, 1000)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                    <div className='flex-grow-1'>
                        
                    </div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
                </div>
                <div className="main detaill-main">
                    <div className='ich-mochte stagger'>
                        Insbesondere möchte ich herausfinden…
                </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques_mobile' /> */}
                    <div>
                        <div
                            className='elem stagger'
                            id='Common'
                            onClick={this.next}
                        >
                            …welche Rente ich bei der Pensionierung erwarten kann.
                    </div>
                        <div
                            className='elem stagger'
                            id='Common'
                            onClick={this.next}
                        >
                            …per wann ich mich frühestens pensionieren lassen kann.
                        </div>
                        <div
                            className='elem stagger'
                            id='Common'
                            onClick={this.next}
                        >
                            …wie ich die Rente durch Einkaufsbeträge beeinflussen kann.
                        </div>
                        <div
                            className='elem stagger'
                            id='Common'
                            onClick={this.next}
                        >
                            …wie viel Geld ich nach der Pensionierung ungefähr brauchen werde.
                        </div>
                    </div>
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataDetaillierung: (data) => dispatch(actions.setUserDataDetaillierung(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wunschberechnung);
