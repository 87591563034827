export const Postcode = [{"postleitzahl": "1000 Lausanne"},
 {"postleitzahl": "1003 Lausanne"},
 {"postleitzahl": "1004 Lausanne"},
 {"postleitzahl": "1005 Lausanne"},
 {"postleitzahl": "1006 Lausanne"},
 {"postleitzahl": "1007 Lausanne"},
 {"postleitzahl": "1008 Jouxtens-Mézery"},
 {"postleitzahl": "1008 Prilly"},
 {"postleitzahl": "1009 Pully"},
 {"postleitzahl": "1010 Lausanne"},
 {"postleitzahl": "1011 Lausanne"},
 {"postleitzahl": "1012 Lausanne"},
 {"postleitzahl": "1015 Lausanne"},
 {"postleitzahl": "1018 Lausanne"},
 {"postleitzahl": "1020 Renens VD"},
 {"postleitzahl": "1022 Chavannes-près-Renens"},
 {"postleitzahl": "1023 Crissier"},
 {"postleitzahl": "1024 Ecublens VD"},
 {"postleitzahl": "1025 St-Sulpice VD"},
 {"postleitzahl": "1026 Denges"},
 {"postleitzahl": "1026 Echandens"},
 {"postleitzahl": "1027 Lonay"},
 {"postleitzahl": "1028 Préverenges"},
 {"postleitzahl": "1029 Villars-Ste-Croix"},
 {"postleitzahl": "1030 Bussigny-près-Lausanne"},
 {"postleitzahl": "1031 Mex VD"},
 {"postleitzahl": "1032 Romanel-sur-Lausanne"},
 {"postleitzahl": "1033 Cheseaux-sur-Lausanne"},
 {"postleitzahl": "1034 Boussens"},
 {"postleitzahl": "1035 Bournens"},
 {"postleitzahl": "1036 Sullens"},
 {"postleitzahl": "1037 Etagnières"},
 {"postleitzahl": "1038 Bercher"},
 {"postleitzahl": "1040 St-Barthélemy VD"},
 {"postleitzahl": "1040 Villars-le-Terroir"},
 {"postleitzahl": "1040 Echallens"},
 {"postleitzahl": "1041 Dommartin"},
 {"postleitzahl": "1041 Poliez-Pittet"},
 {"postleitzahl": "1041 Bottens"},
 {"postleitzahl": "1041 Naz"},
 {"postleitzahl": "1041 Montaubion-Chardonney"},
 {"postleitzahl": "1041 Poliez-le-Grand"},
 {"postleitzahl": "1042 Bettens"},
 {"postleitzahl": "1042 Bioley-Orjulaz"},
 {"postleitzahl": "1042 Assens"},
 {"postleitzahl": "1043 Sugnens"},
 {"postleitzahl": "1044 Fey"},
 {"postleitzahl": "1045 Ogens"},
 {"postleitzahl": "1046 Rueyres"},
 {"postleitzahl": "1047 Oppens"},
 {"postleitzahl": "1052 Le Mont-sur-Lausanne"},
 {"postleitzahl": "1053 Bretigny-sur-Morrens"},
 {"postleitzahl": "1053 Cugy VD"},
 {"postleitzahl": "1054 Morrens VD"},
 {"postleitzahl": "1055 Froideville"},
 {"postleitzahl": "1058 Villars-Tiercelin"},
 {"postleitzahl": "1059 Peney-le-Jorat"},
 {"postleitzahl": "1061 Villars-Mendraz"},
 {"postleitzahl": "1062 Sottens"},
 {"postleitzahl": "1063 Peyres-Possens"},
 {"postleitzahl": "1063 Boulens"},
 {"postleitzahl": "1063 Chapelle-sur-Moudon"},
 {"postleitzahl": "1063 Martherenges"},
 {"postleitzahl": "1066 Epalinges"},
 {"postleitzahl": "1068 Les Monts-de-Pully"},
 {"postleitzahl": "1070 Puidoux"},
 {"postleitzahl": "1071 Rivaz"},
 {"postleitzahl": "1071 St-Saphorin (Lavaux)"},
 {"postleitzahl": "1071 Chexbres"},
 {"postleitzahl": "1072 Forel (Lavaux)"},
 {"postleitzahl": "1073 Mollie-Margot"},
 {"postleitzahl": "1073 Savigny"},
 {"postleitzahl": "1076 Ferlens VD"},
 {"postleitzahl": "1077 Servion"},
 {"postleitzahl": "1078 Essertes"},
 {"postleitzahl": "1080 Les Cullayes"},
 {"postleitzahl": "1081 Montpreveyres"},
 {"postleitzahl": "1082 Corcelles-le-Jorat"},
 {"postleitzahl": "1083 Mézières VD"},
 {"postleitzahl": "1084 Carrouge VD"},
 {"postleitzahl": "1085 Vulliens"},
 {"postleitzahl": "1088 Ropraz"},
 {"postleitzahl": "1090 La Croix (Lutry)"},
 {"postleitzahl": "1091 Aran"},
 {"postleitzahl": "1091 Chenaux"},
 {"postleitzahl": "1091 Grandvaux"},
 {"postleitzahl": "1092 Belmont-sur-Lausanne"},
 {"postleitzahl": "1093 La Conversion"},
 {"postleitzahl": "1094 Paudex"},
 {"postleitzahl": "1095 Lutry"},
 {"postleitzahl": "1096 Villette (Lavaux)"},
 {"postleitzahl": "1096 Cully"},
 {"postleitzahl": "1097 Riex"},
 {"postleitzahl": "1098 Epesses"},
 {"postleitzahl": "1110 Morges"},
 {"postleitzahl": "1112 Echichens"},
 {"postleitzahl": "1113 St-Saphorin-sur-Morges"},
 {"postleitzahl": "1114 Colombier VD"},
 {"postleitzahl": "1115 Vullierens"},
 {"postleitzahl": "1116 Cottens VD"},
 {"postleitzahl": "1117 Grancy"},
 {"postleitzahl": "1121 Bremblens"},
 {"postleitzahl": "1122 Romanel-sur-Morges"},
 {"postleitzahl": "1123 Aclens"},
 {"postleitzahl": "1124 Gollion"},
 {"postleitzahl": "1125 Monnaz"},
 {"postleitzahl": "1126 Vaux-sur-Morges"},
 {"postleitzahl": "1127 Clarmont"},
 {"postleitzahl": "1128 Reverolle"},
 {"postleitzahl": "1131 Tolochenaz"},
 {"postleitzahl": "1132 Lully VD"},
 {"postleitzahl": "1134 Vufflens-le-Château"},
 {"postleitzahl": "1134 Chigny"},
 {"postleitzahl": "1135 Denens"},
 {"postleitzahl": "1136 Bussy-Chardonney"},
 {"postleitzahl": "1141 Sévery"},
 {"postleitzahl": "1142 Pampigny"},
 {"postleitzahl": "1143 Apples"},
 {"postleitzahl": "1144 Ballens"},
 {"postleitzahl": "1145 Bière"},
 {"postleitzahl": "1146 Mollens VD"},
 {"postleitzahl": "1147 Montricher"},
 {"postleitzahl": "1148 Cuarnens"},
 {"postleitzahl": "1148 Moiry VD"},
 {"postleitzahl": "1148 La Praz"},
 {"postleitzahl": "1148 Mont-la-Ville"},
 {"postleitzahl": "1148 Chavannes-le-Veyron"},
 {"postleitzahl": "1148 Mauraz"},
 {"postleitzahl": "1148 Villars-Bozon"},
 {"postleitzahl": "1148 La Coudre"},
 {"postleitzahl": "1148 L'Isle"},
 {"postleitzahl": "1149 Berolle"},
 {"postleitzahl": "1162 St-Prex"},
 {"postleitzahl": "1163 Etoy"},
 {"postleitzahl": "1164 Buchillon"},
 {"postleitzahl": "1165 Allaman"},
 {"postleitzahl": "1166 Perroy"},
 {"postleitzahl": "1167 Lussy-sur-Morges"},
 {"postleitzahl": "1168 Villars-sous-Yens"},
 {"postleitzahl": "1169 Yens"},
 {"postleitzahl": "1170 Aubonne"},
 {"postleitzahl": "1172 Bougy-Villars"},
 {"postleitzahl": "1173 Féchy"},
 {"postleitzahl": "1174 Montherod"},
 {"postleitzahl": "1174 Pizy"},
 {"postleitzahl": "1175 Lavigny"},
 {"postleitzahl": "1176 St-Livres"},
 {"postleitzahl": "1180 Tartegnin"},
 {"postleitzahl": "1180 Rolle"},
 {"postleitzahl": "1182 Gilly"},
 {"postleitzahl": "1183 Bursins"},
 {"postleitzahl": "1184 Vinzel"},
 {"postleitzahl": "1184 Luins"},
 {"postleitzahl": "1185 Mont-sur-Rolle"},
 {"postleitzahl": "1186 Essertines-sur-Rolle"},
 {"postleitzahl": "1187 St-Oyens"},
 {"postleitzahl": "1188 Gimel"},
 {"postleitzahl": "1188 St-George"},
 {"postleitzahl": "1189 Saubraz"},
 {"postleitzahl": "1195 Dully"},
 {"postleitzahl": "1195 Bursinel"},
 {"postleitzahl": "1196 Gland"},
 {"postleitzahl": "1197 Prangins"},
 {"postleitzahl": "1200 Genève"},
 {"postleitzahl": "1201 Genève"},
 {"postleitzahl": "1202 Genève"},
 {"postleitzahl": "1203 Genève"},
 {"postleitzahl": "1204 Genève"},
 {"postleitzahl": "1205 Genève"},
 {"postleitzahl": "1206 Genève"},
 {"postleitzahl": "1207 Genève"},
 {"postleitzahl": "1208 Genève"},
 {"postleitzahl": "1209 Genève"},
 {"postleitzahl": "1212 Grand-Lancy"},
 {"postleitzahl": "1213 Petit-Lancy"},
 {"postleitzahl": "1213 Onex"},
 {"postleitzahl": "1214 Vernier"},
 {"postleitzahl": "1215 Genève 15 Aéroport"},
 {"postleitzahl": "1216 Cointrin"},
 {"postleitzahl": "1217 Meyrin"},
 {"postleitzahl": "1218 Le Grand-Saconnex"},
 {"postleitzahl": "1219 Le Lignon"},
 {"postleitzahl": "1219 Aïre"},
 {"postleitzahl": "1219 Châtelaine"},
 {"postleitzahl": "1220 Les Avanchets"},
 {"postleitzahl": "1222 Vésenaz"},
 {"postleitzahl": "1223 Cologny"},
 {"postleitzahl": "1224 Chêne-Bougeries"},
 {"postleitzahl": "1225 Chêne-Bourg"},
 {"postleitzahl": "1226 Thônex"},
 {"postleitzahl": "1227 Les Acacias"},
 {"postleitzahl": "1227 Carouge GE"},
 {"postleitzahl": "1228 Plan-les-Ouates"},
 {"postleitzahl": "1231 Conches"},
 {"postleitzahl": "1232 Confignon"},
 {"postleitzahl": "1233 Bernex"},
 {"postleitzahl": "1234 Vessy"},
 {"postleitzahl": "1236 Cartigny"},
 {"postleitzahl": "1237 Avully"},
 {"postleitzahl": "1239 Collex"},
 {"postleitzahl": "1241 Puplinge"},
 {"postleitzahl": "1242 Satigny"},
 {"postleitzahl": "1243 Presinge"},
 {"postleitzahl": "1244 Choulex"},
 {"postleitzahl": "1245 Collonge-Bellerive"},
 {"postleitzahl": "1246 Corsier GE"},
 {"postleitzahl": "1247 Anières"},
 {"postleitzahl": "1248 Hermance"},
 {"postleitzahl": "1251 Gy"},
 {"postleitzahl": "1252 Meinier"},
 {"postleitzahl": "1253 Vandoeuvres"},
 {"postleitzahl": "1254 Jussy"},
 {"postleitzahl": "1255 Veyrier"},
 {"postleitzahl": "1256 Troinex"},
 {"postleitzahl": "1257 La Croix-de-Rozon"},
 {"postleitzahl": "1258 Perly"},
 {"postleitzahl": "1260 Nyon"},
 {"postleitzahl": "1261 Longirod"},
 {"postleitzahl": "1261 Marchissy"},
 {"postleitzahl": "1261 Le Vaud"},
 {"postleitzahl": "1262 Eysins"},
 {"postleitzahl": "1263 Crassier"},
 {"postleitzahl": "1264 St-Cergue"},
 {"postleitzahl": "1265 La Cure"},
 {"postleitzahl": "1266 Duillier"},
 {"postleitzahl": "1267 Vich"},
 {"postleitzahl": "1267 Coinsins"},
 {"postleitzahl": "1268 Burtigny"},
 {"postleitzahl": "1268 Begnins"},
 {"postleitzahl": "1269 Bassins"},
 {"postleitzahl": "1270 Trélex"},
 {"postleitzahl": "1271 Givrins"},
 {"postleitzahl": "1272 Genolier"},
 {"postleitzahl": "1273 Le Muids"},
 {"postleitzahl": "1273 Arzier"},
 {"postleitzahl": "1274 Grens"},
 {"postleitzahl": "1274 Signy"},
 {"postleitzahl": "1275 Chéserex"},
 {"postleitzahl": "1276 Gingins"},
 {"postleitzahl": "1277 Arnex-sur-Nyon"},
 {"postleitzahl": "1277 Borex"},
 {"postleitzahl": "1278 La Rippe"},
 {"postleitzahl": "1279 Bogis-Bossey"},
 {"postleitzahl": "1279 Chavannes-de-Bogis"},
 {"postleitzahl": "1281 Russin"},
 {"postleitzahl": "1283 Dardagny"},
 {"postleitzahl": "1283 La Plaine"},
 {"postleitzahl": "1284 Chancy"},
 {"postleitzahl": "1285 Athenaz (Avusy)"},
 {"postleitzahl": "1286 Soral"},
 {"postleitzahl": "1287 Laconnex"},
 {"postleitzahl": "1288 Aire-la-Ville"},
 {"postleitzahl": "1290 Versoix"},
 {"postleitzahl": "1290 Chavannes-des-Bois"},
 {"postleitzahl": "1291 Commugny"},
 {"postleitzahl": "1292 Chambésy"},
 {"postleitzahl": "1293 Bellevue"},
 {"postleitzahl": "1294 Genthod"},
 {"postleitzahl": "1295 Mies"},
 {"postleitzahl": "1295 Tannay"},
 {"postleitzahl": "1296 Coppet"},
 {"postleitzahl": "1297 Founex"},
 {"postleitzahl": "1298 Céligny"},
 {"postleitzahl": "1299 Crans-près-Céligny"},
 {"postleitzahl": "1302 Vufflens-la-Ville"},
 {"postleitzahl": "1303 Penthaz"},
 {"postleitzahl": "1304 Senarclens"},
 {"postleitzahl": "1304 Dizy"},
 {"postleitzahl": "1304 Allens"},
 {"postleitzahl": "1304 Cossonay-Ville"},
 {"postleitzahl": "1305 Penthalaz"},
 {"postleitzahl": "1306 Daillens"},
 {"postleitzahl": "1307 Lussery-Villars"},
 {"postleitzahl": "1308 La Chaux (Cossonay)"},
 {"postleitzahl": "1312 Eclépens"},
 {"postleitzahl": "1313 Ferreyres"},
 {"postleitzahl": "1315 La Sarraz"},
 {"postleitzahl": "1316 Chevilly"},
 {"postleitzahl": "1317 Orny"},
 {"postleitzahl": "1318 Pompaples"},
 {"postleitzahl": "1321 Arnex-sur-Orbe"},
 {"postleitzahl": "1322 Croy"},
 {"postleitzahl": "1323 Romainmôtier"},
 {"postleitzahl": "1324 Premier"},
 {"postleitzahl": "1325 Vaulion"},
 {"postleitzahl": "1326 Juriens"},
 {"postleitzahl": "1329 Bretonnières"},
 {"postleitzahl": "1337 Vallorbe"},
 {"postleitzahl": "1338 Ballaigues"},
 {"postleitzahl": "1341 Orient"},
 {"postleitzahl": "1342 Le Pont"},
 {"postleitzahl": "1343 Les Charbonnières"},
 {"postleitzahl": "1344 L'Abbaye"},
 {"postleitzahl": "1345 Le Lieu"},
 {"postleitzahl": "1346 Les Bioux"},
 {"postleitzahl": "1347 Le Solliat"},
 {"postleitzahl": "1347 Le Sentier"},
 {"postleitzahl": "1348 Le Brassus"},
 {"postleitzahl": "1350 Orbe"},
 {"postleitzahl": "1352 Agiez"},
 {"postleitzahl": "1353 Bofflens"},
 {"postleitzahl": "1354 Montcherand"},
 {"postleitzahl": "1355 Sergey"},
 {"postleitzahl": "1355 L'Abergement"},
 {"postleitzahl": "1356 Les Clées"},
 {"postleitzahl": "1356 La Russille"},
 {"postleitzahl": "1357 Lignerolle"},
 {"postleitzahl": "1358 Valeyres-sous-Rances"},
 {"postleitzahl": "1372 Bavois"},
 {"postleitzahl": "1373 Chavornay"},
 {"postleitzahl": "1374 Corcelles-sur-Chavornay"},
 {"postleitzahl": "1375 Penthéréaz"},
 {"postleitzahl": "1376 Eclagnens"},
 {"postleitzahl": "1376 Goumoens-le-Jux"},
 {"postleitzahl": "1376 Goumoens-la-Ville"},
 {"postleitzahl": "1377 Oulens-sous-Echallens"},
 {"postleitzahl": "1400 Yverdon-les-Bains"},
 {"postleitzahl": "1400 Cheseaux-Noréaz"},
 {"postleitzahl": "1404 Cuarny"},
 {"postleitzahl": "1404 Villars-Epeney"},
 {"postleitzahl": "1405 Pomy"},
 {"postleitzahl": "1406 Cronay"},
 {"postleitzahl": "1407 Bioley-Magnoux"},
 {"postleitzahl": "1407 Gossens"},
 {"postleitzahl": "1407 Mézery-près-Donneloye"},
 {"postleitzahl": "1407 Donneloye"},
 {"postleitzahl": "1408 Prahins"},
 {"postleitzahl": "1409 Chanéaz"},
 {"postleitzahl": "1410 Prévondavaux"},
 {"postleitzahl": "1410 St-Cierges"},
 {"postleitzahl": "1410 Denezy"},
 {"postleitzahl": "1410 Correvon"},
 {"postleitzahl": "1410 Thierrens"},
 {"postleitzahl": "1412 Valeyres-sous-Ursins"},
 {"postleitzahl": "1412 Ursins"},
 {"postleitzahl": "1413 Orzens"},
 {"postleitzahl": "1415 Molondin"},
 {"postleitzahl": "1415 Démoret"},
 {"postleitzahl": "1416 Pailly"},
 {"postleitzahl": "1417 Epautheyres"},
 {"postleitzahl": "1417 Essertines-sur-Yverdon"},
 {"postleitzahl": "1418 Vuarrens"},
 {"postleitzahl": "1420 Fiez"},
 {"postleitzahl": "1421 Fontaines-sur-Grandson"},
 {"postleitzahl": "1421 Grandevent"},
 {"postleitzahl": "1422 Grandson"},
 {"postleitzahl": "1423 Fontanezier"},
 {"postleitzahl": "1423 Romairon"},
 {"postleitzahl": "1423 Vaugondry"},
 {"postleitzahl": "1423 Villars-Burquin"},
 {"postleitzahl": "1424 Champagne"},
 {"postleitzahl": "1425 Onnens VD"},
 {"postleitzahl": "1426 Corcelles-près-Concise"},
 {"postleitzahl": "1426 Concise"},
 {"postleitzahl": "1427 Bonvillars"},
 {"postleitzahl": "1428 Mutrux"},
 {"postleitzahl": "1428 Provence"},
 {"postleitzahl": "1429 Giez"},
 {"postleitzahl": "1430 Orges"},
 {"postleitzahl": "1431 Vugelles-La Mothe"},
 {"postleitzahl": "1431 Novalles"},
 {"postleitzahl": "1432 Belmont-sur-Yverdon"},
 {"postleitzahl": "1432 Gressy"},
 {"postleitzahl": "1433 Suchy"},
 {"postleitzahl": "1434 Ependes VD"},
 {"postleitzahl": "1435 Essert-Pittet"},
 {"postleitzahl": "1436 Treycovagnes"},
 {"postleitzahl": "1436 Chamblon"},
 {"postleitzahl": "1437 Suscévaz"},
 {"postleitzahl": "1438 Mathod"},
 {"postleitzahl": "1439 Rances"},
 {"postleitzahl": "1441 Valeyres-sous-Montagny"},
 {"postleitzahl": "1442 Montagny-près-Yverdon"},
 {"postleitzahl": "1443 Champvent"},
 {"postleitzahl": "1443 Essert-sous-Champvent"},
 {"postleitzahl": "1443 Villars-sous-Champvent"},
 {"postleitzahl": "1445 Vuiteboeuf"},
 {"postleitzahl": "1446 Baulmes"},
 {"postleitzahl": "1450 La Sagne (Ste-Croix)"},
 {"postleitzahl": "1450 Le Château-de-Ste-Croix"},
 {"postleitzahl": "1450 Ste-Croix"},
 {"postleitzahl": "1452 Les Rasses"},
 {"postleitzahl": "1453 Mauborget"},
 {"postleitzahl": "1453 Bullet"},
 {"postleitzahl": "1454 La Vraconnaz"},
 {"postleitzahl": "1454 L'Auberson"},
 {"postleitzahl": "1462 Yvonand"},
 {"postleitzahl": "1463 Rovray"},
 {"postleitzahl": "1464 Chêne-Pâquier"},
 {"postleitzahl": "1464 Chavannes-le-Chêne"},
 {"postleitzahl": "1468 Cheyres"},
 {"postleitzahl": "1470 Bollion"},
 {"postleitzahl": "1470 Lully FR"},
 {"postleitzahl": "1470 Seiry"},
 {"postleitzahl": "1470 Estavayer-le-Lac"},
 {"postleitzahl": "1473 Font"},
 {"postleitzahl": "1473 Châtillon FR"},
 {"postleitzahl": "1474 Châbles FR"},
 {"postleitzahl": "1475 Autavaux"},
 {"postleitzahl": "1475 Forel FR"},
 {"postleitzahl": "1475 Montbrelloz"},
 {"postleitzahl": "1482 Cugy FR"},
 {"postleitzahl": "1483 Frasses"},
 {"postleitzahl": "1483 Vesin"},
 {"postleitzahl": "1483 Montet (Broye)"},
 {"postleitzahl": "1484 Aumont"},
 {"postleitzahl": "1484 Granges-de-Vesin"},
 {"postleitzahl": "1485 Nuvilly"},
 {"postleitzahl": "1486 Vuissens"},
 {"postleitzahl": "1489 Murist"},
 {"postleitzahl": "1509 Vucherens"},
 {"postleitzahl": "1510 Syens"},
 {"postleitzahl": "1510 Moudon"},
 {"postleitzahl": "1512 Chavannes-sur-Moudon"},
 {"postleitzahl": "1513 Hermenches"},
 {"postleitzahl": "1513 Rossenges"},
 {"postleitzahl": "1514 Bussy-sur-Moudon"},
 {"postleitzahl": "1515 Neyruz-sur-Moudon"},
 {"postleitzahl": "1515 Villars-le-Comte"},
 {"postleitzahl": "1521 Curtilles"},
 {"postleitzahl": "1522 Oulens-sur-Lucens"},
 {"postleitzahl": "1522 Lucens"},
 {"postleitzahl": "1523 Granges-près-Marnand"},
 {"postleitzahl": "1524 Marnand"},
 {"postleitzahl": "1525 Seigneux"},
 {"postleitzahl": "1525 Henniez"},
 {"postleitzahl": "1526 Forel-sur-Lucens"},
 {"postleitzahl": "1526 Cremin"},
 {"postleitzahl": "1527 Villeneuve FR"},
 {"postleitzahl": "1528 Praratoud"},
 {"postleitzahl": "1528 Surpierre"},
 {"postleitzahl": "1529 Cheiry"},
 {"postleitzahl": "1530 Payerne"},
 {"postleitzahl": "1532 Fétigny"},
 {"postleitzahl": "1533 Ménières"},
 {"postleitzahl": "1534 Chapelle (Broye)"},
 {"postleitzahl": "1534 Sassel"},
 {"postleitzahl": "1535 Combremont-le-Grand"},
 {"postleitzahl": "1536 Combremont-le-Petit"},
 {"postleitzahl": "1537 Champtauroz"},
 {"postleitzahl": "1538 Treytorrens (Payerne)"},
 {"postleitzahl": "1541 Morens FR"},
 {"postleitzahl": "1541 Sévaz"},
 {"postleitzahl": "1541 Bussy FR"},
 {"postleitzahl": "1542 Rueyres-les-Prés"},
 {"postleitzahl": "1543 Grandcour"},
 {"postleitzahl": "1544 Gletterens"},
 {"postleitzahl": "1545 Chevroux"},
 {"postleitzahl": "1551 Vers-chez-Perrin"},
 {"postleitzahl": "1552 Trey"},
 {"postleitzahl": "1553 Châtonnaye"},
 {"postleitzahl": "1554 Sédeilles"},
 {"postleitzahl": "1554 Rossens VD"},
 {"postleitzahl": "1555 Villarzel"},
 {"postleitzahl": "1562 Corcelles-près-Payerne"},
 {"postleitzahl": "1563 Dompierre FR"},
 {"postleitzahl": "1564 Domdidier"},
 {"postleitzahl": "1565 Vallon"},
 {"postleitzahl": "1565 Missy"},
 {"postleitzahl": "1566 Les Friques"},
 {"postleitzahl": "1566 St-Aubin FR"},
 {"postleitzahl": "1567 Delley"},
 {"postleitzahl": "1568 Portalban"},
 {"postleitzahl": "1580 Oleyres"},
 {"postleitzahl": "1580 Avenches"},
 {"postleitzahl": "1582 Donatyre"},
 {"postleitzahl": "1583 Villarepos"},
 {"postleitzahl": "1584 Villars-le-Grand"},
 {"postleitzahl": "1585 Bellerive VD"},
 {"postleitzahl": "1585 Cotterd"},
 {"postleitzahl": "1585 Salavaux"},
 {"postleitzahl": "1586 Vallamand"},
 {"postleitzahl": "1587 Montmagny"},
 {"postleitzahl": "1587 Constantine"},
 {"postleitzahl": "1588 Cudrefin"},
 {"postleitzahl": "1589 Chabrey"},
 {"postleitzahl": "1595 Clavaleyres"},
 {"postleitzahl": "1595 Faoug"},
 {"postleitzahl": "1607 Les Tavernes"},
 {"postleitzahl": "1607 Les Thioleyres"},
 {"postleitzahl": "1607 Palézieux-Village"},
 {"postleitzahl": "1607 Palézieux"},
 {"postleitzahl": "1608 Chapelle (Glâne)"},
 {"postleitzahl": "1608 Bussigny-sur-Oron"},
 {"postleitzahl": "1608 Chesalles-sur-Oron"},
 {"postleitzahl": "1608 Oron-le-Châtel"},
 {"postleitzahl": "1609 Besencens"},
 {"postleitzahl": "1609 Fiaugères"},
 {"postleitzahl": "1609 Le Jordil"},
 {"postleitzahl": "1609 St-Martin FR"},
 {"postleitzahl": "1610 Châtillens"},
 {"postleitzahl": "1610 Vuibroye"},
 {"postleitzahl": "1610 Oron-la-Ville"},
 {"postleitzahl": "1611 Le Crêt-près-Semsales"},
 {"postleitzahl": "1612 Ecoteaux"},
 {"postleitzahl": "1613 Maracon"},
 {"postleitzahl": "1614 Granges (Veveyse)"},
 {"postleitzahl": "1615 Bossonnens"},
 {"postleitzahl": "1616 Attalens"},
 {"postleitzahl": "1617 Tatroz"},
 {"postleitzahl": "1617 Remaufens"},
 {"postleitzahl": "1618 Châtel-St-Denis"},
 {"postleitzahl": "1619 Les Paccots"},
 {"postleitzahl": "1623 Semsales"},
 {"postleitzahl": "1624 La Verrerie"},
 {"postleitzahl": "1624 Grattavache"},
 {"postleitzahl": "1624 Progens"},
 {"postleitzahl": "1625 Maules"},
 {"postleitzahl": "1625 Sâles (Gruyère)"},
 {"postleitzahl": "1626 Romanens"},
 {"postleitzahl": "1626 Treyfayes"},
 {"postleitzahl": "1626 Rueyres-Treyfayes"},
 {"postleitzahl": "1627 Vaulruz"},
 {"postleitzahl": "1628 Vuadens"},
 {"postleitzahl": "1630 Bulle"},
 {"postleitzahl": "1632 Riaz"},
 {"postleitzahl": "1633 Vuippens"},
 {"postleitzahl": "1633 Marsens"},
 {"postleitzahl": "1634 La Roche FR"},
 {"postleitzahl": "1635 La Tour-de-Trême"},
 {"postleitzahl": "1636 Broc"},
 {"postleitzahl": "1637 Charmey (Gruyère)"},
 {"postleitzahl": "1638 Morlon"},
 {"postleitzahl": "1642 Sorens"},
 {"postleitzahl": "1643 Gumefens"},
 {"postleitzahl": "1644 Avry-devant-Pont"},
 {"postleitzahl": "1645 Le Bry"},
 {"postleitzahl": "1646 Echarlens"},
 {"postleitzahl": "1647 Corbières"},
 {"postleitzahl": "1648 Hauteville"},
 {"postleitzahl": "1649 Pont-la-Ville"},
 {"postleitzahl": "1651 Villarvolard"},
 {"postleitzahl": "1652 Botterens"},
 {"postleitzahl": "1652 Villarbeney"},
 {"postleitzahl": "1653 Châtel-sur-Montsalvens"},
 {"postleitzahl": "1653 Crésuz"},
 {"postleitzahl": "1654 Cerniat FR"},
 {"postleitzahl": "1656 Im Fang"},
 {"postleitzahl": "1656 Jaun"},
 {"postleitzahl": "1657 Abländschen"},
 {"postleitzahl": "1658 La Tine"},
 {"postleitzahl": "1658 Rossinière"},
 {"postleitzahl": "1659 Flendruz"},
 {"postleitzahl": "1659 Rougemont"},
 {"postleitzahl": "1660 Les Moulins"},
 {"postleitzahl": "1660 L'Etivaz"},
 {"postleitzahl": "1660 Château-d'Oex"},
 {"postleitzahl": "1660 La Lécherette"},
 {"postleitzahl": "1661 Le Pâquier-Montbarry"},
 {"postleitzahl": "1663 Moléson-sur-Gruyères"},
 {"postleitzahl": "1663 Pringy"},
 {"postleitzahl": "1663 Gruyères"},
 {"postleitzahl": "1663 Epagny"},
 {"postleitzahl": "1665 Estavannens"},
 {"postleitzahl": "1666 Villars-sous-Mont"},
 {"postleitzahl": "1666 Grandvillard"},
 {"postleitzahl": "1667 Enney"},
 {"postleitzahl": "1669 Neirivue"},
 {"postleitzahl": "1669 Les Sciernes-d'Albeuve"},
 {"postleitzahl": "1669 Montbovon"},
 {"postleitzahl": "1669 Lessoc"},
 {"postleitzahl": "1669 Albeuve"},
 {"postleitzahl": "1670 Bionnens"},
 {"postleitzahl": "1670 Esmonts"},
 {"postleitzahl": "1670 Ursy"},
 {"postleitzahl": "1673 Auboranges"},
 {"postleitzahl": "1673 Ecublens FR"},
 {"postleitzahl": "1673 Rue"},
 {"postleitzahl": "1673 Gillarens"},
 {"postleitzahl": "1673 Promasens"},
 {"postleitzahl": "1674 Vuarmarens"},
 {"postleitzahl": "1674 Montet (Glâne)"},
 {"postleitzahl": "1674 Morlens"},
 {"postleitzahl": "1675 Vauderens"},
 {"postleitzahl": "1675 Blessens"},
 {"postleitzahl": "1675 Mossel"},
 {"postleitzahl": "1676 Chavannes-les-Forts"},
 {"postleitzahl": "1677 Prez-vers-Siviriez"},
 {"postleitzahl": "1678 Siviriez"},
 {"postleitzahl": "1679 Villaraboud"},
 {"postleitzahl": "1680 Berlens"},
 {"postleitzahl": "1680 Romont FR"},
 {"postleitzahl": "1681 Billens"},
 {"postleitzahl": "1681 Hennens"},
 {"postleitzahl": "1682 Villars-Bramard"},
 {"postleitzahl": "1682 Dompierre VD"},
 {"postleitzahl": "1682 Lovatens"},
 {"postleitzahl": "1682 Cerniaz VD"},
 {"postleitzahl": "1682 Prévonloup"},
 {"postleitzahl": "1683 Brenles"},
 {"postleitzahl": "1683 Chesalles-sur-Moudon"},
 {"postleitzahl": "1683 Sarzens"},
 {"postleitzahl": "1684 Mézières FR"},
 {"postleitzahl": "1685 Villariaz"},
 {"postleitzahl": "1686 Grangettes-près-Romont"},
 {"postleitzahl": "1686 La Neirigue"},
 {"postleitzahl": "1687 Estévenens"},
 {"postleitzahl": "1687 La Magne"},
 {"postleitzahl": "1687 Vuisternens-devant-Romont"},
 {"postleitzahl": "1688 Sommentier"},
 {"postleitzahl": "1688 Lieffrens"},
 {"postleitzahl": "1689 Le Châtelard-près-Romont"},
 {"postleitzahl": "1690 Lussy FR"},
 {"postleitzahl": "1690 Villaz-St-Pierre"},
 {"postleitzahl": "1691 Villarimboud"},
 {"postleitzahl": "1692 Massonnens"},
 {"postleitzahl": "1694 Orsonnens"},
 {"postleitzahl": "1694 Chavannes-sous-Orsonnens"},
 {"postleitzahl": "1694 Villargiroud"},
 {"postleitzahl": "1694 Villarsiviriaux"},
 {"postleitzahl": "1695 Villarlod"},
 {"postleitzahl": "1695 Estavayer-le-Gibloux"},
 {"postleitzahl": "1695 Rueyres-St-Laurent"},
 {"postleitzahl": "1695 Villarsel-le-Gibloux"},
 {"postleitzahl": "1696 Vuisternens-en-Ogoz"},
 {"postleitzahl": "1697 La Joux FR"},
 {"postleitzahl": "1697 Les Ecasseys"},
 {"postleitzahl": "1699 Bouloz"},
 {"postleitzahl": "1699 Pont (Veveyse)"},
 {"postleitzahl": "1699 Porsel"},
 {"postleitzahl": "1700 Fribourg"},
 {"postleitzahl": "1708 Fribourg"},
 {"postleitzahl": "1712 Tafers"},
 {"postleitzahl": "1713 St. Antoni"},
 {"postleitzahl": "1714 Heitenried"},
 {"postleitzahl": "1715 Alterswil FR"},
 {"postleitzahl": "1716 Oberschrot"},
 {"postleitzahl": "1716 Schwarzsee"},
 {"postleitzahl": "1716 Plaffeien"},
 {"postleitzahl": "1717 St. Ursen"},
 {"postleitzahl": "1718 Rechthalten"},
 {"postleitzahl": "1719 Zumholz"},
 {"postleitzahl": "1719 Brünisried"},
 {"postleitzahl": "1720 Chésopelloz"},
 {"postleitzahl": "1720 Corminboeuf"},
 {"postleitzahl": "1721 Cormérod"},
 {"postleitzahl": "1721 Courtion"},
 {"postleitzahl": "1721 Misery"},
 {"postleitzahl": "1722 Bourguillon"},
 {"postleitzahl": "1723 Marly"},
 {"postleitzahl": "1723 Pierrafortscha"},
 {"postleitzahl": "1723 Villarsel-sur-Marly"},
 {"postleitzahl": "1724 Bonnefontaine"},
 {"postleitzahl": "1724 Essert FR"},
 {"postleitzahl": "1724 Ferpicloz"},
 {"postleitzahl": "1724 Montévraz"},
 {"postleitzahl": "1724 Oberried FR"},
 {"postleitzahl": "1724 Senèdes"},
 {"postleitzahl": "1724 Zénauva"},
 {"postleitzahl": "1724 Le Mouret"},
 {"postleitzahl": "1725 Posieux"},
 {"postleitzahl": "1726 Farvagny-le-Petit"},
 {"postleitzahl": "1726 Grenilles"},
 {"postleitzahl": "1726 Posat"},
 {"postleitzahl": "1726 Farvagny"},
 {"postleitzahl": "1727 Magnedens"},
 {"postleitzahl": "1727 Corpataux-Magnedens"},
 {"postleitzahl": "1728 Rossens FR"},
 {"postleitzahl": "1730 Ecuvillens"},
 {"postleitzahl": "1731 Ependes FR"},
 {"postleitzahl": "1732 Arconciel"},
 {"postleitzahl": "1733 Treyvaux"},
 {"postleitzahl": "1734 Tentlingen"},
 {"postleitzahl": "1735 Giffers"},
 {"postleitzahl": "1736 St. Silvester"},
 {"postleitzahl": "1737 Plasselb"},
 {"postleitzahl": "1738 Sangernboden"},
 {"postleitzahl": "1740 Neyruz FR"},
 {"postleitzahl": "1741 Cottens FR"},
 {"postleitzahl": "1742 Autigny"},
 {"postleitzahl": "1744 Chénens"},
 {"postleitzahl": "1745 Lentigny"},
 {"postleitzahl": "1746 Prez-vers-Noréaz"},
 {"postleitzahl": "1747 Corserey"},
 {"postleitzahl": "1748 Torny-le-Grand"},
 {"postleitzahl": "1749 Middes"},
 {"postleitzahl": "1752 Villars-sur-Glâne"},
 {"postleitzahl": "1753 Matran"},
 {"postleitzahl": "1754 Rosé"},
 {"postleitzahl": "1754 Corjolens"},
 {"postleitzahl": "1754 Avry-sur-Matran"},
 {"postleitzahl": "1754 Avry-Centre FR"},
 {"postleitzahl": "1756 Onnens FR"},
 {"postleitzahl": "1756 Lovens"},
 {"postleitzahl": "1757 Noréaz"},
 {"postleitzahl": "1762 Givisiez"},
 {"postleitzahl": "1763 Granges-Paccot"},
 {"postleitzahl": "1772 Ponthaux"},
 {"postleitzahl": "1772 Nierlet-les-Bois"},
 {"postleitzahl": "1772 Grolley"},
 {"postleitzahl": "1773 Léchelles"},
 {"postleitzahl": "1773 Chandon"},
 {"postleitzahl": "1773 Russy"},
 {"postleitzahl": "1774 Montagny-les-Monts"},
 {"postleitzahl": "1774 Cousset"},
 {"postleitzahl": "1775 Mannens"},
 {"postleitzahl": "1775 Grandsivaz"},
 {"postleitzahl": "1776 Montagny-la-Ville"},
 {"postleitzahl": "1782 Autafond"},
 {"postleitzahl": "1782 La Corbaz"},
 {"postleitzahl": "1782 Cormagens"},
 {"postleitzahl": "1782 Lossy"},
 {"postleitzahl": "1782 Formangueires"},
 {"postleitzahl": "1782 Belfaux"},
 {"postleitzahl": "1783 Pensier"},
 {"postleitzahl": "1783 Barberêche"},
 {"postleitzahl": "1784 Cournillens"},
 {"postleitzahl": "1784 Wallenried"},
 {"postleitzahl": "1784 Courtepin"},
 {"postleitzahl": "1785 Cressier FR"},
 {"postleitzahl": "1786 Sugiez"},
 {"postleitzahl": "1787 Môtier (Vully)"},
 {"postleitzahl": "1787 Mur (Vully) FR"},
 {"postleitzahl": "1787 Mur (Vully) VD"},
 {"postleitzahl": "1788 Praz (Vully)"},
 {"postleitzahl": "1789 Lugnorre"},
 {"postleitzahl": "1791 Courtaman"},
 {"postleitzahl": "1792 Guschelmuth"},
 {"postleitzahl": "1792 Cordast"},
 {"postleitzahl": "1793 Jeuss"},
 {"postleitzahl": "1794 Salvenach"},
 {"postleitzahl": "1795 Courlevon"},
 {"postleitzahl": "1796 Courgevaux"},
 {"postleitzahl": "1797 Münchenwiler"},
 {"postleitzahl": "1800 Vevey"},
 {"postleitzahl": "1801 Le Mont-Pèlerin"},
 {"postleitzahl": "1802 Corseaux"},
 {"postleitzahl": "1803 Chardonne"},
 {"postleitzahl": "1804 Corsier-sur-Vevey"},
 {"postleitzahl": "1805 Jongny"},
 {"postleitzahl": "1806 St-Légier-La Chiésaz"},
 {"postleitzahl": "1807 Blonay"},
 {"postleitzahl": "1808 Les Monts-de-Corsier"},
 {"postleitzahl": "1809 Fenil-sur-Corsier"},
 {"postleitzahl": "1814 La Tour-de-Peilz"},
 {"postleitzahl": "1815 Clarens"},
 {"postleitzahl": "1816 Chailly-Montreux"},
 {"postleitzahl": "1817 Brent"},
 {"postleitzahl": "1820 Montreux"},
 {"postleitzahl": "1820 Veytaux"},
 {"postleitzahl": "1820 Territet"},
 {"postleitzahl": "1822 Chernex"},
 {"postleitzahl": "1823 Glion"},
 {"postleitzahl": "1824 Caux"},
 {"postleitzahl": "1832 Villard-sur-Chamby"},
 {"postleitzahl": "1832 Chamby"},
 {"postleitzahl": "1833 Les Avants"},
 {"postleitzahl": "1844 Villeneuve VD"},
 {"postleitzahl": "1845 Noville"},
 {"postleitzahl": "1846 Chessel"},
 {"postleitzahl": "1847 Rennaz"},
 {"postleitzahl": "1852 Roche VD"},
 {"postleitzahl": "1853 Yvorne"},
 {"postleitzahl": "1854 Leysin"},
 {"postleitzahl": "1856 Corbeyrier"},
 {"postleitzahl": "1860 Aigle"},
 {"postleitzahl": "1862 La Comballaz"},
 {"postleitzahl": "1862 Les Mosses"},
 {"postleitzahl": "1863 Le Sépey"},
 {"postleitzahl": "1864 Vers-l'Eglise"},
 {"postleitzahl": "1865 Les Diablerets"},
 {"postleitzahl": "1866 La Forclaz VD"},
 {"postleitzahl": "1867 St-Triphon"},
 {"postleitzahl": "1867 Panex"},
 {"postleitzahl": "1867 Ollon VD"},
 {"postleitzahl": "1868 Collombey"},
 {"postleitzahl": "1869 Massongex"},
 {"postleitzahl": "1870 Monthey"},
 {"postleitzahl": "1871 Choëx"},
 {"postleitzahl": "1871 Les Giettes"},
 {"postleitzahl": "1872 Troistorrents"},
 {"postleitzahl": "1873 Champoussin"},
 {"postleitzahl": "1873 Les Crosets"},
 {"postleitzahl": "1873 Val-d'Illiez"},
 {"postleitzahl": "1874 Champéry"},
 {"postleitzahl": "1875 Morgins"},
 {"postleitzahl": "1880 Fenalet-sur-Bex"},
 {"postleitzahl": "1880 Frenières-sur-Bex"},
 {"postleitzahl": "1880 Les Plans-sur-Bex"},
 {"postleitzahl": "1880 Bex"},
 {"postleitzahl": "1882 Les Posses-sur-Bex"},
 {"postleitzahl": "1882 Gryon"},
 {"postleitzahl": "1884 Huémoz"},
 {"postleitzahl": "1884 Arveyes"},
 {"postleitzahl": "1884 Villars-sur-Ollon"},
 {"postleitzahl": "1885 Chesières"},
 {"postleitzahl": "1890 Mex VS"},
 {"postleitzahl": "1890 St-Maurice"},
 {"postleitzahl": "1891 Vérossaz"},
 {"postleitzahl": "1892 Lavey-les-Bains"},
 {"postleitzahl": "1892 Morcles"},
 {"postleitzahl": "1892 Lavey-Village"},
 {"postleitzahl": "1893 Muraz (Collombey)"},
 {"postleitzahl": "1895 Vionnaz"},
 {"postleitzahl": "1896 Miex"},
 {"postleitzahl": "1896 Vouvry"},
 {"postleitzahl": "1897 Les Evouettes"},
 {"postleitzahl": "1897 Bouveret"},
 {"postleitzahl": "1898 St-Gingolph"},
 {"postleitzahl": "1899 Torgon"},
 {"postleitzahl": "1902 Evionnaz"},
 {"postleitzahl": "1903 Collonges"},
 {"postleitzahl": "1904 Vernayaz"},
 {"postleitzahl": "1905 Dorénaz"},
 {"postleitzahl": "1906 Charrat"},
 {"postleitzahl": "1907 Saxon"},
 {"postleitzahl": "1908 Riddes"},
 {"postleitzahl": "1911 Mayens-de-Chamoson"},
 {"postleitzahl": "1911 Ovronnaz"},
 {"postleitzahl": "1912 Produit (Leytron)"},
 {"postleitzahl": "1912 Montagnon (Leytron)"},
 {"postleitzahl": "1912 Dugny (Leytron)"},
 {"postleitzahl": "1912 Leytron"},
 {"postleitzahl": "1913 Saillon"},
 {"postleitzahl": "1914 Auddes-sur-Riddes"},
 {"postleitzahl": "1914 Isérables"},
 {"postleitzahl": "1918 La Tzoumaz"},
 {"postleitzahl": "1920 Martigny"},
 {"postleitzahl": "1921 Martigny-Croix"},
 {"postleitzahl": "1922 Les Granges (Salvan)"},
 {"postleitzahl": "1922 Salvan"},
 {"postleitzahl": "1923 Le Trétien"},
 {"postleitzahl": "1923 Les Marécottes"},
 {"postleitzahl": "1925 Le Châtelard VS"},
 {"postleitzahl": "1925 Finhaut"},
 {"postleitzahl": "1926 Fully"},
 {"postleitzahl": "1927 Chemin"},
 {"postleitzahl": "1928 Ravoire"},
 {"postleitzahl": "1929 Trient"},
 {"postleitzahl": "1932 Les Valettes (Bovernier)"},
 {"postleitzahl": "1932 Bovernier"},
 {"postleitzahl": "1933 Vens (Sembrancher)"},
 {"postleitzahl": "1933 Chamoille (Sembrancher)"},
 {"postleitzahl": "1933 La Garde (Sembrancher)"},
 {"postleitzahl": "1933 Sembrancher"},
 {"postleitzahl": "1934 Bruson"},
 {"postleitzahl": "1934 Cotterg (Le Châble VS)"},
 {"postleitzahl": "1934 Villette (Le Châble VS)"},
 {"postleitzahl": "1934 Fontenelle (Le Châble VS)"},
 {"postleitzahl": "1934 Montagnier (Le Châble VS)"},
 {"postleitzahl": "1934 Le Châble VS"},
 {"postleitzahl": "1936 Verbier"},
 {"postleitzahl": "1937 Orsières"},
 {"postleitzahl": "1938 Champex-Lac"},
 {"postleitzahl": "1941 Cries (Vollèges)"},
 {"postleitzahl": "1941 Vollèges"},
 {"postleitzahl": "1942 Levron"},
 {"postleitzahl": "1943 Praz-de-Fort"},
 {"postleitzahl": "1944 La Fouly VS"},
 {"postleitzahl": "1945 Liddes"},
 {"postleitzahl": "1945 Fontaine Dessus (Liddes)"},
 {"postleitzahl": "1945 Fontaine Dessous (Liddes)"},
 {"postleitzahl": "1945 Dranse (Liddes)"},
 {"postleitzahl": "1945 Chandonne (Liddes)"},
 {"postleitzahl": "1945 Rive Haute (Liddes)"},
 {"postleitzahl": "1945 Fornex (Liddes)"},
 {"postleitzahl": "1945 Les Moulins VS (Liddes)"},
 {"postleitzahl": "1945 Vichères (Liddes)"},
 {"postleitzahl": "1945 Palasuit (Liddes)"},
 {"postleitzahl": "1945 Chez Petit (Liddes)"},
 {"postleitzahl": "1945 Petit Vichères (Liddes)"},
 {"postleitzahl": "1946 Bourg-St-Pierre"},
 {"postleitzahl": "1947 Prarreyer (Versegères)"},
 {"postleitzahl": "1947 Les Places (Versegères)"},
 {"postleitzahl": "1947 La Montoz (Versegères)"},
 {"postleitzahl": "1947 Champsec (Versegères)"},
 {"postleitzahl": "1947 Le Fregnoley (Versegères)"},
 {"postleitzahl": "1947 Versegères"},
 {"postleitzahl": "1948 Lourtier"},
 {"postleitzahl": "1948 Fionnay"},
 {"postleitzahl": "1948 Sarreyer"},
 {"postleitzahl": "1948 Le Planchamp (Lourtier)"},
 {"postleitzahl": "1948 Le Morgnes (Lourtier)"},
 {"postleitzahl": "1950 Sion"},
 {"postleitzahl": "1955 St-Pierre-de-Clages"},
 {"postleitzahl": "1955 Les Vérines (Chamoson)"},
 {"postleitzahl": "1955 Némiaz (Chamoson)"},
 {"postleitzahl": "1955 Grugnay (Chamoson)"},
 {"postleitzahl": "1955 Chamoson"},
 {"postleitzahl": "1957 Ardon"},
 {"postleitzahl": "1958 Uvrier"},
 {"postleitzahl": "1958 St-Léonard"},
 {"postleitzahl": "1961 Vernamiège"},
 {"postleitzahl": "1962 Pont-de-la-Morge (Sion)"},
 {"postleitzahl": "1963 Vétroz"},
 {"postleitzahl": "1964 Conthey"},
 {"postleitzahl": "1965 Chandolin-près-Savièse"},
 {"postleitzahl": "1965 Drône VS"},
 {"postleitzahl": "1965 Roumaz (Savièse)"},
 {"postleitzahl": "1965 Granois (Savièse)"},
 {"postleitzahl": "1965 St-Germain (Savièse)"},
 {"postleitzahl": "1965 Ormône (Savièse)"},
 {"postleitzahl": "1965 Mayens-de-la-Zour (Savièse)"},
 {"postleitzahl": "1965 Monteiller-Savièse"},
 {"postleitzahl": "1965 Savièse"},
 {"postleitzahl": "1966 Signèse (Ayent)"},
 {"postleitzahl": "1966 Fortunau (Ayent)"},
 {"postleitzahl": "1966 Luc (Ayent)"},
 {"postleitzahl": "1966 St-Romain (Ayent)"},
 {"postleitzahl": "1966 Saxonne (Ayent)"},
 {"postleitzahl": "1966 Villa (Ayent)"},
 {"postleitzahl": "1966 La Place (Ayent)"},
 {"postleitzahl": "1966 Botyre (Ayent)"},
 {"postleitzahl": "1966 Blignoud (Ayent)"},
 {"postleitzahl": "1966 Argnoud (Ayent)"},
 {"postleitzahl": "1967 Bramois"},
 {"postleitzahl": "1968 Mase"},
 {"postleitzahl": "1969 Liez (St-Martin)"},
 {"postleitzahl": "1969 Trogne (St-Martin)"},
 {"postleitzahl": "1969 Suen (St-Martin)"},
 {"postleitzahl": "1969 Eison (St.Martin)"},
 {"postleitzahl": "1969 St-Martin VS"},
 {"postleitzahl": "1971 Champlan (Grimisuat)"},
 {"postleitzahl": "1971 Coméraz (Grimisuat)"},
 {"postleitzahl": "1971 Grimisuat"},
 {"postleitzahl": "1972 Anzère"},
 {"postleitzahl": "1973 Nax"},
 {"postleitzahl": "1974 Arbaz"},
 {"postleitzahl": "1975 St-Séverin"},
 {"postleitzahl": "1976 Aven"},
 {"postleitzahl": "1976 Daillon"},
 {"postleitzahl": "1976 Erde"},
 {"postleitzahl": "1977 Icogne"},
 {"postleitzahl": "1978 Lens"},
 {"postleitzahl": "1981 Vex"},
 {"postleitzahl": "1982 Euseigne"},
 {"postleitzahl": "1983 Lana (Evolène)"},
 {"postleitzahl": "1983 Evolène"},
 {"postleitzahl": "1984 Les Haudères"},
 {"postleitzahl": "1985 La Sage"},
 {"postleitzahl": "1986 Arolla"},
 {"postleitzahl": "1987 Mâche"},
 {"postleitzahl": "1987 Hérémence"},
 {"postleitzahl": "1988 Les Collons"},
 {"postleitzahl": "1988 Thyon"},
 {"postleitzahl": "1991 Arvillard (Salins)"},
 {"postleitzahl": "1991 Pravidondaz (Salins)"},
 {"postleitzahl": "1991 Turin (Salins)"},
 {"postleitzahl": "1991 Misériez (Salins)"},
 {"postleitzahl": "1991 Salins"},
 {"postleitzahl": "1992 Les Agettes"},
 {"postleitzahl": "1992 Les Mayens-de-Sion"},
 {"postleitzahl": "1992 La Vernaz (Les Agettes)"},
 {"postleitzahl": "1992 Crête-à-l'Oeil(Les Agettes)"},
 {"postleitzahl": "1993 Clèbes (Nendaz)"},
 {"postleitzahl": "1993 Veysonnaz"},
 {"postleitzahl": "1994 Aproz (Nendaz)"},
 {"postleitzahl": "1996 Fey (Nendaz)"},
 {"postleitzahl": "1996 Beuson (Nendaz)"},
 {"postleitzahl": "1996 Baar (Nendaz)"},
 {"postleitzahl": "1996 Brignon (Nendaz)"},
 {"postleitzahl": "1996 Bioley-de-Brignon (Nendaz)"},
 {"postleitzahl": "1996 Bieudron (Nendaz)"},
 {"postleitzahl": "1996 Condémines (Nendaz)"},
 {"postleitzahl": "1996 Saclentz (Nendaz)"},
 {"postleitzahl": "1996 Basse-Nendaz"},
 {"postleitzahl": "1997 Siviez (Nendaz)"},
 {"postleitzahl": "1997 Haute-Nendaz"},
 {"postleitzahl": "2000 Neuchâtel"},
 {"postleitzahl": "2012 Auvernier"},
 {"postleitzahl": "2013 Colombier NE"},
 {"postleitzahl": "2014 Bôle"},
 {"postleitzahl": "2015 Areuse"},
 {"postleitzahl": "2016 Cortaillod"},
 {"postleitzahl": "2017 Boudry"},
 {"postleitzahl": "2019 Chambrelien"},
 {"postleitzahl": "2019 Rochefort"},
 {"postleitzahl": "2022 Bevaix"},
 {"postleitzahl": "2023 Gorgier"},
 {"postleitzahl": "2024 St-Aubin-Sauges"},
 {"postleitzahl": "2025 Chez-le-Bart"},
 {"postleitzahl": "2027 Montalchez"},
 {"postleitzahl": "2027 Fresens"},
 {"postleitzahl": "2028 Vaumarcus"},
 {"postleitzahl": "2034 Peseux"},
 {"postleitzahl": "2035 Corcelles NE"},
 {"postleitzahl": "2036 Cormondrèche"},
 {"postleitzahl": "2037 Montmollin"},
 {"postleitzahl": "2037 Montezillon"},
 {"postleitzahl": "2042 Valangin"},
 {"postleitzahl": "2043 Boudevilliers"},
 {"postleitzahl": "2046 Fontaines NE"},
 {"postleitzahl": "2052 La Vue-des-Alpes"},
 {"postleitzahl": "2052 Fontainemelon"},
 {"postleitzahl": "2053 Cernier"},
 {"postleitzahl": "2054 Les Vieux-Prés"},
 {"postleitzahl": "2054 Chézard-St-Martin"},
 {"postleitzahl": "2056 Dombresson"},
 {"postleitzahl": "2057 Villiers"},
 {"postleitzahl": "2058 Le Pâquier NE"},
 {"postleitzahl": "2063 Engollon"},
 {"postleitzahl": "2063 Fenin"},
 {"postleitzahl": "2063 Saules"},
 {"postleitzahl": "2063 Vilars NE"},
 {"postleitzahl": "2065 Savagnier"},
 {"postleitzahl": "2067 Chaumont"},
 {"postleitzahl": "2068 Hauterive NE"},
 {"postleitzahl": "2072 St-Blaise"},
 {"postleitzahl": "2073 Enges"},
 {"postleitzahl": "2074 Marin-Epagnier"},
 {"postleitzahl": "2075 Thielle"},
 {"postleitzahl": "2075 Wavre"},
 {"postleitzahl": "2087 Cornaux NE"},
 {"postleitzahl": "2088 Cressier NE"},
 {"postleitzahl": "2103 Noiraigue"},
 {"postleitzahl": "2105 Travers"},
 {"postleitzahl": "2108 Couvet"},
 {"postleitzahl": "2112 Môtiers NE"},
 {"postleitzahl": "2113 Boveresse"},
 {"postleitzahl": "2114 Fleurier"},
 {"postleitzahl": "2115 Buttes"},
 {"postleitzahl": "2116 Mont-de-Buttes"},
 {"postleitzahl": "2117 La Côte-aux-Fées"},
 {"postleitzahl": "2123 St-Sulpice NE"},
 {"postleitzahl": "2124 Les Sagnettes"},
 {"postleitzahl": "2126 Les Verrières"},
 {"postleitzahl": "2127 Les Bayards"},
 {"postleitzahl": "2149 Champ-du-Moulin"},
 {"postleitzahl": "2149 Fretereules"},
 {"postleitzahl": "2149 Brot-Dessous"},
 {"postleitzahl": "2206 Les Geneveys-sur-Coffrane"},
 {"postleitzahl": "2207 Coffrane"},
 {"postleitzahl": "2208 Les Hauts-Geneveys"},
 {"postleitzahl": "2300 La Chaux-de-Fonds"},
 {"postleitzahl": "2300 La Cibourg"},
 {"postleitzahl": "2314 La Sagne NE"},
 {"postleitzahl": "2316 Petit-Martel"},
 {"postleitzahl": "2316 Les Ponts-de-Martel"},
 {"postleitzahl": "2318 Brot-Plamboz"},
 {"postleitzahl": "2322 Le Crêt-du-Locle"},
 {"postleitzahl": "2325 Les Planchettes"},
 {"postleitzahl": "2325 Les Joux-Derrière"},
 {"postleitzahl": "2333 La Cibourg"},
 {"postleitzahl": "2333 La Ferrière"},
 {"postleitzahl": "2336 Les Bois"},
 {"postleitzahl": "2338 Les Emibois"},
 {"postleitzahl": "2338 Muriaux"},
 {"postleitzahl": "2340 Le Noirmont"},
 {"postleitzahl": "2345 Le Cerneux-Veusil"},
 {"postleitzahl": "2345 La Chaux-des-Breuleux"},
 {"postleitzahl": "2345 Les Breuleux"},
 {"postleitzahl": "2350 Saignelégier"},
 {"postleitzahl": "2353 Les Pommerats"},
 {"postleitzahl": "2354 Goumois"},
 {"postleitzahl": "2360 Le Bémont"},
 {"postleitzahl": "2362 Montfavergier"},
 {"postleitzahl": "2362 Montfaucon"},
 {"postleitzahl": "2363 Les Enfers"},
 {"postleitzahl": "2364 St-Brais"},
 {"postleitzahl": "2400 Le Prévoux"},
 {"postleitzahl": "2400 Le Locle"},
 {"postleitzahl": "2405 La Chaux-du-Milieu"},
 {"postleitzahl": "2406 Le Brouillet"},
 {"postleitzahl": "2406 Les Taillères"},
 {"postleitzahl": "2406 La Châtagne"},
 {"postleitzahl": "2406 La Brévine"},
 {"postleitzahl": "2414 Le Cerneux-Péquignot"},
 {"postleitzahl": "2416 Les Brenets"},
 {"postleitzahl": "2500 Biel/Bienne"},
 {"postleitzahl": "2502 Biel/Bienne"},
 {"postleitzahl": "2503 Biel/Bienne"},
 {"postleitzahl": "2504 Biel/Bienne"},
 {"postleitzahl": "2505 Biel/Bienne"},
 {"postleitzahl": "2512 Tüscherz-Alfermée"},
 {"postleitzahl": "2513 Twann"},
 {"postleitzahl": "2514 Ligerz"},
 {"postleitzahl": "2515 Prêles"},
 {"postleitzahl": "2516 Lamboing"},
 {"postleitzahl": "2517 Diesse"},
 {"postleitzahl": "2518 Nods"},
 {"postleitzahl": "2520 La Neuveville"},
 {"postleitzahl": "2523 Lignières"},
 {"postleitzahl": "2525 Le Landeron"},
 {"postleitzahl": "2532 Magglingen/Macolin"},
 {"postleitzahl": "2533 Evilard"},
 {"postleitzahl": "2534 Les Prés-d'Orvin"},
 {"postleitzahl": "2534 Orvin"},
 {"postleitzahl": "2535 Frinvillier"},
 {"postleitzahl": "2536 Plagne"},
 {"postleitzahl": "2537 Vauffelin"},
 {"postleitzahl": "2538 Romont BE"},
 {"postleitzahl": "2540 Grenchen"},
 {"postleitzahl": "2542 Pieterlen"},
 {"postleitzahl": "2543 Lengnau BE"},
 {"postleitzahl": "2544 Bettlach"},
 {"postleitzahl": "2545 Selzach"},
 {"postleitzahl": "2552 Orpund"},
 {"postleitzahl": "2553 Safnern"},
 {"postleitzahl": "2554 Meinisberg"},
 {"postleitzahl": "2555 Brügg BE"},
 {"postleitzahl": "2556 Scheuren"},
 {"postleitzahl": "2556 Schwadernau"},
 {"postleitzahl": "2557 Studen BE"},
 {"postleitzahl": "2558 Aegerten"},
 {"postleitzahl": "2560 Nidau"},
 {"postleitzahl": "2562 Port"},
 {"postleitzahl": "2563 Ipsach"},
 {"postleitzahl": "2564 Bellmund"},
 {"postleitzahl": "2565 Jens"},
 {"postleitzahl": "2572 Mörigen"},
 {"postleitzahl": "2572 Sutz"},
 {"postleitzahl": "2575 Hagneck"},
 {"postleitzahl": "2575 Gerolfingen"},
 {"postleitzahl": "2575 Täuffelen"},
 {"postleitzahl": "2576 Lüscherz"},
 {"postleitzahl": "2577 Finsterhennen"},
 {"postleitzahl": "2577 Siselen BE"},
 {"postleitzahl": "2603 Péry"},
 {"postleitzahl": "2604 La Heutte"},
 {"postleitzahl": "2605 Sonceboz-Sombeval"},
 {"postleitzahl": "2606 Corgémont"},
 {"postleitzahl": "2607 Cortébert"},
 {"postleitzahl": "2608 Montagne-de-Courtelary"},
 {"postleitzahl": "2608 Courtelary"},
 {"postleitzahl": "2610 Mont-Soleil"},
 {"postleitzahl": "2610 Mont-Crosin"},
 {"postleitzahl": "2610 Les Pontins"},
 {"postleitzahl": "2610 St-Imier"},
 {"postleitzahl": "2612 Cormoret"},
 {"postleitzahl": "2613 Villeret"},
 {"postleitzahl": "2615 Montagne-de-Sonvilier"},
 {"postleitzahl": "2615 Sonvilier"},
 {"postleitzahl": "2616 La Cibourg"},
 {"postleitzahl": "2616 Renan BE"},
 {"postleitzahl": "2710 Tavannes"},
 {"postleitzahl": "2712 Le Fuet"},
 {"postleitzahl": "2713 Bellelay"},
 {"postleitzahl": "2714 Le Prédame"},
 {"postleitzahl": "2714 Les Genevez JU"},
 {"postleitzahl": "2715 Monible"},
 {"postleitzahl": "2715 Châtelat"},
 {"postleitzahl": "2716 Sornetan"},
 {"postleitzahl": "2717 Fornet-Dessous"},
 {"postleitzahl": "2717 Rebévelier"},
 {"postleitzahl": "2718 Fornet-Dessus"},
 {"postleitzahl": "2718 Lajoux JU"},
 {"postleitzahl": "2720 La Tanne"},
 {"postleitzahl": "2720 Tramelan"},
 {"postleitzahl": "2722 Les Reussilles"},
 {"postleitzahl": "2723 Mont-Tramelan"},
 {"postleitzahl": "2732 Saicourt"},
 {"postleitzahl": "2732 Saules BE"},
 {"postleitzahl": "2732 Loveresse"},
 {"postleitzahl": "2732 Reconvilier"},
 {"postleitzahl": "2733 Pontenet"},
 {"postleitzahl": "2735 Bévilard"},
 {"postleitzahl": "2735 Champoz"},
 {"postleitzahl": "2735 Malleray"},
 {"postleitzahl": "2736 Sorvilier"},
 {"postleitzahl": "2738 Court"},
 {"postleitzahl": "2740 Moutier"},
 {"postleitzahl": "2742 Perrefitte"},
 {"postleitzahl": "2743 Eschert"},
 {"postleitzahl": "2744 Belprahon"},
 {"postleitzahl": "2745 Grandval"},
 {"postleitzahl": "2746 Crémines"},
 {"postleitzahl": "2747 Seehof"},
 {"postleitzahl": "2747 Corcelles BE"},
 {"postleitzahl": "2748 Souboz"},
 {"postleitzahl": "2748 Les Ecorcheresses"},
 {"postleitzahl": "2762 Roches BE"},
 {"postleitzahl": "2800 Delémont"},
 {"postleitzahl": "2802 Develier"},
 {"postleitzahl": "2803 Bourrignon"},
 {"postleitzahl": "2805 Soyhières"},
 {"postleitzahl": "2806 Mettembert"},
 {"postleitzahl": "2807 Lucelle"},
 {"postleitzahl": "2807 Pleigne"},
 {"postleitzahl": "2812 Movelier"},
 {"postleitzahl": "2813 Ederswiler"},
 {"postleitzahl": "2814 Roggenburg"},
 {"postleitzahl": "2822 Courroux"},
 {"postleitzahl": "2823 Courcelon"},
 {"postleitzahl": "2824 Vicques"},
 {"postleitzahl": "2825 Courchapoix"},
 {"postleitzahl": "2826 Corban"},
 {"postleitzahl": "2827 Schelten"},
 {"postleitzahl": "2827 Mervelier"},
 {"postleitzahl": "2828 Montsevelier"},
 {"postleitzahl": "2829 Vermes"},
 {"postleitzahl": "2830 Vellerat"},
 {"postleitzahl": "2830 Courrendlin"},
 {"postleitzahl": "2832 Rebeuvelier"},
 {"postleitzahl": "2842 Rossemaison"},
 {"postleitzahl": "2843 Châtillon JU"},
 {"postleitzahl": "2852 Courtételle"},
 {"postleitzahl": "2853 Courfaivre"},
 {"postleitzahl": "2854 Bassecourt"},
 {"postleitzahl": "2855 Glovelier"},
 {"postleitzahl": "2856 Boécourt"},
 {"postleitzahl": "2857 Montavon"},
 {"postleitzahl": "2863 Undervelier"},
 {"postleitzahl": "2864 Soulce"},
 {"postleitzahl": "2873 Saulcy"},
 {"postleitzahl": "2874 Montfavergier"},
 {"postleitzahl": "2874 St-Brais"},
 {"postleitzahl": "2875 Les Enfers"},
 {"postleitzahl": "2875 Montfaucon"},
 {"postleitzahl": "2877 Le Bémont JU"},
 {"postleitzahl": "2882 St-Ursanne"},
 {"postleitzahl": "2883 Montmelon"},
 {"postleitzahl": "2884 Montenol"},
 {"postleitzahl": "2885 Epauvillers"},
 {"postleitzahl": "2886 Epiquerez"},
 {"postleitzahl": "2887 Soubey"},
 {"postleitzahl": "2888 Seleute"},
 {"postleitzahl": "2889 Ocourt"},
 {"postleitzahl": "2900 Porrentruy"},
 {"postleitzahl": "2902 Fontenais"},
 {"postleitzahl": "2903 Villars-sur Fontenais"},
 {"postleitzahl": "2904 Bressaucourt"},
 {"postleitzahl": "2905 Courtedoux"},
 {"postleitzahl": "2906 Chevenez"},
 {"postleitzahl": "2907 Rocourt"},
 {"postleitzahl": "2908 Grandfontaine"},
 {"postleitzahl": "2912 Roche-d'Or"},
 {"postleitzahl": "2912 Réclère"},
 {"postleitzahl": "2914 Damvant"},
 {"postleitzahl": "2915 Bure"},
 {"postleitzahl": "2916 Fahy"},
 {"postleitzahl": "2922 Courchavon"},
 {"postleitzahl": "2923 Courtemaîche"},
 {"postleitzahl": "2924 Montignez"},
 {"postleitzahl": "2925 Buix"},
 {"postleitzahl": "2926 Boncourt"},
 {"postleitzahl": "2932 Coeuve"},
 {"postleitzahl": "2933 Damphreux"},
 {"postleitzahl": "2933 Lugnez"},
 {"postleitzahl": "2935 Beurnevésin"},
 {"postleitzahl": "2942 Alle"},
 {"postleitzahl": "2943 Vendlincourt"},
 {"postleitzahl": "2944 Bonfol"},
 {"postleitzahl": "2946 Miécourt"},
 {"postleitzahl": "2947 Charmoille"},
 {"postleitzahl": "2950 Courtemautruy"},
 {"postleitzahl": "2950 Courgenay"},
 {"postleitzahl": "2952 Cornol"},
 {"postleitzahl": "2953 Fregiécourt"},
 {"postleitzahl": "2953 Pleujouse"},
 {"postleitzahl": "2954 Asuel"},
 {"postleitzahl": "3000 Bern"},
 {"postleitzahl": "3004 Bern"},
 {"postleitzahl": "3005 Bern"},
 {"postleitzahl": "3006 Bern"},
 {"postleitzahl": "3007 Bern"},
 {"postleitzahl": "3008 Bern"},
 {"postleitzahl": "3010 Bern"},
 {"postleitzahl": "3011 Bern"},
 {"postleitzahl": "3012 Bern"},
 {"postleitzahl": "3013 Bern"},
 {"postleitzahl": "3014 Bern"},
 {"postleitzahl": "3015 Bern"},
 {"postleitzahl": "3018 Bern"},
 {"postleitzahl": "3019 Bern"},
 {"postleitzahl": "3020 Bern"},
 {"postleitzahl": "3027 Bern"},
 {"postleitzahl": "3032 Hinterkappelen"},
 {"postleitzahl": "3033 Wohlen b. Bern"},
 {"postleitzahl": "3034 Murzelen"},
 {"postleitzahl": "3035 Frieswil"},
 {"postleitzahl": "3036 Detligen"},
 {"postleitzahl": "3037 Herrenschwanden"},
 {"postleitzahl": "3038 Kirchlindach"},
 {"postleitzahl": "3042 Ortschwaben"},
 {"postleitzahl": "3043 Uettligen"},
 {"postleitzahl": "3044 Innerberg"},
 {"postleitzahl": "3045 Meikirch"},
 {"postleitzahl": "3046 Wahlendorf"},
 {"postleitzahl": "3047 Bremgarten b. Bern"},
 {"postleitzahl": "3048 Worblaufen"},
 {"postleitzahl": "3049 Säriswil"},
 {"postleitzahl": "3052 Zollikofen"},
 {"postleitzahl": "3053 Deisswil b. Münchenbuchsee"},
 {"postleitzahl": "3053 Diemerswil"},
 {"postleitzahl": "3053 Wiggiswil"},
 {"postleitzahl": "3053 Lätti"},
 {"postleitzahl": "3053 Münchenbuchsee"},
 {"postleitzahl": "3054 Schüpfen"},
 {"postleitzahl": "3063 Ittigen"},
 {"postleitzahl": "3065 Bolligen"},
 {"postleitzahl": "3066 Stettlen"},
 {"postleitzahl": "3067 Boll"},
 {"postleitzahl": "3068 Utzigen"},
 {"postleitzahl": "3072 Ostermundigen"},
 {"postleitzahl": "3073 Gümligen"},
 {"postleitzahl": "3074 Muri b. Bern"},
 {"postleitzahl": "3075 Rüfenacht BE"},
 {"postleitzahl": "3076 Worb"},
 {"postleitzahl": "3077 Enggistein"},
 {"postleitzahl": "3078 Richigen"},
 {"postleitzahl": "3082 Schlosswil"},
 {"postleitzahl": "3083 Trimstein"},
 {"postleitzahl": "3084 Wabern"},
 {"postleitzahl": "3086 Englisberg"},
 {"postleitzahl": "3086 Zimmerwald"},
 {"postleitzahl": "3087 Niedermuhlern"},
 {"postleitzahl": "3088 Oberbütschel"},
 {"postleitzahl": "3088 Rüeggisberg"},
 {"postleitzahl": "3089 Hinterfultigen"},
 {"postleitzahl": "3095 Spiegel b. Bern"},
 {"postleitzahl": "3096 Oberbalm"},
 {"postleitzahl": "3097 Liebefeld"},
 {"postleitzahl": "3098 Köniz"},
 {"postleitzahl": "3098 Schliern b. Köniz"},
 {"postleitzahl": "3099 Rüti b. Riggisberg"},
 {"postleitzahl": "3110 Münsingen"},
 {"postleitzahl": "3111 Tägertschi"},
 {"postleitzahl": "3112 Allmendingen b. Bern"},
 {"postleitzahl": "3113 Rubigen"},
 {"postleitzahl": "3114 Wichtrach"},
 {"postleitzahl": "3115 Gerzensee"},
 {"postleitzahl": "3116 Mühledorf BE"},
 {"postleitzahl": "3116 Noflen BE"},
 {"postleitzahl": "3116 Kirchdorf BE"},
 {"postleitzahl": "3122 Kehrsatz"},
 {"postleitzahl": "3123 Belp"},
 {"postleitzahl": "3124 Belpberg"},
 {"postleitzahl": "3125 Toffen"},
 {"postleitzahl": "3126 Gelterfingen"},
 {"postleitzahl": "3126 Kaufdorf"},
 {"postleitzahl": "3127 Lohnstorf"},
 {"postleitzahl": "3127 Mühlethurnen"},
 {"postleitzahl": "3128 Kirchenthurnen"},
 {"postleitzahl": "3128 Rümligen"},
 {"postleitzahl": "3132 Riggisberg"},
 {"postleitzahl": "3144 Gasel"},
 {"postleitzahl": "3145 Niederscherli"},
 {"postleitzahl": "3147 Mittelhäusern"},
 {"postleitzahl": "3148 Lanzenhäusern"},
 {"postleitzahl": "3150 Schwarzenburg"},
 {"postleitzahl": "3152 Mamishaus"},
 {"postleitzahl": "3153 Rüschegg Gambach"},
 {"postleitzahl": "3154 Rüschegg Heubach"},
 {"postleitzahl": "3155 Helgisried-Rohrbach"},
 {"postleitzahl": "3156 Riffenmatt"},
 {"postleitzahl": "3157 Milken"},
 {"postleitzahl": "3158 Guggisberg"},
 {"postleitzahl": "3159 Riedstätt"},
 {"postleitzahl": "3172 Niederwangen b. Bern"},
 {"postleitzahl": "3173 Oberwangen b. Bern"},
 {"postleitzahl": "3174 Thörishaus"},
 {"postleitzahl": "3175 Flamatt"},
 {"postleitzahl": "3176 Neuenegg"},
 {"postleitzahl": "3177 Laupen BE"},
 {"postleitzahl": "3178 Bösingen"},
 {"postleitzahl": "3179 Kriechenwil"},
 {"postleitzahl": "3182 Ueberstorf"},
 {"postleitzahl": "3183 Albligen"},
 {"postleitzahl": "3184 Wünnewil"},
 {"postleitzahl": "3185 Schmitten FR"},
 {"postleitzahl": "3186 Düdingen"},
 {"postleitzahl": "3202 Frauenkappelen"},
 {"postleitzahl": "3203 Mühleberg"},
 {"postleitzahl": "3204 Rosshäusern"},
 {"postleitzahl": "3205 Gümmenen"},
 {"postleitzahl": "3206 Rizenbach"},
 {"postleitzahl": "3206 Ferenbalm"},
 {"postleitzahl": "3206 Biberen"},
 {"postleitzahl": "3206 Gammen"},
 {"postleitzahl": "3206 Wallenbuch"},
 {"postleitzahl": "3207 Wileroltigen"},
 {"postleitzahl": "3207 Golaten"},
 {"postleitzahl": "3208 Gurbrü"},
 {"postleitzahl": "3210 Kerzers"},
 {"postleitzahl": "3212 Kleingurmels"},
 {"postleitzahl": "3212 Gurmels"},
 {"postleitzahl": "3213 Liebistorf"},
 {"postleitzahl": "3213 Kleinbösingen"},
 {"postleitzahl": "3214 Ulmiz"},
 {"postleitzahl": "3215 Gempenach"},
 {"postleitzahl": "3215 Büchslen"},
 {"postleitzahl": "3215 Lurtigen"},
 {"postleitzahl": "3216 Agriswil"},
 {"postleitzahl": "3216 Ried b. Kerzers"},
 {"postleitzahl": "3225 Müntschemier"},
 {"postleitzahl": "3226 Treiten"},
 {"postleitzahl": "3232 Ins"},
 {"postleitzahl": "3233 Tschugg"},
 {"postleitzahl": "3234 Vinelz"},
 {"postleitzahl": "3235 Erlach"},
 {"postleitzahl": "3236 Gampelen"},
 {"postleitzahl": "3237 Brüttelen"},
 {"postleitzahl": "3238 Gals"},
 {"postleitzahl": "3250 Lyss"},
 {"postleitzahl": "3251 Ruppoldsried"},
 {"postleitzahl": "3251 Wengi b. Büren"},
 {"postleitzahl": "3252 Worben"},
 {"postleitzahl": "3253 Schnottwil"},
 {"postleitzahl": "3254 Balm b. Messen"},
 {"postleitzahl": "3254 Messen"},
 {"postleitzahl": "3255 Rapperswil BE"},
 {"postleitzahl": "3256 Dieterswil"},
 {"postleitzahl": "3256 Bangerten b. Dieterswil"},
 {"postleitzahl": "3256 Seewil"},
 {"postleitzahl": "3257 Ammerzwil BE"},
 {"postleitzahl": "3257 Grossaffoltern"},
 {"postleitzahl": "3262 Suberg"},
 {"postleitzahl": "3263 Büetigen"},
 {"postleitzahl": "3264 Diessbach b. Büren"},
 {"postleitzahl": "3266 Wiler b. Seedorf"},
 {"postleitzahl": "3267 Seedorf BE"},
 {"postleitzahl": "3268 Lobsigen"},
 {"postleitzahl": "3270 Aarberg"},
 {"postleitzahl": "3271 Radelfingen b. Aarberg"},
 {"postleitzahl": "3272 Epsach"},
 {"postleitzahl": "3272 Walperswil"},
 {"postleitzahl": "3273 Kappelen"},
 {"postleitzahl": "3274 Hermrigen"},
 {"postleitzahl": "3274 Bühl b. Aarberg"},
 {"postleitzahl": "3274 Merzligen"},
 {"postleitzahl": "3280 Meyriez"},
 {"postleitzahl": "3280 Greng"},
 {"postleitzahl": "3280 Murten"},
 {"postleitzahl": "3282 Bargen BE"},
 {"postleitzahl": "3283 Niederried b. Kallnach"},
 {"postleitzahl": "3283 Kallnach"},
 {"postleitzahl": "3284 Fräschels"},
 {"postleitzahl": "3285 Galmiz"},
 {"postleitzahl": "3286 Muntelier"},
 {"postleitzahl": "3292 Busswil b. Büren"},
 {"postleitzahl": "3293 Dotzigen"},
 {"postleitzahl": "3294 Meienried"},
 {"postleitzahl": "3294 Büren an der Aare"},
 {"postleitzahl": "3295 Rüti b. Büren"},
 {"postleitzahl": "3296 Arch"},
 {"postleitzahl": "3297 Leuzigen"},
 {"postleitzahl": "3298 Oberwil b. Büren"},
 {"postleitzahl": "3302 Moosseedorf"},
 {"postleitzahl": "3303 Zuzwil BE"},
 {"postleitzahl": "3303 Ballmoos"},
 {"postleitzahl": "3303 Münchringen"},
 {"postleitzahl": "3303 Jegenstorf"},
 {"postleitzahl": "3305 Iffwil"},
 {"postleitzahl": "3305 Scheunen"},
 {"postleitzahl": "3306 Etzelkofen"},
 {"postleitzahl": "3307 Brunnenthal"},
 {"postleitzahl": "3308 Grafenried"},
 {"postleitzahl": "3309 Kernenried"},
 {"postleitzahl": "3309 Zauggenried"},
 {"postleitzahl": "3312 Fraubrunnen"},
 {"postleitzahl": "3313 Büren zum Hof"},
 {"postleitzahl": "3314 Schalunen"},
 {"postleitzahl": "3315 Kräiligen"},
 {"postleitzahl": "3315 Bätterkinden"},
 {"postleitzahl": "3317 Limpach"},
 {"postleitzahl": "3317 Mülchi"},
 {"postleitzahl": "3322 Mattstetten"},
 {"postleitzahl": "3322 Urtenen-Schönbühl"},
 {"postleitzahl": "3323 Bäriswil BE"},
 {"postleitzahl": "3324 Mötschwil"},
 {"postleitzahl": "3324 Hindelbank"},
 {"postleitzahl": "3325 Hettiswil b. Hindelbank"},
 {"postleitzahl": "3326 Krauchthal"},
 {"postleitzahl": "3360 Herzogenbuchsee"},
 {"postleitzahl": "3362 Niederönz"},
 {"postleitzahl": "3363 Oberönz"},
 {"postleitzahl": "3365 Seeberg"},
 {"postleitzahl": "3365 Grasswil"},
 {"postleitzahl": "3366 Bettenhausen"},
 {"postleitzahl": "3366 Bollodingen"},
 {"postleitzahl": "3367 Ochlenberg"},
 {"postleitzahl": "3367 Thörigen"},
 {"postleitzahl": "3368 Bleienbach"},
 {"postleitzahl": "3372 Wanzwil"},
 {"postleitzahl": "3373 Heimenhausen"},
 {"postleitzahl": "3373 Röthenbach Herzogenbuchsee"},
 {"postleitzahl": "3374 Wangenried"},
 {"postleitzahl": "3375 Inkwil"},
 {"postleitzahl": "3376 Graben"},
 {"postleitzahl": "3376 Berken"},
 {"postleitzahl": "3377 Walliswil b. Wangen"},
 {"postleitzahl": "3380 Walliswil b. Niederbipp"},
 {"postleitzahl": "3380 Wangen an der Aare"},
 {"postleitzahl": "3400 Burgdorf"},
 {"postleitzahl": "3412 Heimiswil"},
 {"postleitzahl": "3413 Kaltacker"},
 {"postleitzahl": "3414 Oberburg"},
 {"postleitzahl": "3415 Schafhausen im Emmental"},
 {"postleitzahl": "3415 Rüegsauschachen"},
 {"postleitzahl": "3415 Hasle b. Burgdorf"},
 {"postleitzahl": "3416 Affoltern im Emmental"},
 {"postleitzahl": "3417 Rüegsau"},
 {"postleitzahl": "3418 Rüegsbach"},
 {"postleitzahl": "3419 Biembach im Emmental"},
 {"postleitzahl": "3421 Rüti b. Lyssach"},
 {"postleitzahl": "3421 Lyssach"},
 {"postleitzahl": "3422 Rüdtligen"},
 {"postleitzahl": "3422 Alchenflüh"},
 {"postleitzahl": "3422 Kirchberg BE"},
 {"postleitzahl": "3423 Ersigen"},
 {"postleitzahl": "3424 Niederösch"},
 {"postleitzahl": "3424 Oberösch"},
 {"postleitzahl": "3425 Willadingen"},
 {"postleitzahl": "3425 Koppigen"},
 {"postleitzahl": "3426 Aefligen"},
 {"postleitzahl": "3427 Utzenstorf"},
 {"postleitzahl": "3428 Wiler b. Utzenstorf"},
 {"postleitzahl": "3429 Hellsau"},
 {"postleitzahl": "3429 Höchstetten"},
 {"postleitzahl": "3432 Lützelflüh-Goldbach"},
 {"postleitzahl": "3433 Schwanden im Emmental"},
 {"postleitzahl": "3434 Obergoldbach"},
 {"postleitzahl": "3435 Ramsei"},
 {"postleitzahl": "3436 Zollbrück"},
 {"postleitzahl": "3437 Rüderswil"},
 {"postleitzahl": "3438 Lauperswil"},
 {"postleitzahl": "3439 Ranflüh"},
 {"postleitzahl": "3452 Grünenmatt"},
 {"postleitzahl": "3453 Heimisbach"},
 {"postleitzahl": "3454 Sumiswald"},
 {"postleitzahl": "3455 Grünen"},
 {"postleitzahl": "3456 Trachselwald"},
 {"postleitzahl": "3457 Wasen im Emmental"},
 {"postleitzahl": "3462 Weier im Emmental"},
 {"postleitzahl": "3463 Häusernmoos im Emmental"},
 {"postleitzahl": "3464 Schmidigen-Mühleweg"},
 {"postleitzahl": "3465 Dürrenroth"},
 {"postleitzahl": "3472 Rumendingen"},
 {"postleitzahl": "3472 Wynigen"},
 {"postleitzahl": "3473 Alchenstorf"},
 {"postleitzahl": "3474 Rüedisbach"},
 {"postleitzahl": "3475 Riedtwil"},
 {"postleitzahl": "3475 Hermiswil"},
 {"postleitzahl": "3476 Oschwand"},
 {"postleitzahl": "3503 Gysenstein"},
 {"postleitzahl": "3504 Niederhünigen"},
 {"postleitzahl": "3504 Oberhünigen"},
 {"postleitzahl": "3506 Grosshöchstetten"},
 {"postleitzahl": "3507 Biglen"},
 {"postleitzahl": "3508 Arni BE"},
 {"postleitzahl": "3510 Freimettigen"},
 {"postleitzahl": "3510 Häutligen"},
 {"postleitzahl": "3510 Konolfingen"},
 {"postleitzahl": "3512 Walkringen"},
 {"postleitzahl": "3513 Bigenthal"},
 {"postleitzahl": "3531 Oberthal"},
 {"postleitzahl": "3532 Mirchel"},
 {"postleitzahl": "3532 Zäziwil"},
 {"postleitzahl": "3533 Bowil"},
 {"postleitzahl": "3534 Signau"},
 {"postleitzahl": "3535 Schüpbach"},
 {"postleitzahl": "3536 Aeschau"},
 {"postleitzahl": "3537 Eggiwil"},
 {"postleitzahl": "3538 Röthenbach im Emmental"},
 {"postleitzahl": "3543 Emmenmatt"},
 {"postleitzahl": "3550 Langnau im Emmental"},
 {"postleitzahl": "3551 Oberfrittenbach"},
 {"postleitzahl": "3552 Bärau"},
 {"postleitzahl": "3553 Gohl"},
 {"postleitzahl": "3555 Trubschachen"},
 {"postleitzahl": "3556 Trub"},
 {"postleitzahl": "3557 Fankhaus (Trub)"},
 {"postleitzahl": "3600 Thun"},
 {"postleitzahl": "3603 Thun"},
 {"postleitzahl": "3604 Thun"},
 {"postleitzahl": "3608 Thun"},
 {"postleitzahl": "3612 Steffisburg"},
 {"postleitzahl": "3613 Steffisburg"},
 {"postleitzahl": "3614 Unterlangenegg"},
 {"postleitzahl": "3615 Heimenschwand"},
 {"postleitzahl": "3616 Schwarzenegg"},
 {"postleitzahl": "3617 Fahrni b. Thun"},
 {"postleitzahl": "3618 Süderen"},
 {"postleitzahl": "3619 Eriz"},
 {"postleitzahl": "3619 Innereriz"},
 {"postleitzahl": "3622 Homberg b. Thun"},
 {"postleitzahl": "3623 Teuffenthal b. Thun"},
 {"postleitzahl": "3623 Horrenbach"},
 {"postleitzahl": "3623 Buchen BE"},
 {"postleitzahl": "3624 Schwendibach"},
 {"postleitzahl": "3624 Goldiwil (Thun)"},
 {"postleitzahl": "3625 Heiligenschwendi"},
 {"postleitzahl": "3626 Hünibach"},
 {"postleitzahl": "3627 Heimberg"},
 {"postleitzahl": "3628 Kienersrüti"},
 {"postleitzahl": "3628 Uttigen"},
 {"postleitzahl": "3629 Oppligen"},
 {"postleitzahl": "3629 Jaberg"},
 {"postleitzahl": "3629 Kiesen"},
 {"postleitzahl": "3631 Höfen b. Thun"},
 {"postleitzahl": "3632 Niederstocken"},
 {"postleitzahl": "3632 Oberstocken"},
 {"postleitzahl": "3633 Amsoldingen"},
 {"postleitzahl": "3634 Thierachern"},
 {"postleitzahl": "3635 Uebeschi"},
 {"postleitzahl": "3636 Längenbühl"},
 {"postleitzahl": "3636 Forst b. Längenbühl"},
 {"postleitzahl": "3638 Pohlern"},
 {"postleitzahl": "3638 Blumenstein"},
 {"postleitzahl": "3645 Gwatt (Thun)"},
 {"postleitzahl": "3645 Zwieselberg"},
 {"postleitzahl": "3646 Einigen"},
 {"postleitzahl": "3647 Reutigen"},
 {"postleitzahl": "3652 Hilterfingen"},
 {"postleitzahl": "3653 Oberhofen am Thunersee"},
 {"postleitzahl": "3654 Gunten"},
 {"postleitzahl": "3655 Sigriswil"},
 {"postleitzahl": "3656 Tschingel ob Gunten"},
 {"postleitzahl": "3656 Aeschlen ob Gunten"},
 {"postleitzahl": "3656 Ringoldswil"},
 {"postleitzahl": "3657 Schwanden (Sigriswil)"},
 {"postleitzahl": "3658 Merligen"},
 {"postleitzahl": "3661 Uetendorf"},
 {"postleitzahl": "3662 Seftigen"},
 {"postleitzahl": "3663 Gurzelen"},
 {"postleitzahl": "3664 Burgistein"},
 {"postleitzahl": "3665 Wattenwil"},
 {"postleitzahl": "3671 Herbligen"},
 {"postleitzahl": "3671 Brenzikofen"},
 {"postleitzahl": "3672 Aeschlen b. Oberdiessbach"},
 {"postleitzahl": "3672 Oberdiessbach"},
 {"postleitzahl": "3673 Linden"},
 {"postleitzahl": "3674 Bleiken b. Oberdiessbach"},
 {"postleitzahl": "3700 Spiez"},
 {"postleitzahl": "3702 Hondrich"},
 {"postleitzahl": "3703 Aeschiried"},
 {"postleitzahl": "3703 Aeschi b. Spiez"},
 {"postleitzahl": "3704 Krattigen"},
 {"postleitzahl": "3705 Faulensee"},
 {"postleitzahl": "3706 Leissigen"},
 {"postleitzahl": "3707 Därligen"},
 {"postleitzahl": "3711 Emdthal"},
 {"postleitzahl": "3711 Mülenen"},
 {"postleitzahl": "3713 Reichenbach im Kandertal"},
 {"postleitzahl": "3714 Wengi b. Frutigen"},
 {"postleitzahl": "3714 Frutigen"},
 {"postleitzahl": "3715 Adelboden"},
 {"postleitzahl": "3716 Kandergrund"},
 {"postleitzahl": "3717 Blausee-Mitholz"},
 {"postleitzahl": "3718 Kandersteg"},
 {"postleitzahl": "3722 Scharnachtal"},
 {"postleitzahl": "3723 Kiental"},
 {"postleitzahl": "3724 Ried (Frutigen)"},
 {"postleitzahl": "3725 Achseten"},
 {"postleitzahl": "3752 Wimmis"},
 {"postleitzahl": "3753 Oey"},
 {"postleitzahl": "3754 Diemtigen"},
 {"postleitzahl": "3755 Horboden"},
 {"postleitzahl": "3756 Zwischenflüh"},
 {"postleitzahl": "3757 Schwenden im Diemtigtal"},
 {"postleitzahl": "3758 Latterbach"},
 {"postleitzahl": "3762 Erlenbach im Simmental"},
 {"postleitzahl": "3763 Därstetten"},
 {"postleitzahl": "3764 Weissenburg"},
 {"postleitzahl": "3765 Oberwil im Simmental"},
 {"postleitzahl": "3766 Boltigen"},
 {"postleitzahl": "3770 Zweisimmen"},
 {"postleitzahl": "3771 Blankenburg"},
 {"postleitzahl": "3772 St. Stephan"},
 {"postleitzahl": "3773 Matten (St. Stephan)"},
 {"postleitzahl": "3775 Lenk im Simmental"},
 {"postleitzahl": "3776 Oeschseite"},
 {"postleitzahl": "3777 Saanenmöser"},
 {"postleitzahl": "3778 Schönried"},
 {"postleitzahl": "3780 Gstaad"},
 {"postleitzahl": "3781 Turbach"},
 {"postleitzahl": "3782 Lauenen b. Gstaad"},
 {"postleitzahl": "3783 Grund b. Gstaad"},
 {"postleitzahl": "3784 Feutersoey"},
 {"postleitzahl": "3785 Gsteig b. Gstaad"},
 {"postleitzahl": "3792 Saanen"},
 {"postleitzahl": "3800 Sundlauenen"},
 {"postleitzahl": "3800 Interlaken"},
 {"postleitzahl": "3800 Unterseen"},
 {"postleitzahl": "3800 Matten b. Interlaken"},
 {"postleitzahl": "3801 Jungfraujoch"},
 {"postleitzahl": "3803 Beatenberg"},
 {"postleitzahl": "3804 Habkern"},
 {"postleitzahl": "3805 Goldswil b. Interlaken"},
 {"postleitzahl": "3806 Bönigen b. Interlaken"},
 {"postleitzahl": "3807 Iseltwald"},
 {"postleitzahl": "3812 Wilderswil"},
 {"postleitzahl": "3813 Saxeten"},
 {"postleitzahl": "3814 Gsteigwiler"},
 {"postleitzahl": "3815 Zweilütschinen"},
 {"postleitzahl": "3815 Gündlischwand"},
 {"postleitzahl": "3816 Lütschental"},
 {"postleitzahl": "3816 Burglauenen"},
 {"postleitzahl": "3818 Grindelwald"},
 {"postleitzahl": "3822 Isenfluh"},
 {"postleitzahl": "3822 Lauterbrunnen"},
 {"postleitzahl": "3823 Kleine Scheidegg"},
 {"postleitzahl": "3823 Eigergletscher"},
 {"postleitzahl": "3823 Wengen"},
 {"postleitzahl": "3824 Stechelberg"},
 {"postleitzahl": "3825 Mürren"},
 {"postleitzahl": "3826 Gimmelwald"},
 {"postleitzahl": "3852 Ringgenberg BE"},
 {"postleitzahl": "3853 Niederried b. Interlaken"},
 {"postleitzahl": "3854 Oberried am Brienzersee"},
 {"postleitzahl": "3855 Schwanden b. Brienz"},
 {"postleitzahl": "3855 Brienz BE"},
 {"postleitzahl": "3855 Axalp"},
 {"postleitzahl": "3856 Brienzwiler"},
 {"postleitzahl": "3857 Unterbach BE"},
 {"postleitzahl": "3858 Hofstetten b. Brienz"},
 {"postleitzahl": "3860 Rosenlaui"},
 {"postleitzahl": "3860 Schattenhalb"},
 {"postleitzahl": "3860 Meiringen"},
 {"postleitzahl": "3860 Brünig"},
 {"postleitzahl": "3862 Innertkirchen"},
 {"postleitzahl": "3863 Gadmen"},
 {"postleitzahl": "3864 Guttannen"},
 {"postleitzahl": "3900 Gamsen"},
 {"postleitzahl": "3900 Brigerbad"},
 {"postleitzahl": "3900 Brig"},
 {"postleitzahl": "3901 Rothwald"},
 {"postleitzahl": "3902 Glis"},
 {"postleitzahl": "3903 Birgisch"},
 {"postleitzahl": "3903 Mund"},
 {"postleitzahl": "3904 Naters"},
 {"postleitzahl": "3905 Saas-Almagell"},
 {"postleitzahl": "3906 Saas-Fee"},
 {"postleitzahl": "3907 Simplon Hospiz"},
 {"postleitzahl": "3907 Gabi (Simplon)"},
 {"postleitzahl": "3907 Gondo"},
 {"postleitzahl": "3907 Simplon Dorf"},
 {"postleitzahl": "3908 Saas-Balen"},
 {"postleitzahl": "3910 Saas-Grund"},
 {"postleitzahl": "3911 Ried-Brig"},
 {"postleitzahl": "3912 Termen"},
 {"postleitzahl": "3913 Rosswald"},
 {"postleitzahl": "3914 Belalp"},
 {"postleitzahl": "3914 Blatten b. Naters"},
 {"postleitzahl": "3916 Ferden"},
 {"postleitzahl": "3917 Goppenstein"},
 {"postleitzahl": "3917 Kippel"},
 {"postleitzahl": "3918 Wiler (Lötschen)"},
 {"postleitzahl": "3919 Blatten (Lötschen)"},
 {"postleitzahl": "3920 Zermatt"},
 {"postleitzahl": "3922 Eisten"},
 {"postleitzahl": "3922 Kalpetran"},
 {"postleitzahl": "3922 Stalden VS"},
 {"postleitzahl": "3923 Törbel"},
 {"postleitzahl": "3924 Gasenried"},
 {"postleitzahl": "3924 St. Niklaus VS"},
 {"postleitzahl": "3925 Grächen"},
 {"postleitzahl": "3926 Embd"},
 {"postleitzahl": "3927 Herbriggen"},
 {"postleitzahl": "3928 Randa"},
 {"postleitzahl": "3929 Täsch"},
 {"postleitzahl": "3930 Eyholz"},
 {"postleitzahl": "3930 Visp"},
 {"postleitzahl": "3931 Lalden"},
 {"postleitzahl": "3932 Visperterminen"},
 {"postleitzahl": "3933 Staldenried"},
 {"postleitzahl": "3934 Zeneggen"},
 {"postleitzahl": "3935 Bürchen"},
 {"postleitzahl": "3937 Baltschieder"},
 {"postleitzahl": "3938 Ausserberg"},
 {"postleitzahl": "3939 Eggerberg"},
 {"postleitzahl": "3940 Steg VS"},
 {"postleitzahl": "3942 Niedergesteln"},
 {"postleitzahl": "3942 St. German"},
 {"postleitzahl": "3942 Raron"},
 {"postleitzahl": "3943 Eischoll"},
 {"postleitzahl": "3944 Unterbäch VS"},
 {"postleitzahl": "3945 Gampel"},
 {"postleitzahl": "3945 Niedergampel"},
 {"postleitzahl": "3946 Gruben"},
 {"postleitzahl": "3946 Turtmann"},
 {"postleitzahl": "3947 Ergisch"},
 {"postleitzahl": "3948 Unterems"},
 {"postleitzahl": "3948 Oberems"},
 {"postleitzahl": "3949 Hohtenn"},
 {"postleitzahl": "3951 Agarn"},
 {"postleitzahl": "3952 Susten"},
 {"postleitzahl": "3953 Inden"},
 {"postleitzahl": "3953 Varen"},
 {"postleitzahl": "3953 Leuk Stadt"},
 {"postleitzahl": "3954 Leukerbad"},
 {"postleitzahl": "3955 Albinen"},
 {"postleitzahl": "3956 Guttet-Feschel"},
 {"postleitzahl": "3957 Bratsch"},
 {"postleitzahl": "3957 Erschmatt"},
 {"postleitzahl": "3960 Corin-de-la-Crête"},
 {"postleitzahl": "3960 Loc"},
 {"postleitzahl": "3960 Muraz (Sierre)"},
 {"postleitzahl": "3960 Niouc"},
 {"postleitzahl": "3960 Sierre"},
 {"postleitzahl": "3961 Chandolin"},
 {"postleitzahl": "3961 Ayer"},
 {"postleitzahl": "3961 St-Jean VS"},
 {"postleitzahl": "3961 Vissoie"},
 {"postleitzahl": "3961 St-Luc"},
 {"postleitzahl": "3961 Zinal"},
 {"postleitzahl": "3961 Grimentz"},
 {"postleitzahl": "3963 Montana"},
 {"postleitzahl": "3963 Crans-Montana"},
 {"postleitzahl": "3963 Aminona"},
 {"postleitzahl": "3965 Chippis"},
 {"postleitzahl": "3966 Réchy"},
 {"postleitzahl": "3966 Chalais"},
 {"postleitzahl": "3967 Vercorin"},
 {"postleitzahl": "3968 Veyras"},
 {"postleitzahl": "3970 Salgesch"},
 {"postleitzahl": "3971 Chermignon-d'en-Bas"},
 {"postleitzahl": "3971 Ollon VS"},
 {"postleitzahl": "3971 Chermignon"},
 {"postleitzahl": "3972 Miège"},
 {"postleitzahl": "3973 Venthône"},
 {"postleitzahl": "3974 Mollens VS"},
 {"postleitzahl": "3975 Randogne"},
 {"postleitzahl": "3976 Champzabé"},
 {"postleitzahl": "3976 Noës"},
 {"postleitzahl": "3977 Granges VS"},
 {"postleitzahl": "3978 Flanthey"},
 {"postleitzahl": "3979 Grône"},
 {"postleitzahl": "3982 Bitsch"},
 {"postleitzahl": "3983 Bister"},
 {"postleitzahl": "3983 Filet"},
 {"postleitzahl": "3983 Goppisberg"},
 {"postleitzahl": "3983 Greich"},
 {"postleitzahl": "3983 Mörel"},
 {"postleitzahl": "3984 Fieschertal"},
 {"postleitzahl": "3984 Fiesch"},
 {"postleitzahl": "3985 Geschinen"},
 {"postleitzahl": "3985 Münster VS"},
 {"postleitzahl": "3986 Ried-Mörel"},
 {"postleitzahl": "3987 Riederalp"},
 {"postleitzahl": "3988 Obergesteln"},
 {"postleitzahl": "3988 Ulrichen"},
 {"postleitzahl": "3989 Niederwald"},
 {"postleitzahl": "3989 Blitzingen"},
 {"postleitzahl": "3989 Biel VS"},
 {"postleitzahl": "3989 Ritzingen"},
 {"postleitzahl": "3989 Selkingen"},
 {"postleitzahl": "3991 Betten"},
 {"postleitzahl": "3992 Bettmeralp"},
 {"postleitzahl": "3993 Grengiols"},
 {"postleitzahl": "3994 Lax"},
 {"postleitzahl": "3994 Martisberg"},
 {"postleitzahl": "3995 Ausserbinn"},
 {"postleitzahl": "3995 Mühlebach (Goms)"},
 {"postleitzahl": "3995 Steinhaus"},
 {"postleitzahl": "3995 Ernen"},
 {"postleitzahl": "3996 Binn"},
 {"postleitzahl": "3997 Bellwald"},
 {"postleitzahl": "3998 Gluringen"},
 {"postleitzahl": "3998 Reckingen VS"},
 {"postleitzahl": "3999 Oberwald"},
 {"postleitzahl": "4000 Basel"},
 {"postleitzahl": "4001 Basel"},
 {"postleitzahl": "4031 Basel Universitätsspital"},
 {"postleitzahl": "4051 Basel"},
 {"postleitzahl": "4052 Basel"},
 {"postleitzahl": "4053 Basel"},
 {"postleitzahl": "4054 Basel"},
 {"postleitzahl": "4055 Basel"},
 {"postleitzahl": "4056 Basel"},
 {"postleitzahl": "4057 Basel"},
 {"postleitzahl": "4058 Basel"},
 {"postleitzahl": "4059 Basel"},
 {"postleitzahl": "4101 Bruderholz"},
 {"postleitzahl": "4102 Binningen"},
 {"postleitzahl": "4103 Bottmingen"},
 {"postleitzahl": "4104 Oberwil BL"},
 {"postleitzahl": "4105 Biel-Benken BL"},
 {"postleitzahl": "4106 Therwil"},
 {"postleitzahl": "4107 Ettingen"},
 {"postleitzahl": "4108 Witterswil"},
 {"postleitzahl": "4112 Bättwil"},
 {"postleitzahl": "4112 Flüh"},
 {"postleitzahl": "4114 Hofstetten SO"},
 {"postleitzahl": "4115 Mariastein"},
 {"postleitzahl": "4116 Metzerlen"},
 {"postleitzahl": "4117 Burg im Leimental"},
 {"postleitzahl": "4118 Rodersdorf"},
 {"postleitzahl": "4123 Allschwil"},
 {"postleitzahl": "4124 Schönenbuch"},
 {"postleitzahl": "4125 Riehen"},
 {"postleitzahl": "4126 Bettingen"},
 {"postleitzahl": "4127 Birsfelden"},
 {"postleitzahl": "4132 Muttenz"},
 {"postleitzahl": "4133 Pratteln"},
 {"postleitzahl": "4142 Münchenstein"},
 {"postleitzahl": "4143 Dornach"},
 {"postleitzahl": "4144 Arlesheim"},
 {"postleitzahl": "4145 Gempen"},
 {"postleitzahl": "4146 Hochwald"},
 {"postleitzahl": "4147 Aesch BL"},
 {"postleitzahl": "4148 Pfeffingen"},
 {"postleitzahl": "4153 Reinach BL"},
 {"postleitzahl": "4202 Duggingen"},
 {"postleitzahl": "4203 Grellingen"},
 {"postleitzahl": "4204 Himmelried"},
 {"postleitzahl": "4206 Seewen SO"},
 {"postleitzahl": "4207 Bretzwil"},
 {"postleitzahl": "4208 Nunningen"},
 {"postleitzahl": "4222 Zwingen"},
 {"postleitzahl": "4223 Blauen"},
 {"postleitzahl": "4224 Nenzlingen"},
 {"postleitzahl": "4225 Brislach"},
 {"postleitzahl": "4226 Breitenbach"},
 {"postleitzahl": "4227 Büsserach"},
 {"postleitzahl": "4228 Erschwil"},
 {"postleitzahl": "4229 Beinwil SO"},
 {"postleitzahl": "4232 Fehren"},
 {"postleitzahl": "4233 Meltingen"},
 {"postleitzahl": "4234 Zullwil"},
 {"postleitzahl": "4242 Laufen"},
 {"postleitzahl": "4243 Dittingen"},
 {"postleitzahl": "4244 Röschenz"},
 {"postleitzahl": "4245 Kleinlützel"},
 {"postleitzahl": "4246 Wahlen b. Laufen"},
 {"postleitzahl": "4247 Grindel"},
 {"postleitzahl": "4252 Bärschwil"},
 {"postleitzahl": "4253 Liesberg"},
 {"postleitzahl": "4254 Liesberg Dorf"},
 {"postleitzahl": "4302 Augst BL"},
 {"postleitzahl": "4303 Kaiseraugst"},
 {"postleitzahl": "4304 Giebenach"},
 {"postleitzahl": "4305 Olsberg"},
 {"postleitzahl": "4310 Rheinfelden"},
 {"postleitzahl": "4312 Magden"},
 {"postleitzahl": "4313 Möhlin"},
 {"postleitzahl": "4314 Zeiningen"},
 {"postleitzahl": "4315 Zuzgen"},
 {"postleitzahl": "4316 Hellikon"},
 {"postleitzahl": "4317 Wegenstetten"},
 {"postleitzahl": "4322 Mumpf"},
 {"postleitzahl": "4323 Wallbach"},
 {"postleitzahl": "4324 Obermumpf"},
 {"postleitzahl": "4325 Schupfart"},
 {"postleitzahl": "4332 Stein AG"},
 {"postleitzahl": "4333 Münchwilen AG"},
 {"postleitzahl": "4334 Sisseln AG"},
 {"postleitzahl": "4402 Frenkendorf"},
 {"postleitzahl": "4410 Liestal"},
 {"postleitzahl": "4411 Seltisberg"},
 {"postleitzahl": "4412 Nuglar"},
 {"postleitzahl": "4413 Büren SO"},
 {"postleitzahl": "4414 Füllinsdorf"},
 {"postleitzahl": "4415 Lausen"},
 {"postleitzahl": "4416 Bubendorf"},
 {"postleitzahl": "4417 Ziefen"},
 {"postleitzahl": "4418 Reigoldswil"},
 {"postleitzahl": "4419 Lupsingen"},
 {"postleitzahl": "4421 St. Pantaleon"},
 {"postleitzahl": "4422 Arisdorf"},
 {"postleitzahl": "4423 Hersberg"},
 {"postleitzahl": "4424 Arboldswil"},
 {"postleitzahl": "4425 Titterten"},
 {"postleitzahl": "4426 Lauwil"},
 {"postleitzahl": "4431 Bennwil"},
 {"postleitzahl": "4432 Lampenberg"},
 {"postleitzahl": "4433 Ramlinsburg"},
 {"postleitzahl": "4434 Hölstein"},
 {"postleitzahl": "4435 Niederdorf"},
 {"postleitzahl": "4436 Liedertswil"},
 {"postleitzahl": "4436 Oberdorf BL"},
 {"postleitzahl": "4437 Waldenburg"},
 {"postleitzahl": "4438 Langenbruck"},
 {"postleitzahl": "4441 Thürnen"},
 {"postleitzahl": "4442 Diepflingen"},
 {"postleitzahl": "4443 Wittinsburg"},
 {"postleitzahl": "4444 Rümlingen"},
 {"postleitzahl": "4445 Häfelfingen"},
 {"postleitzahl": "4446 Buckten"},
 {"postleitzahl": "4447 Känerkinden"},
 {"postleitzahl": "4448 Läufelfingen"},
 {"postleitzahl": "4450 Sissach"},
 {"postleitzahl": "4451 Wintersingen"},
 {"postleitzahl": "4452 Itingen"},
 {"postleitzahl": "4453 Nusshof"},
 {"postleitzahl": "4455 Zunzgen"},
 {"postleitzahl": "4456 Tenniken"},
 {"postleitzahl": "4457 Diegten"},
 {"postleitzahl": "4458 Eptingen"},
 {"postleitzahl": "4460 Gelterkinden"},
 {"postleitzahl": "4461 Böckten"},
 {"postleitzahl": "4462 Rickenbach BL"},
 {"postleitzahl": "4463 Buus"},
 {"postleitzahl": "4464 Maisprach"},
 {"postleitzahl": "4465 Hemmiken"},
 {"postleitzahl": "4466 Ormalingen"},
 {"postleitzahl": "4467 Rothenfluh"},
 {"postleitzahl": "4468 Kienberg"},
 {"postleitzahl": "4469 Anwil"},
 {"postleitzahl": "4492 Tecknau"},
 {"postleitzahl": "4493 Wenslingen"},
 {"postleitzahl": "4494 Oltingen"},
 {"postleitzahl": "4495 Zeglingen"},
 {"postleitzahl": "4496 Kilchberg BL"},
 {"postleitzahl": "4497 Rünenberg"},
 {"postleitzahl": "4500 Solothurn"},
 {"postleitzahl": "4512 Bellach"},
 {"postleitzahl": "4513 Langendorf"},
 {"postleitzahl": "4514 Lommiswil"},
 {"postleitzahl": "4515 Weissenstein b. Solothurn"},
 {"postleitzahl": "4515 Oberdorf SO"},
 {"postleitzahl": "4522 Rüttenen"},
 {"postleitzahl": "4523 Niederwil SO"},
 {"postleitzahl": "4524 Balmberg"},
 {"postleitzahl": "4524 Oberbalmberg"},
 {"postleitzahl": "4524 Günsberg"},
 {"postleitzahl": "4525 Balm b. Günsberg"},
 {"postleitzahl": "4528 Zuchwil"},
 {"postleitzahl": "4532 Feldbrunnen"},
 {"postleitzahl": "4533 Riedholz"},
 {"postleitzahl": "4534 Flumenthal"},
 {"postleitzahl": "4535 Hubersdorf"},
 {"postleitzahl": "4535 Kammersrohr"},
 {"postleitzahl": "4536 Attiswil"},
 {"postleitzahl": "4537 Wiedlisbach"},
 {"postleitzahl": "4538 Oberbipp"},
 {"postleitzahl": "4539 Rumisberg"},
 {"postleitzahl": "4539 Farnern"},
 {"postleitzahl": "4542 Luterbach"},
 {"postleitzahl": "4543 Deitingen"},
 {"postleitzahl": "4552 Derendingen"},
 {"postleitzahl": "4553 Subingen"},
 {"postleitzahl": "4554 Hüniken"},
 {"postleitzahl": "4554 Etziken"},
 {"postleitzahl": "4556 Bolken"},
 {"postleitzahl": "4556 Burgäschi"},
 {"postleitzahl": "4556 Steinhof SO"},
 {"postleitzahl": "4556 Aeschi SO"},
 {"postleitzahl": "4557 Horriwil"},
 {"postleitzahl": "4558 Hersiwil"},
 {"postleitzahl": "4558 Heinrichswil"},
 {"postleitzahl": "4558 Winistorf"},
 {"postleitzahl": "4562 Biberist"},
 {"postleitzahl": "4563 Gerlafingen"},
 {"postleitzahl": "4564 Zielebach"},
 {"postleitzahl": "4564 Obergerlafingen"},
 {"postleitzahl": "4565 Recherswil"},
 {"postleitzahl": "4566 Halten"},
 {"postleitzahl": "4566 Oekingen"},
 {"postleitzahl": "4566 Kriegstetten"},
 {"postleitzahl": "4571 Ichertswil"},
 {"postleitzahl": "4571 Lüterkofen"},
 {"postleitzahl": "4573 Lohn-Ammannsegg"},
 {"postleitzahl": "4574 Lüsslingen"},
 {"postleitzahl": "4574 Nennigkofen"},
 {"postleitzahl": "4576 Tscheppach"},
 {"postleitzahl": "4577 Hessigkofen"},
 {"postleitzahl": "4578 Bibern SO"},
 {"postleitzahl": "4579 Gossliwil"},
 {"postleitzahl": "4581 Küttigkofen"},
 {"postleitzahl": "4582 Brügglen"},
 {"postleitzahl": "4583 Aetigkofen"},
 {"postleitzahl": "4583 Mühledorf SO"},
 {"postleitzahl": "4584 Gächliwil"},
 {"postleitzahl": "4584 Lüterswil"},
 {"postleitzahl": "4585 Biezwil"},
 {"postleitzahl": "4586 Kyburg-Buchegg"},
 {"postleitzahl": "4587 Aetingen"},
 {"postleitzahl": "4588 Unterramsern"},
 {"postleitzahl": "4588 Oberramsern"},
 {"postleitzahl": "4588 Brittern"},
 {"postleitzahl": "4600 Olten"},
 {"postleitzahl": "4612 Wangen b. Olten"},
 {"postleitzahl": "4613 Rickenbach SO"},
 {"postleitzahl": "4614 Hägendorf"},
 {"postleitzahl": "4615 Allerheiligenberg"},
 {"postleitzahl": "4616 Kappel SO"},
 {"postleitzahl": "4617 Gunzgen"},
 {"postleitzahl": "4618 Boningen"},
 {"postleitzahl": "4622 Egerkingen"},
 {"postleitzahl": "4623 Neuendorf"},
 {"postleitzahl": "4624 Härkingen"},
 {"postleitzahl": "4625 Oberbuchsiten"},
 {"postleitzahl": "4626 Niederbuchsiten"},
 {"postleitzahl": "4628 Wolfwil"},
 {"postleitzahl": "4629 Fulenbach"},
 {"postleitzahl": "4632 Trimbach"},
 {"postleitzahl": "4633 Hauenstein"},
 {"postleitzahl": "4634 Wisen SO"},
 {"postleitzahl": "4652 Winznau"},
 {"postleitzahl": "4653 Obergösgen"},
 {"postleitzahl": "4654 Lostorf"},
 {"postleitzahl": "4655 Rohr b. Olten"},
 {"postleitzahl": "4655 Stüsslingen"},
 {"postleitzahl": "4656 Starrkirch-Wil"},
 {"postleitzahl": "4657 Dulliken"},
 {"postleitzahl": "4658 Däniken SO"},
 {"postleitzahl": "4663 Aarburg"},
 {"postleitzahl": "4665 Oftringen"},
 {"postleitzahl": "4702 Oensingen"},
 {"postleitzahl": "4703 Kestenholz"},
 {"postleitzahl": "4704 Wolfisberg"},
 {"postleitzahl": "4704 Niederbipp"},
 {"postleitzahl": "4710 Balsthal"},
 {"postleitzahl": "4712 Laupersdorf"},
 {"postleitzahl": "4713 Matzendorf"},
 {"postleitzahl": "4714 Aedermannsdorf"},
 {"postleitzahl": "4715 Herbetswil"},
 {"postleitzahl": "4716 Gänsbrunnen"},
 {"postleitzahl": "4716 Welschenrohr"},
 {"postleitzahl": "4717 Mümliswil"},
 {"postleitzahl": "4718 Holderbank SO"},
 {"postleitzahl": "4719 Ramiswil"},
 {"postleitzahl": "4800 Zofingen"},
 {"postleitzahl": "4802 Strengelbach"},
 {"postleitzahl": "4803 Vordemwald"},
 {"postleitzahl": "4805 Brittnau"},
 {"postleitzahl": "4806 Wikon"},
 {"postleitzahl": "4812 Mühlethal"},
 {"postleitzahl": "4813 Uerkheim"},
 {"postleitzahl": "4814 Bottenwil"},
 {"postleitzahl": "4852 Rothrist"},
 {"postleitzahl": "4853 Riken AG"},
 {"postleitzahl": "4853 Murgenthal"},
 {"postleitzahl": "4856 Glashütten"},
 {"postleitzahl": "4900 Langenthal"},
 {"postleitzahl": "4911 Schwarzhäusern"},
 {"postleitzahl": "4912 Aarwangen"},
 {"postleitzahl": "4913 Bannwil"},
 {"postleitzahl": "4914 Roggwil BE"},
 {"postleitzahl": "4915 St. Urban"},
 {"postleitzahl": "4916 Untersteckholz"},
 {"postleitzahl": "4917 Busswil b. Melchnau"},
 {"postleitzahl": "4917 Melchnau"},
 {"postleitzahl": "4919 Reisiswil"},
 {"postleitzahl": "4922 Thunstetten"},
 {"postleitzahl": "4922 Bützberg"},
 {"postleitzahl": "4923 Wynau"},
 {"postleitzahl": "4924 Obersteckholz"},
 {"postleitzahl": "4932 Gutenburg"},
 {"postleitzahl": "4932 Lotzwil"},
 {"postleitzahl": "4933 Rütschelen"},
 {"postleitzahl": "4934 Madiswil"},
 {"postleitzahl": "4935 Leimiswil"},
 {"postleitzahl": "4936 Kleindietwil"},
 {"postleitzahl": "4937 Ursenbach"},
 {"postleitzahl": "4938 Rohrbachgraben"},
 {"postleitzahl": "4938 Rohrbach"},
 {"postleitzahl": "4942 Walterswil BE"},
 {"postleitzahl": "4943 Oeschenbach"},
 {"postleitzahl": "4944 Auswil"},
 {"postleitzahl": "4950 Huttwil"},
 {"postleitzahl": "4952 Eriswil"},
 {"postleitzahl": "4953 Schwarzenbach (Huttwil)"},
 {"postleitzahl": "4954 Wyssachen"},
 {"postleitzahl": "4955 Gondiswil"},
 {"postleitzahl": "5000 Aarau"},
 {"postleitzahl": "5004 Aarau"},
 {"postleitzahl": "5012 Eppenberg"},
 {"postleitzahl": "5012 Wöschnau"},
 {"postleitzahl": "5012 Schönenwerd"},
 {"postleitzahl": "5013 Niedergösgen"},
 {"postleitzahl": "5014 Gretzenbach"},
 {"postleitzahl": "5015 Erlinsbach SO"},
 {"postleitzahl": "5017 Barmelweid"},
 {"postleitzahl": "5018 Erlinsbach"},
 {"postleitzahl": "5022 Rombach"},
 {"postleitzahl": "5023 Biberstein"},
 {"postleitzahl": "5024 Küttigen"},
 {"postleitzahl": "5025 Asp"},
 {"postleitzahl": "5026 Densbüren"},
 {"postleitzahl": "5027 Herznach"},
 {"postleitzahl": "5028 Ueken"},
 {"postleitzahl": "5032 Aarau Rohr"},
 {"postleitzahl": "5033 Buchs AG"},
 {"postleitzahl": "5034 Suhr"},
 {"postleitzahl": "5035 Unterentfelden"},
 {"postleitzahl": "5036 Oberentfelden"},
 {"postleitzahl": "5037 Muhen"},
 {"postleitzahl": "5040 Schöftland"},
 {"postleitzahl": "5042 Hirschthal"},
 {"postleitzahl": "5043 Holziken"},
 {"postleitzahl": "5044 Schlossrued"},
 {"postleitzahl": "5046 Schmiedrued"},
 {"postleitzahl": "5046 Walde AG"},
 {"postleitzahl": "5046 Schmiedrued-Walde"},
 {"postleitzahl": "5053 Wittwil"},
 {"postleitzahl": "5053 Staffelbach"},
 {"postleitzahl": "5054 Kirchleerau"},
 {"postleitzahl": "5054 Moosleerau"},
 {"postleitzahl": "5056 Attelwil"},
 {"postleitzahl": "5057 Reitnau"},
 {"postleitzahl": "5058 Wiliberg"},
 {"postleitzahl": "5062 Oberhof"},
 {"postleitzahl": "5063 Wölflinswil"},
 {"postleitzahl": "5064 Wittnau"},
 {"postleitzahl": "5070 Frick"},
 {"postleitzahl": "5072 Oeschgen"},
 {"postleitzahl": "5073 Gipf-Oberfrick"},
 {"postleitzahl": "5074 Eiken"},
 {"postleitzahl": "5075 Hornussen"},
 {"postleitzahl": "5076 Bözen"},
 {"postleitzahl": "5077 Elfingen"},
 {"postleitzahl": "5078 Effingen"},
 {"postleitzahl": "5079 Zeihen"},
 {"postleitzahl": "5080 Laufenburg"},
 {"postleitzahl": "5082 Kaisten"},
 {"postleitzahl": "5083 Ittenthal"},
 {"postleitzahl": "5084 Rheinsulz"},
 {"postleitzahl": "5085 Sulz AG"},
 {"postleitzahl": "5102 Rupperswil"},
 {"postleitzahl": "5103 Wildegg"},
 {"postleitzahl": "5103 Möriken AG"},
 {"postleitzahl": "5105 Auenstein"},
 {"postleitzahl": "5106 Veltheim AG"},
 {"postleitzahl": "5107 Schinznach Dorf"},
 {"postleitzahl": "5108 Oberflachs"},
 {"postleitzahl": "5112 Thalheim AG"},
 {"postleitzahl": "5113 Holderbank AG"},
 {"postleitzahl": "5116 Schinznach Bad"},
 {"postleitzahl": "5200 Brugg AG"},
 {"postleitzahl": "5210 Windisch"},
 {"postleitzahl": "5212 Hausen AG"},
 {"postleitzahl": "5213 Villnachern"},
 {"postleitzahl": "5222 Umiken"},
 {"postleitzahl": "5223 Riniken"},
 {"postleitzahl": "5224 Gallenkirch"},
 {"postleitzahl": "5224 Linn"},
 {"postleitzahl": "5224 Unterbözberg"},
 {"postleitzahl": "5225 Oberbözberg"},
 {"postleitzahl": "5233 Stilli"},
 {"postleitzahl": "5234 Villigen"},
 {"postleitzahl": "5235 Rüfenach AG"},
 {"postleitzahl": "5236 Remigen"},
 {"postleitzahl": "5237 Mönthal"},
 {"postleitzahl": "5242 Birr"},
 {"postleitzahl": "5242 Lupfig"},
 {"postleitzahl": "5243 Mülligen"},
 {"postleitzahl": "5244 Birrhard"},
 {"postleitzahl": "5245 Habsburg"},
 {"postleitzahl": "5246 Scherz"},
 {"postleitzahl": "5272 Gansingen"},
 {"postleitzahl": "5273 Oberhofen AG"},
 {"postleitzahl": "5274 Mettau"},
 {"postleitzahl": "5275 Etzgen"},
 {"postleitzahl": "5276 Wil AG"},
 {"postleitzahl": "5277 Hottwil"},
 {"postleitzahl": "5300 Turgi"},
 {"postleitzahl": "5301 Siggenthal Station"},
 {"postleitzahl": "5303 Würenlingen"},
 {"postleitzahl": "5304 Endingen"},
 {"postleitzahl": "5305 Unterendingen"},
 {"postleitzahl": "5306 Tegerfelden"},
 {"postleitzahl": "5312 Döttingen"},
 {"postleitzahl": "5313 Klingnau"},
 {"postleitzahl": "5314 Kleindöttingen"},
 {"postleitzahl": "5315 Böttstein"},
 {"postleitzahl": "5316 Felsenau AG"},
 {"postleitzahl": "5316 Leuggern"},
 {"postleitzahl": "5317 Hettenschwil"},
 {"postleitzahl": "5318 Mandach"},
 {"postleitzahl": "5322 Koblenz"},
 {"postleitzahl": "5323 Rietheim"},
 {"postleitzahl": "5324 Full-Reuenthal"},
 {"postleitzahl": "5325 Leibstadt"},
 {"postleitzahl": "5326 Schwaderloch"},
 {"postleitzahl": "5330 Bad Zurzach"},
 {"postleitzahl": "5332 Rekingen AG"},
 {"postleitzahl": "5333 Baldingen"},
 {"postleitzahl": "5334 Böbikon"},
 {"postleitzahl": "5400 Baden"},
 {"postleitzahl": "5404 Baden"},
 {"postleitzahl": "5405 Dättwil AG"},
 {"postleitzahl": "5406 Rütihof"},
 {"postleitzahl": "5408 Ennetbaden"},
 {"postleitzahl": "5412 Gebenstorf"},
 {"postleitzahl": "5413 Birmenstorf AG"},
 {"postleitzahl": "5415 Hertenstein AG"},
 {"postleitzahl": "5415 Rieden AG"},
 {"postleitzahl": "5415 Nussbaumen AG"},
 {"postleitzahl": "5416 Kirchdorf AG"},
 {"postleitzahl": "5417 Untersiggenthal"},
 {"postleitzahl": "5420 Ehrendingen"},
 {"postleitzahl": "5423 Freienwil"},
 {"postleitzahl": "5425 Schneisingen"},
 {"postleitzahl": "5426 Lengnau AG"},
 {"postleitzahl": "5430 Wettingen"},
 {"postleitzahl": "5432 Neuenhof"},
 {"postleitzahl": "5436 Würenlos"},
 {"postleitzahl": "5442 Fislisbach"},
 {"postleitzahl": "5443 Niederrohrdorf"},
 {"postleitzahl": "5444 Künten"},
 {"postleitzahl": "5445 Eggenwil"},
 {"postleitzahl": "5452 Oberrohrdorf"},
 {"postleitzahl": "5453 Remetschwil"},
 {"postleitzahl": "5454 Bellikon"},
 {"postleitzahl": "5462 Siglistorf"},
 {"postleitzahl": "5463 Wislikofen"},
 {"postleitzahl": "5464 Rümikon AG"},
 {"postleitzahl": "5465 Mellikon"},
 {"postleitzahl": "5466 Kaiserstuhl AG"},
 {"postleitzahl": "5467 Fisibach"},
 {"postleitzahl": "5502 Hunzenschwil"},
 {"postleitzahl": "5503 Schafisheim"},
 {"postleitzahl": "5504 Othmarsingen"},
 {"postleitzahl": "5505 Brunegg"},
 {"postleitzahl": "5506 Mägenwil"},
 {"postleitzahl": "5507 Mellingen"},
 {"postleitzahl": "5512 Wohlenschwil"},
 {"postleitzahl": "5522 Tägerig"},
 {"postleitzahl": "5524 Nesselnbach"},
 {"postleitzahl": "5524 Niederwil AG"},
 {"postleitzahl": "5525 Fischbach-Göslikon"},
 {"postleitzahl": "5600 Lenzburg"},
 {"postleitzahl": "5600 Ammerswil AG"},
 {"postleitzahl": "5603 Staufen"},
 {"postleitzahl": "5604 Hendschiken"},
 {"postleitzahl": "5605 Dottikon"},
 {"postleitzahl": "5606 Dintikon"},
 {"postleitzahl": "5607 Hägglingen"},
 {"postleitzahl": "5608 Stetten AG"},
 {"postleitzahl": "5610 Wohlen AG"},
 {"postleitzahl": "5611 Anglikon"},
 {"postleitzahl": "5612 Villmergen"},
 {"postleitzahl": "5613 Hilfikon"},
 {"postleitzahl": "5614 Sarmenstorf"},
 {"postleitzahl": "5615 Fahrwangen"},
 {"postleitzahl": "5616 Meisterschwanden"},
 {"postleitzahl": "5617 Tennwil"},
 {"postleitzahl": "5618 Bettwil"},
 {"postleitzahl": "5619 Büttikon AG"},
 {"postleitzahl": "5619 Uezwil"},
 {"postleitzahl": "5620 Bremgarten AG"},
 {"postleitzahl": "5621 Zufikon"},
 {"postleitzahl": "5622 Waltenschwil"},
 {"postleitzahl": "5623 Boswil"},
 {"postleitzahl": "5624 Waldhäusern AG"},
 {"postleitzahl": "5624 Bünzen"},
 {"postleitzahl": "5625 Kallern"},
 {"postleitzahl": "5626 Hermetschwil-Staffeln"},
 {"postleitzahl": "5627 Besenbüren"},
 {"postleitzahl": "5628 Aristau"},
 {"postleitzahl": "5630 Muri AG"},
 {"postleitzahl": "5632 Buttwil"},
 {"postleitzahl": "5634 Merenschwand"},
 {"postleitzahl": "5636 Benzenschwil"},
 {"postleitzahl": "5637 Geltwil"},
 {"postleitzahl": "5637 Beinwil (Freiamt)"},
 {"postleitzahl": "5642 Mühlau"},
 {"postleitzahl": "5643 Alikon"},
 {"postleitzahl": "5643 Meienberg"},
 {"postleitzahl": "5643 Sins"},
 {"postleitzahl": "5644 Auw"},
 {"postleitzahl": "5645 Aettenschwil"},
 {"postleitzahl": "5645 Fenkrieden"},
 {"postleitzahl": "5646 Abtwil AG"},
 {"postleitzahl": "5647 Oberrüti"},
 {"postleitzahl": "5702 Niederlenz"},
 {"postleitzahl": "5703 Seon"},
 {"postleitzahl": "5704 Egliswil"},
 {"postleitzahl": "5705 Hallwil"},
 {"postleitzahl": "5706 Boniswil"},
 {"postleitzahl": "5707 Seengen"},
 {"postleitzahl": "5708 Birrwil"},
 {"postleitzahl": "5712 Beinwil am See"},
 {"postleitzahl": "5722 Gränichen"},
 {"postleitzahl": "5723 Teufenthal AG"},
 {"postleitzahl": "5724 Dürrenäsch"},
 {"postleitzahl": "5725 Leutwil"},
 {"postleitzahl": "5726 Unterkulm"},
 {"postleitzahl": "5727 Oberkulm"},
 {"postleitzahl": "5728 Gontenschwil"},
 {"postleitzahl": "5732 Zetzwil"},
 {"postleitzahl": "5733 Leimbach AG"},
 {"postleitzahl": "5734 Reinach AG"},
 {"postleitzahl": "5735 Pfeffikon LU"},
 {"postleitzahl": "5736 Burg AG"},
 {"postleitzahl": "5737 Menziken"},
 {"postleitzahl": "5742 Kölliken"},
 {"postleitzahl": "5745 Safenwil"},
 {"postleitzahl": "5746 Walterswil SO"},
 {"postleitzahl": "6000 Luzern"},
 {"postleitzahl": "6003 Luzern"},
 {"postleitzahl": "6004 Luzern"},
 {"postleitzahl": "6005 Luzern"},
 {"postleitzahl": "6005 St. Niklausen LU"},
 {"postleitzahl": "6006 Luzern"},
 {"postleitzahl": "6010 Kriens"},
 {"postleitzahl": "6010 Pilatus Kulm"},
 {"postleitzahl": "6012 Obernau"},
 {"postleitzahl": "6013 Eigenthal"},
 {"postleitzahl": "6014 Luzern"},
 {"postleitzahl": "6015 Luzern"},
 {"postleitzahl": "6016 Hellbühl"},
 {"postleitzahl": "6017 Ruswil"},
 {"postleitzahl": "6018 Buttisholz"},
 {"postleitzahl": "6019 Sigigen"},
 {"postleitzahl": "6020 Emmenbrücke"},
 {"postleitzahl": "6022 Grosswangen"},
 {"postleitzahl": "6023 Rothenburg"},
 {"postleitzahl": "6024 Hildisrieden"},
 {"postleitzahl": "6025 Neudorf"},
 {"postleitzahl": "6026 Rain"},
 {"postleitzahl": "6027 Römerswil LU"},
 {"postleitzahl": "6028 Herlisberg"},
 {"postleitzahl": "6030 Ebikon"},
 {"postleitzahl": "6032 Emmen"},
 {"postleitzahl": "6033 Buchrain"},
 {"postleitzahl": "6034 Inwil"},
 {"postleitzahl": "6035 Perlen"},
 {"postleitzahl": "6036 Dierikon"},
 {"postleitzahl": "6037 Root"},
 {"postleitzahl": "6038 Gisikon"},
 {"postleitzahl": "6038 Honau"},
 {"postleitzahl": "6039 Root Längenbold"},
 {"postleitzahl": "6042 Dietwil"},
 {"postleitzahl": "6043 Adligenswil"},
 {"postleitzahl": "6044 Udligenswil"},
 {"postleitzahl": "6045 Meggen"},
 {"postleitzahl": "6047 Kastanienbaum"},
 {"postleitzahl": "6048 Horw"},
 {"postleitzahl": "6052 Hergiswil NW"},
 {"postleitzahl": "6053 Alpnachstad"},
 {"postleitzahl": "6055 Alpnach Dorf"},
 {"postleitzahl": "6056 Kägiswil"},
 {"postleitzahl": "6060 Sarnen"},
 {"postleitzahl": "6060 Ramersberg"},
 {"postleitzahl": "6062 Wilen (Sarnen)"},
 {"postleitzahl": "6063 Stalden (Sarnen)"},
 {"postleitzahl": "6064 Kerns"},
 {"postleitzahl": "6066 St. Niklausen OW"},
 {"postleitzahl": "6067 Melchtal"},
 {"postleitzahl": "6068 Melchsee-Frutt"},
 {"postleitzahl": "6072 Sachseln"},
 {"postleitzahl": "6073 Flüeli-Ranft"},
 {"postleitzahl": "6074 Giswil"},
 {"postleitzahl": "6078 Bürglen OW"},
 {"postleitzahl": "6078 Lungern"},
 {"postleitzahl": "6083 Hasliberg Hohfluh"},
 {"postleitzahl": "6084 Hasliberg Wasserwendi"},
 {"postleitzahl": "6085 Hasliberg Goldern"},
 {"postleitzahl": "6086 Hasliberg Reuti"},
 {"postleitzahl": "6102 Malters"},
 {"postleitzahl": "6103 Schwarzenberg LU"},
 {"postleitzahl": "6105 Schachen LU"},
 {"postleitzahl": "6106 Werthenstein"},
 {"postleitzahl": "6110 Fontannen b. Wolhusen"},
 {"postleitzahl": "6110 Wolhusen"},
 {"postleitzahl": "6112 Doppleschwand"},
 {"postleitzahl": "6113 Romoos"},
 {"postleitzahl": "6114 Steinhuserberg"},
 {"postleitzahl": "6122 Menznau"},
 {"postleitzahl": "6123 Geiss"},
 {"postleitzahl": "6125 Menzberg"},
 {"postleitzahl": "6126 Daiwil"},
 {"postleitzahl": "6130 Willisau"},
 {"postleitzahl": "6132 Rohrmatt"},
 {"postleitzahl": "6133 Hergiswil b. Willisau"},
 {"postleitzahl": "6142 Gettnau"},
 {"postleitzahl": "6143 Ohmstal"},
 {"postleitzahl": "6144 Zell LU"},
 {"postleitzahl": "6145 Fischbach LU"},
 {"postleitzahl": "6146 Grossdietwil"},
 {"postleitzahl": "6147 Altbüron"},
 {"postleitzahl": "6152 Hüswil"},
 {"postleitzahl": "6153 Ufhusen"},
 {"postleitzahl": "6154 Hofstatt"},
 {"postleitzahl": "6156 Luthern Bad"},
 {"postleitzahl": "6156 Luthern"},
 {"postleitzahl": "6162 Rengg"},
 {"postleitzahl": "6162 Finsterwald b. Entlebuch"},
 {"postleitzahl": "6162 Entlebuch"},
 {"postleitzahl": "6163 Ebnet"},
 {"postleitzahl": "6166 Hasle LU"},
 {"postleitzahl": "6167 Bramboden"},
 {"postleitzahl": "6170 Schüpfheim"},
 {"postleitzahl": "6173 Flühli LU"},
 {"postleitzahl": "6174 Sörenberg"},
 {"postleitzahl": "6182 Escholzmatt"},
 {"postleitzahl": "6192 Wiggen"},
 {"postleitzahl": "6196 Marbach LU"},
 {"postleitzahl": "6197 Schangnau"},
 {"postleitzahl": "6203 Sempach Station"},
 {"postleitzahl": "6204 Sempach"},
 {"postleitzahl": "6205 Eich"},
 {"postleitzahl": "6206 Neuenkirch"},
 {"postleitzahl": "6207 Nottwil"},
 {"postleitzahl": "6208 Oberkirch LU"},
 {"postleitzahl": "6210 Sursee"},
 {"postleitzahl": "6211 Buchs LU"},
 {"postleitzahl": "6212 Kaltbach"},
 {"postleitzahl": "6212 St. Erhard"},
 {"postleitzahl": "6213 Knutwil"},
 {"postleitzahl": "6214 Schenkon"},
 {"postleitzahl": "6215 Schwarzenbach Beromünster"},
 {"postleitzahl": "6215 Beromünster"},
 {"postleitzahl": "6216 Mauensee"},
 {"postleitzahl": "6217 Kottwil"},
 {"postleitzahl": "6218 Ettiswil"},
 {"postleitzahl": "6221 Rickenbach LU"},
 {"postleitzahl": "6222 Gunzwil"},
 {"postleitzahl": "6231 Schlierbach"},
 {"postleitzahl": "6232 Geuensee"},
 {"postleitzahl": "6233 Büron"},
 {"postleitzahl": "6234 Kulmerau"},
 {"postleitzahl": "6234 Triengen"},
 {"postleitzahl": "6235 Winikon"},
 {"postleitzahl": "6236 Wilihof"},
 {"postleitzahl": "6242 Wauwil"},
 {"postleitzahl": "6243 Egolzwil"},
 {"postleitzahl": "6244 Nebikon"},
 {"postleitzahl": "6245 Ebersecken"},
 {"postleitzahl": "6246 Altishofen"},
 {"postleitzahl": "6247 Schötz"},
 {"postleitzahl": "6248 Alberswil"},
 {"postleitzahl": "6252 Dagmersellen"},
 {"postleitzahl": "6253 Uffikon"},
 {"postleitzahl": "6260 Reidermoos"},
 {"postleitzahl": "6260 Hintermoos"},
 {"postleitzahl": "6260 Mehlsecken"},
 {"postleitzahl": "6260 Reiden"},
 {"postleitzahl": "6262 Langnau b. Reiden"},
 {"postleitzahl": "6263 Richenthal"},
 {"postleitzahl": "6264 Pfaffnau"},
 {"postleitzahl": "6265 Roggliswil"},
 {"postleitzahl": "6274 Eschenbach LU"},
 {"postleitzahl": "6275 Ballwil"},
 {"postleitzahl": "6276 Hohenrain"},
 {"postleitzahl": "6277 Kleinwangen"},
 {"postleitzahl": "6277 Lieli LU"},
 {"postleitzahl": "6280 Hochdorf"},
 {"postleitzahl": "6280 Urswil"},
 {"postleitzahl": "6283 Baldegg"},
 {"postleitzahl": "6284 Sulz LU"},
 {"postleitzahl": "6284 Gelfingen"},
 {"postleitzahl": "6285 Retschwil"},
 {"postleitzahl": "6285 Hitzkirch"},
 {"postleitzahl": "6286 Altwis"},
 {"postleitzahl": "6287 Aesch LU"},
 {"postleitzahl": "6288 Schongau"},
 {"postleitzahl": "6289 Müswangen"},
 {"postleitzahl": "6289 Hämikon"},
 {"postleitzahl": "6294 Ermensee"},
 {"postleitzahl": "6295 Mosen"},
 {"postleitzahl": "6300 Zug"},
 {"postleitzahl": "6300 Zugerberg"},
 {"postleitzahl": "6312 Steinhausen"},
 {"postleitzahl": "6313 Edlibach"},
 {"postleitzahl": "6313 Finstersee"},
 {"postleitzahl": "6313 Menzingen"},
 {"postleitzahl": "6314 Neuägeri"},
 {"postleitzahl": "6314 Unterägeri"},
 {"postleitzahl": "6315 Morgarten"},
 {"postleitzahl": "6315 Alosen"},
 {"postleitzahl": "6315 Oberägeri"},
 {"postleitzahl": "6317 Oberwil b. Zug"},
 {"postleitzahl": "6318 Walchwil"},
 {"postleitzahl": "6319 Allenwinden"},
 {"postleitzahl": "6330 Cham"},
 {"postleitzahl": "6331 Hünenberg"},
 {"postleitzahl": "6332 Hagendorn"},
 {"postleitzahl": "6333 Hünenberg See"},
 {"postleitzahl": "6340 Baar"},
 {"postleitzahl": "6340 Sihlbrugg"},
 {"postleitzahl": "6343 Buonas"},
 {"postleitzahl": "6343 Risch"},
 {"postleitzahl": "6343 Holzhäusern ZG"},
 {"postleitzahl": "6343 Rotkreuz"},
 {"postleitzahl": "6344 Meierskappel"},
 {"postleitzahl": "6345 Neuheim"},
 {"postleitzahl": "6353 Weggis"},
 {"postleitzahl": "6354 Vitznau"},
 {"postleitzahl": "6356 Rigi Kaltbad"},
 {"postleitzahl": "6362 Stansstad"},
 {"postleitzahl": "6363 Obbürgen"},
 {"postleitzahl": "6363 Bürgenstock"},
 {"postleitzahl": "6363 Fürigen"},
 {"postleitzahl": "6365 Kehrsiten"},
 {"postleitzahl": "6370 Stans"},
 {"postleitzahl": "6370 Oberdorf NW"},
 {"postleitzahl": "6372 Ennetmoos"},
 {"postleitzahl": "6373 Ennetbürgen"},
 {"postleitzahl": "6374 Buochs"},
 {"postleitzahl": "6375 Beckenried"},
 {"postleitzahl": "6376 Emmetten"},
 {"postleitzahl": "6377 Seelisberg"},
 {"postleitzahl": "6382 Büren NW"},
 {"postleitzahl": "6383 Niederrickenbach"},
 {"postleitzahl": "6383 Wiesenberg"},
 {"postleitzahl": "6383 Wirzweli"},
 {"postleitzahl": "6383 Dallenwil"},
 {"postleitzahl": "6386 Wolfenschiessen"},
 {"postleitzahl": "6387 Oberrickenbach"},
 {"postleitzahl": "6388 Grafenort"},
 {"postleitzahl": "6390 Engelberg"},
 {"postleitzahl": "6402 Merlischachen"},
 {"postleitzahl": "6403 Küssnacht am Rigi"},
 {"postleitzahl": "6404 Greppen"},
 {"postleitzahl": "6405 Immensee"},
 {"postleitzahl": "6410 Rigi Klösterli"},
 {"postleitzahl": "6410 Rigi Staffel"},
 {"postleitzahl": "6410 Rigi Kulm"},
 {"postleitzahl": "6410 Rigi Scheidegg"},
 {"postleitzahl": "6410 Goldau"},
 {"postleitzahl": "6414 Oberarth"},
 {"postleitzahl": "6415 Arth"},
 {"postleitzahl": "6416 Steinerberg"},
 {"postleitzahl": "6417 Sattel"},
 {"postleitzahl": "6418 Rothenthurm"},
 {"postleitzahl": "6422 Steinen"},
 {"postleitzahl": "6423 Seewen SZ"},
 {"postleitzahl": "6424 Lauerz"},
 {"postleitzahl": "6430 Schwyz"},
 {"postleitzahl": "6432 Rickenbach b. Schwyz"},
 {"postleitzahl": "6433 Stoos SZ"},
 {"postleitzahl": "6434 Illgau"},
 {"postleitzahl": "6436 Ried (Muotathal)"},
 {"postleitzahl": "6436 Bisisthal"},
 {"postleitzahl": "6436 Muotathal"},
 {"postleitzahl": "6438 Ibach"},
 {"postleitzahl": "6440 Brunnen"},
 {"postleitzahl": "6441 Rütli"},
 {"postleitzahl": "6442 Gersau"},
 {"postleitzahl": "6443 Morschach"},
 {"postleitzahl": "6452 Riemenstalden"},
 {"postleitzahl": "6452 Sisikon"},
 {"postleitzahl": "6454 Flüelen"},
 {"postleitzahl": "6460 Altdorf UR"},
 {"postleitzahl": "6461 Isenthal"},
 {"postleitzahl": "6462 Seedorf UR"},
 {"postleitzahl": "6463 Bürglen UR"},
 {"postleitzahl": "6464 Spiringen"},
 {"postleitzahl": "6465 Unterschächen"},
 {"postleitzahl": "6466 Bauen"},
 {"postleitzahl": "6467 Schattdorf"},
 {"postleitzahl": "6468 Attinghausen"},
 {"postleitzahl": "6469 Haldi b. Schattdorf"},
 {"postleitzahl": "6472 Erstfeld"},
 {"postleitzahl": "6473 Silenen"},
 {"postleitzahl": "6474 Amsteg"},
 {"postleitzahl": "6475 Bristen"},
 {"postleitzahl": "6476 Intschi"},
 {"postleitzahl": "6482 Gurtnellen"},
 {"postleitzahl": "6484 Wassen UR"},
 {"postleitzahl": "6485 Meien"},
 {"postleitzahl": "6487 Göschenen"},
 {"postleitzahl": "6490 Andermatt"},
 {"postleitzahl": "6491 Realp"},
 {"postleitzahl": "6493 Hospental"},
 {"postleitzahl": "6500 Bellinzona"},
 {"postleitzahl": "6503 Bellinzona"},
 {"postleitzahl": "6512 Giubiasco"},
 {"postleitzahl": "6513 Monte Carasso"},
 {"postleitzahl": "6514 Sementina"},
 {"postleitzahl": "6515 Gudo"},
 {"postleitzahl": "6516 Cugnasco"},
 {"postleitzahl": "6517 Arbedo"},
 {"postleitzahl": "6518 Gorduno"},
 {"postleitzahl": "6523 Preonzo"},
 {"postleitzahl": "6524 Moleno"},
 {"postleitzahl": "6525 Gnosca"},
 {"postleitzahl": "6526 Prosito"},
 {"postleitzahl": "6527 Lodrino"},
 {"postleitzahl": "6528 Camorino"},
 {"postleitzahl": "6532 Castione"},
 {"postleitzahl": "6533 Lumino"},
 {"postleitzahl": "6534 S. Vittore"},
 {"postleitzahl": "6535 Roveredo GR"},
 {"postleitzahl": "6537 Grono"},
 {"postleitzahl": "6538 Verdabbio"},
 {"postleitzahl": "6540 Castaneda"},
 {"postleitzahl": "6541 Sta. Maria in Calanca"},
 {"postleitzahl": "6542 Buseno"},
 {"postleitzahl": "6543 Arvigo"},
 {"postleitzahl": "6544 Braggio"},
 {"postleitzahl": "6545 Selma"},
 {"postleitzahl": "6546 Cauco"},
 {"postleitzahl": "6547 Augio"},
 {"postleitzahl": "6548 Rossa"},
 {"postleitzahl": "6549 Laura"},
 {"postleitzahl": "6556 Leggia"},
 {"postleitzahl": "6557 Cama"},
 {"postleitzahl": "6558 Lostallo"},
 {"postleitzahl": "6562 Soazza"},
 {"postleitzahl": "6563 Mesocco"},
 {"postleitzahl": "6565 S. Bernardino"},
 {"postleitzahl": "6571 Indemini"},
 {"postleitzahl": "6572 Quartino"},
 {"postleitzahl": "6573 Magadino"},
 {"postleitzahl": "6574 Vira (Gambarogno)"},
 {"postleitzahl": "6575 Vairano"},
 {"postleitzahl": "6575 S. Nazzaro"},
 {"postleitzahl": "6576 Gerra (Gambarogno)"},
 {"postleitzahl": "6577 Ranzo"},
 {"postleitzahl": "6578 Caviano"},
 {"postleitzahl": "6579 Piazzogna"},
 {"postleitzahl": "6582 Pianezzo"},
 {"postleitzahl": "6583 S. Antonio (Val Morobbia)"},
 {"postleitzahl": "6584 Carena"},
 {"postleitzahl": "6592 S. Antonino"},
 {"postleitzahl": "6593 Cadenazzo"},
 {"postleitzahl": "6594 Contone"},
 {"postleitzahl": "6595 Riazzino"},
 {"postleitzahl": "6596 Gordola"},
 {"postleitzahl": "6597 Agarone"},
 {"postleitzahl": "6598 Tenero"},
 {"postleitzahl": "6599 Robasacco"},
 {"postleitzahl": "6600 Locarno"},
 {"postleitzahl": "6600 Muralto"},
 {"postleitzahl": "6600 Solduno"},
 {"postleitzahl": "6605 Monte Brè sopra Locarno"},
 {"postleitzahl": "6605 Locarno"},
 {"postleitzahl": "6611 Mosogno"},
 {"postleitzahl": "6611 Gresso"},
 {"postleitzahl": "6611 Crana"},
 {"postleitzahl": "6612 Ascona"},
 {"postleitzahl": "6613 Porto Ronco"},
 {"postleitzahl": "6614 Isole di Brissago"},
 {"postleitzahl": "6614 Brissago"},
 {"postleitzahl": "6616 Losone"},
 {"postleitzahl": "6618 Arcegno"},
 {"postleitzahl": "6622 Ronco sopra Ascona"},
 {"postleitzahl": "6631 Corippo"},
 {"postleitzahl": "6632 Vogorno"},
 {"postleitzahl": "6633 Lavertezzo"},
 {"postleitzahl": "6634 Brione (Verzasca)"},
 {"postleitzahl": "6635 Gerra (Verzasca)"},
 {"postleitzahl": "6636 Frasco"},
 {"postleitzahl": "6637 Sonogno"},
 {"postleitzahl": "6644 Orselina"},
 {"postleitzahl": "6645 Brione sopra Minusio"},
 {"postleitzahl": "6646 Contra"},
 {"postleitzahl": "6647 Mergoscia"},
 {"postleitzahl": "6648 Minusio"},
 {"postleitzahl": "6652 Tegna"},
 {"postleitzahl": "6653 Verscio"},
 {"postleitzahl": "6654 Cavigliano"},
 {"postleitzahl": "6655 Verdasio"},
 {"postleitzahl": "6655 Rasa"},
 {"postleitzahl": "6655 Intragna"},
 {"postleitzahl": "6656 Golino"},
 {"postleitzahl": "6657 Palagnedra"},
 {"postleitzahl": "6658 Borgnone"},
 {"postleitzahl": "6659 Moneto"},
 {"postleitzahl": "6659 Camedo"},
 {"postleitzahl": "6661 Auressio"},
 {"postleitzahl": "6661 Berzona"},
 {"postleitzahl": "6661 Loco"},
 {"postleitzahl": "6662 Russo"},
 {"postleitzahl": "6663 Comologno"},
 {"postleitzahl": "6663 Spruga"},
 {"postleitzahl": "6664 Vergeletto"},
 {"postleitzahl": "6670 Avegno"},
 {"postleitzahl": "6672 Gordevio"},
 {"postleitzahl": "6673 Maggia"},
 {"postleitzahl": "6674 Riveo"},
 {"postleitzahl": "6674 Someo"},
 {"postleitzahl": "6675 Cevio"},
 {"postleitzahl": "6676 Bignasco"},
 {"postleitzahl": "6677 Moghegno"},
 {"postleitzahl": "6677 Aurigeno"},
 {"postleitzahl": "6678 Coglio"},
 {"postleitzahl": "6678 Lodano"},
 {"postleitzahl": "6678 Giumaglio"},
 {"postleitzahl": "6682 Linescio"},
 {"postleitzahl": "6683 Cerentino"},
 {"postleitzahl": "6683 Niva (Vallemaggia)"},
 {"postleitzahl": "6684 Campo (Vallemaggia)"},
 {"postleitzahl": "6684 Cimalmotto"},
 {"postleitzahl": "6685 Bosco/Gurin"},
 {"postleitzahl": "6690 Cavergno"},
 {"postleitzahl": "6690 S. Carlo (Val Bavona)"},
 {"postleitzahl": "6692 Brontallo"},
 {"postleitzahl": "6692 Menzonio"},
 {"postleitzahl": "6693 Broglio"},
 {"postleitzahl": "6694 Prato-Sornico"},
 {"postleitzahl": "6695 Peccia"},
 {"postleitzahl": "6695 Piano di Peccia"},
 {"postleitzahl": "6696 Fusio"},
 {"postleitzahl": "6702 Claro"},
 {"postleitzahl": "6703 Osogna"},
 {"postleitzahl": "6705 Cresciano"},
 {"postleitzahl": "6707 Iragna"},
 {"postleitzahl": "6710 Biasca"},
 {"postleitzahl": "6710 Biasca Stazione"},
 {"postleitzahl": "6713 Malvaglia"},
 {"postleitzahl": "6714 Semione"},
 {"postleitzahl": "6715 Dongio"},
 {"postleitzahl": "6716 Leontica"},
 {"postleitzahl": "6716 Lottigna"},
 {"postleitzahl": "6716 Acquarossa"},
 {"postleitzahl": "6717 Dangio-Torre"},
 {"postleitzahl": "6717 Torre"},
 {"postleitzahl": "6718 Camperio"},
 {"postleitzahl": "6718 Olivone"},
 {"postleitzahl": "6719 Aquila"},
 {"postleitzahl": "6720 Campo (Blenio)"},
 {"postleitzahl": "6720 Ghirone"},
 {"postleitzahl": "6721 Ludiano"},
 {"postleitzahl": "6721 Motto (Blenio)"},
 {"postleitzahl": "6722 Corzoneso"},
 {"postleitzahl": "6723 Prugiasco"},
 {"postleitzahl": "6723 Castro"},
 {"postleitzahl": "6723 Marolta"},
 {"postleitzahl": "6724 Ponto Valentino"},
 {"postleitzahl": "6724 Largario"},
 {"postleitzahl": "6742 Pollegio"},
 {"postleitzahl": "6743 Bodio TI"},
 {"postleitzahl": "6744 Personico"},
 {"postleitzahl": "6745 Giornico"},
 {"postleitzahl": "6746 Calonico"},
 {"postleitzahl": "6746 Nivo"},
 {"postleitzahl": "6746 Lavorgo"},
 {"postleitzahl": "6747 Chironico"},
 {"postleitzahl": "6748 Anzonico"},
 {"postleitzahl": "6749 Sobrio"},
 {"postleitzahl": "6749 Cavagnago"},
 {"postleitzahl": "6760 Molare"},
 {"postleitzahl": "6760 Calpiogna"},
 {"postleitzahl": "6760 Carì"},
 {"postleitzahl": "6760 Rossura"},
 {"postleitzahl": "6760 Faido"},
 {"postleitzahl": "6760 Campello"},
 {"postleitzahl": "6763 Mairengo"},
 {"postleitzahl": "6763 Osco"},
 {"postleitzahl": "6764 Chiggiogna"},
 {"postleitzahl": "6772 Rodi-Fiesso"},
 {"postleitzahl": "6773 Prato (Leventina)"},
 {"postleitzahl": "6774 Dalpe"},
 {"postleitzahl": "6775 Ambrì"},
 {"postleitzahl": "6776 Piotta"},
 {"postleitzahl": "6777 Quinto"},
 {"postleitzahl": "6777 Varenzo"},
 {"postleitzahl": "6780 Madrano"},
 {"postleitzahl": "6780 Airolo"},
 {"postleitzahl": "6781 Villa Bedretto"},
 {"postleitzahl": "6781 Bedretto"},
 {"postleitzahl": "6802 Rivera"},
 {"postleitzahl": "6803 Camignolo"},
 {"postleitzahl": "6804 Bironico"},
 {"postleitzahl": "6805 Mezzovico"},
 {"postleitzahl": "6806 Sigirino"},
 {"postleitzahl": "6807 Taverne"},
 {"postleitzahl": "6808 Torricella"},
 {"postleitzahl": "6809 Medeglia"},
 {"postleitzahl": "6810 Isone"},
 {"postleitzahl": "6814 Lamone"},
 {"postleitzahl": "6814 Cadempino"},
 {"postleitzahl": "6815 Melide"},
 {"postleitzahl": "6816 Bissone"},
 {"postleitzahl": "6817 Maroggia"},
 {"postleitzahl": "6818 Melano"},
 {"postleitzahl": "6821 Rovio"},
 {"postleitzahl": "6822 Arogno"},
 {"postleitzahl": "6823 Pugerna"},
 {"postleitzahl": "6825 Capolago"},
 {"postleitzahl": "6826 Riva San Vitale"},
 {"postleitzahl": "6827 Brusino Arsizio"},
 {"postleitzahl": "6828 Balerna"},
 {"postleitzahl": "6830 Chiasso"},
 {"postleitzahl": "6832 Seseglio"},
 {"postleitzahl": "6832 Pedrinate"},
 {"postleitzahl": "6833 Vacallo"},
 {"postleitzahl": "6834 Morbio Inferiore"},
 {"postleitzahl": "6835 Morbio Superiore"},
 {"postleitzahl": "6837 Bruzella"},
 {"postleitzahl": "6837 Caneggio"},
 {"postleitzahl": "6838 Cabbio"},
 {"postleitzahl": "6838 Scudellate"},
 {"postleitzahl": "6838 Muggio"},
 {"postleitzahl": "6839 Sagno"},
 {"postleitzahl": "6850 Mendrisio"},
 {"postleitzahl": "6852 Genestrerio"},
 {"postleitzahl": "6853 Ligornetto"},
 {"postleitzahl": "6854 S. Pietro"},
 {"postleitzahl": "6855 Stabio"},
 {"postleitzahl": "6862 Rancate"},
 {"postleitzahl": "6863 Besazio"},
 {"postleitzahl": "6864 Arzo"},
 {"postleitzahl": "6865 Tremona"},
 {"postleitzahl": "6866 Meride"},
 {"postleitzahl": "6867 Serpiano"},
 {"postleitzahl": "6872 Salorino"},
 {"postleitzahl": "6872 Somazzo"},
 {"postleitzahl": "6873 Corteglia"},
 {"postleitzahl": "6874 Castel San Pietro"},
 {"postleitzahl": "6875 Casima"},
 {"postleitzahl": "6875 Monte"},
 {"postleitzahl": "6877 Coldrerio"},
 {"postleitzahl": "6883 Novazzano"},
 {"postleitzahl": "6900 Lugano"},
 {"postleitzahl": "6900 Massagno"},
 {"postleitzahl": "6900 Paradiso"},
 {"postleitzahl": "6911 Campione d'Italia"},
 {"postleitzahl": "6912 Pazzallo"},
 {"postleitzahl": "6913 Carabbia"},
 {"postleitzahl": "6914 Carona"},
 {"postleitzahl": "6915 Pambio-Noranco"},
 {"postleitzahl": "6916 Grancia"},
 {"postleitzahl": "6917 Barbengo"},
 {"postleitzahl": "6918 Figino"},
 {"postleitzahl": "6919 Carabietta"},
 {"postleitzahl": "6921 Vico Morcote"},
 {"postleitzahl": "6922 Morcote"},
 {"postleitzahl": "6924 Sorengo"},
 {"postleitzahl": "6925 Gentilino"},
 {"postleitzahl": "6926 Montagnola"},
 {"postleitzahl": "6927 Agra"},
 {"postleitzahl": "6928 Manno"},
 {"postleitzahl": "6929 Gravesano"},
 {"postleitzahl": "6930 Bedano"},
 {"postleitzahl": "6932 Breganzona"},
 {"postleitzahl": "6933 Muzzano"},
 {"postleitzahl": "6934 Bioggio"},
 {"postleitzahl": "6935 Bosco Luganese"},
 {"postleitzahl": "6936 Cademario"},
 {"postleitzahl": "6937 Breno"},
 {"postleitzahl": "6938 Vezio"},
 {"postleitzahl": "6938 Fescoggia"},
 {"postleitzahl": "6939 Arosio"},
 {"postleitzahl": "6939 Mugena"},
 {"postleitzahl": "6942 Savosa"},
 {"postleitzahl": "6943 Vezia"},
 {"postleitzahl": "6944 Cureglia"},
 {"postleitzahl": "6945 Origlio"},
 {"postleitzahl": "6946 Ponte Capriasca"},
 {"postleitzahl": "6947 Vaglio"},
 {"postleitzahl": "6948 Porza"},
 {"postleitzahl": "6949 Comano"},
 {"postleitzahl": "6950 Tesserete"},
 {"postleitzahl": "6951 Insone"},
 {"postleitzahl": "6951 Odogno"},
 {"postleitzahl": "6951 Colla"},
 {"postleitzahl": "6951 Bogno"},
 {"postleitzahl": "6951 Cozzo"},
 {"postleitzahl": "6951 Signôra"},
 {"postleitzahl": "6951 Scareglia"},
 {"postleitzahl": "6952 Canobbio"},
 {"postleitzahl": "6953 Lugaggia"},
 {"postleitzahl": "6954 Sala Capriasca"},
 {"postleitzahl": "6954 Bigorio"},
 {"postleitzahl": "6955 Cagiallo"},
 {"postleitzahl": "6955 Oggio"},
 {"postleitzahl": "6956 Lopagno"},
 {"postleitzahl": "6957 Roveredo TI"},
 {"postleitzahl": "6958 Bidogno"},
 {"postleitzahl": "6958 Corticiasca"},
 {"postleitzahl": "6959 Cimadera"},
 {"postleitzahl": "6959 Certara"},
 {"postleitzahl": "6959 Curtina"},
 {"postleitzahl": "6959 Piandera Paese"},
 {"postleitzahl": "6959 Maglio di Colla"},
 {"postleitzahl": "6962 Viganello"},
 {"postleitzahl": "6963 Cureggia"},
 {"postleitzahl": "6963 Pregassona"},
 {"postleitzahl": "6964 Davesco-Soragno"},
 {"postleitzahl": "6965 Cadro"},
 {"postleitzahl": "6966 Villa Luganese"},
 {"postleitzahl": "6967 Dino"},
 {"postleitzahl": "6968 Sonvico"},
 {"postleitzahl": "6974 Aldesago"},
 {"postleitzahl": "6976 Castagnola"},
 {"postleitzahl": "6977 Ruvigliana"},
 {"postleitzahl": "6978 Gandria"},
 {"postleitzahl": "6979 Brè sopra Lugano"},
 {"postleitzahl": "6980 Castelrotto"},
 {"postleitzahl": "6981 Bedigliora"},
 {"postleitzahl": "6981 Biogno-Beride"},
 {"postleitzahl": "6981 Bombinasco"},
 {"postleitzahl": "6981 Banco"},
 {"postleitzahl": "6982 Agno"},
 {"postleitzahl": "6983 Magliaso"},
 {"postleitzahl": "6984 Pura"},
 {"postleitzahl": "6986 Miglieglia"},
 {"postleitzahl": "6986 Curio"},
 {"postleitzahl": "6986 Novaggio"},
 {"postleitzahl": "6987 Caslano"},
 {"postleitzahl": "6988 Ponte Tresa"},
 {"postleitzahl": "6989 Purasca"},
 {"postleitzahl": "6990 Cassina d'Agno"},
 {"postleitzahl": "6991 Neggio"},
 {"postleitzahl": "6992 Cimo"},
 {"postleitzahl": "6992 Vernate"},
 {"postleitzahl": "6993 Iseo"},
 {"postleitzahl": "6994 Aranno"},
 {"postleitzahl": "6995 Madonna del Piano"},
 {"postleitzahl": "6995 Molinazzo di Monteggio"},
 {"postleitzahl": "6996 Ponte Cremenaga"},
 {"postleitzahl": "6997 Sessa"},
 {"postleitzahl": "6998 Termine"},
 {"postleitzahl": "6999 Astano"},
 {"postleitzahl": "7000 Chur"},
 {"postleitzahl": "7012 Felsberg"},
 {"postleitzahl": "7013 Domat/Ems"},
 {"postleitzahl": "7014 Trin"},
 {"postleitzahl": "7015 Tamins"},
 {"postleitzahl": "7016 Trin Mulin"},
 {"postleitzahl": "7017 Flims Dorf"},
 {"postleitzahl": "7018 Flims Waldhaus"},
 {"postleitzahl": "7019 Fidaz"},
 {"postleitzahl": "7023 Haldenstein"},
 {"postleitzahl": "7026 Maladers"},
 {"postleitzahl": "7027 Lüen"},
 {"postleitzahl": "7027 Castiel"},
 {"postleitzahl": "7027 Calfreisen"},
 {"postleitzahl": "7028 Pagig"},
 {"postleitzahl": "7028 St. Peter"},
 {"postleitzahl": "7029 Peist"},
 {"postleitzahl": "7031 Laax GR"},
 {"postleitzahl": "7032 Laax GR 2"},
 {"postleitzahl": "7050 Arosa"},
 {"postleitzahl": "7056 Molinis"},
 {"postleitzahl": "7057 Langwies"},
 {"postleitzahl": "7058 Litzirüti"},
 {"postleitzahl": "7062 Passugg-Araschgen"},
 {"postleitzahl": "7063 Praden"},
 {"postleitzahl": "7064 Tschiertschen"},
 {"postleitzahl": "7074 Malix"},
 {"postleitzahl": "7075 Churwalden"},
 {"postleitzahl": "7076 Parpan"},
 {"postleitzahl": "7077 Valbella"},
 {"postleitzahl": "7078 Lenzerheide/Lai"},
 {"postleitzahl": "7082 Vaz/Obervaz"},
 {"postleitzahl": "7083 Lantsch/Lenz"},
 {"postleitzahl": "7084 Brienz/Brinzauls GR"},
 {"postleitzahl": "7104 Arezen"},
 {"postleitzahl": "7104 Versam"},
 {"postleitzahl": "7106 Tenna"},
 {"postleitzahl": "7107 Safien Platz"},
 {"postleitzahl": "7109 Thalkirch"},
 {"postleitzahl": "7110 Peiden"},
 {"postleitzahl": "7111 Pitasch"},
 {"postleitzahl": "7112 Duvin"},
 {"postleitzahl": "7113 Camuns"},
 {"postleitzahl": "7114 Uors (Lumnezia)"},
 {"postleitzahl": "7115 Surcasti"},
 {"postleitzahl": "7116 Tersnaus"},
 {"postleitzahl": "7116 St. Martin (Lugnez)"},
 {"postleitzahl": "7122 Valendas"},
 {"postleitzahl": "7122 Carrera"},
 {"postleitzahl": "7126 Castrisch"},
 {"postleitzahl": "7127 Sevgein"},
 {"postleitzahl": "7128 Riein"},
 {"postleitzahl": "7130 Schnaus"},
 {"postleitzahl": "7130 Ilanz"},
 {"postleitzahl": "7132 Vals"},
 {"postleitzahl": "7133 Obersaxen Affeier"},
 {"postleitzahl": "7134 Obersaxen Meierhof"},
 {"postleitzahl": "7135 Obersaxen Giraniga"},
 {"postleitzahl": "7136 Obersaxen Friggahüs"},
 {"postleitzahl": "7137 Flond"},
 {"postleitzahl": "7138 Surcuolm"},
 {"postleitzahl": "7141 Luven"},
 {"postleitzahl": "7142 Cumbel"},
 {"postleitzahl": "7143 Morissen"},
 {"postleitzahl": "7144 Vella"},
 {"postleitzahl": "7145 Degen"},
 {"postleitzahl": "7146 Vattiz"},
 {"postleitzahl": "7147 Vignogn"},
 {"postleitzahl": "7148 Surin"},
 {"postleitzahl": "7148 Lumbrein"},
 {"postleitzahl": "7149 Vrin"},
 {"postleitzahl": "7151 Schluein"},
 {"postleitzahl": "7152 Sagogn"},
 {"postleitzahl": "7153 Falera"},
 {"postleitzahl": "7154 Ruschein"},
 {"postleitzahl": "7155 Ladir"},
 {"postleitzahl": "7156 Pigniu"},
 {"postleitzahl": "7156 Rueun"},
 {"postleitzahl": "7157 Siat"},
 {"postleitzahl": "7158 Waltensburg/Vuorz"},
 {"postleitzahl": "7159 Andiast"},
 {"postleitzahl": "7162 Tavanasa"},
 {"postleitzahl": "7163 Danis"},
 {"postleitzahl": "7164 Dardin"},
 {"postleitzahl": "7165 Breil/Brigels"},
 {"postleitzahl": "7166 Trun"},
 {"postleitzahl": "7167 Zignau"},
 {"postleitzahl": "7168 Schlans"},
 {"postleitzahl": "7172 Rabius"},
 {"postleitzahl": "7173 Surrein"},
 {"postleitzahl": "7174 S. Benedetg"},
 {"postleitzahl": "7175 Sumvitg"},
 {"postleitzahl": "7176 Cumpadials"},
 {"postleitzahl": "7180 Disentis/Mustér"},
 {"postleitzahl": "7182 Cavardiras"},
 {"postleitzahl": "7183 Mompé Medel"},
 {"postleitzahl": "7184 Curaglia"},
 {"postleitzahl": "7185 Platta"},
 {"postleitzahl": "7186 Segnas"},
 {"postleitzahl": "7187 Camischolas"},
 {"postleitzahl": "7188 Sedrun"},
 {"postleitzahl": "7189 Rueras"},
 {"postleitzahl": "7201 Untervaz Bahnhof"},
 {"postleitzahl": "7202 Says"},
 {"postleitzahl": "7203 Trimmis"},
 {"postleitzahl": "7204 Untervaz"},
 {"postleitzahl": "7205 Zizers"},
 {"postleitzahl": "7206 Igis"},
 {"postleitzahl": "7208 Malans GR"},
 {"postleitzahl": "7212 Seewis Dorf"},
 {"postleitzahl": "7213 Valzeina"},
 {"postleitzahl": "7214 Seewis-Pardisla"},
 {"postleitzahl": "7214 Seewis-Schmitten"},
 {"postleitzahl": "7214 Grüsch"},
 {"postleitzahl": "7215 Fanas"},
 {"postleitzahl": "7220 Schiers"},
 {"postleitzahl": "7222 Mittellunden"},
 {"postleitzahl": "7223 Buchen im Prättigau"},
 {"postleitzahl": "7224 Putz"},
 {"postleitzahl": "7226 Stels"},
 {"postleitzahl": "7226 Fajauna"},
 {"postleitzahl": "7228 Schuders"},
 {"postleitzahl": "7228 Pusserein"},
 {"postleitzahl": "7231 Pragg-Jenaz"},
 {"postleitzahl": "7232 Furna"},
 {"postleitzahl": "7233 Jenaz"},
 {"postleitzahl": "7235 Fideris"},
 {"postleitzahl": "7240 Küblis"},
 {"postleitzahl": "7241 Conters im Prättigau"},
 {"postleitzahl": "7242 Luzein"},
 {"postleitzahl": "7243 Pany"},
 {"postleitzahl": "7244 Gadenstätt"},
 {"postleitzahl": "7245 Ascharina"},
 {"postleitzahl": "7246 St. Antönien"},
 {"postleitzahl": "7247 Saas im Prättigau"},
 {"postleitzahl": "7249 Serneus"},
 {"postleitzahl": "7250 Klosters"},
 {"postleitzahl": "7252 Klosters Dorf"},
 {"postleitzahl": "7260 Davos Dorf"},
 {"postleitzahl": "7265 Davos Wolfgang"},
 {"postleitzahl": "7270 Davos Platz"},
 {"postleitzahl": "7272 Davos Clavadel"},
 {"postleitzahl": "7276 Davos Frauenkirch"},
 {"postleitzahl": "7277 Davos Glaris"},
 {"postleitzahl": "7278 Davos Monstein"},
 {"postleitzahl": "7302 Landquart"},
 {"postleitzahl": "7303 Mastrils"},
 {"postleitzahl": "7304 Maienfeld"},
 {"postleitzahl": "7306 Fläsch"},
 {"postleitzahl": "7307 Jenins"},
 {"postleitzahl": "7310 Bad Ragaz"},
 {"postleitzahl": "7312 Pfäfers"},
 {"postleitzahl": "7313 St. Margrethenberg"},
 {"postleitzahl": "7314 Vadura"},
 {"postleitzahl": "7315 Vättis"},
 {"postleitzahl": "7317 Vasön"},
 {"postleitzahl": "7317 Valens"},
 {"postleitzahl": "7320 Sargans"},
 {"postleitzahl": "7323 Wangs"},
 {"postleitzahl": "7324 Vilters"},
 {"postleitzahl": "7325 Schwendi im Weisstannental"},
 {"postleitzahl": "7326 Weisstannen"},
 {"postleitzahl": "7402 Bonaduz"},
 {"postleitzahl": "7403 Rhäzüns"},
 {"postleitzahl": "7404 Feldis/Veulden"},
 {"postleitzahl": "7405 Rothenbrunnen"},
 {"postleitzahl": "7407 Trans"},
 {"postleitzahl": "7408 Realta"},
 {"postleitzahl": "7408 Cazis"},
 {"postleitzahl": "7411 Sils im Domleschg"},
 {"postleitzahl": "7412 Scharans"},
 {"postleitzahl": "7413 Fürstenaubruck"},
 {"postleitzahl": "7414 Fürstenau"},
 {"postleitzahl": "7415 Pratval"},
 {"postleitzahl": "7415 Rodels"},
 {"postleitzahl": "7416 Almens"},
 {"postleitzahl": "7417 Paspels"},
 {"postleitzahl": "7418 Tumegl/Tomils"},
 {"postleitzahl": "7419 Scheid"},
 {"postleitzahl": "7421 Summaprada"},
 {"postleitzahl": "7422 Tartar"},
 {"postleitzahl": "7423 Sarn"},
 {"postleitzahl": "7423 Portein"},
 {"postleitzahl": "7424 Präz"},
 {"postleitzahl": "7424 Dalin"},
 {"postleitzahl": "7425 Masein"},
 {"postleitzahl": "7426 Flerden"},
 {"postleitzahl": "7427 Urmein"},
 {"postleitzahl": "7428 Tschappina"},
 {"postleitzahl": "7428 Glaspass"},
 {"postleitzahl": "7430 Rongellen"},
 {"postleitzahl": "7430 Thusis"},
 {"postleitzahl": "7431 Mutten"},
 {"postleitzahl": "7431 Obermutten"},
 {"postleitzahl": "7432 Zillis"},
 {"postleitzahl": "7433 Farden"},
 {"postleitzahl": "7433 Wergenstein"},
 {"postleitzahl": "7433 Lohn GR"},
 {"postleitzahl": "7433 Donat"},
 {"postleitzahl": "7433 Mathon"},
 {"postleitzahl": "7434 Sufers"},
 {"postleitzahl": "7435 Splügen"},
 {"postleitzahl": "7436 Medels im Rheinwald"},
 {"postleitzahl": "7437 Nufenen"},
 {"postleitzahl": "7438 Hinterrhein"},
 {"postleitzahl": "7440 Andeer"},
 {"postleitzahl": "7442 Clugin"},
 {"postleitzahl": "7443 Pignia"},
 {"postleitzahl": "7444 Ausserferrera"},
 {"postleitzahl": "7445 Innerferrera"},
 {"postleitzahl": "7446 Campsut-Cröt"},
 {"postleitzahl": "7447 Cresta (Avers)"},
 {"postleitzahl": "7447 Am Bach (Avers)"},
 {"postleitzahl": "7448 Juf"},
 {"postleitzahl": "7450 Tiefencastel"},
 {"postleitzahl": "7451 Alvaschein"},
 {"postleitzahl": "7452 Cunter"},
 {"postleitzahl": "7453 Tinizong"},
 {"postleitzahl": "7454 Rona"},
 {"postleitzahl": "7455 Mulegns"},
 {"postleitzahl": "7456 Sur"},
 {"postleitzahl": "7456 Marmorera"},
 {"postleitzahl": "7457 Bivio"},
 {"postleitzahl": "7458 Mon"},
 {"postleitzahl": "7459 Stierva"},
 {"postleitzahl": "7460 Savognin"},
 {"postleitzahl": "7462 Salouf"},
 {"postleitzahl": "7463 Riom"},
 {"postleitzahl": "7464 Parsonz"},
 {"postleitzahl": "7472 Surava"},
 {"postleitzahl": "7473 Alvaneu Bad"},
 {"postleitzahl": "7477 Filisur"},
 {"postleitzahl": "7482 Stugl/Stuls"},
 {"postleitzahl": "7482 Preda"},
 {"postleitzahl": "7482 Bergün/Bravuogn"},
 {"postleitzahl": "7484 Latsch"},
 {"postleitzahl": "7492 Alvaneu Dorf"},
 {"postleitzahl": "7493 Schmitten (Albula)"},
 {"postleitzahl": "7494 Wiesen GR"},
 {"postleitzahl": "7500 St. Moritz"},
 {"postleitzahl": "7502 Bever"},
 {"postleitzahl": "7503 Samedan"},
 {"postleitzahl": "7504 Pontresina"},
 {"postleitzahl": "7505 Celerina/Schlarigna"},
 {"postleitzahl": "7512 Champfèr"},
 {"postleitzahl": "7513 Silvaplana-Surlej"},
 {"postleitzahl": "7513 Silvaplana"},
 {"postleitzahl": "7514 Fex"},
 {"postleitzahl": "7514 Sils/Segl Maria"},
 {"postleitzahl": "7515 Sils/Segl Baselgia"},
 {"postleitzahl": "7516 Maloja"},
 {"postleitzahl": "7517 Plaun da Lej"},
 {"postleitzahl": "7522 La Punt-Chamues-ch"},
 {"postleitzahl": "7523 Madulain"},
 {"postleitzahl": "7524 Zuoz"},
 {"postleitzahl": "7525 S-chanf"},
 {"postleitzahl": "7526 Cinuos-chel"},
 {"postleitzahl": "7527 Brail"},
 {"postleitzahl": "7530 Zernez"},
 {"postleitzahl": "7532 Tschierv"},
 {"postleitzahl": "7533 Fuldera"},
 {"postleitzahl": "7534 Lü"},
 {"postleitzahl": "7535 Valchava"},
 {"postleitzahl": "7536 Sta. Maria Val Müstair"},
 {"postleitzahl": "7537 Müstair"},
 {"postleitzahl": "7542 Susch"},
 {"postleitzahl": "7543 Lavin"},
 {"postleitzahl": "7545 Guarda"},
 {"postleitzahl": "7546 Ardez"},
 {"postleitzahl": "7550 Scuol"},
 {"postleitzahl": "7551 Ftan"},
 {"postleitzahl": "7552 Vulpera"},
 {"postleitzahl": "7553 Tarasp"},
 {"postleitzahl": "7554 Crusch"},
 {"postleitzahl": "7554 Sent"},
 {"postleitzahl": "7556 Ramosch"},
 {"postleitzahl": "7557 Vnà"},
 {"postleitzahl": "7558 Strada"},
 {"postleitzahl": "7559 Tschlin"},
 {"postleitzahl": "7560 Martina"},
 {"postleitzahl": "7562 Samnaun-Compatsch"},
 {"postleitzahl": "7563 Samnaun Dorf"},
 {"postleitzahl": "7602 Casaccia"},
 {"postleitzahl": "7603 Vicosoprano"},
 {"postleitzahl": "7604 Borgonovo"},
 {"postleitzahl": "7605 Stampa"},
 {"postleitzahl": "7606 Promontogno"},
 {"postleitzahl": "7608 Castasegna"},
 {"postleitzahl": "7610 Soglio"},
 {"postleitzahl": "7710 Ospizio Bernina"},
 {"postleitzahl": "7710 Alp Grüm"},
 {"postleitzahl": "7741 S. Carlo (Poschiavo)"},
 {"postleitzahl": "7742 Sfazù"},
 {"postleitzahl": "7742 La Rösa"},
 {"postleitzahl": "7742 Poschiavo"},
 {"postleitzahl": "7743 Miralago"},
 {"postleitzahl": "7743 Brusio"},
 {"postleitzahl": "7744 Campocologno"},
 {"postleitzahl": "7745 Li Curt"},
 {"postleitzahl": "7746 Le Prese"},
 {"postleitzahl": "7747 Viano"},
 {"postleitzahl": "7748 Campascio"},
 {"postleitzahl": "8000 Zürich"},
 {"postleitzahl": "8001 Zürich"},
 {"postleitzahl": "8002 Zürich"},
 {"postleitzahl": "8003 Zürich"},
 {"postleitzahl": "8004 Zürich"},
 {"postleitzahl": "8005 Zürich"},
 {"postleitzahl": "8006 Zürich"},
 {"postleitzahl": "8008 Zürich"},
 {"postleitzahl": "8032 Zürich"},
 {"postleitzahl": "8037 Zürich"},
 {"postleitzahl": "8038 Zürich"},
 {"postleitzahl": "8041 Zürich"},
 {"postleitzahl": "8044 Gockhausen"},
 {"postleitzahl": "8044 Zürich"},
 {"postleitzahl": "8045 Zürich"},
 {"postleitzahl": "8046 Zürich"},
 {"postleitzahl": "8047 Zürich"},
 {"postleitzahl": "8048 Zürich"},
 {"postleitzahl": "8049 Zürich"},
 {"postleitzahl": "8050 Zürich"},
 {"postleitzahl": "8051 Zürich"},
 {"postleitzahl": "8052 Zürich"},
 {"postleitzahl": "8053 Zürich"},
 {"postleitzahl": "8055 Zürich"},
 {"postleitzahl": "8057 Zürich"},
 {"postleitzahl": "8063 Zürich"},
 {"postleitzahl": "8064 Zürich"},
 {"postleitzahl": "8099 Zürich"},
 {"postleitzahl": "8102 Oberengstringen"},
 {"postleitzahl": "8103 Unterengstringen"},
 {"postleitzahl": "8104 Weiningen ZH"},
 {"postleitzahl": "8105 Regensdorf"},
 {"postleitzahl": "8105 Watt"},
 {"postleitzahl": "8106 Adlikon b. Regensdorf"},
 {"postleitzahl": "8107 Buchs ZH"},
 {"postleitzahl": "8108 Dällikon"},
 {"postleitzahl": "8109 Kloster Fahr"},
 {"postleitzahl": "8112 Otelfingen"},
 {"postleitzahl": "8113 Boppelsen"},
 {"postleitzahl": "8114 Dänikon ZH"},
 {"postleitzahl": "8115 Hüttikon"},
 {"postleitzahl": "8117 Fällanden"},
 {"postleitzahl": "8118 Pfaffhausen"},
 {"postleitzahl": "8121 Benglen"},
 {"postleitzahl": "8122 Binz"},
 {"postleitzahl": "8123 Ebmatingen"},
 {"postleitzahl": "8124 Maur"},
 {"postleitzahl": "8125 Zollikerberg"},
 {"postleitzahl": "8126 Zumikon"},
 {"postleitzahl": "8127 Forch"},
 {"postleitzahl": "8132 Hinteregg"},
 {"postleitzahl": "8132 Egg b. Zürich"},
 {"postleitzahl": "8133 Esslingen"},
 {"postleitzahl": "8134 Adliswil"},
 {"postleitzahl": "8135 Sihlbrugg Station"},
 {"postleitzahl": "8135 Sihlwald"},
 {"postleitzahl": "8135 Langnau am Albis"},
 {"postleitzahl": "8136 Gattikon"},
 {"postleitzahl": "8142 Uitikon Waldegg"},
 {"postleitzahl": "8143 Uetliberg"},
 {"postleitzahl": "8143 Stallikon"},
 {"postleitzahl": "8152 Glattpark (Opfikon)"},
 {"postleitzahl": "8152 Glattbrugg"},
 {"postleitzahl": "8152 Opfikon"},
 {"postleitzahl": "8153 Rümlang"},
 {"postleitzahl": "8154 Oberglatt ZH"},
 {"postleitzahl": "8155 Niederhasli"},
 {"postleitzahl": "8156 Oberhasli"},
 {"postleitzahl": "8157 Dielsdorf"},
 {"postleitzahl": "8158 Regensberg"},
 {"postleitzahl": "8162 Steinmaur"},
 {"postleitzahl": "8164 Bachs"},
 {"postleitzahl": "8165 Oberweningen"},
 {"postleitzahl": "8165 Schleinikon"},
 {"postleitzahl": "8165 Schöfflisdorf"},
 {"postleitzahl": "8166 Niederweningen"},
 {"postleitzahl": "8172 Niederglatt ZH"},
 {"postleitzahl": "8173 Neerach"},
 {"postleitzahl": "8174 Stadel b. Niederglatt"},
 {"postleitzahl": "8175 Windlach"},
 {"postleitzahl": "8180 Bülach"},
 {"postleitzahl": "8181 Höri"},
 {"postleitzahl": "8182 Hochfelden"},
 {"postleitzahl": "8184 Bachenbülach"},
 {"postleitzahl": "8185 Winkel"},
 {"postleitzahl": "8187 Weiach"},
 {"postleitzahl": "8192 Zweidlen"},
 {"postleitzahl": "8192 Glattfelden"},
 {"postleitzahl": "8193 Eglisau"},
 {"postleitzahl": "8194 Hüntwangen"},
 {"postleitzahl": "8195 Wasterkingen"},
 {"postleitzahl": "8196 Wil ZH"},
 {"postleitzahl": "8197 Rafz"},
 {"postleitzahl": "8200 Schaffhausen"},
 {"postleitzahl": "8203 Schaffhausen"},
 {"postleitzahl": "8207 Schaffhausen"},
 {"postleitzahl": "8208 Schaffhausen"},
 {"postleitzahl": "8212 Neuhausen am Rheinfall"},
 {"postleitzahl": "8212 Nohl"},
 {"postleitzahl": "8213 Neunkirch"},
 {"postleitzahl": "8214 Gächlingen"},
 {"postleitzahl": "8215 Hallau"},
 {"postleitzahl": "8216 Oberhallau"},
 {"postleitzahl": "8217 Wilchingen"},
 {"postleitzahl": "8218 Osterfingen"},
 {"postleitzahl": "8219 Trasadingen"},
 {"postleitzahl": "8222 Beringen"},
 {"postleitzahl": "8223 Guntmadingen"},
 {"postleitzahl": "8224 Löhningen"},
 {"postleitzahl": "8225 Siblingen"},
 {"postleitzahl": "8226 Schleitheim"},
 {"postleitzahl": "8228 Beggingen"},
 {"postleitzahl": "8231 Hemmental"},
 {"postleitzahl": "8232 Merishausen"},
 {"postleitzahl": "8233 Bargen SH"},
 {"postleitzahl": "8234 Stetten SH"},
 {"postleitzahl": "8235 Lohn SH"},
 {"postleitzahl": "8236 Opfertshofen SH"},
 {"postleitzahl": "8236 Büttenhardt"},
 {"postleitzahl": "8238 Büsingen"},
 {"postleitzahl": "8239 Dörflingen"},
 {"postleitzahl": "8240 Thayngen"},
 {"postleitzahl": "8241 Barzheim"},
 {"postleitzahl": "8242 Hofen SH"},
 {"postleitzahl": "8242 Bibern SH"},
 {"postleitzahl": "8243 Altdorf SH"},
 {"postleitzahl": "8245 Feuerthalen"},
 {"postleitzahl": "8246 Langwiesen"},
 {"postleitzahl": "8247 Flurlingen"},
 {"postleitzahl": "8248 Uhwiesen"},
 {"postleitzahl": "8252 Schlatt TG"},
 {"postleitzahl": "8253 Willisdorf"},
 {"postleitzahl": "8253 Diessenhofen"},
 {"postleitzahl": "8254 Basadingen"},
 {"postleitzahl": "8255 Schlattingen"},
 {"postleitzahl": "8259 Kaltenbach"},
 {"postleitzahl": "8259 Etzwilen"},
 {"postleitzahl": "8259 Wagenhausen"},
 {"postleitzahl": "8259 Rheinklingen"},
 {"postleitzahl": "8260 Stein am Rhein"},
 {"postleitzahl": "8261 Hemishofen"},
 {"postleitzahl": "8262 Ramsen"},
 {"postleitzahl": "8263 Buch SH"},
 {"postleitzahl": "8264 Eschenz"},
 {"postleitzahl": "8265 Mammern"},
 {"postleitzahl": "8266 Steckborn"},
 {"postleitzahl": "8267 Berlingen"},
 {"postleitzahl": "8268 Salenstein"},
 {"postleitzahl": "8268 Mannenbach-Salenstein"},
 {"postleitzahl": "8269 Fruthwilen"},
 {"postleitzahl": "8272 Ermatingen"},
 {"postleitzahl": "8273 Triboltingen"},
 {"postleitzahl": "8274 Gottlieben"},
 {"postleitzahl": "8274 Tägerwilen"},
 {"postleitzahl": "8280 Kreuzlingen"},
 {"postleitzahl": "8302 Kloten"},
 {"postleitzahl": "8303 Bassersdorf"},
 {"postleitzahl": "8304 Wallisellen"},
 {"postleitzahl": "8305 Dietlikon"},
 {"postleitzahl": "8306 Brüttisellen"},
 {"postleitzahl": "8307 Ottikon b. Kemptthal"},
 {"postleitzahl": "8307 Effretikon"},
 {"postleitzahl": "8308 Agasul"},
 {"postleitzahl": "8308 Illnau"},
 {"postleitzahl": "8309 Nürensdorf"},
 {"postleitzahl": "8310 Kemptthal"},
 {"postleitzahl": "8310 Grafstal"},
 {"postleitzahl": "8311 Brütten"},
 {"postleitzahl": "8312 Winterberg ZH"},
 {"postleitzahl": "8314 Kyburg"},
 {"postleitzahl": "8315 Lindau"},
 {"postleitzahl": "8317 Tagelswangen"},
 {"postleitzahl": "8320 Fehraltorf"},
 {"postleitzahl": "8322 Madetswil"},
 {"postleitzahl": "8330 Hermatswil"},
 {"postleitzahl": "8330 Pfäffikon ZH"},
 {"postleitzahl": "8331 Auslikon"},
 {"postleitzahl": "8332 Russikon"},
 {"postleitzahl": "8335 Hittnau"},
 {"postleitzahl": "8340 Hinwil"},
 {"postleitzahl": "8342 Wernetshausen"},
 {"postleitzahl": "8344 Bäretswil"},
 {"postleitzahl": "8345 Adetswil"},
 {"postleitzahl": "8352 Ricketwil (Winterthur)"},
 {"postleitzahl": "8352 Elsau"},
 {"postleitzahl": "8353 Elgg"},
 {"postleitzahl": "8354 Hofstetten ZH"},
 {"postleitzahl": "8355 Aadorf"},
 {"postleitzahl": "8356 Ettenhausen TG"},
 {"postleitzahl": "8357 Guntershausen b. Aadorf"},
 {"postleitzahl": "8360 Wallenwil"},
 {"postleitzahl": "8360 Eschlikon TG"},
 {"postleitzahl": "8362 Balterswil"},
 {"postleitzahl": "8363 Bichelsee"},
 {"postleitzahl": "8370 Busswil TG"},
 {"postleitzahl": "8370 Sirnach"},
 {"postleitzahl": "8372 Wiezikon b. Sirnach"},
 {"postleitzahl": "8374 Oberwangen TG"},
 {"postleitzahl": "8374 Dussnang"},
 {"postleitzahl": "8376 Fischingen"},
 {"postleitzahl": "8376 Au TG"},
 {"postleitzahl": "8400 Winterthur"},
 {"postleitzahl": "8404 Reutlingen (Winterthur)"},
 {"postleitzahl": "8404 Stadel (Winterthur)"},
 {"postleitzahl": "8404 Winterthur"},
 {"postleitzahl": "8405 Winterthur"},
 {"postleitzahl": "8406 Winterthur"},
 {"postleitzahl": "8408 Winterthur"},
 {"postleitzahl": "8409 Winterthur"},
 {"postleitzahl": "8412 Aesch (Neftenbach)"},
 {"postleitzahl": "8412 Riet (Neftenbach)"},
 {"postleitzahl": "8412 Hünikon (Neftenbach)"},
 {"postleitzahl": "8413 Neftenbach"},
 {"postleitzahl": "8414 Buch am Irchel"},
 {"postleitzahl": "8415 Berg am Irchel"},
 {"postleitzahl": "8415 Gräslikon"},
 {"postleitzahl": "8416 Flaach"},
 {"postleitzahl": "8418 Schlatt b. Winterthur"},
 {"postleitzahl": "8421 Dättlikon"},
 {"postleitzahl": "8422 Pfungen"},
 {"postleitzahl": "8424 Embrach"},
 {"postleitzahl": "8425 Oberembrach"},
 {"postleitzahl": "8426 Lufingen"},
 {"postleitzahl": "8427 Freienstein"},
 {"postleitzahl": "8427 Rorbas"},
 {"postleitzahl": "8428 Teufen ZH"},
 {"postleitzahl": "8442 Hettlingen"},
 {"postleitzahl": "8444 Henggart"},
 {"postleitzahl": "8447 Dachsen"},
 {"postleitzahl": "8450 Andelfingen"},
 {"postleitzahl": "8451 Kleinandelfingen"},
 {"postleitzahl": "8452 Adlikon b. Andelfingen"},
 {"postleitzahl": "8453 Alten"},
 {"postleitzahl": "8454 Buchberg"},
 {"postleitzahl": "8455 Rüdlingen"},
 {"postleitzahl": "8457 Humlikon"},
 {"postleitzahl": "8458 Dorf"},
 {"postleitzahl": "8459 Volken"},
 {"postleitzahl": "8460 Marthalen"},
 {"postleitzahl": "8461 Oerlingen"},
 {"postleitzahl": "8462 Rheinau"},
 {"postleitzahl": "8463 Benken ZH"},
 {"postleitzahl": "8464 Ellikon am Rhein"},
 {"postleitzahl": "8465 Rudolfingen"},
 {"postleitzahl": "8465 Wildensbuch"},
 {"postleitzahl": "8466 Trüllikon"},
 {"postleitzahl": "8467 Truttikon"},
 {"postleitzahl": "8468 Waltalingen"},
 {"postleitzahl": "8468 Guntalingen"},
 {"postleitzahl": "8471 Rutschwil (Dägerlen)"},
 {"postleitzahl": "8471 Dägerlen"},
 {"postleitzahl": "8471 Oberwil (Dägerlen)"},
 {"postleitzahl": "8471 Berg (Dägerlen)"},
 {"postleitzahl": "8471 Bänk (Dägerlen)"},
 {"postleitzahl": "8472 Seuzach"},
 {"postleitzahl": "8474 Dinhard"},
 {"postleitzahl": "8475 Ossingen"},
 {"postleitzahl": "8476 Unterstammheim"},
 {"postleitzahl": "8477 Oberstammheim"},
 {"postleitzahl": "8478 Thalheim an der Thur"},
 {"postleitzahl": "8479 Altikon"},
 {"postleitzahl": "8482 Sennhof (Winterthur)"},
 {"postleitzahl": "8483 Kollbrunn"},
 {"postleitzahl": "8484 Neschwil"},
 {"postleitzahl": "8484 Theilingen"},
 {"postleitzahl": "8484 Weisslingen"},
 {"postleitzahl": "8486 Rikon im Tösstal"},
 {"postleitzahl": "8487 Rämismühle"},
 {"postleitzahl": "8487 Zell ZH"},
 {"postleitzahl": "8488 Turbenthal"},
 {"postleitzahl": "8489 Wildberg"},
 {"postleitzahl": "8492 Wila"},
 {"postleitzahl": "8493 Saland"},
 {"postleitzahl": "8494 Bauma"},
 {"postleitzahl": "8495 Schmidrüti"},
 {"postleitzahl": "8496 Steg im Tösstal"},
 {"postleitzahl": "8497 Fischenthal"},
 {"postleitzahl": "8498 Gibswil-Ried"},
 {"postleitzahl": "8499 Sternenberg"},
 {"postleitzahl": "8500 Frauenfeld"},
 {"postleitzahl": "8500 Gerlikon"},
 {"postleitzahl": "8505 Dettighofen"},
 {"postleitzahl": "8505 Pfyn"},
 {"postleitzahl": "8506 Lanzenneunforn"},
 {"postleitzahl": "8507 Hörhausen"},
 {"postleitzahl": "8508 Homburg"},
 {"postleitzahl": "8512 Lustdorf"},
 {"postleitzahl": "8512 Wetzikon TG"},
 {"postleitzahl": "8512 Thundorf"},
 {"postleitzahl": "8514 Amlikon-Bissegg"},
 {"postleitzahl": "8522 Häuslenen"},
 {"postleitzahl": "8522 Aawangen"},
 {"postleitzahl": "8523 Hagenbuch ZH"},
 {"postleitzahl": "8524 Buch b. Frauenfeld"},
 {"postleitzahl": "8524 Uesslingen"},
 {"postleitzahl": "8525 Niederneunforn"},
 {"postleitzahl": "8525 Wilen b. Neunforn"},
 {"postleitzahl": "8526 Oberneunforn"},
 {"postleitzahl": "8532 Weiningen TG"},
 {"postleitzahl": "8532 Warth"},
 {"postleitzahl": "8535 Herdern"},
 {"postleitzahl": "8536 Hüttwilen"},
 {"postleitzahl": "8537 Nussbaumen TG"},
 {"postleitzahl": "8537 Uerschhausen"},
 {"postleitzahl": "8542 Wiesendangen"},
 {"postleitzahl": "8543 Bertschikon"},
 {"postleitzahl": "8543 Gundetswil"},
 {"postleitzahl": "8543 Kefikon ZH"},
 {"postleitzahl": "8544 Attikon"},
 {"postleitzahl": "8545 Rickenbach ZH"},
 {"postleitzahl": "8545 Rickenbach Sulz"},
 {"postleitzahl": "8546 Kefikon TG"},
 {"postleitzahl": "8546 Islikon"},
 {"postleitzahl": "8546 Menzengrüt"},
 {"postleitzahl": "8547 Gachnang"},
 {"postleitzahl": "8548 Ellikon an der Thur"},
 {"postleitzahl": "8552 Felben-Wellhausen"},
 {"postleitzahl": "8553 Eschikofen"},
 {"postleitzahl": "8553 Harenwilen"},
 {"postleitzahl": "8553 Mettendorf TG"},
 {"postleitzahl": "8553 Hüttlingen"},
 {"postleitzahl": "8554 Müllheim-Wigoltingen"},
 {"postleitzahl": "8554 Bonau"},
 {"postleitzahl": "8555 Müllheim Dorf"},
 {"postleitzahl": "8556 Engwang"},
 {"postleitzahl": "8556 Illhart"},
 {"postleitzahl": "8556 Lamperswil TG"},
 {"postleitzahl": "8556 Wigoltingen"},
 {"postleitzahl": "8558 Raperswilen"},
 {"postleitzahl": "8560 Märstetten"},
 {"postleitzahl": "8561 Ottoberg"},
 {"postleitzahl": "8564 Lipperswil"},
 {"postleitzahl": "8564 Engwilen"},
 {"postleitzahl": "8564 Wäldi"},
 {"postleitzahl": "8564 Sonterswil"},
 {"postleitzahl": "8564 Wagerswil"},
 {"postleitzahl": "8564 Hattenhausen"},
 {"postleitzahl": "8564 Gunterswilen"},
 {"postleitzahl": "8564 Hefenhausen"},
 {"postleitzahl": "8565 Hugelshofen"},
 {"postleitzahl": "8566 Dotnacht"},
 {"postleitzahl": "8566 Neuwilen"},
 {"postleitzahl": "8566 Ellighausen"},
 {"postleitzahl": "8566 Lippoldswilen"},
 {"postleitzahl": "8570 Weinfelden"},
 {"postleitzahl": "8572 Andhausen"},
 {"postleitzahl": "8572 Graltshausen"},
 {"postleitzahl": "8572 Guntershausen b. Berg"},
 {"postleitzahl": "8572 Berg TG"},
 {"postleitzahl": "8573 Alterswilen"},
 {"postleitzahl": "8573 Altishausen"},
 {"postleitzahl": "8573 Siegershausen"},
 {"postleitzahl": "8574 Illighausen"},
 {"postleitzahl": "8574 Lengwil-Oberhofen"},
 {"postleitzahl": "8575 Istighofen"},
 {"postleitzahl": "8575 Bürglen TG"},
 {"postleitzahl": "8576 Mauren TG"},
 {"postleitzahl": "8577 Toos"},
 {"postleitzahl": "8577 Schönholzerswilen"},
 {"postleitzahl": "8580 Sommeri"},
 {"postleitzahl": "8580 Hagenwil b. Amriswil"},
 {"postleitzahl": "8580 Hefenhofen"},
 {"postleitzahl": "8580 Amriswil"},
 {"postleitzahl": "8581 Schocherswil"},
 {"postleitzahl": "8582 Dozwil"},
 {"postleitzahl": "8583 Götighofen"},
 {"postleitzahl": "8583 Donzhausen"},
 {"postleitzahl": "8583 Sulgen"},
 {"postleitzahl": "8584 Leimbach TG"},
 {"postleitzahl": "8584 Opfershofen TG"},
 {"postleitzahl": "8585 Happerswil"},
 {"postleitzahl": "8585 Eggethof"},
 {"postleitzahl": "8585 Zuben"},
 {"postleitzahl": "8585 Schönenbaumgarten"},
 {"postleitzahl": "8585 Herrenhof"},
 {"postleitzahl": "8585 Birwinken"},
 {"postleitzahl": "8585 Klarsreuti"},
 {"postleitzahl": "8585 Mattwil"},
 {"postleitzahl": "8585 Langrickenbach"},
 {"postleitzahl": "8586 Andwil TG"},
 {"postleitzahl": "8586 Kümmertshausen"},
 {"postleitzahl": "8586 Riedt b. Erlen"},
 {"postleitzahl": "8586 Buchackern"},
 {"postleitzahl": "8586 Engishofen"},
 {"postleitzahl": "8586 Ennetaach"},
 {"postleitzahl": "8586 Buch b. Kümmertshausen"},
 {"postleitzahl": "8586 Erlen"},
 {"postleitzahl": "8587 Oberaach"},
 {"postleitzahl": "8588 Zihlschlacht"},
 {"postleitzahl": "8589 Sitterdorf"},
 {"postleitzahl": "8590 Romanshorn"},
 {"postleitzahl": "8592 Uttwil"},
 {"postleitzahl": "8593 Kesswil"},
 {"postleitzahl": "8594 Güttingen"},
 {"postleitzahl": "8595 Altnau"},
 {"postleitzahl": "8596 Münsterlingen"},
 {"postleitzahl": "8596 Scherzingen"},
 {"postleitzahl": "8597 Landschlacht"},
 {"postleitzahl": "8598 Bottighofen"},
 {"postleitzahl": "8599 Salmsach"},
 {"postleitzahl": "8600 Dübendorf"},
 {"postleitzahl": "8602 Wangen b. Dübendorf"},
 {"postleitzahl": "8603 Schwerzenbach"},
 {"postleitzahl": "8604 Volketswil"},
 {"postleitzahl": "8605 Gutenswil"},
 {"postleitzahl": "8606 Nänikon"},
 {"postleitzahl": "8606 Greifensee"},
 {"postleitzahl": "8607 Aathal-Seegräben"},
 {"postleitzahl": "8608 Bubikon"},
 {"postleitzahl": "8610 Uster"},
 {"postleitzahl": "8614 Sulzbach"},
 {"postleitzahl": "8614 Bertschikon (Gossau ZH)"},
 {"postleitzahl": "8615 Wermatswil"},
 {"postleitzahl": "8615 Freudwil"},
 {"postleitzahl": "8616 Riedikon"},
 {"postleitzahl": "8617 Mönchaltorf"},
 {"postleitzahl": "8618 Oetwil am See"},
 {"postleitzahl": "8620 Wetzikon ZH"},
 {"postleitzahl": "8623 Wetzikon ZH"},
 {"postleitzahl": "8624 Grüt (Gossau ZH)"},
 {"postleitzahl": "8625 Gossau ZH"},
 {"postleitzahl": "8626 Ottikon (Gossau ZH)"},
 {"postleitzahl": "8627 Grüningen"},
 {"postleitzahl": "8630 Rüti ZH"},
 {"postleitzahl": "8632 Tann"},
 {"postleitzahl": "8633 Wolfhausen"},
 {"postleitzahl": "8634 Hombrechtikon"},
 {"postleitzahl": "8635 Dürnten"},
 {"postleitzahl": "8636 Wald ZH"},
 {"postleitzahl": "8637 Laupen ZH"},
 {"postleitzahl": "8638 Goldingen"},
 {"postleitzahl": "8639 Faltigberg"},
 {"postleitzahl": "8640 Rapperswil SG"},
 {"postleitzahl": "8640 Hurden"},
 {"postleitzahl": "8645 Jona"},
 {"postleitzahl": "8646 Wagen"},
 {"postleitzahl": "8700 Küsnacht ZH"},
 {"postleitzahl": "8702 Zollikon"},
 {"postleitzahl": "8703 Erlenbach ZH"},
 {"postleitzahl": "8704 Herrliberg"},
 {"postleitzahl": "8706 Meilen"},
 {"postleitzahl": "8707 Uetikon am See"},
 {"postleitzahl": "8708 Männedorf"},
 {"postleitzahl": "8712 Stäfa"},
 {"postleitzahl": "8713 Uerikon"},
 {"postleitzahl": "8714 Feldbach"},
 {"postleitzahl": "8715 Bollingen"},
 {"postleitzahl": "8716 Schmerikon"},
 {"postleitzahl": "8717 Benken SG"},
 {"postleitzahl": "8718 Schänis"},
 {"postleitzahl": "8722 Kaltbrunn"},
 {"postleitzahl": "8723 Rufi"},
 {"postleitzahl": "8725 Gebertingen"},
 {"postleitzahl": "8725 Ernetschwil"},
 {"postleitzahl": "8726 Ricken SG"},
 {"postleitzahl": "8727 Walde SG"},
 {"postleitzahl": "8730 Uznach"},
 {"postleitzahl": "8732 Neuhaus SG"},
 {"postleitzahl": "8733 Eschenbach SG"},
 {"postleitzahl": "8734 Ermenswil"},
 {"postleitzahl": "8735 Rüeterswil"},
 {"postleitzahl": "8735 St. Gallenkappel"},
 {"postleitzahl": "8737 Gommiswald"},
 {"postleitzahl": "8738 Uetliburg SG"},
 {"postleitzahl": "8739 Rieden SG"},
 {"postleitzahl": "8750 Klöntal"},
 {"postleitzahl": "8750 Glarus"},
 {"postleitzahl": "8750 Riedern"},
 {"postleitzahl": "8751 Urnerboden"},
 {"postleitzahl": "8752 Näfels"},
 {"postleitzahl": "8753 Mollis"},
 {"postleitzahl": "8754 Netstal"},
 {"postleitzahl": "8755 Ennenda"},
 {"postleitzahl": "8756 Mitlödi"},
 {"postleitzahl": "8757 Filzbach"},
 {"postleitzahl": "8758 Obstalden"},
 {"postleitzahl": "8762 Schwändi b. Schwanden"},
 {"postleitzahl": "8762 Sool"},
 {"postleitzahl": "8762 Schwanden GL"},
 {"postleitzahl": "8765 Engi"},
 {"postleitzahl": "8766 Matt"},
 {"postleitzahl": "8767 Elm"},
 {"postleitzahl": "8772 Nidfurn"},
 {"postleitzahl": "8773 Haslen GL"},
 {"postleitzahl": "8774 Leuggelbach"},
 {"postleitzahl": "8775 Luchsingen"},
 {"postleitzahl": "8775 Hätzingen"},
 {"postleitzahl": "8777 Diesbach GL"},
 {"postleitzahl": "8777 Betschwanden"},
 {"postleitzahl": "8782 Rüti GL"},
 {"postleitzahl": "8783 Linthal"},
 {"postleitzahl": "8784 Braunwald"},
 {"postleitzahl": "8800 Thalwil"},
 {"postleitzahl": "8802 Kilchberg ZH"},
 {"postleitzahl": "8803 Rüschlikon"},
 {"postleitzahl": "8804 Au ZH"},
 {"postleitzahl": "8805 Richterswil"},
 {"postleitzahl": "8806 Bäch SZ"},
 {"postleitzahl": "8807 Freienbach"},
 {"postleitzahl": "8808 Pfäffikon SZ"},
 {"postleitzahl": "8810 Horgen"},
 {"postleitzahl": "8815 Horgenberg"},
 {"postleitzahl": "8816 Hirzel"},
 {"postleitzahl": "8820 Wädenswil"},
 {"postleitzahl": "8824 Schönenberg ZH"},
 {"postleitzahl": "8825 Hütten"},
 {"postleitzahl": "8832 Wilen b. Wollerau"},
 {"postleitzahl": "8832 Wollerau"},
 {"postleitzahl": "8833 Samstagern"},
 {"postleitzahl": "8834 Schindellegi"},
 {"postleitzahl": "8835 Feusisberg"},
 {"postleitzahl": "8836 Bennau"},
 {"postleitzahl": "8840 Trachslau"},
 {"postleitzahl": "8840 Einsiedeln"},
 {"postleitzahl": "8841 Gross"},
 {"postleitzahl": "8842 Unteriberg"},
 {"postleitzahl": "8843 Oberiberg"},
 {"postleitzahl": "8844 Euthal"},
 {"postleitzahl": "8845 Studen SZ"},
 {"postleitzahl": "8846 Willerzell"},
 {"postleitzahl": "8847 Egg SZ"},
 {"postleitzahl": "8849 Alpthal"},
 {"postleitzahl": "8852 Altendorf"},
 {"postleitzahl": "8853 Lachen SZ"},
 {"postleitzahl": "8854 Siebnen"},
 {"postleitzahl": "8854 Galgenen"},
 {"postleitzahl": "8855 Wangen SZ"},
 {"postleitzahl": "8856 Tuggen"},
 {"postleitzahl": "8857 Vorderthal"},
 {"postleitzahl": "8858 Innerthal"},
 {"postleitzahl": "8862 Schübelbach"},
 {"postleitzahl": "8863 Buttikon SZ"},
 {"postleitzahl": "8864 Reichenburg"},
 {"postleitzahl": "8865 Bilten"},
 {"postleitzahl": "8866 Ziegelbrücke"},
 {"postleitzahl": "8867 Niederurnen"},
 {"postleitzahl": "8868 Oberurnen"},
 {"postleitzahl": "8872 Weesen"},
 {"postleitzahl": "8873 Amden"},
 {"postleitzahl": "8874 Mühlehorn"},
 {"postleitzahl": "8877 Murg"},
 {"postleitzahl": "8878 Quinten"},
 {"postleitzahl": "8880 Walenstadt"},
 {"postleitzahl": "8881 Knoblisbühl"},
 {"postleitzahl": "8881 Tscherlach"},
 {"postleitzahl": "8881 Walenstadtberg"},
 {"postleitzahl": "8882 Unterterzen"},
 {"postleitzahl": "8883 Quarten"},
 {"postleitzahl": "8884 Oberterzen"},
 {"postleitzahl": "8885 Mols"},
 {"postleitzahl": "8886 Mädris-Vermol"},
 {"postleitzahl": "8887 Mels"},
 {"postleitzahl": "8888 Heiligkreuz (Mels)"},
 {"postleitzahl": "8889 Plons"},
 {"postleitzahl": "8890 Flums"},
 {"postleitzahl": "8892 Berschis"},
 {"postleitzahl": "8893 Flums Hochwiese"},
 {"postleitzahl": "8894 Flumserberg Saxli"},
 {"postleitzahl": "8895 Flumserberg Portels"},
 {"postleitzahl": "8896 Flumserberg Bergheim"},
 {"postleitzahl": "8897 Flumserberg Tannenheim"},
 {"postleitzahl": "8898 Flumserberg Tannenbodenalp"},
 {"postleitzahl": "8902 Urdorf"},
 {"postleitzahl": "8903 Birmensdorf ZH"},
 {"postleitzahl": "8904 Aesch ZH"},
 {"postleitzahl": "8905 Islisberg"},
 {"postleitzahl": "8905 Arni AG"},
 {"postleitzahl": "8906 Bonstetten"},
 {"postleitzahl": "8907 Wettswil"},
 {"postleitzahl": "8908 Hedingen"},
 {"postleitzahl": "8909 Zwillikon"},
 {"postleitzahl": "8910 Affoltern am Albis"},
 {"postleitzahl": "8911 Rifferswil"},
 {"postleitzahl": "8912 Obfelden"},
 {"postleitzahl": "8913 Ottenbach"},
 {"postleitzahl": "8914 Aeugstertal"},
 {"postleitzahl": "8914 Aeugst am Albis"},
 {"postleitzahl": "8915 Hausen am Albis"},
 {"postleitzahl": "8916 Jonen"},
 {"postleitzahl": "8917 Oberlunkhofen"},
 {"postleitzahl": "8918 Unterlunkhofen"},
 {"postleitzahl": "8919 Rottenschwil"},
 {"postleitzahl": "8925 Ebertswil"},
 {"postleitzahl": "8926 Hauptikon"},
 {"postleitzahl": "8926 Uerzlikon"},
 {"postleitzahl": "8926 Kappel am Albis"},
 {"postleitzahl": "8932 Mettmenstetten"},
 {"postleitzahl": "8933 Maschwanden"},
 {"postleitzahl": "8934 Knonau"},
 {"postleitzahl": "8942 Oberrieden"},
 {"postleitzahl": "8951 Fahrweid"},
 {"postleitzahl": "8952 Schlieren"},
 {"postleitzahl": "8953 Dietikon"},
 {"postleitzahl": "8954 Geroldswil"},
 {"postleitzahl": "8955 Oetwil an der Limmat"},
 {"postleitzahl": "8956 Killwangen"},
 {"postleitzahl": "8957 Spreitenbach"},
 {"postleitzahl": "8962 Bergdietikon"},
 {"postleitzahl": "8964 Rudolfstetten"},
 {"postleitzahl": "8965 Berikon"},
 {"postleitzahl": "8966 Oberwil-Lieli"},
 {"postleitzahl": "8967 Widen"},
 {"postleitzahl": "9000 St. Gallen"},
 {"postleitzahl": "9007 St. Gallen"},
 {"postleitzahl": "9008 St. Gallen"},
 {"postleitzahl": "9010 St. Gallen"},
 {"postleitzahl": "9011 St. Gallen"},
 {"postleitzahl": "9012 St. Gallen"},
 {"postleitzahl": "9014 St. Gallen"},
 {"postleitzahl": "9015 St. Gallen"},
 {"postleitzahl": "9016 St. Gallen"},
 {"postleitzahl": "9030 Abtwil SG"},
 {"postleitzahl": "9032 Engelburg"},
 {"postleitzahl": "9033 Untereggen"},
 {"postleitzahl": "9034 Eggersriet"},
 {"postleitzahl": "9035 Grub AR"},
 {"postleitzahl": "9036 Grub SG"},
 {"postleitzahl": "9037 Speicherschwendi"},
 {"postleitzahl": "9038 Rehetobel"},
 {"postleitzahl": "9042 Speicher"},
 {"postleitzahl": "9043 Trogen"},
 {"postleitzahl": "9044 Wald AR"},
 {"postleitzahl": "9050 Appenzell Eggerstanden"},
 {"postleitzahl": "9050 Appenzell Enggenhütten"},
 {"postleitzahl": "9050 Appenzell Meistersrüte"},
 {"postleitzahl": "9050 Appenzell Steinegg"},
 {"postleitzahl": "9050 Appenzell Schlatt"},
 {"postleitzahl": "9050 Appenzell"},
 {"postleitzahl": "9052 Niederteufen"},
 {"postleitzahl": "9053 Teufen AR"},
 {"postleitzahl": "9054 Haslen AI"},
 {"postleitzahl": "9055 Bühler"},
 {"postleitzahl": "9056 Gais"},
 {"postleitzahl": "9057 Schwende"},
 {"postleitzahl": "9057 Wasserauen"},
 {"postleitzahl": "9057 Weissbad"},
 {"postleitzahl": "9058 Brülisau"},
 {"postleitzahl": "9062 Lustmühle"},
 {"postleitzahl": "9063 Stein AR"},
 {"postleitzahl": "9064 Hundwil"},
 {"postleitzahl": "9100 Herisau"},
 {"postleitzahl": "9103 Schwellbrunn"},
 {"postleitzahl": "9104 Waldstatt"},
 {"postleitzahl": "9105 Schönengrund"},
 {"postleitzahl": "9107 Urnäsch"},
 {"postleitzahl": "9108 Gontenbad"},
 {"postleitzahl": "9108 Jakobsbad"},
 {"postleitzahl": "9108 Gonten"},
 {"postleitzahl": "9112 Schachen b. Herisau"},
 {"postleitzahl": "9113 Degersheim"},
 {"postleitzahl": "9114 Hoffeld"},
 {"postleitzahl": "9115 Dicken"},
 {"postleitzahl": "9116 Wolfertswil"},
 {"postleitzahl": "9122 Ebersol"},
 {"postleitzahl": "9122 Mogelsberg"},
 {"postleitzahl": "9123 Nassen"},
 {"postleitzahl": "9125 Brunnadern"},
 {"postleitzahl": "9126 Necker"},
 {"postleitzahl": "9127 St. Peterzell"},
 {"postleitzahl": "9200 Gossau SG"},
 {"postleitzahl": "9203 Niederwil SG"},
 {"postleitzahl": "9204 Andwil SG"},
 {"postleitzahl": "9205 Waldkirch"},
 {"postleitzahl": "9212 Arnegg"},
 {"postleitzahl": "9213 Hauptwil"},
 {"postleitzahl": "9214 Kradolf"},
 {"postleitzahl": "9215 Schönenberg an der Thur"},
 {"postleitzahl": "9215 Buhwil"},
 {"postleitzahl": "9216 Heldswil"},
 {"postleitzahl": "9216 Hohentannen"},
 {"postleitzahl": "9217 Neukirch an der Thur"},
 {"postleitzahl": "9220 Bischofszell"},
 {"postleitzahl": "9223 Schweizersholz"},
 {"postleitzahl": "9223 Halden"},
 {"postleitzahl": "9225 Wilen (Gottshaus)"},
 {"postleitzahl": "9225 St. Pelagiberg"},
 {"postleitzahl": "9230 Flawil"},
 {"postleitzahl": "9231 Egg (Flawil)"},
 {"postleitzahl": "9240 Niederglatt SG"},
 {"postleitzahl": "9240 Uzwil"},
 {"postleitzahl": "9242 Oberuzwil"},
 {"postleitzahl": "9243 Jonschwil"},
 {"postleitzahl": "9244 Niederuzwil"},
 {"postleitzahl": "9245 Sonnental"},
 {"postleitzahl": "9245 Oberbüren"},
 {"postleitzahl": "9246 Niederbüren"},
 {"postleitzahl": "9247 Henau"},
 {"postleitzahl": "9248 Bichwil"},
 {"postleitzahl": "9249 Oberstetten"},
 {"postleitzahl": "9249 Niederstetten"},
 {"postleitzahl": "9249 Algetshausen"},
 {"postleitzahl": "9300 Wittenbach"},
 {"postleitzahl": "9304 Bernhardzell"},
 {"postleitzahl": "9305 Berg SG"},
 {"postleitzahl": "9306 Freidorf TG"},
 {"postleitzahl": "9308 Lömmenschwil"},
 {"postleitzahl": "9312 Häggenschwil"},
 {"postleitzahl": "9313 Muolen"},
 {"postleitzahl": "9314 Steinebrunn"},
 {"postleitzahl": "9315 Winden"},
 {"postleitzahl": "9315 Neukirch (Egnach)"},
 {"postleitzahl": "9320 Frasnacht"},
 {"postleitzahl": "9320 Stachen"},
 {"postleitzahl": "9320 Arbon"},
 {"postleitzahl": "9322 Egnach"},
 {"postleitzahl": "9323 Steinach"},
 {"postleitzahl": "9325 Roggwil TG"},
 {"postleitzahl": "9326 Horn"},
 {"postleitzahl": "9327 Tübach"},
 {"postleitzahl": "9400 Rorschach"},
 {"postleitzahl": "9402 Mörschwil"},
 {"postleitzahl": "9403 Goldach"},
 {"postleitzahl": "9404 Rorschacherberg"},
 {"postleitzahl": "9405 Wienacht-Tobel"},
 {"postleitzahl": "9410 Heiden"},
 {"postleitzahl": "9411 Reute AR"},
 {"postleitzahl": "9413 Oberegg"},
 {"postleitzahl": "9414 Schachen b. Reute"},
 {"postleitzahl": "9422 Staad SG"},
 {"postleitzahl": "9423 Altenrhein"},
 {"postleitzahl": "9424 Rheineck"},
 {"postleitzahl": "9425 Thal"},
 {"postleitzahl": "9426 Lutzenberg"},
 {"postleitzahl": "9427 Zelg (Wolfhalden)"},
 {"postleitzahl": "9427 Wolfhalden"},
 {"postleitzahl": "9428 Walzenhausen"},
 {"postleitzahl": "9430 St. Margrethen SG"},
 {"postleitzahl": "9434 Au SG"},
 {"postleitzahl": "9435 Heerbrugg"},
 {"postleitzahl": "9436 Balgach"},
 {"postleitzahl": "9437 Marbach SG"},
 {"postleitzahl": "9442 Büriswilen"},
 {"postleitzahl": "9442 Berneck"},
 {"postleitzahl": "9443 Widnau"},
 {"postleitzahl": "9444 Diepoldsau"},
 {"postleitzahl": "9445 Rebstein"},
 {"postleitzahl": "9450 Lüchingen"},
 {"postleitzahl": "9450 Altstätten SG"},
 {"postleitzahl": "9451 Kriessern"},
 {"postleitzahl": "9452 Hinterforst"},
 {"postleitzahl": "9453 Eichberg"},
 {"postleitzahl": "9462 Montlingen"},
 {"postleitzahl": "9463 Oberriet SG"},
 {"postleitzahl": "9464 Lienz"},
 {"postleitzahl": "9464 Rüthi (Rheintal)"},
 {"postleitzahl": "9465 Salez"},
 {"postleitzahl": "9466 Sennwald"},
 {"postleitzahl": "9467 Frümsen"},
 {"postleitzahl": "9468 Sax"},
 {"postleitzahl": "9469 Haag (Rheintal)"},
 {"postleitzahl": "9470 Buchs SG"},
 {"postleitzahl": "9470 Werdenberg"},
 {"postleitzahl": "9472 Grabserberg"},
 {"postleitzahl": "9472 Grabs"},
 {"postleitzahl": "9473 Gams"},
 {"postleitzahl": "9475 Sevelen"},
 {"postleitzahl": "9476 Fontnas"},
 {"postleitzahl": "9476 Weite"},
 {"postleitzahl": "9477 Trübbach"},
 {"postleitzahl": "9478 Azmoos"},
 {"postleitzahl": "9479 Gretschins"},
 {"postleitzahl": "9479 Malans SG"},
 {"postleitzahl": "9479 Oberschan"},
 {"postleitzahl": "9485 Nendeln"},
 {"postleitzahl": "9486 Schaanwald"},
 {"postleitzahl": "9487 Gamprin-Bendern"},
 {"postleitzahl": "9488 Schellenberg"},
 {"postleitzahl": "9490 Vaduz"},
 {"postleitzahl": "9491 Ruggell"},
 {"postleitzahl": "9492 Eschen"},
 {"postleitzahl": "9493 Mauren FL"},
 {"postleitzahl": "9494 Schaan"},
 {"postleitzahl": "9495 Triesen"},
 {"postleitzahl": "9496 Balzers"},
 {"postleitzahl": "9497 Triesenberg"},
 {"postleitzahl": "9498 Planken"},
 {"postleitzahl": "9500 Wil SG"},
 {"postleitzahl": "9502 Braunau"},
 {"postleitzahl": "9503 Stehrenberg"},
 {"postleitzahl": "9503 Lanterswil"},
 {"postleitzahl": "9504 Friltschen"},
 {"postleitzahl": "9506 Lommis"},
 {"postleitzahl": "9507 Stettfurt"},
 {"postleitzahl": "9508 Weingarten-Kalthäusern"},
 {"postleitzahl": "9512 Rossrüti"},
 {"postleitzahl": "9514 Wuppenau"},
 {"postleitzahl": "9515 Hosenruck"},
 {"postleitzahl": "9517 Mettlen"},
 {"postleitzahl": "9523 Züberwangen"},
 {"postleitzahl": "9524 Zuzwil SG"},
 {"postleitzahl": "9525 Lenggenwil"},
 {"postleitzahl": "9526 Zuckenriet"},
 {"postleitzahl": "9527 Niederhelfenschwil"},
 {"postleitzahl": "9532 Rickenbach b. Wil"},
 {"postleitzahl": "9533 Kirchberg SG"},
 {"postleitzahl": "9534 Gähwil"},
 {"postleitzahl": "9535 Wilen b. Wil"},
 {"postleitzahl": "9536 Schwarzenbach SG"},
 {"postleitzahl": "9542 Münchwilen TG"},
 {"postleitzahl": "9543 St. Margarethen TG"},
 {"postleitzahl": "9545 Wängi"},
 {"postleitzahl": "9546 Tuttwil"},
 {"postleitzahl": "9547 Wittenwil"},
 {"postleitzahl": "9548 Matzingen"},
 {"postleitzahl": "9552 Bronschhofen"},
 {"postleitzahl": "9553 Bettwiesen"},
 {"postleitzahl": "9554 Tägerschen"},
 {"postleitzahl": "9555 Tobel"},
 {"postleitzahl": "9556 Zezikon"},
 {"postleitzahl": "9556 Affeltrangen"},
 {"postleitzahl": "9562 Buch b. Märwil"},
 {"postleitzahl": "9562 Märwil"},
 {"postleitzahl": "9565 Rothenhausen"},
 {"postleitzahl": "9565 Schmidshof"},
 {"postleitzahl": "9565 Oppikon"},
 {"postleitzahl": "9565 Oberbussnang"},
 {"postleitzahl": "9565 Bussnang"},
 {"postleitzahl": "9573 Littenheid"},
 {"postleitzahl": "9601 Lütisburg Station"},
 {"postleitzahl": "9602 Müselbach"},
 {"postleitzahl": "9602 Bazenheid"},
 {"postleitzahl": "9604 Oberrindal"},
 {"postleitzahl": "9604 Unterrindal"},
 {"postleitzahl": "9604 Lütisburg"},
 {"postleitzahl": "9606 Bütschwil"},
 {"postleitzahl": "9607 Mosnang"},
 {"postleitzahl": "9608 Ganterschwil"},
 {"postleitzahl": "9612 Dreien"},
 {"postleitzahl": "9613 Mühlrüti"},
 {"postleitzahl": "9614 Libingen"},
 {"postleitzahl": "9615 Dietfurt"},
 {"postleitzahl": "9620 Lichtensteig"},
 {"postleitzahl": "9621 Oberhelfenschwil"},
 {"postleitzahl": "9622 Krinau"},
 {"postleitzahl": "9630 Wattwil"},
 {"postleitzahl": "9631 Ulisbach"},
 {"postleitzahl": "9633 Bächli (Hemberg)"},
 {"postleitzahl": "9633 Hemberg"},
 {"postleitzahl": "9642 Ebnat-Kappel"},
 {"postleitzahl": "9643 Krummenau"},
 {"postleitzahl": "9650 Nesslau"},
 {"postleitzahl": "9651 Ennetbühl"},
 {"postleitzahl": "9652 Neu St. Johann"},
 {"postleitzahl": "9655 Stein SG"},
 {"postleitzahl": "9656 Alt St. Johann"},
 {"postleitzahl": "9657 Unterwasser"},
 {"postleitzahl": "9658 Wildhaus"}]
