import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import classNames from 'classnames';
import Modal from 'react-modal'
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { Input } from '../../../components/Input/index';
import { Button, EButtonType } from "../../../components/Button";
import close from "../../../assets/icons/Close_Icon.svg";
import SelectList from "../../../components/Sidebar/SelectList";
import gsap from 'gsap';
import { animationConfig } from '../../../constants';
import { formatNumber, toggleFullScreen } from '../../../utils/react';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { isNumericOrDot } from '../../../utils/variableEvaluation';

const translatableStrings = defineMessages({
    explanataionParagraph1: {
        id: 'pageAhvrente.explanataionParagraph1',
        defaultMessage: 'Die Höhe der AHV-Rente hat keinen Einfluss auf die Berechnungen. Wenn Sie möchten, kann diese im Ergebnis mit angezeigt werden.'
    },
    explanataionParagraph2: {
        id: 'pageAhvrente.explanataionParagraph2',
        defaultMessage: 'Da die Rente individuellje nach Beitragsjahren berechnet wird, können Sie hier wählen, wie hoch Ihre AHV-Rente voraussichtlich sein wird.'
    },
    explanataionParagraph3: {
        id: 'pageAhvrente.explanataionParagraph3',
        defaultMessage: 'Die genaue Höhe Ihrer individuellen AHV-Rente bringen Sie bei Ihrer AHV-Ausgleichskasse in Erfahrung. Eine grobe Schätzung ist auch über das {link} möglich.',
    },
    linkTitle: {
        id: 'pageAhvrente.linkTitle',
        defaultMessage: 'Online-Tool der AHV',
    },
    linkUrl: {
        id: 'pageAhvrente.linkUrl',
        defaultMessage: 'http://acor-avs.ch/requerant',
    },
    whatIsYourAhv: {
        id: 'pageAhvrente.whatIsYourAhv',
        defaultMessage: 'Zu erwartende AHV-Rente mit einbeziehen?'
    },
    whyAhv: {
        id: 'pageAhvrente.whyAhv',
        defaultMessage: 'Was hat die AHV-Rente damit zu tun?'
    },
    skipWithouthAhv: {
        id: 'pageAhvrente.skipWithouthAhv',
        defaultMessage: 'Weiter ohne AHV Rente'
    },
    next: {
        id: 'button.next',
        defaultMessage: 'WEITER'
    },
    ifIncludeAhv: {
        id: 'pageAhvrente.ifIncludeAhv',
        defaultMessage: 'Zu erwartende AHV-Rente mit einbeziehen?'
    },
    month: {
        id: 'general.month',
        defaultMessage: 'Monat'
    },
    ahvrente: {
        id: 'navigation.ahvrente',
        defaultMessage: 'AHV-Rente'
    },
})
interface State {
    value: any;
    modalIsOpen: boolean;
}

interface Props {
    actions: any;
    history: any;
    setUserDataAHVRente: any;
    userDataWI: any;
    intl: any;
}

class AHVRente extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            modalIsOpen: false,
            value: this.props.userDataWI.ahvPensionAmount ? this.props.userDataWI.ahvPensionAmount : "",
        };
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        // if (window.innerWidth > 896) {
        setTimeout(() => {
            (document.querySelector('.main input') as any) && (document.querySelector('.main input') as any).focus();
        }, 1200);
        // }
    }

    async setUserDataAHVRente(data) {
        await this.props.setUserDataAHVRente(data);
    }

    next(e) {
        window.innerWidth < 897 && toggleFullScreen(true, e);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        // let parts = e.toString().split(".");
        // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        // this.setUserDataAHVRente(parts.join("."));
        this.setUserDataAHVRente(e);
        if (document.querySelectorAll('.crossFade').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                    autoAlpha: 0
                })
        }

        if (document.querySelectorAll('.wunschberechnung_container .stagger').length) {
            this.timeline
                // .to(document.querySelector('#logo3 ~ .dropdown'), {maxHeight: 50 })
                .fromTo(document.querySelectorAll('.wunschberechnung_container .stagger'), { autoAlpha: 1 }, {
                    autoAlpha: 0,
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                })
        }

        this.timeline.play();

        setTimeout(() => {
            this.props.history.push({
                pathname: `/fragen`
            });
        }, 1200)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    handleChange(name, value) {
        isNumericOrDot(value) && this.setState({
            value: value
        });
    };

    showHideNotif = () => {
        if (this.state.modalIsOpen && window.innerWidth < 897) {
            this.setState({
                modalIsOpen: false
            })
        } else {
            this.setState({
                modalIsOpen: true
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    async enter(event) {
        const maxValue = this.props.userDataWI.settings ? this.props.userDataWI.settings.maxAHVPensionPerMonth : 2370;
        if (event.keyCode === 13) {
            this.state.value <= maxValue && this.next(event.currentTarget.value)
        }
    }

    render() {
        const maxValue = this.props.userDataWI.settings ? this.props.userDataWI.settings.maxAHVPensionPerMonth : 2370;
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                  <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                </div>
                <div className="main ahv-main">
                    <FormattedMessageCustom id={translatableStrings.ifIncludeAhv.id} text={this.props.intl.formatMessage(translatableStrings.ifIncludeAhv)}>
                        <div className='ich-mochte mb-40 stagger'>
                        </div>
                    </FormattedMessageCustom>
                    <div className='rente stagger'>
                        <div className={classNames('rente__main', {
                            "invalid": this.state.value > maxValue
                        })}>
                            <Input
                                value={this.state.value}
                                max={maxValue}
                                placeholder={this.props.intl.formatMessage(translatableStrings.ahvrente)}
                                inputWrapperClass="w-100 number_input wide"
                                onChange={(name, val) => this.handleChange(name, val)}
                                onKeyDown={(event) => this.enter(event)}
                                type='money'
                                // validationMessageConfig={{}}
                                inputWrapperStyle={{}}>
                                CHF/<FormattedMessageCustom id={translatableStrings.month.id} text={this.props.intl.formatMessage(translatableStrings.month)} />
                            </Input>
                        </div>
                        <div className='rente-sub'>max. CHF {formatNumber(maxValue,0)}</div>
                        {(this.state) ? this.state.value ?
                            <div className='autosuggestButton'>
                                <Button
                                    className="button text-uppercase"
                                    submit={true}
                                    isDisabled={this.state.value > maxValue}
                                    buttonType={EButtonType.FullWidth}
                                    onClick={() => this.state.value <= maxValue && this.next(this.state.value)}
                                >
                                    <FormattedMessageCustom id={translatableStrings.next.id} text={this.props.intl.formatMessage(translatableStrings.next)}>
                                        <div></div>
                                    </FormattedMessageCustom>
                                </Button>
                            </div> : <div className='autosuggestButton fullWidthBtn'>
                                <Button
                                    className="button text-uppercase"
                                    submit={true}
                                    buttonType={EButtonType.FullWidth}
                                    onClick={() => this.next("")}
                                >
                                    <FormattedMessageCustom id={translatableStrings.skipWithouthAhv.id} text={this.props.intl.formatMessage(translatableStrings.skipWithouthAhv)}>
                                        <div></div>
                                    </FormattedMessageCustom>
                                </Button>
                            </div> : null

                        }
                    </div>
                    <FormattedMessageCustom id={translatableStrings.whyAhv.id} text={this.props.intl.formatMessage(translatableStrings.whyAhv)}>
                        <div className="inform stagger" onClick={this.showHideNotif}>
                        </div>
                    </FormattedMessageCustom>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.showHideNotif}
                        // contentLabel="Large Modal"
                        className={"mobile-modal"}
                        closeTimeoutMS={600}
                        overlayClassName={"large-modal-overlay"}>
                        {this.state.modalIsOpen && <div id='beispiel' className='beispiel' onClick={this.showHideNotif}>
                            <div className="color">
                                <img alt="doc" src={close} className='close' />
                                <div className='ahv'>
                                    <FormattedMessageCustom id={translatableStrings.explanataionParagraph1.id} text={this.props.intl.formatMessage(translatableStrings.explanataionParagraph1)}>
                                        <p className='text-left'>
                                        </p>
                                    </FormattedMessageCustom>
                                    <FormattedMessageCustom id={translatableStrings.explanataionParagraph2.id} text={this.props.intl.formatMessage(translatableStrings.explanataionParagraph2)}>
                                        <p className='text-left'>
                                        </p>
                                    </FormattedMessageCustom>
                                    <FormattedMessageCustom id={translatableStrings.explanataionParagraph3.id} text={this.props.intl.formatMessage(translatableStrings.explanataionParagraph3, {
                                        link: '<a href=' + this.props.intl.formatMessage(translatableStrings.linkUrl) + ' target="_blank" rel="noopener noreferrer" >' +
                                            this.props.intl.formatMessage(translatableStrings.linkTitle) + '</a>'
                                    })}>
                                        <p className='text-left'>
                                        </p>
                                    </FormattedMessageCustom>
                                </div>
                            </div>
                        </div>
                        }
                    </Modal>
                </div>
                <div className='optional crossFade' style={{ marginTop: '21px' }}>
                    <div className='optional-text'>
                        <FormattedMessageCustom id={translatableStrings.explanataionParagraph1.id} text={this.props.intl.formatMessage(translatableStrings.explanataionParagraph1)}>
                            <p className="text-left"></p>
                        </FormattedMessageCustom>

                        <FormattedMessageCustom id={translatableStrings.explanataionParagraph2.id} text={this.props.intl.formatMessage(translatableStrings.explanataionParagraph2)}>
                            <p className="text-left"></p>
                        </FormattedMessageCustom>
                        <FormattedMessageCustom id={translatableStrings.explanataionParagraph3.id} text={this.props.intl.formatMessage(translatableStrings.explanataionParagraph3, {
                            link: '<a href=' + this.props.intl.formatMessage(translatableStrings.linkUrl) + ' target="_blank" rel="noopener noreferrer" >' +
                                this.props.intl.formatMessage(translatableStrings.linkTitle) + '</a>'
                        })}>
                            <p className="text-left"></p>
                        </FormattedMessageCustom>
                    </div>
                </div >
            </div >
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataAHVRente: (data) => dispatch(actions.setUserDataAHVRente(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)((AHVRente));
