import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import classNames from 'classnames';
import * as actions from '../../../actions';
// import '../../utils/jwtToken';
import { parseDate } from '../../../utils/react';
// import { defined } from '../../../utils/variableEvaluation';
import '../../../containers/Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { DropdownCollapse } from '../../DropdownCollapse';
// import second from "../../assets/icons/menu_group/second_unc.svg";
// import secondOpen from "../../assets/icons/menu_group/second_c.svg";
// import complete, { ReactComponent as IconComplete } from "../../assets/icons/menu_group/complete.svg";
// import { ReactComponent as CheckmarkSlim } from "../../../assets/icons/checkmark-slim.svg";
import { ReactComponent as CheckmarkBold } from "../../../assets/icons/checkmark-bold.svg";
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    personalDetails: {
        id: 'navigation.personalDetails',
        defaultMessage: 'Persönliche Angaben'
    },
    birthDate: {
        id: 'navigation.birthDate',
        defaultMessage: 'Geburtsdatum'
    },
    gender: {
        id: 'navigation.gender',
        defaultMessage: 'Geschlecht'
    },
})

const stepsRange = [3, 6];
interface State {
    readyToAnimate: boolean;
    visibleOnChart: boolean;
}

interface Props {
    back?: any;
    currentPage: string;
    currentStep: number;
    open?: boolean;
    onChart: boolean;
    start?: any;
    toggle: any;
    stepsOpened: any;
    userDataWI: any;
    intl: any;
}


class Second extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            readyToAnimate: false,
            visibleOnChart: false
        }
    }

    componentDidMount() {
        this.setState({
            readyToAnimate: true,
        });

        document.body.addEventListener('click', this.bodyClick)
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick)
    }


    bodyClick = (event) => {
        if (window.innerWidth < 896) {

            const menuElem = (document.getElementById("step2") as any);
            // console.log("body clicked, in second nav", event.target,
            // 'menuelem', menuElem,
            // 'contains:', menuElem.contains(event.target),
            //  (event.target as any).classList,
            // (event.target as any).parentElement.classList
            // );
            if (!(menuElem).contains(event.target) && this.props.open) {
                console.log('will toggle on mobile 2');
                document.body.classList.remove('blurred');
                this.props.open && this.props.toggle();
            }
        }
    }

    showHide = () => {
        if (window.innerWidth < 896) {
            if (window.innerWidth < 896) {
                this.props.toggle();
                if(!this.props.open) {
                    document.body.classList.add('blurred');
                } else {
                    document.body.classList.remove('blurred');
                }
            }
        }
    }

    handleMouseEnter = () => {
        if (window.innerWidth > 896 && this.props.onChart) {
            this.setState({
                visibleOnChart: true
            })
        }
    }

    handleMouseLeave = () => {
        if (window.innerWidth > 896 && this.props.onChart) {
            this.props.open && this.props.toggle();
            this.setState({
                visibleOnChart: false
            })
        }
    }

    render() {
        const secondStepCompleted = this.props.userDataWI.Geschlecht && this.props.userDataWI.Geburtsdatum;
        const insideStep = this.props.currentStep > stepsRange[0] && this.props.currentStep < stepsRange[1];
        return (
            <label
                id="step2"
                className={classNames('select', {
                    "visible": this.state.visibleOnChart,
                    "hidden": !this.state.visibleOnChart,
                })}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div onClick={this.showHide}
                    className={classNames('circle', {
                        'open': this.props.open,
                        'insideStep': insideStep,
                        'finished': secondStepCompleted
                    })}
                >
                    {secondStepCompleted ? (<CheckmarkBold />) : "1"}
                </div>

                <div className={classNames("dropdown_content forMobileOnly", {
                    "opened": this.props.open
                })} id="logo2"
                >
                    <FormattedMessageCustom id={translatableStrings.personalDetails.id} text={this.props.intl.formatMessage(translatableStrings.personalDetails)}>
                        <div
                            id={this.props.userDataWI.calculationType ? `anliegen` : null}
                            style={this.props.open && insideStep ? { color: 'var(--blue-light)' } : null}
                            className={classNames('dropdown_elem point', {
                                'completed': secondStepCompleted,
                                'current': this.props.currentPage === 'wohnort',
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                    {/* <div
                        id={this.props.userDataWI.Datenquelle ? `datenquelle` : null}
                        onClick={this.props.back}
                        style={this.props.userDataWI.Datenquelle ? null : (this.props.currentPage === 'Datenquelle') ? { color: 'var(--main)' } : null}
                        className={classNames('dropdown_elem', {
                            'completed': this.props.userDataWI.Datenquelle,
                            'current': this.props.currentPage === 'Datenquelle'
                        })}
                    >
                        Datenquelle
                    </div> */}
                    <FormattedMessageCustom id={translatableStrings.birthDate.id} text={this.props.intl.formatMessage(translatableStrings.birthDate)}>
                        <div
                            id={this.props.userDataWI.Geburtsdatum ? `geburtsdatum` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.Geburtsdatum ? null : (this.props.currentPage === 'geburtsdatum') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.Geburtsdatum,
                                'current': this.props.currentPage === 'geburtsdatum'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.gender.id} text={this.props.intl.formatMessage(translatableStrings.gender)}>
                        <div
                            id={this.props.userDataWI.Geburtsdatum ? `geschlecht` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.Geschlecht ? null : (this.props.currentPage === 'geschlecht') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.Geschlecht,
                                'current': this.props.currentPage === 'geschlecht'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                    {/* <div
                        id={this.props.userDataWI.Wohnort ? `wohnort` : null}
                        onClick={this.props.back}
                        style={this.props.userDataWI.Wohnort ? null : (this.props.currentPage === 'wohnort') ? { color: 'var(--main)' } : null}
                        className={classNames('dropdown_elem', {
                            'completed': this.props.userDataWI.Wohnort,
                            'current': this.props.currentPage === 'wohnort'
                        })}
                    >
                        Wohnort
                </div> */}
                </div>
                <DropdownCollapse
                    className={classNames({
                        'open': this.props.open,
                    })}
                    isComplete={secondStepCompleted ? true : null}
                    isOpen={this.props.open}
                    toggle={this.props.toggle}
                    // isOpen={(this.props.userDataWI.pensionType && !this.props.userDataWI.Wohnort)}
                    // allowAnimation={this.props.allowAnimation}
                    insideStep={this.props.currentStep > stepsRange[0] && (this.props.currentStep < stepsRange[1])}
                    isActive={this.props.userDataWI.pensionType}
                    children={
                        [
                            // <div
                            //     key="datenquellesMobile"
                            //     id={this.props.userDataWI.Datenquelle ? `datenquelle` : null}
                            //     onClick={this.props.back}
                            //     className={classNames('menuTopElem menuElem', {
                            //         'completed': this.props.userDataWI.Datenquelle,
                            //         'current': this.props.currentPage === 'Datenquelle' && this.state.readyToAnimate
                            //     })}
                            // >
                            //     Datenquelle {this.props.userDataWI.Datenquelle}
                            // </div>,
                            // <br key="break21" />,
                            <div
                                key="geburtsdatumMobile"
                                id={(this.props.userDataWI.Geburtsdatum || this.props.userDataWI.Geburtsdatum === null) ? `geburtsdatum` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem menuTopElem', {
                                    'completed': this.props.userDataWI.Geburtsdatum,
                                    'current': this.props.currentPage === 'geburtsdatum' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.birthDate.id} text={this.props.intl.formatMessage(translatableStrings.birthDate)} /> {parseDate(this.props.userDataWI.Geburtsdatum, "secsToDDMMYYYY")}
                            </div>,
                            <br key="break22" />,
                            <div
                                key="geschlechtMobile"
                                id={(this.props.userDataWI.Geburtsdatum || this.props.userDataWI.Geschlecht === null) ? `geschlecht` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem menuLastElem', {
                                    'completed': this.props.userDataWI.Geschlecht,
                                    'current': this.props.currentPage === 'geschlecht' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.gender.id} text={this.props.intl.formatMessage(translatableStrings.gender)} /> {this.props.userDataWI.Geschlecht}
                            </div>,
                            // <br key="break23" />,
                            // <div
                            //     key="wohnortMobile"
                            //     id={this.props.userDataWI.Wohnort ? `wohnort` : null}
                            //     onClick={this.props.back}
                            //     className={classNames('menuLastElem menuElem', {
                            //         'completed': this.props.userDataWI.Wohnort,
                            //         'current': this.props.currentPage === 'Wohnort' && this.state.readyToAnimate
                            //     })}
                            // >
                            //     Wohnort {this.props.userDataWI.Wohnort}
                            // </div>
                        ]
                    }
                    label='Persönliche Angaben' />
                <div className='dots' />
            </label>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Second);
