import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import './Wunschberechnung.scss';
import SelectList from "../../../components/Sidebar/SelectList";
import gsap from 'gsap';
import { animationConfig } from "../../../constants";


interface State {
    login: string;
    password: string;
    passwordType: string;
    rememberMe: boolean;
}

interface Props {
    actions: any;
    history: any;
    userDataWI: any;
    setUserDataAnliegen: any;
    setStepsOpened: any;
    intl: any;
}

class Wunschberechnung extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        document.body.classList.remove("willkommen");

        this.choose = this.choose.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    setUserDataAnliegen = (data) => {
        this.props.setUserDataAnliegen(data);
    }

    componentDidMount() {
        console.log('anliegen did mount');
        document.body.classList.add('anliegen');
        // setTimeout(() => {
        // this.props.setStepsOpened({ 1: true })
        // }, 2200);
        // document.querySelector('.routeContainer').classList.remove('overflowHidden');
        // document.body.classList.add('animationFinished');
    }

    componentWillUnmount() {
        document.body.classList.remove('anliegen')
    }

    choose = (e) => {
        console.log('choose', document.querySelector('.currentNavItem'));
        const targetId = e.currentTarget.id;

        this.setUserDataAnliegen(targetId);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'),
                    { autoAlpha: 1 },
                    {
                        y: -900,
                        stagger: { ...animationConfig().stagger }
                        // delay: 0,
                        // duration: 1,
                        // stagger: {
                        //     each: .12,
                        //     ease: 'linear'
                        // },
                        // autoAlpha: 0
                    })
        }

        this.timeline.play();

        setTimeout((e) => {

            this.props.history.push({
                pathname: `/detaillierung`
            })
        }, 1500)
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    async showHideBack() {
        document.body.classList.remove('blur');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                    <div className='flex-grow-1'>
                        
                    </div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                neustartHide={!(Object.keys(this.props.userDataWI).length > 0)}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    <div>
                        {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
                    </div>
                </div>
                <div className="main">
                    <div className='ich-mochte stagger in'>Ich möchte…</div>
                    {/* <img alt="logo" src={HelpIcon} className='ques_mobile' /> */}
                    <div className='list'>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …meine Pension berechnen.
                        </div>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …Steuern sparen durch Einkauf.
                           </div>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …mein Arbeitspensum verändern.
                        </div>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …die Auswirkungen einer Lohnänderung berechnen.
                        </div>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …Wohneigentum berechnen.
                        </div>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …die Auswirkungen einer Auszahlung bei Ehescheidung berechnen.
                        </div>
                        <div
                            className='elem stagger in'
                            id='Retirement'
                            onClick={this.choose}
                        >
                            …etwas anderes.
                        </div>
                    </div>
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataAnliegen: (data) => dispatch(actions.setUserDataAnliegen(data)),
        setStepsOpened: (data) => dispatch(actions.setStepsOpened(data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wunschberechnung);
