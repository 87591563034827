import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import classNames from 'classnames';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';

import { formatNumber } from '../../../utils/react';
import '../../../containers/Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { DropdownCollapse } from '../../DropdownCollapse';
import { defined } from '../../../utils/variableEvaluation';
import { parseDate } from '../../../utils/react';
// import { ReactComponent as CheckmarkSlim } from "../../../assets/icons/checkmark-slim.svg";
import { ReactComponent as CheckmarkBold } from "../../../assets/icons/checkmark-bold.svg";
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    versicherungsausweis: {
        id: 'navigation.versicherungsausweis',
        defaultMessage: 'Versicherungsausweis'
    },
    sparguthaben: {
        id: 'navigation.sparguthaben',
        defaultMessage: 'Sparguthaben'
    },
    ausgestelltam: {
        id: 'navigation.ausgestelltam',
        defaultMessage: 'Ausgestellt am'
    },
    ahvjahreslohn: {
        id: 'navigation.ahvjahreslohn',
        defaultMessage: 'AHV-Jahreslohn'
    },
    beschaftigungsgrad: {
        id: 'navigation.beschaftigungsgrad',
        defaultMessage: 'Beschäftigungsgrad'
    },
    ahvrente: {
        id: 'navigation.ahvrente',
        defaultMessage: 'AHV-Rente'
    },
    chfPerMonth: {
        id: 'navigation.chfPerMonth',
        defaultMessage: 'CHF/Monat'
    },
    lohnklasse: {
        id: 'navigation.lohnklasse',
        defaultMessage: 'Lohnklasse'
    },
    plan: {
        id: 'navigation.plan',
        defaultMessage: 'Plan'
    },
})

const stepsRange = [5, 13];
interface State {
    readyToAnimate: boolean;
    visibleOnChart: boolean;
}

interface Props {
    // allowAnimation: boolean;
    back?: any;
    currentPage: string;
    currentStep: number;
    open?: boolean;
    onChart: boolean;
    start?: any;
    toggle: any;
    userDataWI: any;
    stepsOpened: any;
    intl: any;
    hasPayrollClasses: boolean;
    hasMultiplePlans: boolean;
    insurancePlans: any;
}


class Third extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            readyToAnimate: false,
            visibleOnChart: false
        }
    }

    componentDidMount() {
        this.setState({
            readyToAnimate: true
        });

        document.body.addEventListener('click', this.bodyClick)
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick)
    }


    bodyClick = (event) => {
        if (window.innerWidth < 896) {

            const menuElem = (document.getElementById("step3") as any);
            // console.log("body clicked, in second nav", event.target,
            // 'menuelem', menuElem,
            // 'contains:', menuElem.contains(event.target),
            //  (event.target as any).classList,
            // (event.target as any).parentElement.classList
            // );
            if (!(menuElem).contains(event.target) && this.props.open) {
                console.log('will toggle on mobile 3');
                document.body.classList.remove('blurred');
                this.props.open && this.props.toggle();
            }
        }
    }

    showHide = () => {
        if (window.innerWidth < 896) {
            this.props.toggle();
            if(!this.props.open) {
                document.body.classList.add('blurred');
            } else {
                document.body.classList.remove('blurred');
            }
        }
    }

    handleMouseEnter = () => {
        if (window.innerWidth > 896 && this.props.onChart) {
            this.setState({
                visibleOnChart: true
            })
        }
    }

    handleMouseLeave = () => {
        if (window.innerWidth > 896 && this.props.onChart) {
            this.props.open && this.props.toggle();
            this.setState({
                visibleOnChart: false
            })
        }
    }

    render() {
        const thirdStepCompleted = defined(this.props.userDataWI.AusgestelltAm) &&
            defined(this.props.userDataWI.Lohnsumme) &&
            defined(this.props.userDataWI.Sparguthaben) &&
            // defined(this.props.userDataWI.VPKonto) &&
            // defined(this.props.userDataWI.VPKontoAmount) &&
            defined(this.props.userDataWI.ahvPensionAmount);
        const secondStepCompleted = this.props.userDataWI.Geschlecht && this.props.userDataWI.Geburtsdatum;
        const insideStep = this.props.currentStep > stepsRange[0] && this.props.currentStep < stepsRange[1];
        return (
            <label
                id="step3"
                className={classNames('select', {
                    "visible": this.state.visibleOnChart,
                    "hidden": !this.state.visibleOnChart,
                })}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >
                <div onClick={this.showHide}
                    className={classNames('circle', {
                        'open': this.props.open,
                        'insideStep': insideStep,
                        'finished': thirdStepCompleted
                    })}
                >
                    {thirdStepCompleted ? (<CheckmarkBold />) : "2"}
                </div>

                <div className={classNames("dropdown_content forMobileOnly", {
                    "opened": this.props.open
                })}
                    id="logo3"
                >
                    <FormattedMessageCustom id={translatableStrings.versicherungsausweis.id} text={this.props.intl.formatMessage(translatableStrings.versicherungsausweis)}>
                        <div
                            id={this.props.userDataWI.calculationType ? `anliegen` : null}
                            style={this.props.open && insideStep ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem point', {
                                'completed': this.props.userDataWI.ahvPensionAmount,
                                'current': this.props.currentPage === 'ahvrente'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.ausgestelltam.id} text={this.props.intl.formatMessage(translatableStrings.ausgestelltam)}>
                        <div
                            id={secondStepCompleted ? `ausgestelltam` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.AusgestelltAm ? null : (this.props.currentPage === 'ausgestelltam') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.AusgestelltAm,
                                'current': this.props.currentPage === 'ausgestelltam'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.ahvjahreslohn.id} text={this.props.intl.formatMessage(translatableStrings.ahvjahreslohn)}>
                        <div
                            id={this.props.userDataWI.Lohnsumme ? `lohnsumme` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.Lohnsumme ? null : (this.props.currentPage === 'lohnsumme') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.Lohnsumme,
                                'current': this.props.currentPage === 'lohnsumme'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                    {this.props.hasPayrollClasses && <FormattedMessageCustom id={translatableStrings.lohnklasse.id} text={this.props.intl.formatMessage(translatableStrings.lohnklasse)}>
                        <div
                            id={this.props.userDataWI.payrollClass ? `lohnklasse` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.payrollClass ? null : (this.props.currentPage === 'lohnklasse') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.payrollClass,
                                'current': this.props.currentPage === 'lohnklasse'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>}
                    <FormattedMessageCustom id={translatableStrings.beschaftigungsgrad.id} text={this.props.intl.formatMessage(translatableStrings.beschaftigungsgrad)}>
                        <div
                            id={this.props.userDataWI.Beschaftigungsgrad ? `beschaftigungsgrad` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.Beschaftigungsgrad ? null : (this.props.currentPage === 'beschaftigungsgrad') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.Beschaftigungsgrad,
                                'current': this.props.currentPage === 'beschaftigungsgrad'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.sparguthaben.id} text={this.props.intl.formatMessage(translatableStrings.sparguthaben)}>
                        <div
                            id={this.props.userDataWI.Sparguthaben ? `sparguthaben` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.Sparguthaben ? null : (this.props.currentPage === 'sparguthaben') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.Sparguthaben,
                                'current': this.props.currentPage === 'sparguthaben'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>
                    {this.props.hasMultiplePlans && <FormattedMessageCustom id={translatableStrings.plan.id} text={this.props.intl.formatMessage(translatableStrings.plan)}>
                        <div
                            id={this.props.userDataWI.insurancePlan ? `plan` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.insurancePlan ? null : (this.props.currentPage === 'plan') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.insurancePlan,
                                'current': this.props.currentPage === 'plan'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>}
                    {/* <div
                        id={this.props.userDataWI.VPKonto ? `vpkonto` : null}
                        onClick={(e) => {
                            this.handleMouseLeave();
                            this.props.back(e);
                        }}
                        style={this.props.userDataWI.VPKonto ? null : (this.props.currentPage === 'vpkonto') ? { color: 'var(--main)' } : null}
                        className={classNames('dropdown_elem', {
                            'completed': this.props.userDataWI.VPKonto,
                            'current': this.props.currentPage === 'vpkonto'
                        })}
                    >
                        VP-Konto
                    </div> */}
                    <FormattedMessageCustom id={translatableStrings.ahvrente.id} text={this.props.intl.formatMessage(translatableStrings.ahvrente)}>
                        <div
                            id={this.props.userDataWI.ahvPensionAmount ? `ahvrente` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={this.props.userDataWI.ahvPensionAmount ? null : (this.props.currentPage === 'ahvrente') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem', {
                                'completed': this.props.userDataWI.ahvPensionAmount,
                                'current': this.props.currentPage === 'ahvrente'
                            })}
                        >
                        </div>
                    </FormattedMessageCustom>
                </div>
                <DropdownCollapse
                    className={classNames({
                        'open': this.props.open,
                    })}
                    isOpen={this.props.open}
                    toggle={this.props.toggle}
                    isComplete={thirdStepCompleted}
                    isActive={this.props.userDataWI.Wohnort}
                    // allowAnimation={this.props.allowAnimation}
                    insideStep={this.props.currentStep > stepsRange[0] && (this.props.currentStep < stepsRange[1])}
                    children={
                        [
                            <div
                                key="ausgestelltMobile"
                                id={(this.props.userDataWI.AusgestelltAm || this.props.userDataWI.AusgestelltAm === null) ? `ausgestelltam` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuTopElem menuElem', {
                                    'completed': this.props.userDataWI.AusgestelltAm,
                                    'current': this.props.currentPage === 'ausgestelltam' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.ausgestelltam.id} text={this.props.intl.formatMessage(translatableStrings.ausgestelltam)} /> {parseDate(this.props.userDataWI.AusgestelltAm, "secsToDDMMYYYY")}
                            </div>,
                            <br key="break31" />,
                            <div
                                key="lohnsummeMobile"
                                id={(this.props.userDataWI.Lohnsumme || this.props.userDataWI.Lohnsumme === null) ? `lohnsumme` : (null)}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem', {
                                    'completed': this.props.userDataWI.Lohnsumme,
                                    'current': this.props.currentPage === 'lohnsumme' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.ahvjahreslohn.id} text={this.props.intl.formatMessage(translatableStrings.ahvjahreslohn)} /> {this.props.userDataWI.Lohnsumme ? 'CHF ' + formatNumber(this.props.userDataWI.Lohnsumme, 0) : null}
                            </div>,
                            <br key="break32" />,
                            this.props.hasPayrollClasses && <div
                                key="lohnklasseMobile"
                                id={(this.props.userDataWI.payrollClass || this.props.userDataWI.payrollClass === null) ? `lohnklasse` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem', {
                                    'completed': this.props.userDataWI.payrollClass,
                                    'current': this.props.currentPage === 'lohnklasse' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.lohnklasse.id} text={this.props.intl.formatMessage(translatableStrings.lohnklasse)} /> {this.props.userDataWI.payrollClass ? Number(this.props.userDataWI.payrollClass) : null}
                            </div>,
                            this.props.hasPayrollClasses && <br key="breakc33" />,
                            <div
                                key="beschaftigungsgradMobile"
                                id={(this.props.userDataWI.Beschaftigungsgrad || this.props.userDataWI.Beschaftigungsgrad === null) ? `beschaftigungsgrad` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem', {
                                    'completed': this.props.userDataWI.Beschaftigungsgrad,
                                    'current': this.props.currentPage === 'beschaftigungsgrad' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.beschaftigungsgrad.id} text={this.props.intl.formatMessage(translatableStrings.beschaftigungsgrad)} /> {this.props.userDataWI.Beschaftigungsgrad ? Number(this.props.userDataWI.Beschaftigungsgrad) + '%' : null}
                            </div>,
                            <br key="breakc33" />,
                            <div
                                key="sparguthabenMobile"
                                id={(this.props.userDataWI.Sparguthaben || this.props.userDataWI.Sparguthaben === null) ? `sparguthaben` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem', {
                                    'completed': this.props.userDataWI.Sparguthaben,
                                    'current': this.props.currentPage === 'sparguthaben' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.sparguthaben.id} text={this.props.intl.formatMessage(translatableStrings.sparguthaben)} /> {this.props.userDataWI.Sparguthaben ? 'CHF ' + formatNumber(this.props.userDataWI.Sparguthaben, 0) : null}
                            </div>,
                            this.props.hasMultiplePlans && <br key="break32" />,
                            this.props.hasMultiplePlans && <div
                                key="planMobile"
                                id={(this.props.userDataWI.insurancePlan || this.props.userDataWI.insurancePlan === null) ? `plan` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuElem', {
                                    'completed': this.props.userDataWI.insurancePlan,
                                    'current': this.props.currentPage === 'plan' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.plan.id} text={this.props.intl.formatMessage(translatableStrings.plan)} /> {this.props.insurancePlans.find(plan => plan.planCode === this.props.userDataWI.insurancePlan)?.planName || this.props.userDataWI.insurancePlan}
                            </div>,

                            // <div
                            //     key="vpkontoMobile"
                            //     id={(this.props.userDataWI.VPKonto || this.props.userDataWI.VPKonto === null) ? `vpkonto` : null}
                            //     onClick={(e) => {
                            //         this.handleMouseLeave();
                            //         this.props.back(e);
                            //     }}
                            //     className={classNames('menuElem', {
                            //         'completed': this.props.userDataWI.VPKonto,
                            //         'current': this.props.currentPage === 'vpkonto' && this.state.readyToAnimate
                            //     })}
                            // >
                            //     VP-Konto {this.props.userDataWI.VPKonto} {this.props.userDataWI.VPKontoAmount ? (this.props.userDataWI.VPKontoAmount && ", CHF " + formatNumber(this.props.userDataWI.VPKontoAmount, 0)) : null}
                            // </div>,
                            <br key="break34" />,
                            <div
                                key="ahvrenteMobile"
                                id={(this.props.userDataWI.ahvPensionAmount || this.props.userDataWI.ahvPensionAmount === null) ? `ahvrente` : null}
                                onClick={(e) => {
                                    this.handleMouseLeave();
                                    this.props.back(e);
                                }}
                                className={classNames('menuLastElem menuElem', {
                                    'completed': this.props.userDataWI.ahvPensionAmount,
                                    'current': this.props.currentPage === 'ahvrente' && this.state.readyToAnimate
                                })}
                            >
                                <FormattedMessageCustom id={translatableStrings.ahvrente.id} text={this.props.intl.formatMessage(translatableStrings.ahvrente)} /> {this.props.userDataWI.ahvPensionAmount ? this.props.intl.formatMessage(translatableStrings.chfPerMonth) + " " + formatNumber(this.props.userDataWI.ahvPensionAmount, 0) : null}
                            </div>
                        ]
                    }
                    label='Versicherungsausweis' />
                <div className='dots' />
            </label>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Third);
