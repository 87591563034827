import * as React from 'react';
import {IInputConfig} from '../index';
import {DropdownOption} from './DropdownOption';
import {defined} from '../../../utils/variableEvaluation';
import {ReactComponent as IconArrow} from '../../../assets/icons/arrow-down.svg';
import {ReactComponent as IconClose} from '../../../assets/icons/x-dark.svg';
import styles from './DropdownInput.module.scss'; // Import css modules stylesheet as styles
import './DropdownInput.scss';

interface IProps {
    dropdownOptions?: IInputConfig[];
    name?: string;
    value?: string[] | string | number;
    isDisabled: boolean;
    clearable: boolean;
    onOpen: (isOpen : boolean) => void;
    onChange?: (name : string, value : string) => void;
}

interface IState {
    isOpen: boolean;
    currentlyActiveIndex: number;
    optionSelected: string;
}

export class DropdownInput extends React.Component<IProps, IState> {
    public constructor(props? : any, context? : any) {
        super(props, context);
        this.state = {
            isOpen: false,
            optionSelected: '',
            currentlyActiveIndex: -1
        };
        console.log("Dropwown constructor", this.state.currentlyActiveIndex);
    }

    private listRef = null;

    public componentDidMount() {
        window.addEventListener('click', this.handleClickWindow);
        // window.addEventListener('keydown', this.handleKeyPress);
    }

    public componentWillUnmount() {
        window.removeEventListener('click', this.handleClickWindow);
        // window.removeEventListener('keydown', this.handleKeyPress);
    }

    selectOption = (idx) => {
        this.setState({currentlyActiveIndex: idx});
        // this.listRef.childNodes[idx].classList.add('active');
    }

    private handleKeyPress = event => {
        const code = event.keyCode || event.charCode;
        // console.log("handleKeyPress", code, this.state.currentlyActiveIndex);
        if (code === 40) {
            // arrow down
            if (this.state.currentlyActiveIndex < this.props.dropdownOptions.length - 1) {
                if (this.state.currentlyActiveIndex < 0) {
                    this.selectOption(0);
                } else {
                    this.selectOption(this.state.currentlyActiveIndex + 1);
                }
            }
            if (!this.state.isOpen) {
                this.setState({isOpen: true});
            }
        } else if (code === 38 && (this.state.currentlyActiveIndex > 0)) {
            // arrow up
            this.selectOption(this.state.currentlyActiveIndex - 1);
        } else if (code === 27) {
            // escape
            this.setState({isOpen: false});
        } else if (code === 13) {
            // enter
            // console.log("currentlyActiveIndex", this.state.currentlyActiveIndex, this.props.dropdownOptions[this.state.currentlyActiveIndex]['dropdownLabel']);
            if (this.state.currentlyActiveIndex >= 0) {
                this.handleOptionClick(this.props.dropdownOptions[this.state.currentlyActiveIndex]['dropdownLabel'], this.state.currentlyActiveIndex);
            }
            this.setState({isOpen: !this.state.isOpen});
        } else if (code === 8) {
            // backspace
            this.handleOptionClick("", -1)
        }
    };

    private handleClickWindow = () => {
        this.setState({isOpen: false});
    };

    private handleWrapperClick = e => {
        console.log("handleWrapperClick", e);
        const isDisabled = defined(this.props.isDisabled) && this.props.isDisabled;
        if (!isDisabled) {
            // const nextIsOpen = !this.state.isOpen;
            this.setState({isOpen: true});
            this.props.onOpen && this.props.onOpen(true);
        }
        e.stopPropagation();
    };

    private handleOptionClick = (value : string, idx: number) => {
        console.log("handleOptionClick", value, this.props.name, idx);
        this.setState({optionSelected: value, currentlyActiveIndex: idx});
        this.props.onChange(this.props.name, value);
    };

    private handleFocus = (e) => {
        // console.log("handleFocus");
        this.setState({isOpen: true});
    };

    private handleBlur = (e) => {
        // console.log("handleBlur");
        setTimeout(() => {
            this.setState({isOpen: false});
        }, 150);
    };

    private renderChildren(child, i): JSX.Element {
        return React.cloneElement(child, {
            key: `InputText ${i}`,
            value: this.props.value,
            /* || this.state.optionSelected */
        });
    }

    handleArrowClick(e) {
        // console.log("arrow clicked");
        e.stopPropagation();
        this.setState({isOpen: !this.state.isOpen});
    }

    public render() {
        const {children, dropdownOptions} = this.props;

        const options = dropdownOptions.map((input : IInputConfig, idx) => (
            <DropdownOption
                key={input.id}
                id={input.id}
                dropdownLabel={input.dropdownLabel}
                selected={idx === this.state.currentlyActiveIndex}
                onOptionClick={(label) => this.handleOptionClick(label, idx)}/>
            ));
        return (
            <div
                className={`dropdown ${styles.inputSelectWrapper} ${this.state.isOpen ? styles.isOpen : ''}`}
                onClick={this.handleWrapperClick}
                onKeyDown={(e) => this.handleKeyPress(e)}
                onFocus={this.handleFocus}
                onBlur={this.handleBlur}
            >
                    {React.Children.map(children, (child, i) => this.renderChildren(child, i))}
                    {/*{this.props.value && <div className={styles.clearIcon} onClick={(e) => {*/}
                    {/*        e.stopPropagation();*/}
                    {/*        this.handleOptionClick("", -1)*/}
                    {/*    }}>*/}
                    {/*    <IconClose className="strokeGrey"/>*/}
                    {/*</div>}*/}

            <div className={styles.inputSelectArrow} onClick={(e) => this.handleArrowClick(e)}>
                <IconArrow/>
            </div>
            <div
                ref={div => {
                    this.listRef = div
                }}
                className={styles.inputSelectOptionsWrapper + " selectOptionsWrapper"}>{options}</div>
        </div>);
    }
}
