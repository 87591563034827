import { Link } from 'react-router-dom';
import { defined } from '../../utils/variableEvaluation';
import { renderSwitch } from '../../utils/react';
import * as React from 'react';
import classNames from 'classnames';
import './Button.scss';

export enum EButtonType {
  PillMedium = 'Pill Medium',
  InvertedPill = 'Inverted Pill',
  PillSmall = 'Pill Small',
  FullWidth = 'Full Width',
  FullWidthInverted = 'Full Width Inverted',
  BasicLink = 'Basic Link'
}

enum EButtonActionType {
  InternalLink = 'Internal Link',
  ExternalLink = 'External Link',
  NormalButton = 'Normal Button'
}


interface IProps {
  buttonType?: EButtonType;
  to?: string;
  href?: string;
  isLoading?: boolean;
  isDisabled?: boolean;
  submit?: boolean;
  className?: string;
  onClick?: any;
  title?: string;
}

export class Button extends React.Component<IProps> {
  private action(): string {
    if (defined(this.props.to)) {
      return EButtonActionType.InternalLink;
    } else if (defined(this.props.href)) {
      return EButtonActionType.ExternalLink;
    } else {
      return EButtonActionType.NormalButton;
    }
  }

  private renderButton() {
    const {
      isDisabled,
      isLoading,
      buttonType,
      to,
      href,
      children,
      className,
      onClick,
      submit,
      title,
      ...remainingProps
    } = this.props;

    const buttonClass = classNames('button', className, {
      "bPill medium": buttonType === EButtonType.PillMedium,
      "bPill small": buttonType === EButtonType.PillSmall,
      "bPill fullWidth": buttonType === EButtonType.FullWidth,
      "bPill fullWidthInverted": buttonType === EButtonType.FullWidthInverted,
      "invertedPill": buttonType === EButtonType.InvertedPill,
      "basicLink": buttonType === EButtonType.BasicLink
    });

    return renderSwitch(this.action(), {
      [EButtonActionType.InternalLink]: () => (
        <Link
          key={`Link-${to}`}
          to={(to as string)}
          className={buttonClass}
          role="button"
          {...remainingProps}
        >
          {children}
          {/*<span className={styles.mask} />*/}
        </Link>
      ),
      [EButtonActionType.ExternalLink]: () => (
        <a
          key={`a-${href}`}
          href={href}
          className={buttonClass}
          role="button"
          target="_blank"
          rel="noopener noreferrer"
          {...remainingProps}
        >
          {children}
          {/*<span className={styles.mask} />*/}
        </a>
      ),
      [EButtonActionType.NormalButton]: () => (
        <button key="button" title={title} className={buttonClass} disabled={isDisabled} onClick={onClick} type={submit ? "submit" : "button"}>
          {children}
          {/*<span className={styles.mask} />*/}
        </button>
      )
    });
  }

  public render() {
    return this.renderButton();
  }
}
