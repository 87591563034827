import {Postcode} from './postcode';
import React from 'react';
import Autosuggest from 'react-autosuggest';
import './style.scss'
import {Button, EButtonType} from "../../../components/Button";

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0 ? [] : Postcode.filter(lang =>
        lang.postleitzahl.toLowerCase().slice(0, inputLength) === inputValue || lang.postleitzahl.toLowerCase().slice(5, inputLength + 5) === inputValue
    );
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.postleitzahl;

class Example extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: (this.props && this.props.userDataWI) ? this.props.userDataWI : '',
            suggestions: [],
            hasButton: 0,
        };
    }

    isInputValid = (val) => {
        return Postcode.find((postcode) => postcode.postleitzahl === val) !== undefined;
    }

    onChange = (event, {newValue}) => {
        this.setState({
            hasButton: event.nativeEvent.which === 40 || event.nativeEvent.which === 38 ? 0 : !!event.nativeEvent.which,
            value: newValue
        });
    };


    onSuggestionsFetchRequested = ({value}) => {
        this.setState({
            suggestions: getSuggestions(value)
        });
    };

    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };


    onSuggestionSelected = (event, {suggestionValue, method}) => {
        console.log('suggestionValue',suggestionValue);
        this.setState({
            hasButton: suggestionValue === this.state.value || method === 'click' || method === 'enter',
        })
    };

    onKeyPress = (e) => {
        if(e.key === "Enter" && this.isInputValid(this.state.value)) {
            this.setState({
                hasButton: 1,
                focusBtn: true
            }, () => {
                document.querySelector('.react-autosuggest__input').blur();
                document.querySelector('.autosuggestButton').childNodes[0].focus();
            });
        }
    }

    // Use your imagination to render suggestions.
    renderSuggestion = (suggestion, queryObj) => {
        const regexString = queryObj.query;
        const regex = new RegExp(regexString,"gi");
        const match = regex.exec(suggestion.postleitzahl);
        if (match.length) {
            const prefix = suggestion.postleitzahl.substring(0, match.index) || null;
            const suffix = suggestion.postleitzahl.substring( match.index + match[0].length ) || null;
            return <span>
                {prefix}<span className="font-weight-bold">{match[0]}</span>{suffix}
            </span>
        } else {
            return suggestion.postleitzahl
        }
    };


    render() {
        const {value, suggestions} = this.state;

        const inputProps = {
            placeholder: 'PLZ oder Ortsnamen',
            value,
            onChange: this.onChange,
            onKeyPress: this.onKeyPress,
        };

        return (
            <div className='autosuggestContainer stagger'>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    focusInputOnSuggestionClick={window.innerWidth > 800}
                />
                {(this.state.hasButton || ((this.props.userDataWI === this.state.value) && this.props.userDataWI !== '')) ?
                    <div className='autosuggestButton'>
                        <Button
                            className="button"
                            submit={true}
                            onClick={() => this.props.onClick(this.state.value)}
                            buttonType={EButtonType.FullWidth}
                        >
                            <div>WEITER</div>
                        </Button>
                    </div>
                    : null
                }
            </div>
        );
    }
}

export default Example