import React from 'react';
import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
import { RootState } from '../../../../reducers/index';
import { Button, EButtonType } from "../../../../components/Button";
import './BlockCapital.scss';
import gsap from 'gsap';
import { ChartSettings } from '../chartSettings';
import { formatNumber } from "../../../../utils/react";
// import Tooltip from 'rc-tooltip';
import classNames from 'classnames';
import { ReactComponent as IconArrowDown } from '../../../../assets/icons/arrow-down.svg';
import { animationConfig } from '../../../../constants';
import { arraySum } from '../../../../utils/array';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    info: {
        id: 'pageChart.info',
        defaultMessage: 'Info'
    },
    bridgingPension: {
        id: 'pageChart.bridgingPension',
        defaultMessage: 'Überbrückungsrente'
    },
    calculationBasis: {
        id: 'pageChart.calculationBasis',
        defaultMessage: 'Berechnungsbasis'
    },
    sparguthaben: {
        id: 'pageChart.sparguthaben',
        defaultMessage: 'Sparguthaben'
    },
    capitalBuyIn: {
        id: 'pageChart.capitalBuyIn',
        defaultMessage: 'Kapitaleinkauf'
    },
    kapitalverwendung: {
        id: 'pageChart.kapitalverwendung',
        defaultMessage: 'Kapitalverwendung'
    },
    kapitalstruktur: {
        id: 'pageChart.kapitalstruktur',
        defaultMessage: 'Kapitalstruktur'
    }
});

interface State {
    structure: boolean;
    tooltipVisible: boolean;
}

interface Props {
    animateCounter: number;
    bridgingPensionAmount: number;
    calculateCounterTrigger: number;
    capitalWithdrawals: any;
    capitalWithdrawalsTotal: any;
    conversionRate: number;
    intl: any;
    openModal: any;
    overview?: boolean;
    partialPensionsNo: number;
    purchasedCapitals: any;
    sparguthaben: number;
    structure?: boolean;
    totalCapital: number;
    totalCapitalForAnnnuity: number;
    totalPossibleCapital: number;
    wrapInTooltip: any;
}

export class BlockCapital extends React.Component<Props, State> {
    private barCapitalLabel = null;
    private barCapitalAll = null;
    private barCapitalPerc = null;
    private barCapitalNote = null;
    // private barcapitalPurchase = null;
    private barCapitalWithdrawal = null;
    private labelTitle = null;
    private labelValue = null;
    private percTitle = null;
    private percValue = null;
    private percTitle2 = null;
    private percValue2 = null;
    private percTitle3 = null;
    private percValue3 = null;
    private percTitle4 = null;
    private percValue4 = null;
    private barBridging = null;
    private percTitle5 = null;
    private percValue5 = null;
    private barSparguthaben = null;
    private animateCounter = 0;
    private timeline = null;

    constructor(props: any) {
        super(props);
        console.log("BlockCapital constructor called");

        this.state = {
            structure: this.props.structure || false,
            tooltipVisible: false
        };
    }

    componentDidMount() {
        console.log('BlockCapital app did mount', this.props);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        setTimeout(() => {
            if (this.props.overview) {
                console.log('will run timeline for overview blockcapital');
                this.runTimeline();
            }
        }, 500);

        document.body.addEventListener('click', this.bodyClick);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick);
        console.log('BlockCapital will unmount', this.props);
    }

    bodyClick = (event) => {
        const barSwitch = (document.querySelector(".barSwitch") as any);
        const tooltipTrigger = (document.querySelector(".tooltipTrigger.arrowE") as any);
        console.log('bodyclick');
        if (!(barSwitch).contains(event.target) && !(tooltipTrigger).contains(event.target)) {
            console.log('clicked outside of barswitch');
            if (this.state.tooltipVisible) {
                this.setState({
                    tooltipVisible: false
                })
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Object.entries(this.props).forEach(([key, val]) =>
        //     prevProps[key] !== val && console.log(`Prop Chartcontainer'${key}' changed`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State Chartcontainer'${key}' changed`)
        //     );
        // }
        // Dont animate on dropdown expansion
        if (prevState.tooltipVisible === this.state.tooltipVisible) {
            if (this.props.animateCounter === 1 && this.props.calculateCounterTrigger === 0) {
                this.animateCounter += 1;
                this.runTimeline();
            } else if (this.animateCounter === 0 && this.props.animateCounter === 1 && this.props.calculateCounterTrigger === 1) {
                this.runTimeline();
            }
        }

        console.log(
            'didupdate blockcapital', this.props.animateCounter,
            'calculateCounterTrigger', this.props.calculateCounterTrigger
            // 'earlyPensionShowed', this.props.isEarlyPensionPresent
        );

        // if (/*this.props.isEarlyPensionPresent && */this.props.animateCounter > 1 && this.props.partialPensionsNo === 0) {
        //     console.log('will dispatch blockCapitalAnimatedWhenNoPartialPensions in blockcapital in didupdate', this.props.animateCounter,
        //         'isEarlyPension: ', this.props.isEarlyPensionPresent);
        //     setTimeout(() => {
        //         (window as any).dispatchEvent((window as any).blockCapitalAnimatedWhenNoPartialPensions);
        //     }, 300)
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const animateCounterIncreased = nextProps.animateCounter > this.props.animateCounter;
        const calculateCounterTriggerIncreased = nextProps.calculateCounterTrigger > this.props.calculateCounterTrigger;
        const capitalWithdrawalsTotalChanged = nextProps.capitalWithdrawalsTotal !== this.props.capitalWithdrawalsTotal;
        const totalCapitalChanged = nextProps.totalCapital !== this.props.totalCapital;
        const tooltipVisibleChanged = nextState.tooltipVisible !== this.state.tooltipVisible;
        const bridgingPensionChanged = nextProps.bridgingPensionAmount !== this.props.bridgingPensionAmount;
        // const purchasedCapitalsChanged = arraySum(nextProps.purchasedCapitals) !== arraySum(this.props.purchasedCapitals);
        // const earlyPensionShowed = nextProps.isEarlyPensionPresent !== this.props.isEarlyPensionPresent;
        console.log('shouldComponentUpdate blockcapital',
            animateCounterIncreased, nextProps.animateCounter,
            // 'calculateCounterTriggerIncreased', calculateCounterTriggerIncreased,
            this.props,
            'nextProps', nextProps
            // 'earlyPensionShowed', earlyPensionShowed
        );
        if (totalCapitalChanged || animateCounterIncreased || tooltipVisibleChanged || capitalWithdrawalsTotalChanged || calculateCounterTriggerIncreased || bridgingPensionChanged
            //  || purchasedCapitalsChanged
             ) {
            if (nextProps.animateCounter > 1) {
                // console.log('will add forcebvisible', document.querySelector('.addTeilContainer .addTeilButton2') );
                (document.querySelector('.addTeilContainer .addTeilButton2') as any).classList.add("forceVisible");
            };
            return true;
        } else {
            return false
        }
    }

    runTimeline = () => {
        console.log('runTimeline barcapital', this.props.animateCounter);
        const startSec = animationConfig().startSec;
        this.timeline.pause();

        // Hide initially and show before anything starts
        this.timeline.fromTo(document.querySelectorAll('.blockCapital')[this.props.overview ? 1 : 0],
            { autoAlpha: 0, ease: animationConfig().easing },
            { autoAlpha: 1 }, startSec);

        if (this.props.overview) {
            this.timeline.fromTo([
                // this.labelTitle,
                // this.labelValue,
                document.querySelectorAll('.overviewTitles'),
            ],
                { autoAlpha: 0 },
                { delay: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 0.3);
        } else {
            // Kapital label
            this.timeline.from(this.barCapitalLabel,
                { y: -50, autoAlpha: 0, ease: animationConfig().easing, stagger: 0.1 }, startSec);
            // White pillar
            this.timeline.from(this.barCapitalAll,
                { maxHeight: 0, ease: animationConfig().easing }, startSec + 0.3);
            // Gray pillar, shows after white
            this.timeline.from(this.barCapitalPerc,
                { maxHeight: 0, ease: animationConfig().easing }, startSec + 0.6);
                document.querySelectorAll('.barcapitalPurchase').length && this.timeline.from(document.querySelectorAll('.barcapitalPurchase'),
                { maxHeight: 0, ease: animationConfig().easing }, startSec + 0.6);

            document.querySelectorAll('.barCapitalWithdrawals').length && this.timeline.from(document.querySelectorAll('.barCapitalWithdrawals'),
                { maxHeight: 0, ease: animationConfig().easing }, startSec + 0.9);

            document.querySelectorAll('.barcapitalPurchase').length && this.timeline.from(document.querySelectorAll('.barcapitalPurchase'),
                { maxHeight: 0, ease: animationConfig().easing }, startSec + 0.9);


            // this.barCapitalWithdrawal && this.timeline.from(this.barCapitalWithdrawal,
            //     { maxHeight: 0, ease: animationConfig().easing }, startSec + 0.9);

            // If there is enough place, labels inside gray pillar will be animated
            this.timeline.fromTo([this.percTitle, this.percValue, this.barCapitalNote],
                { y: -30, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 0.9);

            // Kapitaleinkauf
            document.querySelectorAll('.percTitle2s').length && this.timeline.fromTo(document.querySelectorAll('.percTitle2s'),
                { y: -30, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 1.2);

            // this.percTitle2 && this.timeline.fromTo(this.percTitle2,
            // { y: -30 },
            // { y: 0, ease: animationConfig().easing }, startSec + 1.2);
            document.querySelectorAll('.percValue2s').length && this.timeline.fromTo(document.querySelectorAll('.percValue2s'),
                { y: -50, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 1.2);
            // this.percValue2 && this.timeline.fromTo(this.percValue2,
            //     { y: -30 },
            //     { y: 0, ease: animationConfig().easing }, startSec + 1.2);

            // Kapitalbezug
            document.querySelectorAll('.percTitle3s').length && this.timeline.fromTo(document.querySelectorAll('.percTitle3s'),
                { y: -50, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 1);
            // this.percTitle3 && this.timeline.fromTo(this.percTitle3,
            // { y: -50 },
            // { y: 0, ease: animationConfig().easing }, startSec + 1);
            document.querySelectorAll('.percValue3s').length && this.timeline.fromTo(document.querySelectorAll('.percValue3s'),
                { y: -50, autoAlpha: 0 },
                { y: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 1);
            // this.percValue3 && this.timeline.fromTo(this.percValue3,
            //     { y: -50 },
            //     { y: 0, ease: animationConfig().easing }, startSec + 1);
            this.timeline.fromTo([this.percTitle, this.percValue, this.percTitle2, this.percValue2, this.barCapitalNote],
                { autoAlpha: 0 },
                { delay: 0, autoAlpha: 1, ease: animationConfig().easing }, startSec + 0.9);


        }



        this.timeline.timeScale(animationConfig().scale || 0.75)
            .restart()
            .play();

        this.timeline.eventCallback("onStart", () => {
            console.log('oncomplete blockcapital animateCounter', this.props.animateCounter, this.props.partialPensionsNo);
            // if (this.props.partialPensionsNo === 0) {
            //     console.log('will dispatch blockCapitalAnimatedWhenNoPartialPensions in blockcapital oncomplete');
            //     setTimeout(() => {
            //         (window as any).dispatchEvent((window as any).blockCapitalAnimatedWhenNoPartialPensions);
            //     }, animationConfig().scale * 700);
            // }
        });
    }

    render() {
        const maxValue = Math.max(this.props.totalPossibleCapital, this.props.totalCapital);
        const purchasedCapitals = arraySum(this.props.purchasedCapitals);
        const capitalWithdrawals = arraySum(this.props.capitalWithdrawals);

        const availableHeight = window.innerWidth > 896 ? (document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - ChartSettings.chartTopPadding : 0) : 600;
        const realValueToPxRatio = availableHeight / maxValue;

        // const maxHeightVal = this.props.overview ? 600 : ChartSettings.maxBlockHeight * 6;


        // const capitalPurchaseToMaxPerc = (purchasedCapitals / maxValue);
        // const capitalPurchasePerc = (purchasedCapitals / (capitalWithdrawals + this.props.totalCapitalForAnnnuity) * 100);
        // const capitalWithdrawalToMaxPerc = (capitalWithdrawals / maxValue);
        // const capitalWithdrawalPerc = (capitalWithdrawals / (purchasedCapitals + this.props.totalCapitalForAnnnuity) * 100);

        console.log('conversionRate: ', this.props, this.props.conversionRate,
            'arraySum', purchasedCapitals,
            'availableHeight / maxValue', availableHeight / maxValue,
            'availableHeight', availableHeight,
            'maxValue', maxValue,
            'realValueToPxRatio', realValueToPxRatio,
            'purchasedCapitals', this.props.purchasedCapitals,
            // 'capitalPurchasePerc', capitalPurchasePerc,
            'availableHeight', availableHeight,
            'capitalWithdrawals', capitalWithdrawals,
            // 'totalCapitalForAnnnuity', this.props.totalCapitalForAnnnuity,
            'berechnung umwa', availableHeight * (this.props.totalCapitalForAnnnuity / maxValue),
            'capitalWithdrawals sum', capitalWithdrawals,
            'capitalWithdrawals', this.props.capitalWithdrawals,
            'iphone debug', this.props.totalPossibleCapital, this.props.totalCapitalForAnnnuity, purchasedCapitals, capitalWithdrawals,
            // 'capitalWithdrawalPerc', capitalWithdrawalPerc
        );

        const buttons = [
            <button key="b1" className="switchBtn nonButton verwendung" onClick={() => { this.setState({ structure: false, tooltipVisible: false }) }}>Kapitalverwendung</button>,
            <button key="b2" className="switchBtn nonButton structure" onClick={() => { this.setState({ structure: true, tooltipVisible: false }) }}>Kapitalstruktur</button>
        ];

        return (
            <div className="blockCapital blockFullHeight" style={{
                width: ChartSettings.minWidths.capital,
                opacity: 0
            }}>
                <div ref={c => this.barCapitalLabel = c} id="barCapitalLabel" className="blockLabel" >
                    <p className="blockTitle overviewTitles" ref={c => this.labelTitle = c}
                        title={'max: ' + (this.props.totalPossibleCapital || 1).toString()} >
                        {this.state.structure ? <FormattedMessageCustom id={translatableStrings.kapitalstruktur.id} text={this.props.intl.formatMessage(translatableStrings.kapitalstruktur)} key="berenchnungBasis1" /> :
                            <FormattedMessageCustom id={translatableStrings.kapitalverwendung.id} text={this.props.intl.formatMessage(translatableStrings.kapitalverwendung)} key="berenchnungBasis1" />
                        }</p>
                    <p className="blockValue overviewTitles" ref={c => this.labelValue = c}>CHF {formatNumber(this.props.totalCapital)}</p>
                    {/* <p className="blockValue" ref={c => this.labelValue = c}>CHF {formatNumber(this.props.totalPossibleCapital)}</p> */}
                    <div className={classNames("arrowE tooltipTrigger", {
                        "rotated": this.state.tooltipVisible,
                    })}
                        onClick={() => { this.setState({ tooltipVisible: !this.state.tooltipVisible }) }}
                    >
                        <IconArrowDown />
                    </div>
                    <div className={classNames("barSwitch", {
                        "structure": this.state.structure
                    })}>
                        {this.state.structure ? buttons.reverse() : buttons}
                    </div>
                    {!this.props.overview && <Button
                        className="button openCapitalButton"
                        onClick={() => this.props.openModal("capital")}
                        buttonType={EButtonType.BasicLink}
                    >
                        <FormattedMessageCustom id={translatableStrings.info.id} text={this.props.intl.formatMessage(translatableStrings.info)} />
                    </Button>}
                </div>

                <div ref={c => this.barCapitalAll = c} id="barCapitalAll" className="blockStyleRounded blockElasticHeight"
                    style={{
                        height: this.state.structure ? (maxValue - Math.max(this.props.totalCapitalForAnnnuity, this.props.sparguthaben) - purchasedCapitals) : (maxValue - this.props.totalCapitalForAnnnuity - capitalWithdrawals),
                        // maxHeight: this.state.structure ? (maxValue - this.props.sparguthaben - purchasedCapitals) * realValueToPxRatio : (maxValue - this.props.totalCapitalForAnnnuity - capitalWithdrawals) * realValueToPxRatio,
                        maxHeight: maxValue * realValueToPxRatio
                    }}>
                </div>

                {this.state.structure === false && this.props.capitalWithdrawals.map((withdrawal, idx) => {
                    console.log('withdrawal', withdrawal,
                        "availableHeight", availableHeight,
                        "maxValue", maxValue,
                        availableHeight * withdrawal / maxValue

                    );
                    const enoughHeightFor1Row = withdrawal * realValueToPxRatio >= 30;
                    return withdrawal > 0 && <div
                        ref={c => this.barCapitalWithdrawal = c}
                        id="barCapitalWithdrawal"
                        key={`capitalWithdrawalPillar${idx}`}
                        className={classNames("blockElasticHeight barCapitalWithdrawals",
                            {
                                "blockStyleRounded": idx === 0,
                                "loaded": this.props.calculateCounterTrigger > 0 || this.props.animateCounter > 1,
                                "enoughHeightFor1Row": enoughHeightFor1Row,
                                "inTooltip": idx === 0 ? availableHeight * withdrawal / maxValue < 95 : availableHeight * withdrawal / maxValue < 35
                            })} style={{
                                height: withdrawal,
                                maxHeight: withdrawal * realValueToPxRatio,
                                // maxHeight: maxHeightVal * withdrawal / maxValue,
                                // borderBottom: '1px solid #AFB1B3',
                                paddingTop: idx > 0 ? 8 : 0
                            }}
                    >
                        {this.props.wrapInTooltip(
                            <div
                                className={classNames("forTooltipOnly", {
                                })} style={{
                                    height: "100%",
                                    maxHeight: "100%",
                                    paddingTop: idx > 0 ? 8 : 0
                                }}
                            >
                                {idx === 0 && <p className="blockTitle percTitle3s overviewTitles" ref={c => this.percTitle3 = c}>Kapitalbezug</p>}
                                <p className="blockValue percValue3s overviewTitles" ref={c => this.percValue3 = c}>CHF {formatNumber(withdrawal)}</p>
                            </div>,
                            [
                                <p key="kapitalWithdrawal1" className="blockTitle percTitle3s" ref={c => this.percTitle3 = c}>Kapitalbezug</p>,
                                !enoughHeightFor1Row && <p key="kapitalWithdrawal2" className="blockValue percValue3" ref={c => this.percValue3 = c}>CHF {formatNumber(withdrawal)}</p>
                            ],
                            idx === 0 ? availableHeight * withdrawal / maxValue < 95 : availableHeight * withdrawal / maxValue < 35
                        )}
                    </div>
                })}

                {this.state.structure === false && this.props.bridgingPensionAmount > 0 && this.props.wrapInTooltip(<div
                    ref={c => this.barBridging = c}
                    id="barBridging"
                    className={classNames(
                        "blockElasticHeight", {
                        "blockStyleRounded": capitalWithdrawals === 0,
                        "enoughHeightFor1Row": this.props.bridgingPensionAmount * realValueToPxRatio >= 30,
                        "loaded": this.props.calculateCounterTrigger > 0 || this.props.animateCounter > 1,
                        "inTooltip": availableHeight * (this.props.bridgingPensionAmount / maxValue) < 55
                    })}
                    style={{
                        height: this.props.bridgingPensionAmount,
                        maxHeight: this.props.bridgingPensionAmount * realValueToPxRatio,
                        // maxHeight: maxHeightVal * (this.props.bridgingPensionAmount / (maxValue || 1)),
                    }}
                >
                    <FormattedMessageCustom id={translatableStrings.bridgingPension.id} text={this.props.intl.formatMessage(translatableStrings.bridgingPension)} >
                        <p className="blockTitle overviewTitles bridgingPensionTitle" ref={c => this.percTitle4 = c}></p>
                    </FormattedMessageCustom>
                    <p className="blockValue overviewTitles" ref={c => this.percValue4 = c}>CHF {formatNumber(this.props.bridgingPensionAmount)}</p>
                </div>,
                    [
                        <FormattedMessageCustom id={translatableStrings.bridgingPension.id} text={this.props.intl.formatMessage(translatableStrings.bridgingPension)} key="barBridging1">
                            <p className="blockTitle" ref={c => this.percTitle4 = c}></p>
                        </FormattedMessageCustom>,
                        <p key="barBridging2" className="blockValue" ref={c => this.percValue4 = c}>CHF {formatNumber(this.props.bridgingPensionAmount)}</p>,
                    ],
                    availableHeight * (this.props.bridgingPensionAmount / maxValue) < 55
                )}
                {console.log(
                    'height: ', this.props.totalCapitalForAnnnuity,
                    'maxHeight:', this.props.totalCapitalForAnnnuity * realValueToPxRatio
                )}
                {this.state.structure === false && <div
                    ref={c => this.barCapitalPerc = c}
                    id="barCapitalPerc"
                    key="barCapitalPercT"
                    className={classNames(
                        "blockElasticHeight", {
                        "blockStyleRounded": capitalWithdrawals === 0 && !this.props.bridgingPensionAmount,
                        "loaded": this.props.calculateCounterTrigger > 0 || this.props.animateCounter > 1,
                        "enoughHeightFor1Row": this.props.totalCapitalForAnnnuity * realValueToPxRatio >= 30,
                        "inTooltip": this.props.partialPensionsNo === 0 ? availableHeight * (this.props.totalCapitalForAnnnuity / maxValue) < 100 : availableHeight * (this.props.totalCapitalForAnnnuity / maxValue) < 55
                    })}
                    style={{
                        height: this.props.totalCapitalForAnnnuity,
                        maxHeight: this.props.totalCapitalForAnnnuity * realValueToPxRatio,
                    }}
                >
                    {this.props.wrapInTooltip(<>
                        <FormattedMessageCustom id={translatableStrings.calculationBasis.id} text={this.props.intl.formatMessage(translatableStrings.calculationBasis)} >
                            <p className="blockTitle overviewTitles" ref={c => this.percTitle = c}></p>
                        </FormattedMessageCustom>
                        <p className="blockValue overviewTitles chfValue" ref={c => this.percValue = c}>CHF {formatNumber(this.props.totalCapitalForAnnnuity)}</p>
                        <p ref={c => this.barCapitalNote = c} id="barCapitalNote" className="blockValue overviewTitles" >
                            {this.props.partialPensionsNo === 0 && (`Umwandlungssatz ${(Math.round((this.props.conversionRate || 0) * 1000) / 1000).toFixed(3)}%`)}
                        </p>
                    </>, [
                        <FormattedMessageCustom id={translatableStrings.calculationBasis.id} text={this.props.intl.formatMessage(translatableStrings.calculationBasis)} key="berenchnungBasis1" >
                            <p className="blockTitle" ref={c => this.percTitle = c}></p>
                        </FormattedMessageCustom>
                        ,
                        <p key="berenchnungBasis2" className="blockValue" ref={c => this.percValue = c}>CHF {formatNumber(this.props.totalCapitalForAnnnuity)}</p>,
                        <p key="berenchnungBasis3" ref={c => this.barCapitalNote = c} id="barCapitalNote" className="blockValue  " >
                            {this.props.partialPensionsNo === 0 && (`Umwandlungssatz ${(Math.round((this.props.conversionRate || 0) * 1000) / 1000).toFixed(3)}%`)}
                        </p>
                    ],
                        this.props.partialPensionsNo === 0 ? availableHeight * (this.props.totalCapitalForAnnnuity / maxValue) < 100 : availableHeight * (this.props.totalCapitalForAnnnuity / maxValue) < 55
                    )}
                </div>
                }

                {/* Kapitalstruktur  */}
                {this.state.structure === true && this.props.wrapInTooltip(<div
                    ref={c => this.barSparguthaben = c}
                    id="barSparguthaben"
                    className={classNames(
                        "blockElasticHeight", {
                        "enoughHeightFor1Row": this.props.sparguthaben * realValueToPxRatio >= 30,
                        "blockStyleRounded": purchasedCapitals === 0 || true,
                        "loaded": this.props.calculateCounterTrigger > 0 || this.props.animateCounter > 1,
                        "inTooltip": availableHeight * (this.props.sparguthaben / maxValue) < 55
                    })}
                    style={{
                        height: this.props.sparguthaben,
                        maxHeight: this.props.sparguthaben * realValueToPxRatio
                        // maxHeight: maxHeightVal * (this.props.sparguthaben / (maxValue || 1)),
                    }}
                >
                    <FormattedMessageCustom id={translatableStrings.sparguthaben.id} text={this.props.intl.formatMessage(translatableStrings.sparguthaben)} >
                        <p className="blockTitle overviewTitles" ref={c => this.percTitle5 = c}></p>
                    </FormattedMessageCustom>
                    <p className="blockValue overviewTitles" ref={c => this.percValue5 = c}>CHF {formatNumber(this.props.sparguthaben)}</p>
                    <p ref={c => this.barCapitalNote = c} id="barCapitalNote" className="blockValue overviewTitles" >
                        {this.props.partialPensionsNo === 0 && (`Umwandlungssatz ${(Math.round((this.props.conversionRate || 0) * 1000) / 1000).toFixed(3)}%`)}
                    </p>
                </div>,
                    [
                        <FormattedMessageCustom id={translatableStrings.sparguthaben.id} text={this.props.intl.formatMessage(translatableStrings.sparguthaben)} key="barSparguthaben1" >
                            <p className="blockTitle" ref={c => this.percTitle5 = c}></p>
                        </FormattedMessageCustom>
                        ,
                        <p key="barSparguthaben2" className="blockValue" ref={c => this.percValue5 = c}>CHF {formatNumber(this.props.sparguthaben)}</p>,
                    ],
                    availableHeight * (this.props.sparguthaben / maxValue) < 55
                )}

                {this.state.structure === true && this.props.purchasedCapitals.map((purchasedCapital, idx) => {
                    console.log('purchasedCapital',purchasedCapital, idx);
                    return purchasedCapital > 0 && this.props.wrapInTooltip(
                        <div
                            // ref={c => this.barcapitalPurchase = c}
                            // id="barcapitalPurchase"
                            className={classNames("blockElasticHeight barcapitalPurchase", {
                                "loaded": this.props.calculateCounterTrigger > 0 || this.props.animateCounter > 1,
                                "enoughHeightFor1Row": purchasedCapitals * realValueToPxRatio >= 30,
                                "inTooltip": availableHeight * purchasedCapital / maxValue < 50 || (this.props.purchasedCapitals.length > 1 && idx === 0)
                            })} style={{
                                height: purchasedCapital,
                                maxHeight: purchasedCapitals * realValueToPxRatio,
                                // maxHeight: maxHeightVal * purchasedCapital / maxValue,
                                // borderBottom: '1px solid #AFB1B3',
                                paddingTop: (idx > 0 && (availableHeight * (purchasedCapital / maxValue) > 50)) ? 8 : 0
                            }}
                        >
                            {idx === 0 && <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)} >
                                <p className="blockTitle percTitle2s overviewTitles" ref={c => this.percTitle2 = c}></p>
                            </FormattedMessageCustom>
                            }
                            <p className="blockValue percValue2s overviewTitles" ref={c => this.percValue2 = c}>CHF {formatNumber(purchasedCapital)}</p>
                        </div>,
                        [
                            <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)} key={`kapitalEinkauf${idx}1`}>
                                <p className="blockTitle percTitle2s" ref={c => this.percTitle2 = c}></p>
                            </FormattedMessageCustom>,
                            <p key={`kapitalEinkauf${idx}2`} className="blockValue percValue2s" ref={c => this.percValue2 = c}>CHF {formatNumber(purchasedCapital)}</p>
                        ],
                        availableHeight * purchasedCapital / maxValue < 50 || (this.props.purchasedCapitals.length > 1 && idx === 0)
                    )
                })}

            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockCapital);
