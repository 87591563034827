import React from 'react';
// import {connect} from 'react-redux';
// import {RootState} from '../../../reducers/index';
// import {Dispatch} from 'redux';
// import * as actions from '../../../actions/';
import './Year.scss';
import { parseDate } from '../../../utils/react';


interface State {
    yearArr: Array<number>,
    minYear?: any,
    maxYear?: any,
}

interface Props {
    numberOfDaysPerMonth?: number,
    second?: number,
    onClick: any,
    dateRange: any;
    reversed?: boolean;
    selectedDate: any;
}

export class Year extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        console.log('year constructor mount');
        // Here should be the date in the format ["JJJJ-MM-TTT12:00:00.000Z", "JJJJ-MM-TTT12:00:00.000Z"]
        const date = this.props.dateRange || [];
        if (date.length) {
            this.state = {
                yearArr: [],
                minYear: parseInt(date[0]),
                maxYear: parseInt(date[1]),
            }
        } else {
            this.state = {
                yearArr: [],
            }
        }
    }

    UNSAFE_componentWillMount() {
        console.log('year will mount, selectedDate: ', this.props.selectedDate);
        let yearArr = [];
        const today = new Date();
        const todayYear = this.state.maxYear ? this.state.maxYear : this.props.second ? this.props.second : today.getFullYear();
        const count = this.state.minYear ? this.state.minYear : this.props.second ? 2020 : 1950;
        for (let i = count; i <= todayYear; i++) {
            let year: number = i;
            if (this.props.dateRange && this.props.dateRange.length > 0) {
                // console.log('determining years', year, parseDate(this.props.dateRange[0], "toSecs"), parseDate(this.props.dateRange[1], "toSecs"),
                // `${year}-${this.props.selectedDate.MM}-${this.props.selectedDate.TT}`,
                // parseDate(`${year}-${this.props.selectedDate.MM}-${this.props.selectedDate.TT}`, "toSecs")
                //     )
                // if (window.location.hash.includes('ausgestelltam')) {
                //     yearArr.push(year);
                // } else 
                if (parseDate(this.props.dateRange[0], "toSecs") <= parseDate(`${year}-${this.props.selectedDate.MM}-${this.props.selectedDate.TT}`, "toSecs")) {
                    if (parseDate(this.props.dateRange[1], "toSecs") >= parseDate(`${year}-${this.props.selectedDate.MM}-${this.props.selectedDate.TT}`, "toSecs")) {
                        console.log('true push', year);
                        yearArr.push(year);
                    }
                } 
            } else {
                // console.log('else push', year);
                yearArr.push(year);
            }
        }
        this.setState({
            yearArr: this.props.reversed ? yearArr.reverse() : yearArr
        })
    }

    render() {
        const count = this.state.minYear ? this.state.minYear : this.props.second ? 2020 : 1950;
        return (
            <div className="years">
                {this.state.yearArr.map(year => (
                    <React.Fragment key={year}>
                        {year % 10 === 0 && year !== count ?
                            <>
                                <div className='line' />
                                <div className="year"
                                    id={year.toString()}
                                    onClick={(e) => {
                                        this.props.onClick(e)
                                    }}
                                    key={year.toString()}
                                >
                                    {year}
                                </div>

                            </>
                            :
                            <div className="year"
                                id={year.toString()}
                                onClick={(e) => {
                                    this.props.onClick(e)
                                }}
                                key={year.toString()}
                            >
                                {year}
                            </div>
                        }
                    </React.Fragment>
                ))
                }

            </div>
        );
    }
}