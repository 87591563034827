// import style from './Logo.module.scss'; // Import css modules stylesheet as styles
import React from 'react';
import PKlogo from '../../assets/imgs/pkar_logo.png';
import logo from '../../assets/icons/logo.png';

export interface Props {
    tenantName: string;
}

const Logo = (props: Props) => {
    return <>
        { props.tenantName === "PKAR" && <img alt="logo" src={PKlogo} className='logo pkar' /> }
        { props.tenantName === "BVG" && <img alt="logo" src={logo} className='logo' /> }
    </>
};

export default Logo;
