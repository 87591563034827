import * as React from 'react';
import { IInputConfig } from '../index';
import { defined } from '../../../utils/variableEvaluation';
import { InputText } from '../Text/index';
import { InputSelect } from '../Select/input';
import { InputCheckbox } from '../Checkbox/index';
import { renderSwitch } from '../../../utils/react';
import { InputRadio } from '../Radio/index';
import { EInputTextType } from '../index';


export enum EInputType {
    Text = 'Text',
    Checkbox = 'Checkbox',
    Radio = 'Radio',
    Select = 'Select',
    Custom = 'Custom'
}

interface IProps {
    inputConfig: IInputConfig;
}

export class InputContent extends React.Component<IProps, {}> {
    private inputType(): EInputType {
        const {
            type,
            selectOptions,
            radioOptions,
            customComponent
        } = this.props.inputConfig;
        if (type === 'checkbox') {
            return EInputType.Checkbox;
        } else if (defined(selectOptions)) {
            return EInputType.Select;
        } else if (defined(radioOptions)) {
            return EInputType.Radio;
        } else if (defined(customComponent)) {
            return EInputType.Custom;
        } else {
            return EInputType.Text;
        }
    }

    public render(): JSX.Element {
        const {
            checkboxLabel,
            checked,
            customComponent,
            disabled,
            inputTextType,
            isFocused,
            isLoading,
            max,
            // maxLength,
            // min,
            onKeyDown,
            name,
            onBlur,
            onChange,
            onDropdownOpen,
            placeholder,
            radioOptions,
            readOnly,
            selectOptions,
            type,
            value,
            step,
        } = this.props.inputConfig;

        return renderSwitch(this.inputType(), {
            [EInputType.Custom]: () => customComponent!,
            [EInputType.Checkbox]: () => (
                <InputCheckbox
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name}
                    value={value}
                    checked={checked}
                    label={checkboxLabel}
                    type={type!}
                    isDisabled={disabled}
                    onChange={onChange!}
                />
            ),
            [EInputType.Radio]: () => <InputRadio
                radioOptions={radioOptions}
            />,
            [EInputType.Select]: () => (
                <InputSelect
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name!}
                    value={value}
                    placeholder={placeholder}
                    type={type!}
                    isDisabled={disabled}
                    onChange={onChange}
                    onDropdownOpen={onDropdownOpen!}
                    selectOptions={selectOptions}
                    inputTextType={inputTextType || EInputTextType.Material}
                />
            ),
            [EInputType.Text]: () => (
                <InputText
                    inputTextType={inputTextType || EInputTextType.Material}
                    isDisabled={disabled}
                    isFocused={isFocused}
                    isLoading={isLoading}
                    name={name}
                    max={max}
                    onBlur={onBlur}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    placeholder={placeholder}
                    readOnly={readOnly}
                    type={type}
                    step={step}
                    value={value}
                />
            )
        });
    }
}
