import * as React from 'react';
// import classNames from 'classnames';
// import style from './Handle.module.scss'; // Import css modules stylesheet as styles

export interface Props {
    year: number;
}

const Handle = (props: Props) => (
    // <div className={classNames(style.handle)}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="69"
        height="82"
        viewBox="0 0 69 82"
    >
        <defs>
            <path
                id="a"
                d="M26.431.693C19.374 8.7 8.018 16.094 3.107 25.646-6.405 44.13 7.097 66 27.657 66c19.949 0 33.45-21.564 24.55-39.739C47.607 16.713 35.638 8.701 28.58.693c-.613-.924-1.534-.924-2.147 0z"
            ></path>
            <filter
                id="b"
                width="158.2%"
                height="148.5%"
                x="-29.1%"
                y="-12.1%"
                filterUnits="objectBoundingBox"
            >
                <feMorphology
                    in="SourceAlpha"
                    radius="2.5"
                    result="shadowSpreadOuter1"
                ></feMorphology>
                <feOffset
                    dy="8"
                    in="shadowSpreadOuter1"
                    result="shadowOffsetOuter1"
                ></feOffset>
                <feGaussianBlur
                    in="shadowOffsetOuter1"
                    result="shadowBlurOuter1"
                    stdDeviation="6.5"
                ></feGaussianBlur>
                <feColorMatrix
                    in="shadowBlurOuter1"
                    values="0 0 0 0 0.149019608 0 0 0 0 0.196078431 0 0 0 0 0.219607843 0 0 0 0.2 0"
                ></feColorMatrix>
            </filter>
        </defs>
        <g
            fill="none"
            fillRule="evenodd"
            stroke="none"
            strokeWidth="1"
            transform="translate(-868 -562) translate(296 562) translate(537) translate(42)"
        >
            <mask id="c" fill="#fff">
                <use xlinkHref="#a"></use>
            </mask>
            <use fill="#000" filter="url(#b)" xlinkHref="#a"></use>
            <use fill="var(--blue)" xlinkHref="#a"></use>
            <text
                className="yearInHandle"
                fill="#FFF"
                fontFamily="Inter-Bold, Inter"
                fontSize="22"
                fontWeight="bold"
                mask="url(#c)"
            >
                <tspan x="12.781" y="47">
                    {props.year}
                </tspan>
            </text>
        </g>
    </svg>
    // </div>
);

export default Handle;
