import * as React from 'react';
import classNames from 'classnames';
import styles from './DropdownCollapse.module.scss'; // Import css modules stylesheet as styles
import './DropdownCollapse.scss'; // Import css modules stylesheet as styles
import '../Input/Text/InputText.module.scss';
// import { defined } from '../../utils/variableEvaluation';

interface State {
    // isOpened: boolean;
    // readyToAnimate: boolean;
}

interface Props {
    label: string;
    fullWidth?: boolean;
    // isOpenInitially?: boolean;
    isOpen: boolean;
    isComplete?: boolean;
    isActive?: boolean;
    insideStep?: boolean;
    func?: any;
    className?: string;
    toggleOpen?: any;
    // animationDelay?: number;
    toggle: any;
    // allowAnimation?: boolean;
}

export class DropdownCollapse extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            // isOpened: this.props.isOpen,
            // isOpened: this.props.isOpenInitially || false,
            // readyToAnimate: false,
        };
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.modifyArrowOnCollapse = this.modifyArrowOnCollapse.bind(this);

        // document.body.addEventListener('click', (event) => {
        // console.log("body clicked", event.target, (event.target as any).classList, (event.target as any).parentElement.classList);
        // if (!(event.target as any).contains(event.target)) {
        //   // event.stopPropagation();
        //   console.log('will remove backdrop');
        //   document.body.classList.remove('backdrop');
        //   this.setState({isOpened: false})
        // } 
        // if ((event.target as any).classList.contains('profileTrigger') || (event.target as any).parentElement.classList.contains('profileTrigger')) {
        //   console.log('will add backdrop');
        //   document.body.classList.add('backdrop');
        //   this.setState({isOpened: true})
        // }
        // });
    }

    // static getDerivedStateFromProps(props, state) {
    //     Update state so the next render will show the fallback UI.
    //     console.log("getDerivedStateFromProps");
    //     if (props.open !== props.isOpen) {
    //         return {
    //             isOpened: props.open
    //         }
    //     } else {
    //         return null
    //     }
    // }

    // componentDidUpdate() {
    //     if (this.props.isOpen !== this.props.isOpen) {
    //         this.setState({
    //             isOpened: this.props.isOpen
    //         })
    //     }
    // }

    componentDidMount() {
        // console.log('collapse did mount', this.props);
        // if (this.props.isOpenInitially) {
        //     this.setState({
        //         readyToAnimate: true,
        //         // readyToAnimate: this.props.allowAnimation,
        //         // isOpened: this.props.isOpen
        //     })
        // } else {
        //     setTimeout(() => {
        //         this.setState({
        //             readyToAnimate: true,
        //             // readyToAnimate: this.props.allowAnimation,
        //             isOpened: this.props.isOpen
        //         })
        //     }, this.props.animationDelay || 0);
        // }
    }

    toggleCollapse(e): void {
        console.log('toggleCollapse', this.props.isOpen);
        // if (e.currentTarget.classList !== e.target.classList) {
        // this.setState({
        // isOpened: !this.props.isOpen
        // }, () => {

        if (this.props.isOpen) {
            // document.body.classList.add('backdrop');
        } else {
            // document.body.classList.remove('backdrop');
        }
        this.props.toggle();
        // });
        // this.props.toggleOpen();
        // }
    }

    modifyArrowOnCollapse() {
        return this.props.isOpen ? styles.activeArrow : '';
    }

    render() {
        return (
            <div className={classNames("dropdown", this.props.className, styles.dropdownCollapse, {
                'fullWidth': this.props.fullWidth,
                // when anliegen page is loaded, when going to step 2
                'animateClosing': this.props.isComplete && this.props.insideStep,
                // 'animateOpening': (this.props.isOpen) && this.state.readyToAnimate,
                "isOpened": this.props.isOpen
            })}>
                <div className={styles.labelContainer + " labelContainer"} >
                    <button
                        className={classNames(
                            'd-flex',
                            'toggleTrigger',
                            styles.collapseButton
                        )}
                    >
                        <div className={classNames('textMaterial',
                            styles.label,
                            {
                                // "isOpened": this.props.isOpenInitially || this.props.isActive,
                                "isOpened": this.props.isOpen,
                                [styles.isCompleted]: this.props.isComplete
                            },
                        )}
                            onClick={(e) => { this.props.func && this.props.func(e); this.toggleCollapse(e) }}
                        >
                            {this.props.label}
                        </div>
                        <div onClick={(e) => this.toggleCollapse(e)}
                            className={classNames(styles.arrow, this.modifyArrowOnCollapse(), {
                                "isOpened": this.props.isOpen,
                                [styles.isCompleted]: this.props.isComplete
                            })} />
                    </button>
                </div>

                <div className={styles.contentContainer + " contentContainer"}>
                    <div
                        className={classNames(styles.content, {
                            "isOpened": this.props.isOpen
                        }
                        )}>
                        <div className={styles.paddingContainer}>
                            <div className={styles.anliegen}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
