import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../reducers';
import * as actions from '../../actions';
import '../../utils/jwtToken';
import '../../containers/Wunschberechnung/Anliegen/Wunschberechnung.scss';
// import First from "./components/first";
import Second from "./components/second";
import Third from "./components/third";
import Four from "./components/four";
import { ReactComponent as IconReplay } from '../../assets/icons/replay.svg';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    neustart: {
        id: 'general.neustart',
        defaultMessage: 'NEUSTART'
    }
})


interface State {
}

interface Props {
    back: any;
    clearData: any;
    neustartHide?: boolean;
    onChart: boolean;
    setStepsOpened: any;
    start: any;
    stepsOpened: any;
    intl: any;
}


class SelectList extends React.Component<Props, State> {
    // constructor(props: any) {
    // super(props);

    // this.state = {
    //     visible: false,
    //     readyToAnimate: false,
    // }
    // }

    componentDidMount() {
        // this.setState({
        //     readyToAnimate: true,
        // })
    }

    render() {
        return (
            <>
                <div className='select-list'>
                    <div className="select neustartLabel labelLike" onClick={(e) => {
                        // If you know a better way, let me know please.
                        e.stopPropagation();
                        const tenantName = JSON.parse((window as any).localStorage.getItem("userDataWI"))?.tenantName;
                        console.log('on button click e', e, e.target);
                        const userDataWI = {
                            tenantName
                        };
                        (window as any).localStorage.removeItem("userDataWI");
                        (window as any).localStorage.setItem("userDataWI", JSON.stringify(userDataWI));
                        setTimeout(() => {
                            this.props.start(`/geburtsdatum`);
                        }, 1);
                        setTimeout(() => {
                            this.props.clearData();
                        }, 150);
                    }}>
                        <div className="circle inBackButton d-inline-block">
                            <IconReplay />
                        </div>
                        <div
                            className={classNames("backButton", {
                                "invisible": this.props.neustartHide
                            })}
                        >
                            <FormattedMessageCustom id={translatableStrings.neustart.id} text={this.props.intl.formatMessage(translatableStrings.neustart)}>
                                <button className="nonButton buttonText text-uppercase">
                                </button>
                            </FormattedMessageCustom>
                        </div>
                    </div>
                    {/* <First
                        toggle={() => { this.props.setStepsOpened({ 1: !this.props.stepsOpened[1] }) }}
                        back={(event) => this.props.back(event)}
                        start={() => this.props.start(`/geburtsdatum`)}
                        choose={'Anliegen'}
                        open={this.props.stepsOpened[1]}
                    /> */}
                    <Second
                        intl={this.props.intl}
                        onChart={this.props.onChart}
                        currentPage={window.location.hash.split('/')[1].split('?')[0]}
                        stepsOpened={this.props.stepsOpened}
                        toggle={() => { this.props.setStepsOpened({ 2: !this.props.stepsOpened[2] }) }}
                        back={(event) => this.props.back(event)}
                        start={() => this.props.start(`/datenquelle`)}
                        open={this.props.stepsOpened[2]}
                    />
                    <Third
                        intl={this.props.intl}
                        currentPage={window.location.hash.split('/')[1].split('?')[0]}
                        onChart={this.props.onChart}
                        toggle={() => { console.log("toggled"); this.props.setStepsOpened({ 3: !this.props.stepsOpened[3] }) }}
                        stepsOpened={this.props.stepsOpened}
                        back={(event) => this.props.back(event)}
                        start={() => this.props.start(`/ausgestelltam`)}
                        open={this.props.stepsOpened[3]}
                    />
                    <Four
                        intl={this.props.intl}
                        currentPage={window.location.hash.split('/')[1].split('?')[0]}
                        onChart={this.props.onChart}
                        toggle={() => { this.props.setStepsOpened({ 4: !this.props.stepsOpened[4] }) }}
                        back={(event) => this.props.back(event)}
                        start={() => this.props.start(`/rentenanteil`)}
                        open={this.props.stepsOpened[4]}
                        stepsOpened={this.props.stepsOpened}
                    />
                </div>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        stepsOpened: state.app.stepsOpened
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setStepsOpened: (data) => dispatch(actions.setStepsOpened(data)),
        clearData: (data) => actions.clearData(data, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectList);
