import React from 'react'
import { connect } from 'react-redux';
import { RootState } from './reducers/index';
import { Dispatch } from 'redux';
import { Route, matchPath, withRouter } from 'react-router-dom'
import * as actions from './actions/';
import { bindActionCreators } from 'redux';
import { TransitionGroup, Transition } from 'react-transition-group'
import gsap from 'gsap';
import WelcomeDekstop from './containers/Welcome/StartPage'
import Anliegen from "./containers/Wunschberechnung/Anliegen";
import Detaillierung from "./containers/Wunschberechnung/Detaillierung";
import Geburtsdatum from "./containers/PersonlicheAngaben/Geburtsdatum";
import Geschlecht from "./containers/PersonlicheAngaben/Geschlecht";
import Wohnort from "./containers/PersonlicheAngaben/Wohnort";
import AusgestelltAm from "./containers/Versicherungsausweis/AusgestelltAm";
import Lohnsumme from "./containers/Versicherungsausweis/Lohnsumme";
import Beschaftigungsgrad from "./containers/Versicherungsausweis/Beschaftigungsgrad";
import Sparguthaben from "./containers/Versicherungsausweis/Sparguthaben";
import VPKonto from "./containers/Versicherungsausweis/VP-Konto";
import AHVRente from "./containers/Versicherungsausweis/AHV-Rente";
import Fragen from "./containers/Versicherungsausweis/FragenErledigt";
import Rentenanteil from "./containers/Rentenberechnung/Rentenanteil Teilpensionierung";
import Arbeitspensum from "./containers/Rentenberechnung/Arbeitspensum";
import Zeitpunkt from "./containers/Rentenberechnung/Zeitpunkt Teilpensionierung";
import FragenBean from "./containers/Rentenberechnung/FragenBeantwortet";
import Spinner from './components/Spinner';
import Visualisation from './containers/Visualisation';
import { animationConfig } from './constants';
import './App.scss';
import { injectIntl } from 'react-intl';
import bgGradient from "./assets/imgs/bg_gradient.jpg"
import Logo from './components/Logo';
import Bruttolohn from './containers/Rentenberechnung/Bruttolohn';
import Lohnklasse from "./containers/Versicherungsausweis/Lohnklasse";
import Plan from "./containers/Versicherungsausweis/Plan";
export const routes = [
    {
        component: WelcomeDekstop,
        showInMenu: false,
        key: 'welcomedekstop',
        path: '/welcomedekstop',
        id: 'welcomedekstop',
        title: 'Welcome | Site',
        step: 4,
        description: 'If you are using react-helmet, you can read this description.',
        exact: true
    },
    {
        component: Anliegen,
        showInMenu: true,
        key: 'anliegen',
        path: '/anliegen',
        id: 'anliegen',
        exact: true,
        step: 4,
        navigationStep: 1
    },
    {
        component: Detaillierung,
        showInMenu: true,
        key: 'detaillierung',
        path: '/detaillierung',
        id: 'detaillierung',
        exact: true,
        step: 4,
        navigationStep: 1
    },
    // {
    //     component: Datenquelle,
    //     showInMenu: true,
    //     key: 'datenquelle',
    //     path: '/datenquelle',
    //     id: 'datenquelle',
    //     exact: true,
    //     step: 4,
    //     navigationStep: 1
    // },
    {
        component: Geburtsdatum,
        showInMenu: true,
        key: 'geburtsdatum',
        path: '/geburtsdatum',
        id: 'geburtsdatum',
        exact: true,
        step: 4,
        navigationStep: 2
    },
    {
        component: Geschlecht,
        showInMenu: true,
        key: 'geschlecht',
        path: '/geschlecht',
        id: 'geschlecht',
        exact: true,
        step: 5,
        navigationStep: 2
    },
    {
        component: Wohnort,
        showInMenu: true,
        key: 'wohnort',
        path: '/wohnort',
        id: 'wohnort',
        exact: true,
        step: 6,
        navigationStep: 2,
    },
    {
        component: AusgestelltAm,
        showInMenu: true,
        key: 'ausgestelltam',
        path: '/ausgestelltam',
        id: 'ausgestelltam',
        exact: true,
        step: 7,
        navigationStep: 3
    },
    {
        component: Lohnsumme,
        showInMenu: true,
        key: 'lohnsumme',
        path: '/lohnsumme',
        id: 'lohnsumme',
        exact: true,
        step: 8,
        navigationStep: 3
    },
    {
        component: Lohnklasse,
        showInMenu: true,
        key: 'lohnklasse',
        path: '/lohnklasse',
        id: 'lohnklasse',
        exact: true,
        step: 9,
        navigationStep: 3
    },
    {
        component: Beschaftigungsgrad,
        showInMenu: true,
        key: 'beschaftigungsgrad',
        path: '/beschaftigungsgrad',
        id: 'beschaftigungsgrad',
        exact: true,
        step: 9,
        navigationStep: 3
    },
    {
        component: Sparguthaben,
        showInMenu: true,
        key: 'sparguthaben',
        path: '/sparguthaben',
        id: 'sparguthaben',
        exact: true,
        step: 10,
        navigationStep: 3
    },
    {
        component: Plan,
        showInMenu: true,
        key: 'plan',
        path: '/plan',
        id: 'plan',
        exact: true,
        step: 10,
        navigationStep: 3
    },
    {
        component: VPKonto,
        showInMenu: true,
        key: 'vpkonto',
        path: '/vpkonto',
        id: 'vpkonto',
        exact: true,
        step: 11,
        navigationStep: 3
    },
    {
        component: AHVRente,
        showInMenu: true,
        key: 'ahvrente',
        path: '/ahvrente',
        id: 'ahvrente',
        exact: true,
        step: 12,
        navigationStep: 3
    },
    {
        component: Fragen,
        showInMenu: true,
        key: 'fragen',
        path: '/fragen',
        id: 'fragen',
        exact: true,
        step: 13,
        navigationStep: null
    },
    {
        component: Zeitpunkt,
        showInMenu: true,
        key: 'zeitpunkt',
        path: '/zeitpunkt',
        id: 'zeitpunkt',
        exact: true,
        step: 14,
        navigationStep: 4
    },
    {
        component: Bruttolohn,
        showInMenu: true,
        key: 'bruttolohn',
        path: '/bruttolohn',
        id: 'bruttolohn',
        exact: true,
        step: 14,
        navigationStep: 4
    },
    {
        component: Rentenanteil,
        showInMenu: true,
        key: 'rentenanteil',
        path: '/rentenanteil',
        id: 'rentenanteil',
        exact: true,
        step: 15,
        navigationStep: 4
    },
    {
        component: Arbeitspensum,
        showInMenu: true,
        key: 'arbeitspensum',
        path: '/arbeitspensum',
        id: 'arbeitspensum',
        exact: true,
        step: 16,
        navigationStep: 4
    },
    {
        component: FragenBean,
        showInMenu: true,
        key: 'fragenbean',
        path: '/fragenbean',
        id: 'fragenbean',
        exact: true,
        step: 15,
        navigationStep: 4
    },
    {
        component: Visualisation,
        showInMenu: true,
        key: 'visualisation',
        path: '/chart',
        id: 'visualisation',
        exact: true,
        // navigationStep: 3
    }
]

const routesObj = {};
routes.forEach((route) => {
    routesObj[route.path] = route;
});
// console.log('routesobj', routesObj);

/**
 * filterRoutes returns true or false and compares
 * location.pathname with the path key
 * in each Object declared above in the routes Array.
 */
const filterRoutes = (location) => {
    return routes.filter(({ path, exact }) => {
        return !!matchPath(location.pathname, {
            path,
            exact
        })
    })
}

interface State {
}

interface Props {
    history: any;
    location: any;
    loading: boolean;
    // clearData: any;
    fetchSettings: any;
    setStepsOpened: any;
    userDataWI: any;
    intl: any
    globalSettingsFetched: any;
    hasPayrollClasses: any;
    hasMultiplePlans: any;
    // autoFillData: any;
}


class Routes extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {};
    }

    private timeline = null;

    playIn = (path, node, appears?) => {
        console.log('playIn', path, node, appears, document.querySelectorAll('.stagger').length);
        // this.timeline.restart();
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        // if (path !== "/welcomemobile") {
        document.querySelector('.routeContainer').classList.add('overflowHidden');
        // }

        if (node.querySelectorAll('.stagger').length && (path !== "/chart")) {
            this.timeline.fromTo(document.querySelectorAll('.stagger'), {
                autoAlpha: 0,
                y: 900,
                stagger: { ...animationConfig().stagger },
                // delay: 0, stagger: {
                //     each: .1,
                //     ease: 'sine.out'
                // }
            }, {
                autoAlpha: 1,
                y: 0,
                stagger: { ...animationConfig().stagger },
                // delay: 0, stagger: {
                //     each: .1,
                //     ease: 'sine.out'
                // }
            }, 0.65);

            this.timeline.to(document.querySelector('#root .animationMask'), {
                autoAlpha: 0,
                ease: 'sine.out',
                duration: 0.1
            }, 0);

            this.timeline.fromTo(document.querySelector('#root .wunschberechnung_container .main'),
                { autoAlpha: 0, duration: 0 },
                { autoAlpha: 1, delay: 0 }, 0
            );

            (path === '/geburtsdatum' || path === '/zeitpunkt') && this.timeline
                .fromTo(document.querySelector('#root .sidebar, #root .logo'),
                    { opacity: (path === '/geburtsdatum' || path === '/zeitpunkt') ? 0 : 1 },
                    { opacity: 1 }, 0.5
                )
                .fromTo(document.querySelector('#root .sidebar .selects'),
                    { y: (path === '/geburtsdatum' || path === '/zeitpunkt') ? -20 : 0 },
                    { y: 0 }, 0.5
                );

            this.timeline.play();
        }

        if (node.querySelectorAll('.crossFade').length) {
            this.timeline.to(document.querySelectorAll('.crossFade'), {
                autoAlpha: 1
            })
        }

        this.timeline.eventCallback("onComplete", () => {
            console.log('oncomplete animation routes');
            // setTimeout(() => {
            document.querySelector('.routeContainer').classList.remove('overflowHidden');
            // }, 0)
            if (path === "/fragen" || path === "/fragenbean" || path === "/chart") {
                setTimeout(() => {
                    this.props.setStepsOpened({ 1: false, 2: false, 3: false, 4: false });
                }, 150);
            } else {
                // this.props.setStepsOpened({ [routesObj[path]['navigationStep']]: true });
            }
        });

        this.timeline.eventCallback("onStart", () => {
            console.log('onStart animation routes');
            if (routesObj[path]['navigationStep'] && window.innerWidth > 896) {
                setTimeout(() => {
                    this.props.setStepsOpened({ [routesObj[path]['navigationStep']]: true })
                }, 800);
            }
            // we probably don't need it here as we have it above
            document.querySelector('.routeContainer').classList.add('overflowHidden');
        });

        if (path === "chart") {
            console.log('will load chart page');
        }

    }

    componentDidMount() {
        console.log('routes mounted');
        if (this.props.userDataWI.settings === null || !(Object.keys(this.props.userDataWI.settings).length > 0)) {
            this.props.fetchSettings().then((res) => {
                if(this.props.location.pathname === "/lohnklasse" && !res?.data?.hasPayrollClasses) {
                    this.props.history.push("/beschaftigungsgrad");
                }
                if(this.props.location.pathname === "/plan" && !res?.data?.hasMultiplePlans) {
                    this.props.history.push("/sparguthaben");
                }
                console.log('globalsettings fetched', res);
                // this.timeline && this.timeline.play();
            });
        }
        // window.addEventListener('orientationchange', (ev) => {
        //     toggleFullScreen(true, ev);
        // });
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if(this.props.globalSettingsFetched) {
            if(this.props.location.pathname === "/lohnklasse" && !this.props.hasPayrollClasses) {
                this.props.history.push("/beschaftigungsgrad");
            }
            if(this.props.location.pathname === "/plan" && !this.props.hasMultiplePlans) {
                this.props.history.push("/sparguthaben");
            }
        }
    }

    playOut = (path, node) => {
        console.log('playOut', path, node, document.querySelectorAll('.stagger').length);
    }

    getBackgroundImage = () => {
        const backgroundImage = this.props.userDataWI.settings?.customDesign?.backgroundImage;

        if (backgroundImage) {
            return backgroundImage
        }
        return bgGradient;
    }

    render() {
        const intl = this.props.intl;
        const backgroundImage = this.getBackgroundImage();
        return (
            <>
                {this.props.userDataWI.settings && <picture className="backgroundImage">
                    {/* <source srcSet={bgGradient + ' 1x,' + bgGradient + ' 2x'} type="image/webp" /> */}
                    {/* <source srcSet={bgGradient + ' 1x,' + bgGradient + ' 2x'} type="image/jpeg" /> */}
                    <img src={backgroundImage} alt="backgroundImage" />
                </picture>}
                <div className="routeContainer">
                    <div className="animationMask"></div>
                    {this.props.loading && <Spinner />}
                    <Route render={({ location, history }) => {
                        const path = `/${location.pathname.split('/')[1]}`;
                        if (!filterRoutes(location).length) {
                            return (
                                <WelcomeDekstop location={location} history={history} intl={intl} />
                            )
                        }
                        console.log('path', path);
                        return ([
                            routes.find(r => r.path === path).step && <Logo key="logo0" tenantName={this.props.userDataWI.tenantName} />,
                            <TransitionGroup appear key="transition1">
                                {filterRoutes(location).map(({ key, ...props }) => {
                                    // const clearData = this.props.clearData;
                                    // <SwitchTransition>
                                    return <Transition
                                        key={'child-' + key}
                                        timeout={{ enter: 0, exit: 0 }}
                                        onEnter={(node, appears?) => this.playIn(path, node, appears)}
                                        onExit={(node?) => this.playOut(path, node)}
                                    >
                                        {React.createElement(routes.find(r => r.path === path).component, {
                                            ...props,
                                            location,
                                            history,
                                            intl
                                            // clearData
                                        }, null)}
                                    </Transition>
                                    // </SwitchTransition>
                                })}
                            </TransitionGroup>]
                        )
                    }} />
                </div >
            </>
        )
    }
}


function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        // clearData: bindActionCreators(actions.clearData, dispatch),
        setStepsOpened: bindActionCreators(actions.setStepsOpened, dispatch),
        fetchSettings: bindActionCreators(actions.fetchSettings, dispatch),
        // autoFillData: bindActionCreators(actions.autoFillData, dispatch),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(Routes)));
