import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import Modal from 'react-modal';
import doc from '../../../assets/imgs/ausgellt-2.png';
import close from '../../../assets/icons/Close_Icon.svg';
import Calendar from "../../../components/Calendar";
import gsap from 'gsap';
import SelectList from "../../../components/Sidebar/SelectList";
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import {ExamplePictures} from "../../../reducers/app";
import classNames from "classnames";
const translatableStrings = defineMessages({
    releaseDate: {
        id: 'pageAusgestelltam.releaseDate',
        defaultMessage: 'Wann wurde Ihr Versicherungsausweis ausgestellt?'
    },
    whereDate: {
        id: 'pageAusgestelltam.whereDate',
        defaultMessage: 'WO FINDE ICH DAS AUSSTELLUNGSDATUM?'
    },
    example: {
        id: 'pageAusgestelltam.example',
        defaultMessage: 'Beispiel'
    },
    exampleOfInsurance: {
        id: 'pageAusgestelltam.exampleOfInsurance',
        defaultMessage: 'Beispiel Versicherungsausweis'
    }
})

interface State {
    dateOfBirth: string;
    modalIsOpen: boolean;
}

interface Props {
    actions: any;
    history: any;
    setUserDataAusgestelltAm: any
    userDataWI: any;
    intl: any;
    examplePicturesSet: ExamplePictures;
}

class AusgestelltAm extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            dateOfBirth: '',
            modalIsOpen: false,
        };
        this.setDateOfBirth = this.setDateOfBirth.bind(this)
        this.back = this.back.bind(this);

    }
    private timeline = null;


    componentDidMount() {
    }

    async setUserDataAusgestelltAm(data) {
        await this.props.setUserDataAusgestelltAm(data);
    }

    async next(e) {
        await this.setUserDataAusgestelltAm(e);

        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.crossFade').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                    autoAlpha: 0
                })
        }

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {
            this.props.history.push({
                pathname: `/lohnsumme`
            })
        }, 1200)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async setDateOfBirth(e) {
        this.setState({
            dateOfBirth: e,
        });
        this.next(e);
    }

    showHideNotif = () => {
        if (this.state.modalIsOpen && window.innerWidth < 897) {
            this.setState({
                modalIsOpen: false
            })
        } else {
            this.setState({
                modalIsOpen: true
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        let list = ['logo1', 'logo2', 'logo3', 'logo4'];
        list.forEach(arr => {
            let title = document.getElementById(arr);
            let title2 = document.getElementById('back');
            title.style.display = 'none';
            title2.style.display = 'none';
            document.body.classList.remove('blurred');
        })
        let finishedIcons = document.getElementsByClassName('circle');
        for (var i = 0; i < finishedIcons.length; i++) {
            finishedIcons[i].classList.remove('grayed');
        }
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                  <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
                </div>
                <div className="main ausg-main">
                    <FormattedMessageCustom id={translatableStrings.releaseDate.id} text={this.props.intl.formatMessage(translatableStrings.releaseDate)}>
                        <div className="ich-mochte mb-40 stagger">

                        </div>
                    </FormattedMessageCustom>
                    <Calendar
                        intl={this.props.intl}
                        // day={true}
                        yearReversed={true}
                        dateRange={[
                            this.props.userDataWI.settings ? this.props.userDataWI.settings.minIssuedByDate : `${new Date().getFullYear() - 1}-${("0" + new Date().getMonth()).slice(-2)}-01T00:00:00`,
                            this.props.userDataWI.settings ? this.props.userDataWI.settings.maxIssuedByDate : `${new Date().getFullYear()}-${("0" + new Date().getMonth()).slice(-2)}-01T00:00:00`
                        ]}
                        // dateRange={["2010-01-01T00:00:00",  ]}
                        setDateOfBirth={this.setDateOfBirth}
                    />
                    <FormattedMessageCustom id={translatableStrings.whereDate.id} text={this.props.intl.formatMessage(translatableStrings.whereDate)}>
                        <div className={classNames("inform stagger", {"d-none": !this.props.examplePicturesSet.dateOfIssuePictureUrl})} onClick={this.showHideNotif}>
                        </div>
                    </FormattedMessageCustom>

                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.showHideNotif}
                        // contentLabel="Large Modal"
                        className={"mobile-modal"}
                        closeTimeoutMS={600}
                        overlayClassName={"large-modal-overlay"}>
                        {<div id='beispiel' className='beispiel' onClick={this.showHideNotif}>
                            <div className="color">
                                <img alt="doc" src={close} className='close' />
                                <div>
                                    <img alt="doc" src={this.props.examplePicturesSet.dateOfIssuePictureUrl} className='document' />
                                    <FormattedMessageCustom id={translatableStrings.exampleOfInsurance.id} text={this.props.intl.formatMessage(translatableStrings.exampleOfInsurance)}>
                                        <div></div>
                                    </FormattedMessageCustom>
                                </div>
                            </div>
                        </div>
                        }
                    </Modal>
                </div>
                <div className='optional crossFade'>
                    {this.props.examplePicturesSet.dateOfIssuePictureUrl &&
                    <div className='doc'>
                        <img alt="doc" src={this.props.examplePicturesSet.dateOfIssuePictureUrl} />
                        <FormattedMessageCustom id={translatableStrings.example.id} text={this.props.intl.formatMessage(translatableStrings.example)}>
                            <p></p>
                        </FormattedMessageCustom>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataAusgestelltAm: (data) => dispatch(actions.setUserDataAusgestelltAm(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AusgestelltAm);
