import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
// import { bindActionCreators } from 'redux';
import { RootState } from '../../../../reducers/index';
import './BlockPension.scss';
import gsap from 'gsap';
import { ChartSettings } from '../chartSettings';
import { formatNumber } from '../../../../utils/react';
// import { parseDate } from '../../../../utils/react';
import Tooltip from 'rc-tooltip';
import { ReactComponent as HelpIcon } from "../../../../assets/icons/Help Icon.svg";
import { animationConfig } from '../../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';
import ScrollToPlugin from "gsap/ScrollToPlugin"

gsap.registerPlugin(ScrollToPlugin);

const translatableStrings = defineMessages({
    retirement: {
        id: 'pageChart.retirement',
        defaultMessage: 'Pensionierung'
    },
    expectedPension: {
        id: 'pageChart.expectedPension',
        defaultMessage: 'Erwartete AHV-Rente'
    },
    normalRetirement: {
        id: 'pageChart.normalRetirement',
        defaultMessage: 'Altersrente bei ordentlichter Pensionierung'
    },
    altersRente: {
        id: 'pageChart.altersRente',
        defaultMessage: 'Altersrente'
    },
});

interface State {
    animateCounter: number;
}

interface Props {
    ahvPension: number;
    ahvPensionierungObj: any;
    animateCounter: number;
    basicPension: number;
    intl: any;
    isEarlyPensionPresent: boolean;
    firstPension: number;
    maxVal: number;
    nominalRetirementDate: any;
    retirementSteps: any;
    salary: number;
    segment: number;
    showLine?: boolean;
    totalIncome: number;
    width: any;
    wrapInTooltip: any;
    year: number;
}

export class BlockPension extends React.Component<Props, State> {
    private barPensionierungLabel: any;
    private barPensionPrivate: any;
    private barPensionPrivateWrapper: any;
    private barPensionGov: any;
    private barPensionGovWrapper: any;

    private barPensionPrivateLabels: any;
    private timeline = null;
    private gridTimeline = null;

    constructor(props: any) {
        super(props);
        console.log("BlockPension constructor called");

        this.state = {
            animateCounter: 0
        };
    }

    componentDidMount() {
        console.log('BlockPension app did mount animateCounter', this.props.animateCounter);
        window.addEventListener('lastPartialPensionAnimated', this.runTimeline);
        // window.addEventListener('blockCapitalAnimatedWhenNoPartialPensions', this.runTimeline);
    }

    componentWillUnmount() {
        window.removeEventListener('lastPartialPensionAnimated', this.runTimeline);
        console.log('block pension componentWillUnmount');
        this.timeline && this.timeline.kill();
        this.gridTimeline && this.gridTimeline.kill();
        // window.removeEventListener('blockCapitalAnimatedWhenNoPartialPensions', this.runTimeline);
    }

    runTimeline = () => {
        console.log('runTimeline blockPension animatecounter: ', this.props.animateCounter,
            this.props.isEarlyPensionPresent,
            document.getElementById('barPensionGovWrapper'),
            this.barPensionGovWrapper,
            document.getElementById("barPensionGovLabels")
        );
        if (this.props.animateCounter === 1) {

            this.timeline = gsap.timeline({ paused: true });
            this.timeline && this.timeline.pause();

            let startSec = this.props.isEarlyPensionPresent ? (animationConfig().startSec + 0.6) * animationConfig().scale : animationConfig().startSec;
            this.timeline
                // green block
                .fromTo([
                    document.getElementById('barPensionGovWrapper'),
                    document.getElementById('barPensionPrivateWrapper')
                ], { autoAlpha: 0, duration: 0 }, { autoAlpha: 1 }, startSec + 1.3)
                .from(document.getElementById('barPensionGovWrapper'),
                    { height: 0, maxHeight: 0, ease: animationConfig().easing }, startSec + 1)
                .to(document.getElementById('barPensionierungLabelWrapper'),
                    {
                        height: this.props.maxVal - this.props.basicPension - this.props.ahvPension,
                        ease: animationConfig().easing
                    }, startSec + 1)
                .from(document.getElementById('barPensionPrivateWrapper'),
                    { maxHeight: 0, ease: animationConfig().easing }, startSec + 1.3)


                .fromTo([
                    document.getElementById('barPensionGovLabels'),
                    document.getElementById('barPensionierungLabel'),
                ],
                    { y: -40, autoAlpha: 0 },
                    { autoAlpha: 1, y: 0 },
                    startSec + 1.5)
                .fromTo([document.querySelector('#barPensionPrivate .blockLabels')],
                    { y: -40, autoAlpha: 0 },
                    { autoAlpha: 1, y: 0 },
                    startSec + 1.5)


            this.timeline.play();

            this.timeline.eventCallback("onComplete", () => {
                console.log('oncomplete pensionierung animateCounter', this.props.animateCounter);
                this.setState({
                    animateCounter: this.state.animateCounter + 1
                })
                if (this.props.animateCounter === 1) {
                    this.runGridTimeline();
                } else {
                    console.log('will dispatch animationFinishedEvent blockpension oncomplete');


                    (window as any).dispatchEvent((window as any).animationFinishedEvent);
                }
            });

        } else {
            (window as any).dispatchEvent((window as any).animationFinishedEvent);
        }
    }

    runGridTimeline = () => {
        console.log('runGridTimeline');
        this.gridTimeline = gsap.timeline({ paused: true });
        this.gridTimeline && this.gridTimeline.pause();

        this.gridTimeline.fromTo(document.querySelector('.chartRight .gridYears'),
            { autoAlpha: 0, scale: 0.5 },
            { duration: .5, autoAlpha: 1, scale: 1, ease: 'sine.out', clearProps: "transform" }, 0)

        this.gridTimeline.fromTo([document.querySelectorAll('.chartRight .handleWrapper')],
            { autoAlpha: 0, scale: 1.5 },
            { duration: .5, autoAlpha: 1, scale: 1, ease: 'sine.out' }, 0.6)
        this.gridTimeline.fromTo([document.querySelector('.controls'), document.querySelector('.addTeilButton2'), document.querySelector('.zuruckSetzenButton')],
            { autoAlpha: 0, y: -20 },
            { duration: .5, autoAlpha: 1, y: 0, ease: 'sine.out' }, 0.6)

        let handle = document.querySelector('.handle0') as HTMLElement;
        if (handle) {
            const { x } = handle.getBoundingClientRect();
            console.log("OFFSET", x);
            this.gridTimeline.to(document.querySelector('.scrollableContainer'), { duration: 1, scrollTo: { x: x - 20 } });
        }

        this.gridTimeline.play();


        this.gridTimeline.eventCallback("onComplete", () => {
            console.log('will dispatch animationFinishedEvent blockpension oncomplete gridTimeline');
            (window as any).dispatchEvent((window as any).animationFinishedEvent);
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        const widthChanged = nextProps.width !== this.props.width;
        const animateCounterIncreased = nextProps.animateCounter > this.props.animateCounter;
        // const activeSegmentChanged = nextProps.activeSegment !== this.props.activeSegment;
        const maxValChanged = nextProps.maxVal !== this.props.maxVal;
        const basicPensionChanged = nextProps.basicPension !== this.props.basicPension;
        // const animationFinished = nextState.animationFinished !== this.state.animationFinished;
        // const animateCounterChanged = nextProps.animateCounter > this.props.animateCounter;
        // console.log('shouldComponentUpdate BlockPension widthChanged', widthChanged);
        console.log('shouldComponentUpdate blockpension maxValChanged', maxValChanged, 'animateCounterCHanged: ', this.props.animateCounter, nextProps.animateCounter, nextProps.maxVal, this.props.maxVal);
        if (animateCounterIncreased || basicPensionChanged || maxValChanged || widthChanged) {
            console.log('shouldComponentUpdate TRUE blockpension', animateCounterIncreased);
            return true;
        } else {
            return false
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    render() {
        const availableHeight = document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - 75 : 0;
        const realValueToPxRatio = availableHeight / (this.props.maxVal || 1);
        console.log('realValueToPxRatio pension', realValueToPxRatio, this.props.basicPension * realValueToPxRatio);
        const enoughHeightFor1RowAhv = this.props.ahvPension * realValueToPxRatio >= 30;
        const tooNarrow = this.props.width < 180;
        const enoughHeightFor1RowPension = this.props.basicPension * realValueToPxRatio >= 30;
        console.log('render blockpension availableheight', availableHeight, realValueToPxRatio,
            'this.props.basicPension', this.props.basicPension,
            'this.props.ahvPension', this.props.ahvPension);

        return (
            <div className={classNames("blockPension blockPadding blockFullHeight", {})} style={{
                maxWidth: this.props.width,
                marginLeft: 'auto'
            }}
            >
                <div id="barPensionierungLabelWrapper" className="blockLabelWrapper"
                    style={{
                        // height: ChartSettings.maxBlockHeight * 6
                        height: this.props.maxVal - this.props.ahvPension - this.props.basicPension,
                        // maxHeight: this.props.ahvPension * realValueToPxRatio,
                    }}
                >
                    <div id="barPensionierungLabel" className="blockLabel" ref={c => this.barPensionierungLabel = c}
                        style={{
                            visibility: 'hidden'
                        }}
                    >
                        <FormattedMessageCustom id={translatableStrings.retirement.id}
                            text={this.props.intl.formatMessage(translatableStrings.retirement)}>
                            <p className="blockTitle">
                            </p>
                        </FormattedMessageCustom>
                        <p className="blockValue">CHF {formatNumber(this.props.totalIncome)}</p>
                    </div>
                </div>

                <div
                    id="barPensionPrivateWrapper"
                    className={classNames("blockWrapper", {
                        "inTooltip": this.props.ahvPension * realValueToPxRatio < 55 || tooNarrow,
                        "enoughHeightFor1Row": enoughHeightFor1RowAhv,
                        "tooNarrow": tooNarrow
                    })}
                    ref={c => this.barPensionPrivateWrapper = c}
                    style={{
                        height: this.props.ahvPension,
                        maxHeight: this.props.ahvPension * realValueToPxRatio,
                        opacity: this.props.animateCounter < 2 ? 0 : 1
                    }}>
                    {this.props.wrapInTooltip(<div id="barPensionPrivate"
                        className="blockElasticHeight blockStyleRounded"
                        ref={c => this.barPensionPrivate = c} style={{
                            height: '100%',
                            // maxHeight: this.props.ahvPension * realValueToPxRatio,
                            // maxHeight: ChartSettings.maxBlockHeight * 6,
                        }}>
                        <div className="blockLabels" ref={c => this.barPensionPrivateLabels = c}
                            style={{
                                // visibility: 'hidden'
                            }}
                        >
                            <FormattedMessageCustom id={translatableStrings.expectedPension.id}
                                text={this.props.intl.formatMessage(translatableStrings.expectedPension)}>
                                <p className="blockTitle"></p>
                            </FormattedMessageCustom>
                            <p className="blockValue">CHF {formatNumber(this.props.ahvPension)}</p>
                        </div>
                    </div>,
                        [
                            <FormattedMessageCustom id={translatableStrings.expectedPension.id}
                                text={this.props.intl.formatMessage(translatableStrings.expectedPension)}
                                key="ahvRente1">
                                <p className="blockTitle"></p>
                            </FormattedMessageCustom>,
                            !enoughHeightFor1RowAhv &&
                            <p key="ahvRente2" className="blockValue">CHF {formatNumber(this.props.ahvPension)}</p>
                        ],
                        this.props.ahvPension * realValueToPxRatio < 55 || tooNarrow
                    )}
                </div>

                {
                    this.props.ahvPensionierungObj.label === "AHV-Pensionierung" &&
                    <Tooltip
                        mouseEnterDelay={0}
                        mouseLeaveDelay={0}
                        overlayClassName="test2"
                        destroyTooltipOnHide={false}
                        placement={'topLeft'}
                        trigger={'click'}
                        overlay={<FormattedMessageCustom id={translatableStrings.normalRetirement.id}
                            text={this.props.intl.formatMessage(translatableStrings.normalRetirement)}>
                            <div></div>
                        </FormattedMessageCustom>
                        }>
                        <div className="iconClickable" id="referenceTooltip" tabIndex={0}
                            style={{
                                zIndex: 9999,
                                width: '18px',
                                position: 'absolute',
                                right: -22,
                                opacity: 0,
                                // bottom: -5
                                bottom: realValueToPxRatio * this.props.firstPension - 8

                            }}
                        >
                            <HelpIcon />
                        </div>
                    </Tooltip>}

                <div id="barPensionGovWrapper"
                    className={
                        classNames("blockWrapper", {
                            "blockStyleRounded": !(this.props.ahvPension > 0)
                        })
                    }
                    ref={c => this.barPensionGovWrapper = c}
                    style={{
                        height: this.props.basicPension,
                        // this causes wrong heigh calculation when switching tabs from yearly to monthly
                        // minHeight: 2,
                        maxHeight: this.props.basicPension * realValueToPxRatio,
                        // maxHeight: this.state.animateCounter === 0 ? 0 : this.props.basicPension * realValueToPxRatio,
                        opacity: this.state.animateCounter < 1 ? 0 : 1,
                        overflow: 'visible'
                    }}>
                    {this.props.wrapInTooltip(<div
                        id="barPensionGov"
                        className={classNames("blockElasticHeight", {
                            "inTooltip": this.props.basicPension * realValueToPxRatio < 50,
                            "enoughHeightFor1Row": enoughHeightFor1RowPension,
                        })}
                        ref={c => this.barPensionGov = c}
                        style={{
                            height: "100%",
                            maxHeight: ChartSettings.maxBlockHeight * 6,
                        }}>
                        <div id="barPensionGovLabels"
                            className="bottomLabel"
                            style={{
                                opacity: this.state.animateCounter < 1 ? 0 : 1
                            }}
                        >
                            <FormattedMessageCustom id={translatableStrings.altersRente.id}
                                text={this.props.intl.formatMessage(translatableStrings.altersRente)}>
                                <p className="blockTitle">
                                </p>
                            </FormattedMessageCustom>
                            <p className="blockValue">CHF {formatNumber(this.props.basicPension)}</p>
                        </div>
                    </div>,
                        [
                            <FormattedMessageCustom id={translatableStrings.altersRente.id}
                                text={this.props.intl.formatMessage(translatableStrings.altersRente)}
                                key="altersRente1">
                                <p className="blockTitle"></p>
                            </FormattedMessageCustom>,
                            !enoughHeightFor1RowPension && <p key="altersRente2"
                                className="blockValue">CHF {formatNumber(this.props.basicPension)}</p>],
                        this.props.basicPension * realValueToPxRatio < 50
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(BlockPension);
