import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { Button, EButtonType } from "../../../components/Button";
import done from "../../../assets/icons/Done.svg";
import SelectList from "../../../components/Sidebar/SelectList";
import gsap from 'gsap';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    questionsAnswered: {
        id: 'general.questionsAnswered',
        defaultMessage: 'Sie haben alle Fragen beantwortet. Ihre Teilpensionierung wird mit einberechnet.'
    }
})
interface State {
    value: any;
}

interface Props {
    actions: any;
    history: any;
    userDataWI: any;
    intl: any;
}

class FragenBean extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
        this.start = this.start.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        setTimeout(() => {

            this.timeline = gsap.timeline({ paused: true });
            this.timeline && this.timeline.pause();
            const startSec = 1.5;
            
            this.timeline
                .fromTo(
                    document.querySelector('#step4 .contentContainer'), { autoAlpha: 1 },
                    { autoAlpha: 0, duration: 0.6 }, startSec);

            // document.querySelector('#root .logo') && this.timeline.fromTo(
            //     document.querySelector('#root .logo'), { autoAlpha: 1 },
            //     { autoAlpha: 0, duration: 0.6 }, startSec);


            this.timeline.to([document.querySelector('#step4 .circle')], {
                backgroundColor: '#263238',
                color: 'white',
                borderColor: '#263238'
            }, startSec - 0.4)
                .to([document.querySelector('#step4 .toggleTrigger')], {
                    color: '#263238'
                }, startSec - 0.6)
                .to([
                    document.querySelector('.selects .backButton .buttonText'),
                    document.querySelectorAll('.dropdown .labelContainer')
                ],
                    { autoAlpha: 0, duration: 0.3 }, startSec + 0.9)
                .to(document.querySelector('.wunschberechnung_container .main'),
                    { autoAlpha: 0, duration: 0.3 }, startSec + 1.2)


            // setTimeout(() => {
            //     document.querySelector('#step4').classList.add('activated');
            // }, 900);

            this.timeline.play();

            this.timeline.eventCallback("onComplete", () => {
                setTimeout(() => {
                    this.props.history.push({
                        pathname: '/chart',
                    })
                }, 800)
            });

        }, 150)
    }

    async next() {
        this.props.history.push({
            pathname: `/rentenanteil`
        })
    }

    start = (e) => {
        this.props.history.push({
            pathname: e,
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />

                <div className='sidebar'>
                    <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                </div>

                <div className="main frag-beant-main">
                    <div className='fragen'>
                        <div className="stagger text-center">
                            <Button
                                className="button text-center strokeGrey font-12 text-uppercase colorGrey font-500 px-4 preventFocus"
                                to="chart"
                                buttonType={EButtonType.BasicLink}
                            >
                                <img alt="logo" src={done} className="d-sm-inline done fragenBeant-done" />
                            </Button>
                        </div>
                        <FormattedMessageCustom id={translatableStrings.questionsAnswered.id} text={this.props.intl.formatMessage(translatableStrings.questionsAnswered)}>
                            <div className='ich-mochte stagger'>
                            </div>
                        </FormattedMessageCustom>
                    </div>
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataZeitpunkt: (data) => dispatch(actions.setUserDataZeitpunkt(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FragenBean);
