import React from 'react';
import { defined } from '../../utils/variableEvaluation';

interface MyProps {
    children?: React.ReactNode;
    text: string;
    id: string;
}

const FormattedMessageCustom: React.FC<MyProps> = (props) => {
    if (defined(props.children)) {
        const modChild = props.children;
        // console.log('modChild', modChild);
        // delete modChild.props.children;
        return <>
            {React.cloneElement(modChild as React.ReactElement<any>, {
                "data-translate": props.id,
                dangerouslySetInnerHTML: {__html: props.text}
            })}
        </>
    } else {
        return <>
            {props.text}
        </>
    }
};

export default FormattedMessageCustom;