import * as React from 'react';
import { InputRadioOption } from './InputRadioOption';
import { IInputConfig } from '../index';
import styles from './InputRadio.module.scss'; // Import css modules stylesheet as styles

interface IProps {
  radioOptions?: IInputConfig[];
}

export function InputRadio(props: IProps) {
  const options = props.radioOptions.map((input: IInputConfig, i: number) => (
    <InputRadioOption
      key={input.id}
      input={input}
      index={i}
      defaultChecked={input.defaultChecked}
      forcedValue={input.value}
    />
  ));

  return <ul className={styles.radioWrapper}>{options}</ul>;
}
