export class ChartSettings {
    static maxBlockHeight: number = 200;
    static minBlockWidth1: number = 131 + 5;
    static minBlockWidth2: number = 140;
    static yearWidthInitial = 108;
    // static initialHandlePos = 8 / 14 * 100;
    static chartLeftPadding = 35;
    static chartTopPadding = 75;
    static handleWidth = 35;
    static chartRightPadding = 35;

    static blockPadding = 5.5;
    static barGap = 11;

    static minAge = 58;
    static maxAge = 70;

    static minWidths: any = {
        capital: 158,
        other: 158,
    };
}
