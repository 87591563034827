import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import './index.scss';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import SelectList from "../../../components/Sidebar/SelectList";
import { ReactComponent as Weiblich } from "../../../assets/imgs/weiblich.svg";
import { ReactComponent as Mannlich } from "../../../assets/imgs/mannlich.svg";
import gsap from 'gsap';
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
const translatableStrings = defineMessages({
    gender: {
        id: 'pageGeschlecht.gender',
        defaultMessage: 'Wie ist Ihr Geschlecht?'
    },
    man: {
        id: 'pageGeschlecht.man',
        defaultMessage: 'männlich'
    },
    woman: {
        id: 'pageGeschlecht.woman',
        defaultMessage: 'weiblich'
    }
})

interface State {
    login: string;
    password: string;
    passwordType: string;
    rememberMe: boolean;
}

interface Props {
    actions: any;
    history: any;
    userDataWI: any;
    setUserDataGeschlecht: any;
    intl: any;
}

class Geschlecht extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        console.log('geschlecht did mount');
        setTimeout(() => {
            (document.querySelector('.fakeFocusable') as any) && (document.querySelector('.fakeFocusable') as any).focus();
        }, 1200);
    }


    async setUserDataGeschlecht(data) {
        await this.props.setUserDataGeschlecht(data);
    }

    async next(e) {
        await this.setUserDataGeschlecht(e.currentTarget.id);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            // document.querySelector('.routeContainer').classList.add('overflowHidden');
            this.timeline
                // .to(document.querySelector('.currentNavItem'), { color: '#80994D', duration: 0.3 })
                // .to(document.querySelector('.routeContainer'), {overflow: 'hidden', delay: 0.0} )
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {

            this.props.history.push({
                pathname: `/ausgestelltam`
                // pathname: `/wohnort`
            })
        }, 1200)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                  <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                </div>
                <div className="main geschl-main">
                    <div className='mainBlock'>
                        <FormattedMessageCustom id={translatableStrings.gender.id} text={this.props.intl.formatMessage(translatableStrings.gender)}>
                            <div className='ich-mochte mb-40 stagger'>
                            </div>
                        </FormattedMessageCustom>
                        <div
                            className='blocksGeschlecht'
                        >
                            <a className='fakeFocusable' tabIndex={1} href="/">FOCUSABLE</a>
                            <button id='weiblich' className='blockGeschlecht stagger focusableBlock' onClick={this.next} tabIndex={2}>
                                <div className={'imgGeschlecht'}>
                                    <div>
                                        <Weiblich />
                                    </div>
                                </div>
                                <FormattedMessageCustom id={translatableStrings.woman.id} text={this.props.intl.formatMessage(translatableStrings.woman)}>
                                    <p className='text'></p>
                                </FormattedMessageCustom>
                            </button>
                            <button id='männlich' className='blockGeschlecht stagger focusableBlock' onClick={this.next} tabIndex={3}>
                                <div className={'imgGeschlecht'}>
                                    <div>
                                        <Mannlich />
                                    </div>
                                </div>
                                <FormattedMessageCustom id={translatableStrings.man.id} text={this.props.intl.formatMessage(translatableStrings.man)}>
                                    <p className='text'></p>
                                </FormattedMessageCustom>
                            </button>
                        </div>
                    </div>
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataGeschlecht: (data) => dispatch(actions.setUserDataGeschlecht(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Geschlecht);
