import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { getParameterByName } from '../../../utils/toSearchParams'
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import Calendar from "../../../components/Calendar";
import gsap from 'gsap';
import SelectList from "../../../components/Sidebar/SelectList";
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { getMonth, parseToApiDate } from '../../../utils/react';
import { Button, EButtonType } from "../../../components/Button";

const translatableStrings = defineMessages({
    when: {
        id: 'pageZeitpunkt.when',
        defaultMessage: 'Ab wann wollen Sie die Teilpension beziehen?'
    },
    rentenanteil: {
        id: 'general.rentenanteil',
        defaultMessage: 'Rentenanteil'
    },
    cancel: {
        id: 'button.cancel',
        defaultMessage: 'ABBRECHEN',
    },
})

interface State {
    zeitPunkt: string;
}

interface Props {
    actions: any;
    history: any;
    setUserDataZeitpunkt: any;
    userDataWI: any;
    intl: any;
    // possibleRetirementStepList: any;
}

class Zeitpunkt extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            zeitPunkt: '',
        };
        this.back = this.back.bind(this);
    }
    private timeline = null;


    async setUserDataZeitpunkt(data) {
        await this.props.setUserDataZeitpunkt([data]);
    }

    async next(e) {
        console.log('setUserDataZeitpunkt', e, this.props.userDataWI.partialPensionLimits.isDerivedFromActivityReduction)
        await this.setUserDataZeitpunkt(e);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger:not(.preventStaggerOut)'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        this.timeline.eventCallback("onComplete", () => {
            this.props.history.push({
                pathname: this.props.userDataWI.partialPensionLimits.isDerivedFromActivityReduction ? `/bruttolohn` : `/rentenanteil`,
                search: `no=${getParameterByName("no")}`
            })
        });
    }

    cancel(e) {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger:not(.preventStaggerOut)'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        this.timeline.eventCallback("onComplete", () => {
            this.props.history.push({
                pathname: `/chart`
            })
        });
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id,
                search: `no=${getParameterByName("no")}`
            })
        }
    }

    setDate = (e) => {
        this.setState({
            zeitPunkt: e,
        });
        this.next(e);
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    getLimits = () => {
        console.log('getlimits', getMonth(this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[0].startDate),
            // `${new Date().getFullYear() - 1}-${("0" + new Date().getMonth()).slice(-2)}-01T00:00:00`
        )

        const dateSegments1 = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[0].startDate.split(/[-.]/);
        const dateSegments2 = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.props.userDataWI.partialPensionLimits.possibleRetirementStepList.length - 1].endDate.split(/[-.]/);
        // let day = dateSegments[0];
        let month1 = Number(dateSegments1[1]);
        let year1 = Number(dateSegments1[0]);
        console.log('month1', month1, year1, dateSegments1, dateSegments2);
        // if december
        if (month1 % 12 === 0) {
            month1 = 1;
            year1 += 1;
        } else {
            month1 += 1;
        }
        let month2 = Number(dateSegments2[1]);
        let year2 = Number(dateSegments2[0]);
        console.log('month2', month2, year2);
        // if december
        if (month2 % 12 === 0) {
            month2 = 1;
            year2 += 1;
        } else {
            month2 += 1;
        }

        const d1 = parseToApiDate(`01-${('0' + month1).slice(-2)}-${year1}`, "ddmmyyyy");
        const d2 = parseToApiDate(`01-${('0' + month2).slice(-2)}-${year2}`, "ddmmyyyy");
        console.log('d1', d1, 'd2', d2);

        return [d1, d2
            // this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[0].startDate,
            // this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.props.userDataWI.partialPensionLimits.possibleRetirementStepList.length - 1].endDate,
        ]
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar stagger preventStaggerOut'
                // style={{opacity: 0}}
                >
                  <div className='flex-grow-1'></div>
                    <div className='selects' style={{ flexGrow: 1.53 }}>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                </div>
                <div className="main calend-main">
                    <FormattedMessageCustom id={translatableStrings.when.id} text={this.props.intl.formatMessage(translatableStrings.when)}>
                        <div className="ich-mochte mb-40 stagger"></div>
                    </FormattedMessageCustom>
                    <Calendar
                        intl={this.props.intl}
                        second={true}
                        dateRange={this.getLimits()}
                        // this.props.userDataWI.retirementDateAt65
                        //     dateRange={[
                        //         parseToApiDate(
                        //             addYears(this.props.userDataWI.retirementDateAt65, -7)
                        //         ),
                        //     parseToApiDate(this.props.userDataWI.retirementDateAt65)
                        // ]}
                        setDateOfBirth={this.setDate}
                    />
                    <Button
                        className="stagger"
                        buttonType={EButtonType.BasicLink}
                        onClick={(e) => this.cancel(e)}>
                        <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                    </Button>
                </div>
                <div className='optional'>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataZeitpunkt: (data) => dispatch(actions.setUserDataZeitpunkt(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Zeitpunkt);
