import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
// import { bindActionCreators } from 'redux';
import { RootState } from '../../../../reducers/index';
import { parseDate } from '../../../../utils/react';
import './Block65plus.scss';
import gsap from 'gsap';
import { ChartSettings } from '../chartSettings';
import { animationConfig } from '../../../../constants';
// import { ChartSettings } from '../chartSettings';
// import Tooltip from 'rc-tooltip';
// import { formatNumber } from '../../../../utils/react';

interface State {
    // readyToShowDottedLine: boolean;
}

interface Props {
    // height: number;
    // width: number;
    ahvPension: number;
    animateCounter: number;
    basicPension: number;
    containerWidthPx: number;
    gender: string;
    maxVal: number;
    nominalRetirementDate: any;
    retirementSteps: any;
}

export class Block65plus extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        console.log("Block65plus constructor called");

        // this.state = {
        //     readyToShowDottedLine: false
        // };
    }

    private timeline: any = null;
    private entered: boolean = false;

    componentDidMount() {
        console.log('Block65plus app did mount', this.props, parseDate(this.props.retirementSteps[0], "dateObject"), parseDate(this.props.nominalRetirementDate, "dateObject"));
        window.addEventListener('animationFinished', this.animationFinished);
        // window.addEventListener('lastPartialPensionAnimated', this.animationFinished);
        // this.animationFinished(true);
    }

    componentWillUnmount() {
        window.removeEventListener('animationFinished', this.animationFinished);
        // window.removeEventListener('lastPartialPensionAnimated', this.animationFinished);
    }

    animationFinished = (initial?) => {
        console.log('animationFInished Block65plus initial', initial);
        setTimeout(() => {
            let height, width;
            if (this.props.gender === "weiblich") {
                width = this.props.containerWidthPx - ((document.querySelector('.gridYear[data-age="64"]') as any) ? (document.querySelector('.gridYear[data-age="64"]') as any).offsetLeft : 0) - 35;
            } else {
                width = this.props.containerWidthPx - ((document.querySelector('.gridYear[data-age="65"]') as any) ? (document.querySelector('.gridYear[data-age="65"]') as any).offsetLeft : 0) - 35;
            }
            height = this.props.basicPension;
            this.animateIn(width, height);
            this.entered = true;
        },
            // initial ? (this.props.retirementSteps.length > 1 ? 2500 : 1500) * animationConfig().scale : 
            300 * animationConfig().scale);
    }

    animateIn = (width, height) => {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        const availableHeight = document.querySelector('.chartRightTop') ? document.querySelector('.chartRightTop').clientHeight - 75 : 0;
        const realValueToPxRatio = availableHeight / (this.props.maxVal || 1);
        const maxHeight = height * realValueToPxRatio;

        console.log('animatein dotted line', width, height);

        this.timeline
            .fromTo(document.querySelector('#referenceTooltip'), {
                autoAlpha: 0
            }, { autoAlpha: 1 })
            .to(document.querySelector('.dottedLineHeight'),
                {
                    height: maxHeight - 7,
                    autoAlpha: 1,
                    duration: 0.1
                })
            .to(document.querySelector('.dottedLineWidth'),
                {
                    width: width + ChartSettings.blockPadding,
                    autoAlpha: 1,
                    duration: 0.1
                });

        this.timeline.play();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log('block65 plus did update');
        if (this.entered) {
            setTimeout(() => {
                let height, width;
                if (this.props.gender === "weiblich") {
                    width = this.props.containerWidthPx - ((document.querySelector('.gridYear[data-age="64"]') as any) ? (document.querySelector('.gridYear[data-age="64"]') as any).offsetLeft : 0) - 35;
                } else {
                    width = this.props.containerWidthPx - ((document.querySelector('.gridYear[data-age="65"]') as any) ? (document.querySelector('.gridYear[data-age="65"]') as any).offsetLeft : 0) - 35;
                };
                height = this.props.basicPension;
                this.animateIn(width, height);
            }, 300)
        };
        // setTimeout(() => {
        // const width = this.props.containerWidthPx - ((document.querySelector('.gridYear[data-age="65"]') as any) ? (document.querySelector('.gridYear[data-age="65"]') as any).offsetLeft : 0) - 35;
        // const height = (document.querySelector('#barPensionGovWrapper') as any) ? (document.querySelector('#barPensionGovWrapper') as any).clientHeight : 0;
        // }, 2500); 
    }

    shouldComponentUpdate(nextProps, nextState) {
        const animateCounterChanged = nextProps.animateCounter > this.props.animateCounter;
        console.log('shouldComponentUpdate block65 widthChanged');
        const basicPensionChanged = nextProps.basicPension !== this.props.basicPension;
        // console.log('shouldComponentUpdate Blocksalary widthChanged', widthChanged, 'animateCounterCHanged: ', this.props.animateCounter, nextProps.animateCounter, animateCounterChanged);
        if (animateCounterChanged || basicPensionChanged) {
            console.log('shouldComponentUpdate TRUE block65', this.props, nextProps);
            return true;
        } else {
            console.log('shouldComponentUpdate FALSE block65 plus');
            return false
        }
    }

    render() {
        return (
            <div
                className={classNames("blockPadding dottedLineWidth block65plus blockFullHeight", {})}>
                <div id="barPensionierungLabelWrapperPlus" className="blockLabelWrapper">
                    {
                        <div id="barPensionGuidePlus" className="horizontal">
                            <svg width="100%" height="5px">
                                <defs>
                                    <pattern id="polka-dots2" x="0" y="0" width="10px" height="100%" patternUnits="userSpaceOnUse">
                                        <circle fill="var(--blue-light)" cx="3" cy="3" r="1.5" />
                                    </pattern>
                                </defs>

                                <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots2)" />
                            </svg>
                        </div>
                    }
                </div>
                <div id="barPensionGovWrapperPlus"
                    className="blockWrapper dottedLineHeight"
                    style={{
                        height: 0,
                    }}>
                    {
                        <div
                            id="barPensionGuidePlus"
                            style={{
                                top: 0,
                            }}>
                            <svg width="5px" height="100%"
                                style={{
                                    position: "absolute"
                                }}>
                                <defs>
                                    <pattern id="polka-dots" x="0" y="0" height="10px" width="100%" patternUnits="userSpaceOnUse">
                                        <circle fill="var(--blue-light)" cx="3" cy="3" r="1.5" />
                                    </pattern>
                                </defs>
                                <rect x="0" y="0" width="100%" height="100%" fill="url(#polka-dots)" />
                            </svg>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Block65plus);