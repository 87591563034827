import * as React from 'react';
import classNames from 'classnames';
import { exists } from '../../../utils/variableEvaluation';
import { formatNumber } from '../../../utils/react';
import { EInputTextType } from '../index';
import styles from './InputText.module.scss'; // Import css modules stylesheet as styles

interface IProps {
    additionalClass?: string;
    autoFocus?: boolean;
    inputTextType?: EInputTextType;
    isDisabled?: boolean;
    isFocused: boolean;
    isLoading: boolean;
    max?: number;
    maxLength?: number;
    min?: number;
    name?: string;
    onBlur?: any;
    onFocus?: any;
    onChange?: (name: string, value: string) => void;
    onKeyDown?: any;
    placeholder: string;
    readOnly?: boolean;
    step?: string;
    type: string;
    value?: string[] | string | number;
}

interface IState {
    isFocused: boolean;
}


export class InputText extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            isFocused: false
        }
    }
    private inputRef: HTMLInputElement;
    private focusTimeoutId;

    public componentDidMount() {
        this.focusTimeoutId = setTimeout(() => {
            if (this.props.isFocused) {
                this.inputRef.focus();
            }
        }, 0);
    }

    public componentWillUnmount() {
        clearTimeout(this.focusTimeoutId);
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        console.log("handleChange", name, value);
        this.props.onChange && this.props.onChange(name, value);
    };

    private handleFocus = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("handleFocus");
        this.setState({ isFocused: true });
        this.props.onFocus && this.props.onFocus(e);
        // console.log("handleChange",e.currentTarget);
    };

    private handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log("handleBlur");
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;
        console.log(this.determineValue(value))
        if (this.props.type == "date") {
            this.setState({ isFocused: false }, () => {
                this.props.onChange(name, this.determineValue(value))
            });
        } else {
            this.setState({ isFocused: false });
        }
        this.props.onBlur && this.props.onBlur(e);
    };

    determineValue = (val) => {
        console.log('determinavalue', val);
        if (this.props.type === "date") {
            return this.formatDate(val)
        } else {
            if (this.state.isFocused) {
                // return formatNumber(val);
                return val;
            } else {
                if (this.props.type === "money" && !this.isFirefox()) {
                    return formatNumber(val, 2);
                    // } else if (this.props.type === "percentage") {
                    //     return val ? (val + "%") : "";
                } else {
                    return val;
                }
            }
        }
    }

    formatDate = (val) => {
        if (val == "") return val;
        var value = val;
        if (!value) return val;
        if (this.state.isFocused) {
            let parts = val.split(".");
            if (parts.length == 1) {
                if (val.length > 2) {
                    value = val[0] + val[1] + "." + val[2];
                }
            }
            if (parts.length == 2) {
                if (parts[0].length == 1 && parts[1].length == 0) {
                    value = "0" + parts[0] + ".";
                }
                if (parts[1].length == 3) {
                    value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[1][2];
                }
            }
            if (parts.length == 3) {
                if (parts[1].length == 1 && parts[2].length == 0) {
                    value = parts[0] + ".0" + parts[1] + "." + parts[2];
                }
                if (parts[1].length == 2) {
                    value = parts[0] + "." + parts[1][0] + parts[1][1] + "." + parts[2];
                }
            }
        } else {
            let parts = val.split(".");
            if (parts.length == 1) {
                if (val.length > 2) {
                    return val[0] + val[1] + "." + val[2];
                }
            }
            if (parts.length == 2) {
                if (parts[0].length == 1) {
                    return "0" + parts[0] + ".";
                }
                if (parts[1].length > 2) {
                    return parts[0] + "." + parts[1][0] + parts[1][1] + ".";
                }
            }
            if (parts.length == 3) {
                if (parts[2].length == 2) {
                    if (parseInt(parts[2]) < 45) {
                        value = parts[0] + "." + parts[1] + "." + "20" + parts[2];
                    } else {
                        value = parts[0] + "." + parts[1] + "." + "19" + parts[2];
                    }
                } else if (parts[2].length == 4) {
                    value = parts[0] + "." + parts[1] + "." + parts[2];
                }
            }
        }
        return value;
    }

    isFirefox = () => {
        let browser = navigator.userAgent.toLowerCase();
        if (browser.indexOf('firefox') > -1) {
            return true;
        };
        return false

    }

    isiOS = () => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    }

    public render() {
        const {
            name,
            value,
            placeholder,
            type,
            isFocused,
            isLoading,
            additionalClass,
            isDisabled,
            readOnly,
            min,
            max,
            step,
            maxLength,
            inputTextType,
            onKeyDown
        } = this.props;

        const inputClass = classNames(styles.inputText, {
            [additionalClass]: exists(additionalClass),
            [styles.hasValue]: exists(value),
            [styles.textStandard]: inputTextType === EInputTextType.Standard,
            [styles.textMaterial]: inputTextType === EInputTextType.Material
        });

        let typeMobileEnhanced;
        if (type === "money") {
            if (this.isFirefox() && window.innerWidth < 897) {
                typeMobileEnhanced = "number"
            } else {
                if (window.innerWidth < 897) {
                    typeMobileEnhanced = this.state.isFocused ? "number" : "text";
                } else {
                    typeMobileEnhanced = this.state.isFocused ? "text" : "text";
                }
            }
        } else if (type === "number") {
            if (window.innerWidth < 897) {
                typeMobileEnhanced = "number"
            } else {
                typeMobileEnhanced = "text"
            }
        } else {
            typeMobileEnhanced = type;
        }

        return (
            <input
                name={name}
                type={typeMobileEnhanced}
                min={min}
                max={max}
                step={step}
                inputMode={typeMobileEnhanced === "number" ? "decimal" : "text"}
                placeholder={placeholder}
                value={this.determineValue(value)}
                maxLength={maxLength || 255}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                readOnly={readOnly}
                onChange={this.handleChange}
                className={inputClass}
                ref={input => isFocused && (this.inputRef = input)}
                autoFocus={isFocused}
                disabled={isDisabled || isLoading}
                autoComplete="off"
                onKeyDown={onKeyDown}
            />
        );
    }
}
