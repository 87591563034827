import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import Modal from 'react-modal';
import { Input } from 'icr-shared-components';
import doc from '../../../assets/imgs/sparguthaben-2.png';
import { Button, EButtonType } from "../../../components/Button";
import SelectList from "../../../components/Sidebar/SelectList";
import close from "../../../assets/icons/Close_Icon.svg";
import gsap from 'gsap';
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { isNumericOrDot } from '../../../utils/variableEvaluation';
import {ExamplePictures} from "../../../reducers/app";
import classNames from "classnames";

const translatableStrings = defineMessages({
    example: {
        id: 'pageBeschaftigungsgrad.example',
        defaultMessage: 'Beispiel'
    },
    exampleOfInsurance: {
        id: 'pageBeschaftigungsgrad.exampleOfInsurance',
        defaultMessage: 'Beispiel Versicherungsausweis'
    },
    whereToFindCapital: {
        id: 'pageSparguthaben.whereToFindCapital',
        defaultMessage: 'WO FINDE ICH MEIN ALTERSGUTHABEN / AUSTRITTSLEISTUNG'
    },
    whatIsYourPlan: {
        id: 'pagePlan.whatIsYourPlan',
        defaultMessage: 'Mit welchem Plan sind Sie versichert?'
    },
    enterPlan: {
        id: 'pagePlan.enterPlan',
        defaultMessage: 'Plan wählen'
    }
})

interface State {
    value: any;
    modalIsOpen: boolean;
}

interface Props {
    actions: any;
    history: any;
    setUserDataInsurancePlan: any;
    userDataWI: any;
    intl: any;
    hasMultiplePlans: any;
    insurancePlans: any;
    examplePicturesSet: ExamplePictures;
}

class Plan extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            modalIsOpen: false,
            value: this.props.userDataWI.insurancePlan ? this.props.userDataWI.insurancePlan : "",
        };
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        // if (window.innerWidth > 896) {
        // setTimeout(() => {
            // (document.querySelector('.main input') as any) && (document.querySelector('.main input') as any).focus();
        // }, 1200);
        // }
    }

    preparePlanOptions = (plans) => {
        return plans.map((plan) => ({
                label: plan.planName,
                value: plan.planCode
            }));
    };

    async setUserDataInsurancePlan(data) {
        await this.props.setUserDataInsurancePlan(data);
    }

    async next(e) {
        // let parts = e.toString().split(".");
        // parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, "'");
        // await this.setUserDataSparguthaben(parts.join("."));
        await this.setUserDataInsurancePlan(e);

        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();


        if (document.querySelectorAll('.crossFade').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                    autoAlpha: 0
                })
        }

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {

            this.props.history.push({
                pathname: `/ahvrente`
                // pathname: `/vpkonto`
            })
        }, 1200)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    handleChange(name, value) {
        // const value = e.currentTarget.value
        // .replace(/[^.\d]+/g, "").replace(/^([^\.]*\.)|\./g, '$1');
        this.setState({
            value: value
        });
    };

    showHideNotif = () => {
        if (this.state.modalIsOpen && window.innerWidth < 897) {
            this.setState({
                modalIsOpen: false
            })
        } else {
            this.setState({
                modalIsOpen: true
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    enter(event) {
        if (event.keyCode === 13) {
            this.next(event.currentTarget.value)
        }
    }

    render() {
        const planOptions = this.preparePlanOptions(this.props.insurancePlans);
        const planValue = planOptions.find(option => option.value == this.state.value)?.label || this.state.value;
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                  <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
                </div>
                <div className="main sparg-main">
                    <FormattedMessageCustom id={translatableStrings.whatIsYourPlan.id} text={this.props.intl.formatMessage(translatableStrings.whatIsYourPlan)}>
                        <div className='ich-mochte mb-40 stagger'>

                        </div>
                    </FormattedMessageCustom>
                    {/* <img alt="logo" src={HelpIcon} className='ques_mobile' /> */}
                    <div className='lohnsumme stagger'>
                        <div className='lohnsumme__main'>
                            <Input
                                selectOptions={planOptions}
                                value={planValue}
                                placeholder={this.props.intl.formatMessage(translatableStrings.enterPlan)}
                                inputWrapperClass="w-100"
                                onChange={(name, val) => this.handleChange(name, val)}
                                onSelected={(name, val) => this.handleChange(name, val)}
                                // onKeyDown={(event) => this.enter(event)}
                                type='text'
                                // validationMessageConfig={{}}
                                inputWrapperStyle={{ marginTop: 0, height: "50px", minHeight: "50px" }}>
                            </Input>
                        </div>
                        <div className='lohnsumme__button'>
                            <Button
                                className="button"
                                submit={true}
                                buttonType={EButtonType.FullWidth}
                                onClick={() => this.next(this.state.value)}
                                // Changed by Glauk, necessary?
                                isDisabled={!this.state.value}
                            >
                                <div>WEITER</div>
                            </Button>
                        </div>
                    </div>
                    <FormattedMessageCustom id={translatableStrings.whereToFindCapital.id} text={this.props.intl.formatMessage(translatableStrings.whereToFindCapital)}>
                        <div className={classNames("inform stagger", {"d-none": !this.props.examplePicturesSet.insurancePlanPictureUrl})} onClick={this.showHideNotif}>
                        </div>
                    </FormattedMessageCustom>
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.showHideNotif}
                        // contentLabel="Large Modal"
                        className={"mobile-modal"}
                        closeTimeoutMS={600}
                        overlayClassName={"large-modal-overlay"}>
                        {this.state.modalIsOpen && <div id='beispiel' className='beispiel' onClick={this.showHideNotif}>
                            <div className="color">
                                <img alt="doc" src={close} className='close' />
                                <div>
                                    <img alt="doc" src={this.props.examplePicturesSet.insurancePlanPictureUrl} className='document' />
                                    <FormattedMessageCustom id={translatableStrings.exampleOfInsurance.id} text={this.props.intl.formatMessage(translatableStrings.exampleOfInsurance)}>
                                        <div></div>
                                    </FormattedMessageCustom>
                                </div>
                            </div>
                        </div>
                        }
                    </Modal>
                </div>
                <div className='optional crossFade'>
                    {this.props.examplePicturesSet.insurancePlanPictureUrl &&
                    <div className='doc'>
                        <img alt="doc" src={this.props.examplePicturesSet.insurancePlanPictureUrl} />
                        <FormattedMessageCustom id={translatableStrings.example.id} text={this.props.intl.formatMessage(translatableStrings.example)}>
                            <p></p>
                        </FormattedMessageCustom>
                    </div>}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataInsurancePlan: (data) => dispatch(actions.setUserDataInsurancePlan(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Plan);
