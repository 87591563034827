import React from 'react';
import classNames from 'classnames';
import Slider from '../../../../components/Slider/index'
import { parseDate, parseToApiDate, addToLSObject, formatNumber, isDate } from '../../../../utils/react';
import { defined } from '../../../../utils/variableEvaluation';
// import { postExample } from "../../../../constants/payloads";
import { Button, EButtonType } from "../../../../components/Button";
import { ReactComponent as HelpIcon } from "../../../../assets/icons/Help Icon.svg";
import Tooltip from 'rc-tooltip';
import PKlogo from '../../../../assets/imgs/pkar_logo.png';
import logo from '../../../../assets/icons/logo.png';
import { ReactComponent as IconTrash } from '../../../../assets/icons/trash.svg';
import { ReactComponent as IconPlus } from '../../../../assets/icons/plus.svg';
import './ChartControls.scss';
import { removeInArrayByIdx, checkIfArrayIsUnique } from '../../../../utils/array';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    capitalWithdrawal: {
        id: 'pageChart.capitalWithdrawal',
        defaultMessage: 'Kapitalbezug'
    },
    capitalWithdrawalTooltip: {
        id: 'pageChart.capitalWithdrawalTooltip',
        defaultMessage: 'Anstelle einer Rente können Sie das angesparte Kapital beziehen. In diesem Fall erhalten Sie bei der Pensionierung eine einmalige Auszahlung des Kapitals. Sie haben die Möglichkeit einen Teil der Altersleistungen als Rente und einen Teil als Kapital zu beziehen. Beachten Sie, dass durch den Bezug von Kapital die Rente vermindert wird oder wegfällt. Allfällige Beschränkungen sind im Simulator berücksichtigt. '
    },
    allBuyInsRemoved: {
        id: 'pageChart.allBuyInsRemoved',
        defaultMessage: 'Sie haben alle Kapitaleinkäufe gelöscht'
    },
    partialPensionRetirement: {
        id: 'pageChart.partialPensionRetirement',
        defaultMessage: 'Teilpensionierung'
    },
    fullPensionRetirement: {
        id: 'pageChart.fullPensionRetirement',
        defaultMessage: 'Vollpensionierung'
    },
    forPartialPension: {
        id: 'pageChart.forPartialPension',
        defaultMessage: 'Per {partialPensionDate} Teilpensionierung {partialPensionNo}%'
    },
    howMuchWithdraw: {
        id: 'pageChart.howMuchWithdraw',
        defaultMessage: 'Wie viel Kapital wollen Sie beziehen?',
    },
    capitalBuyInInvalidExplanation: {
        id: 'pageChart.capitalBuyInInvalidExplanation',
        defaultMessage: 'Spätestes Kapitaleinkaufsdatum ist der Zeitpunkt der vollen Pensionierung. Dieser Kapitaleinkauf wird daher für die Berechnung nicht berücksichtigt. Ändern Sie das Datum es Einkaufs oder den Zeitpunkt der vollen Pensionierung.'
    },
    ready: {
        id: 'button.ready',
        defaultMessage: 'FERTIG',
    },
    back: {
        id: 'button.back',
        defaultMessage: 'Zurück',
    },
    cancel: {
        id: 'button.cancel',
        defaultMessage: 'ABBRECHEN',
    },
    change: {
        id: 'button.change',
        defaultMessage: 'BEARBEITEN',
    },
    goBack: {
        id: 'button.goBack',
        defaultMessage: 'Zurücksetzen'
    },
    partialPension: {
        id: 'pageChart.partialPension',
        defaultMessage: 'Teilpension'
    },
    bridgingPension: {
        id: 'pageChart.bridgingPension',
        defaultMessage: 'Überbrückungsrente'
    },
    bridgingPensionTooltip: {
        id: 'pageChart.bridgingPensionTooltip',
        defaultMessage: 'Im Falle einer Frühpensionierung können Sie mit einer Überbrückungsrente die bis zur ordentlichen Pensionierung noch nicht verfügbare AHV-Rente kompensieren. Beachten Sie, dass der Bezug einer Überbrückungsrente die Höhe der Rente nach der ordentlichen Pensionierung beeinflusst.'
    },
    hide: {
        id: 'pageChart.hide',
        defaultMessage: 'HIDE'
    },
    showAll: {
        id: 'pageChart.showAll',
        defaultMessage: 'ALLE ANZEIGEN'
    },
    addCapitalWithdrawal: {
        id: 'pageChart.addCapitalWithdrawal',
        defaultMessage: 'Kapitalbezug hinzufügen'
    },
    addCapitalBuyIn: {
        id: 'pageChart.addCapitalBuyIn',
        defaultMessage: 'Kapitaleinkauf hinzufügen'
    },
    moreWithdrawals: {
        id: 'pageChart.moreWithdrawals',
        defaultMessage: 'weiterer Kapitalbezug'
    },
    moreBuyIns: {
        id: 'pageChart.moreBuyIns',
        defaultMessage: 'weiterer Kapitaleinkauf'
    },
    capitalBuyIn: {
        id: 'pageChart.capitalBuyIn',
        defaultMessage: 'Kapitaleinkauf'
    },
    capitalBuyInTooltip: {
        id: 'pageChart.capitalBuyInTooltip',
        defaultMessage: 'Der Kapitaleinkauf ist eine freiwillige Einzahlung in die Pensionskasse. Dadurch lassen sich einerseits die Altersleistungen erhöhen und andrerseits Steuern sparen, da der Einzahlungsbetrag von den Steuern abziehbar ist. Ein Kapitaleinkauf untersteht gewissen Beschränkungen, welche hier im Simulator automatisch berücksichtigt werden.'
    },
    whenDeposit: {
        id: 'pageChart.whenDeposit',
        defaultMessage: 'Wann möchten Sie einzahlen und wie hoch ist der Einkaufsbetrag?'
    },
    capitalBuyInNotPossible: {
        id: 'pageChart.capitalBuyInNotPossible',
        defaultMessage: 'An diesem Datum ist kein Einkauf möglich.'
    },
    wrongDateForCapitalBuyIn: {
        id: 'pageChart.wrongDateForCapitalBuyIn',
        defaultMessage: 'Ungültiges Datum für den Kapitaleinkauf'
    },
    date: {
        id: 'general.date',
        defaultMessage: 'TT.MM.JJJJ'
    },
    variables: {
        id: 'pageChart.variables',
        defaultMessage: 'Variable Werte'
    },
    capitalEditOrRemove: {
        id: 'pageChart.capitalEditOrRemove',
        defaultMessage: 'Kapitaleinkäufe bearbeiten oder löschen.'
    },
    capitalOverview: {
        id: 'pageChart.capitalOverview',
        defaultMessage: 'Übersicht Kapitaleinkäufe'
    },
    capitalWithdrawalOverview: {
        id: 'pageChart.capitalWithdrawalOverview',
        defaultMessage: 'Capital Withdrawals overview'
    },
    teilpensionNotPossible: {
        id: 'pageChart.teilpensionNotPossible',
        defaultMessage: 'Es ist maximal ein Teilpensionierungsschritt möglich.'
    },
    teilpensionNotPossiblePKAR: {
        id: 'pageChart.teilpensionNotPossiblePKAR',
        defaultMessage: 'Es kann aus technischen Gründen nur ein Teilpensionierungsschritt erfasst werden.'
    }
});
interface State {
    capitalPurchaseDateInvalid: Array<boolean>;
    capitalPurchaseDateInvalidFromDrag: boolean;
    capitalPurchaseDates: Array<string>;
    capitalPurchaseDatesCandidate: Array<string>;
    capitalPurchaseRemoveCandidate: Array<number>;
    resetCounter: number;
    showAll: boolean;
    showAllWithdrawals: boolean;
    sliderValueBridgingPension: number;
    sliderValuesPurchase: Array<any>;
    sliderValuesPurchaseCandidate: Array<any>;
    sliderValuesWithdrawal: Array<any>;
    sliderValuesWithdrawalCandidate: Array<any>;
}

interface Props {
    // activeSegment: number;
    // getSegmentIdx: any;
    // sliderValues: any;
    // sliderValuesPurchase: any;
    bridgingPensionAmountMax: number;
    bridgingPensionAmountMin: number
    calculateCounter: number;
    calculateCounterTrigger: number;
    calculateDebounced: any;
    calculateMaxPurchase: any;
    calculationSteps: any;
    calculations: any;
    capitalWidthdrawalAmountMax: number;
    capitalWidthdrawalAmountMin: number;
    capitalWithdrawalPercentageMax: number;
    clearControls: any;
    closeModal: any;
    goToRenten: any;
    lastCalculatePayload: any;
    intl: any;
    isEarlyPensionPresent: boolean;
    mobileModal: boolean;
    modalContent: string;
    modalIsOpen: boolean;
    monthlyOrYearly: string;
    openModal: any;
    removeCapitalPurchase: any;
    renderModalWithChildren: any;
    removeTeilpension: any;
    retirementSteps: any;
    setBridgingPension: any;
    setCapitalPurchase: any;
    setCapitalWithdrawal: any;
    userDataWI: any;
    wrapInTooltip: any;
}



// const initialSliderValuesPurchase = [];
// const initialSliderValuesWithdrawal = [];

// Modal.setAppElement('#root');

export default class ChartControls extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        // let lsUserData;
        // try {
        //     lsUserData = localStorage.getItem("userDataWI") ? JSON.parse((window as any).localStorage.getItem("userDataWI")) : null;
        // } catch (error) {
        //     console.warn('LS problem', error)
        // }
        // console.log("ChartControls constructor called", this.props.userDataWI);

        let capitalPurchaseDates = [];
        let sliderValuesWithdrawal = [];
        let sliderValuesPurchase = [];
        Object.keys(this.props.userDataWI.maxPurchases).forEach((key) => {
            const date = parseDate(key, "secsToDDMMYYYY");
            capitalPurchaseDates.push(date);
        });

        Object.keys(this.props.userDataWI.capitalWithdrawals).filter(key => key !== 'number').forEach((key) => {
            const amount = this.props.userDataWI.capitalWithdrawals[key]['amount'];
            console.log('amount', amount)
            sliderValuesWithdrawal.push(amount);
        });

        Object.keys(this.props.userDataWI.capitalPurchases).forEach((key) => {
            const amount = this.props.userDataWI.capitalPurchases[key]['amount'];
            sliderValuesPurchase.push(amount);
        });


        this.state = {
            showAll: false,
            showAllWithdrawals: false,
            capitalPurchaseDateInvalid: [false],
            capitalPurchaseDateInvalidFromDrag: false,
            capitalPurchaseDates: capitalPurchaseDates,
            capitalPurchaseDatesCandidate: capitalPurchaseDates,
            capitalPurchaseRemoveCandidate: [],
            sliderValueBridgingPension: this.props.userDataWI.bridgingPension,
            resetCounter: 0,
            sliderValuesWithdrawal: sliderValuesWithdrawal,
            sliderValuesWithdrawalCandidate: sliderValuesWithdrawal,
            sliderValuesPurchase: sliderValuesPurchase,
            sliderValuesPurchaseCandidate: sliderValuesPurchase,
        };
    }
    private updateTimeout: any = null;
    private recalculatedMaxPurchasesAt: number = 0;
    private capitalPurchaseDateInvalid: Array<boolean> = [false];

    componentDidMount() {
        // console.log('ChartControls app did mount', this.props);
        this.checkCapitalBuyInDates();
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("ChartControls componenentDidUpdate", prevProps, prevState, snapshot);
        // this.animateIn();
        // Object.entries(this.props).forEach(([key, val]) =>
        // prevProps[key] !== val && console.log(`Prop ChartControls'${key}' changed`)
        // );

        if (prevProps.monthlyOrYearly !== this.props.monthlyOrYearly) {
            this.setState({
                sliderValueBridgingPension: this.props.monthlyOrYearly === "annualIncome" ? this.props.userDataWI.bridgingPension : this.props.userDataWI.bridgingPension / 12
            });
        }

        if (Object.keys(prevProps.userDataWI.capitalWithdrawals).length !== Object.keys(this.props.userDataWI.capitalWithdrawals).length) {
            let sliderValuesWithdrawal = [];
            Object.keys(this.props.userDataWI.capitalWithdrawals).forEach((key) => {
                const amount = this.props.userDataWI.capitalWithdrawals[key]['amount'];
                console.log('amount', amount)
                sliderValuesWithdrawal.push(amount);
            });

            this.setState({
                sliderValuesWithdrawal: sliderValuesWithdrawal,
                sliderValuesWithdrawalCandidate: sliderValuesWithdrawal,
            });
        }

        if (Object.keys(prevProps.userDataWI.capitalPurchases).length !== Object.keys(this.props.userDataWI.capitalPurchases).length) {
            let sliderValuesPurchase = [];
            Object.keys(this.props.userDataWI.capitalPurchases).forEach((key) => {
                const amount = this.props.userDataWI.capitalPurchases[key]['amount'];
                console.log('amount', amount)
                sliderValuesPurchase.push(amount);
            });

            this.setState({
                sliderValuesPurchase: sliderValuesPurchase,
                sliderValuesPurchaseCandidate: sliderValuesPurchase
            });
        }

        if (prevProps.userDataWI.RetirementSteps !== this.props.userDataWI.RetirementSteps) {
            console.log('RetirementSteps changed', prevProps.userDataWI.RetirementSteps, this.props.userDataWI.RetirementSteps);
            this.checkCapitalBuyInDates();
        }

        // console.log('chartcontrols didupdate',
        //     'recalculatedMaxPurchasesAt', this.recalculatedMaxPurchasesAt,
        //     'this.props.calculateCounter', this.props.calculateCounter,
        //     'prevProps.calculateCounter', prevProps.calculateCounter
        // );

        // if (prevState.capitalPurchaseDateInvalid.includes(true) && !this.state.capitalPurchaseDateInvalid.includes(true)) {
        if (prevState.capitalPurchaseDateInvalid.includes(true) && !this.state.capitalPurchaseDateInvalid.includes(true)) {
            this.recalculatedMaxPurchasesAt = this.props.calculateCounter;
        }

        if (this.props.calculateCounter > this.recalculatedMaxPurchasesAt) {
            Object.keys(this.props.userDataWI.capitalPurchases).forEach((key, idx) => {
                this.calculateCapitalPurchase({
                    target: {
                        value: this.props.userDataWI.capitalPurchases[key]['date']
                    }
                }, idx);
            });
            this.recalculatedMaxPurchasesAt = this.props.calculateCounter;
        }

        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State ChartControls'${key}' changed`)
        //     );
        // }
    }

    checkCapitalBuyInDates = () => {
        console.log('checkCapitalBuyInDates', this.props.userDataWI.RetirementSteps)
        // let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
        let newCapitalPurchaseDateInvalid = [...this.capitalPurchaseDateInvalid];
        let capitalPurchaseDateInvalidFromDrag = false;
        this.props.userDataWI.RetirementSteps.forEach((stepDate, idx) => {
            Object.keys(this.props.userDataWI.capitalPurchases).forEach((key, idx2) => {
                const purchaseDateTime = new Date(this.props.userDataWI.capitalPurchases[key]['date']).getTime();
                const stepDateTime = new Date(stepDate).getTime();
                console.log('purchaseDateTime', purchaseDateTime,
                    'stepDateTime', stepDateTime,
                    'this.props.userDataWI.capitalPurchases[key][date]', this.props.userDataWI.capitalPurchases[key]['date'])

                if (stepDateTime < purchaseDateTime) {
                    console.log('invalid idx2', idx2)
                    newCapitalPurchaseDateInvalid[idx2] = true;
                    capitalPurchaseDateInvalidFromDrag = true;
                } else {
                    console.log('valid', idx2)
                    newCapitalPurchaseDateInvalid[idx2] = false;
                    capitalPurchaseDateInvalidFromDrag = false;
                }

                console.log('will set state, newCapitalPurchaseDateInvalid', newCapitalPurchaseDateInvalid);
            })
        });

        this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
        this.setState({
            capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid],
            capitalPurchaseDateInvalidFromDrag
        });
    }

    resetControls = () => {
        console.log('resetControls2');
        this.capitalPurchaseDateInvalid = [false];
        this.setState({
            sliderValuesWithdrawal: [0],
            sliderValuesWithdrawalCandidate: [0],
            sliderValueBridgingPension: 0,
            sliderValuesPurchase: [0],
            sliderValuesPurchaseCandidate: [0],
            capitalPurchaseDateInvalidFromDrag: false,
            resetCounter: this.state.resetCounter + 1,
            capitalPurchaseDates: [""],
            capitalPurchaseDatesCandidate: [""],
            capitalPurchaseDateInvalid: [false]
        }, () => {
            this.props.userDataWI.RetirementSteps.forEach((date, idx) => {
                idx !== 0 && this.props.removeTeilpension(date, idx);
            })
            this.props.clearControls();
            // this.props.calculateDebounced();
        })
    }

    renderModal = (modalContent) => {
        console.log('rendermodal', modalContent);
        return (
            <div className={`modal`}>
                {this.props.userDataWI.tenantName?.toUpperCase() === "PKAR" ?
                    <img alt="logo" src={PKlogo} className='modalLogo' /> :
                    <img alt="logo" src={logo} className='modalLogo' />}
                {this.renderModalContent(modalContent)}
            </div>
        )
    }

    renderModalContent(modalContent) {
        console.log('modalContent', modalContent);
        if (modalContent === "capitalwithdrawal") {
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.howMuchWithdraw.id} text={this.props.intl.formatMessage(translatableStrings.howMuchWithdraw)}>
                        <div className="modal-header withdrawal add">
                        </div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal`}>
                        <div className="row">
                            {this.props.calculations.length && [...this.props.calculations.filter((stepObj) => Boolean(stepObj.linkedRetirementStep))].map((stepObj, idx) => {
                                return (
                                    <div className="col-12 mb-4">
                                        <FormattedMessageCustom id={translatableStrings.forPartialPension.id} text={this.props.intl.formatMessage(translatableStrings.forPartialPension, {
                                            partialPensionDate: parseDate(stepObj.linkedRetirementStep.retirementDate, "secsToDDMMYYYY"),
                                            partialPensionNo: stepObj.linkedRetirementStep.retirementPercentage
                                        })}>
                                            <div className="sliderLikeLabel">
                                            </div>
                                        </FormattedMessageCustom>
                                        <Slider
                                            controlled
                                            intl={this.props.intl}
                                            // tooltip
                                            // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalTooltip)}
                                            translateId={translatableStrings.capitalWithdrawal.id}
                                            label={this.props.intl.formatMessage(translatableStrings.capitalWithdrawal)}
                                            showPercInput
                                            maxVal={stepObj.limitSet.maximumLimit.capitalWithdrawalAmount}
                                            maxPercentage={stepObj.limitSet.maximumLimit.capitalWithdrawalPercentage}
                                            // activeSegment={0}
                                            minVal={stepObj.limitSet.minimumLimit.capitalWithdrawalAmount}
                                            initialValue={this.state.sliderValuesWithdrawalCandidate[idx]}
                                            forcedValue={this.state.sliderValuesWithdrawalCandidate[idx]}
                                            className={classNames("middle capitalWithdrawalSlider", {
                                                "inactive": this.props.capitalWidthdrawalAmountMax === 0
                                            })}
                                            domain={[stepObj.limitSet.minimumLimit.capitalWithdrawalAmount, stepObj.limitSet.maximumLimit.capitalWithdrawalAmount]}
                                            sliderValueChanged={(val, initial) => {
                                                const newSliderValues = [...this.state.sliderValuesWithdrawalCandidate];
                                                newSliderValues[idx] = val;
                                                console.log('newSliderValues1', newSliderValues)
                                                this.setState({
                                                    sliderValuesWithdrawalCandidate: newSliderValues,
                                                })
                                            }}
                                            resetCounter={this.state.resetCounter}
                                        />
                                    </div>
                                )
                            })}
                            <div className="modal-buttons">
                                <Button
                                    className="button text-center mx-auto"
                                    submit={true}
                                    onClick={() => {
                                        let sliderValuesWithdrawalCandidate = [...this.state.sliderValuesWithdrawalCandidate];
                                        // const cleanValues = sliderValuesWithdrawalCandidate.filter((el) => {
                                        //     return defined(el)
                                        // });
                                        [...this.props.calculationSteps].reverse().forEach((stepObj, idx) => {
                                            console.log('idx', idx, 'calculationSteps', stepObj);
                                            // if (stepObj.limitSet.maximumLimit.capitalWithdrawalAmount > 0) {
                                            console.log('limitPositive', idx, sliderValuesWithdrawalCandidate);
                                            // defined(sliderValuesWithdrawalCandidate[idx]) && 
                                            this.props.setCapitalWithdrawal({
                                                "amount": defined(sliderValuesWithdrawalCandidate[idx]) ? sliderValuesWithdrawalCandidate[idx] : 0,
                                                "index": idx,
                                                "percentage": defined(sliderValuesWithdrawalCandidate[idx]) ? (sliderValuesWithdrawalCandidate[idx] / stepObj.limitSet.maximumLimit.capitalWithdrawalAmount * 100).toFixed(2) : 0
                                            });
                                            // }
                                        });
                                        this.setState({
                                            sliderValuesWithdrawal: sliderValuesWithdrawalCandidate,
                                            // sliderValuesWithdrawalCandidate: cleanValues,
                                        })
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                                </Button>

                                <Button
                                    className="button"
                                    onClick={() => {

                                        this.setState({
                                            sliderValuesWithdrawalCandidate: [...this.state.sliderValuesWithdrawal]
                                        })
                                        this.props.closeModal()
                                    }
                                    }
                                    buttonType={EButtonType.BasicLink}
                                >
                                    <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "capitalbuyin") {
            const nextIdx = Object.keys(this.props.userDataWI.capitalPurchases).length;
            console.log('nextIdx', nextIdx);
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.whenDeposit.id} text={this.props.intl.formatMessage(translatableStrings.whenDeposit)}>
                        <div className="modal-header  buyin"></div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            <div className="col-12">
                                <div className="sliderLikeLabel d-flex flex-row mb-4">
                                    <div className="d-flex flex-row w-100 flex-wrap mb-0">
                                        {Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {
                                            return (
                                                [<div className={classNames("d-flex w-100 flex-wrap justify-content-between mb-4", {
                                                    // "mt-md-5": idx > 0,
                                                    // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    "invalid": this.capitalPurchaseDateInvalid[idx]
                                                })}>
                                                    <div className="mr-1" style={{}}>{idx + 1}. <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)} /></div>
                                                    <div className="capitalBuyInText" style={{ minWidth: 100 }}>({parseDate(this.props.userDataWI.capitalPurchases[key]['date'], "secsToDDMMYYYY")})</div>
                                                    <div className="" style={{ minWidth: 82 }}>
                                                        CHF {formatNumber(this.props.userDataWI.capitalPurchases[key]['amount'], 0)}
                                                    </div>
                                                </div>,
                                                // this.state.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate">
                                                this.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate">
                                                    {this.state.capitalPurchaseDateInvalidFromDrag ?
                                                        <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                                        </FormattedMessageCustom>
                                                        : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                                        </FormattedMessageCustom>
                                                    }
                                                    <Tooltip
                                                        mouseEnterDelay={0}
                                                        mouseLeaveDelay={0}
                                                        destroyTooltipOnHide={true}
                                                        placement={'top'}
                                                        trigger={'click'}
                                                        overlay={
                                                            <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                            </FormattedMessageCustom>
                                                        }
                                                    >
                                                        <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                    </Tooltip>
                                                </p> : null
                                                ]

                                            )
                                        })}
                                    </div>
                                </div>
                                <Slider
                                    intl={this.props.intl}
                                    controlled
                                    // tooltip
                                    // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                                    translateId={translatableStrings.capitalBuyIn.id}
                                    label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}
                                    // activeSegment={nextIdx}
                                    maxVal={this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[nextIdx])}
                                    minVal={0}
                                    className={classNames("capitalBuyInSlider", {
                                        // "inactive": this.state.capitalPurchaseDates.indexOf(this.stacapitalBuyInSliderte.capitalPurchaseDatesCandidate[nextIdx]) >= 0 || Object.keys(this.props.userDataWI.maxPurchases).length === nextIdx
                                        "inactive": !checkIfArrayIsUnique(this.state.capitalPurchaseDatesCandidate)
                                    })}
                                    domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[nextIdx])]}
                                    initialValue={this.state.sliderValuesPurchaseCandidate[nextIdx]}
                                    forcedValue={this.state.sliderValuesPurchaseCandidate[nextIdx]}
                                    sliderValueChanged={(val, initial) => {
                                        const newSliderValues = [...this.state.sliderValuesPurchaseCandidate];
                                        newSliderValues[nextIdx] = val;
                                        this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;

                                        this.setState({
                                            sliderValuesPurchaseCandidate: newSliderValues,
                                        }, () => {
                                        })
                                    }}
                                    resetCounter={this.state.resetCounter}
                                >

                                    <div className="sliderDateInput">
                                        {/* <IconCalendar style={{
                                            position: 'absolute',
                                            right: '0px',
                                            bottom: '7px'
                                        }} /> */}
                                        <input
                                            data-translate={translatableStrings.date.id}
                                            placeholder={this.props.intl.formatMessage(translatableStrings.date)}
                                            type="text"
                                            pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"
                                            onBlur={(e) => this.calculateCapitalPurchase(e, nextIdx)}
                                            onChange={(e) => {
                                                console.log('inputChange capitalPurchaseDateInvalid', this.capitalPurchaseDateInvalid)
                                                const newDate = e.target.value;
                                                let newCapitalPurchaseDates = [...this.state.capitalPurchaseDatesCandidate];
                                                newCapitalPurchaseDates[nextIdx] = newDate;
                                                let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
                                                newCapitalPurchaseDateInvalid[nextIdx] = false;
                                                this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                                                this.setState({
                                                    capitalPurchaseDatesCandidate: [...newCapitalPurchaseDates],
                                                    capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]

                                                });
                                            }}
                                            value={this.state.capitalPurchaseDatesCandidate[nextIdx]}
                                            className={classNames("slider_val text-left", {
                                                // "invalid": this.state.capitalPurchaseDateInvalid[nextIdx]
                                                "invalid": this.capitalPurchaseDateInvalid[nextIdx]
                                            })}
                                        />
                                    </div>

                                </Slider>
                                {/* {this.state.capitalPurchaseDateInvalid[nextIdx] && */}
                                {this.capitalPurchaseDateInvalid[nextIdx] &&
                                    <p className="invalidPurchaseDate">
                                        {this.state.capitalPurchaseDateInvalidFromDrag ?
                                            <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                            </FormattedMessageCustom>
                                            : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                            </FormattedMessageCustom>
                                        }
                                        <Tooltip
                                            mouseEnterDelay={0}
                                            mouseLeaveDelay={0}
                                            destroyTooltipOnHide={true}
                                            placement={'top'}
                                            trigger={'click'}
                                            overlay={
                                                <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                </FormattedMessageCustom>
                                            }
                                        >
                                            <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                        </Tooltip>
                                    </p>
                                }

                            </div>
                            <div className="modal-buttons">
                                <Button
                                    className="button text-center mx-auto"
                                    submit={true}
                                    onClick={() => {
                                        const idx = this.state.sliderValuesPurchaseCandidate.length - 1;
                                        console.log('idx', idx, this.state.sliderValuesPurchaseCandidate);
                                        defined(this.state.sliderValuesPurchaseCandidate[idx]) && this.props.setCapitalPurchase({
                                            "amount": this.state.sliderValuesPurchaseCandidate[idx] || 0,
                                            "index": idx,
                                            "date": this.state.capitalPurchaseDatesCandidate[idx]
                                        });
                                        addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchaseCandidate);

                                        this.setState({
                                            capitalPurchaseDates: [...this.state.capitalPurchaseDatesCandidate],
                                            sliderValuesPurchase: [...this.state.sliderValuesPurchaseCandidate],
                                        })
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                                </Button>

                                <Button
                                    className="button"
                                    onClick={() => {
                                        this.setState({
                                            capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],
                                            sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],
                                        });
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.BasicLink}
                                >
                                    <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "capitalbuyinedit") {
            console.log('modalContent', modalContent);
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.capitalEditOrRemove.id} text={this.props.intl.formatMessage(translatableStrings.capitalEditOrRemove)}>
                        <div className="modal-header"></div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal buyinedit`}>
                        <div className="row">
                            {Object.keys(this.state.sliderValuesPurchaseCandidate).length ? Object.keys(this.state.sliderValuesPurchaseCandidate).map((key, idx) => {
                                console.log('this.state.capitalPurchaseDates.indexOf(this.state.capitalPurchaseDatesCandidate[idx])', this.state.capitalPurchaseDates.indexOf(this.state.capitalPurchaseDatesCandidate[idx]));
                                return <>
                                    <div className="col-11-desktop p-relative">
                                        <Slider
                                            intl={this.props.intl}
                                            controlled
                                            // tooltip
                                            // tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                                            className={classNames("capitalBuyInSlider", {
                                                "inactive": Object.keys(this.props.userDataWI.maxPurchases).length === 0 || !checkIfArrayIsUnique(this.state.capitalPurchaseDatesCandidate),
                                                // "notForThisDate": this.state.capitalPurchaseDateInvalid[idx]
                                                "notForThisDate": this.capitalPurchaseDateInvalid[idx] || this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[idx]) === 0
                                            })}
                                            label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}
                                            translateId={translatableStrings.capitalBuyIn.id}
                                            maxVal={this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[idx])}
                                            minVal={0}
                                            domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDatesCandidate[idx])]}
                                            initialValue={defined(this.state.sliderValuesPurchaseCandidate[idx]) ? this.state.sliderValuesPurchaseCandidate[idx] : 0}
                                            forcedValue={defined(this.state.sliderValuesPurchaseCandidate[idx]) ? this.state.sliderValuesPurchaseCandidate[idx] : 0}
                                            sliderValueChanged={(val, initial) => {
                                                const newSliderValuesPurchase = [...this.state.sliderValuesPurchaseCandidate];
                                                newSliderValuesPurchase[idx] = val;
                                                this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;
                                                this.setState({
                                                    sliderValuesPurchaseCandidate: newSliderValuesPurchase,
                                                }, () => {

                                                })
                                            }}
                                            resetCounter={this.state.resetCounter}
                                        >
                                            <div className="sliderDateInput">
                                                {/* <IconCalendar style={{
                                                    position: 'absolute',
                                                    right: '0px',
                                                    bottom: '7px'
                                                }} /> */}
                                                <input
                                                    data-translate={translatableStrings.date.id}
                                                    placeholder={this.props.intl.formatMessage(translatableStrings.date)}
                                                    type="text"
                                                    onBlur={(e) => this.calculateCapitalPurchase(e, idx)}
                                                    pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"
                                                    onChange={(e) => {
                                                        console.log('inputChange capitalPurchaseDateInvalid', this.state.capitalPurchaseDateInvalid)
                                                        const newDate = e.target.value;
                                                        let newCapitalPurchaseDates = [...this.state.capitalPurchaseDatesCandidate];
                                                        newCapitalPurchaseDates[idx] = newDate;
                                                        let newCapitalPurchaseDateInvalid = [...this.capitalPurchaseDateInvalid];
                                                        // let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
                                                        newCapitalPurchaseDateInvalid[idx] = false;
                                                        this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                                                        this.setState({
                                                            capitalPurchaseDatesCandidate: [...newCapitalPurchaseDates],
                                                            capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                                                        });
                                                    }}
                                                    value={this.state.capitalPurchaseDatesCandidate[idx]}
                                                    className={classNames("slider_val text-left", {
                                                        // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                        "invalid": this.capitalPurchaseDateInvalid[idx]
                                                    })}
                                                />
                                            </div>
                                        </Slider>
                                        {/* {this.state.capitalPurchaseDateInvalid[idx] && <p className="invalidPurchaseDate"> */}
                                        {this.capitalPurchaseDateInvalid[idx] && <p className="invalidPurchaseDate">
                                            {this.state.capitalPurchaseDateInvalidFromDrag ?
                                                <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                                </FormattedMessageCustom>
                                                : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                                </FormattedMessageCustom>
                                            }
                                            <Tooltip
                                                mouseEnterDelay={0}
                                                mouseLeaveDelay={0}
                                                destroyTooltipOnHide={true}
                                                placement={'top'}
                                                trigger={'click'}
                                                overlay={
                                                    <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                    </FormattedMessageCustom>
                                                }
                                            >
                                                <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                            </Tooltip>
                                        </p>}
                                        <button
                                            onClick={() => {
                                                console.log('click capitalPurchaseDateInvalid', this.state.capitalPurchaseDateInvalid);
                                                this.capitalPurchaseDateInvalid = [...removeInArrayByIdx([...this.state.capitalPurchaseDateInvalid], idx)];
                                                this.setState({
                                                    sliderValuesPurchaseCandidate: [...removeInArrayByIdx([...this.state.sliderValuesPurchaseCandidate], idx)],
                                                    capitalPurchaseDatesCandidate: [...removeInArrayByIdx([...this.state.capitalPurchaseDatesCandidate], idx)],
                                                    capitalPurchaseRemoveCandidate: [...this.state.capitalPurchaseRemoveCandidate, idx],
                                                    capitalPurchaseDateInvalid: [...removeInArrayByIdx([...this.state.capitalPurchaseDateInvalid], idx)],
                                                    // resetCounter: this.state.resetCounter + 1
                                                });
                                            }}
                                            className="removeCapitalIcon nonButton"
                                        >
                                            <IconTrash />
                                        </button>
                                    </div>
                                </>
                            })
                                : <p className="allRemoved">
                                    <FormattedMessageCustom id={translatableStrings.allBuyInsRemoved.id} text={this.props.intl.formatMessage(translatableStrings.allBuyInsRemoved)} />
                                </p>
                            }
                            <div className="modal-buttons">
                                <Button
                                    className="button text-center mx-auto"
                                    submit={true}
                                    onClick={() => {
                                        // const apiDate = parseToApiDate(purchaseDate, "ddmmyyyy");
                                        // const key = apiDate;
                                        console.log('maxPurchases delete', this.props.userDataWI.maxPurchases);
                                        this.state.capitalPurchaseRemoveCandidate.forEach((purchaseIdx) => {
                                            this.props.removeCapitalPurchase(purchaseIdx);
                                        });

                                        this.state.capitalPurchaseDatesCandidate.forEach((purchaseDate, idx) => {
                                            defined(this.state.sliderValuesPurchaseCandidate[idx]) && this.props.setCapitalPurchase({
                                                "amount": this.state.sliderValuesPurchaseCandidate[idx] || 0,
                                                "index": idx,
                                                "date": this.state.capitalPurchaseDatesCandidate[idx]
                                            });
                                        });

                                        addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchaseCandidate);

                                        this.setState({
                                            capitalPurchaseDates: [...this.state.capitalPurchaseDatesCandidate],
                                            sliderValuesPurchase: [...this.state.sliderValuesPurchaseCandidate],
                                            capitalPurchaseRemoveCandidate: []
                                        }, () => {
                                            this.props.closeModal();
                                        })
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.ready.id} text={this.props.intl.formatMessage(translatableStrings.ready)} />
                                </Button>

                                <Button
                                    className="button"
                                    onClick={() => {
                                        this.setState({
                                            capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],
                                            sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],
                                            capitalPurchaseRemoveCandidate: []
                                        });
                                        this.props.closeModal();
                                    }
                                    }
                                    buttonType={EButtonType.BasicLink}
                                >
                                    <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "showAllBuyIns") {
            const nextIdx = Object.keys(this.props.userDataWI.capitalPurchases).length;
            console.log('nextIdx', nextIdx);
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.capitalOverview.id} text={this.props.intl.formatMessage(translatableStrings.capitalOverview)}>
                        <div className="modal-header  buyin"></div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            <div className="col-12">
                                <div className="sliderLikeLabel d-flex flex-row mb-4">
                                    <div className="d-flex flex-row w-100 flex-wrap mb-0">
                                        {Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {
                                            return (
                                                [<div className={classNames("d-flex w-100 flex-wrap justify-content-between mb-1", {
                                                    "mt-1": idx > 0,
                                                    // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    "invalid": this.capitalPurchaseDateInvalid[idx]
                                                })}>
                                                    <div className="mr-1" style={{}}>{idx + 1}. <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)} /></div>
                                                    <div className="capitalBuyInText" style={{ minWidth: 100 }}>({parseDate(this.props.userDataWI.capitalPurchases[key]['date'], "secsToDDMMYYYY")})
                                                    {/* {this.state.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate"> */}
                                                        {this.capitalPurchaseDateInvalid[idx] ? <p className="invalidPurchaseDate">
                                                            {/* {this.state.capitalPurchaseDateInvalidFromDrag ?
                                                        <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                                        </FormattedMessageCustom>
                                                        : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                                        </FormattedMessageCustom>
                                                    } */}
                                                            <Tooltip
                                                                mouseEnterDelay={0}
                                                                mouseLeaveDelay={0}
                                                                destroyTooltipOnHide={true}
                                                                placement={'top'}
                                                                trigger={'click'}
                                                                overlay={
                                                                    <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                                    </FormattedMessageCustom>
                                                                }
                                                            >
                                                                <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                            </Tooltip>
                                                        </p> : null}
                                                    </div>
                                                    <div className="" style={{ minWidth: 82 }}>
                                                        CHF {formatNumber(this.props.userDataWI.capitalPurchases[key]['amount'], 0)}
                                                    </div>
                                                </div>
                                                ]
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-buttons">
                                <Button
                                    className="button"
                                    onClick={() => {
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.back.id} text={this.props.intl.formatMessage(translatableStrings.back)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        } else if (modalContent === "showallwithdrawals") {
            return (
                <>
                    <FormattedMessageCustom id={translatableStrings.capitalWithdrawalOverview.id} text={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalOverview)}>
                        <div className="modal-header  buyin"></div>
                    </FormattedMessageCustom>

                    <div className={`modal-content withdrawal buyin`}>
                        <div className="row">
                            <div className="col-12">
                                <div className="sliderLikeLabel d-flex flex-row mb-4">
                                    <div className="d-flex flex-row w-100 flex-wrap mb-0">
                                        {
                                            this.props.calculations.filter((stepObj) => Boolean(stepObj.linkedRetirementStep)).map((stepObj, idx) => {
                                                return (

                                                    <div className={classNames("d-flex w-100 flex-wrap justify-content-between mb-1 capitalPurchaseRowInModal", {
                                                        // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    })}>
                                                        <div>
                                                            {parseDate(stepObj.linkedRetirementStep.retirementDate, "secsToDDMMYYYY") + ' '}
                                                            ({
                                                                stepObj.linkedRetirementStep.retirementPercentage === 100 ? <FormattedMessageCustom id={translatableStrings.fullPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.fullPensionRetirement)} /> :
                                                                    [<FormattedMessageCustom id={translatableStrings.partialPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.partialPensionRetirement)} />,
                                                                    ` ${stepObj.linkedRetirementStep.retirementPercentage}%`]
                                                            })
                                                        </div>
                                                        <div>{defined(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) ? "CHF " + formatNumber(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) : null}</div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="modal-buttons">
                                <Button
                                    className="button"
                                    onClick={() => {
                                        this.props.closeModal();
                                    }}
                                    buttonType={EButtonType.PillSmall}
                                >
                                    <FormattedMessageCustom id={translatableStrings.back.id} text={this.props.intl.formatMessage(translatableStrings.back)} />
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    getCapitalMaxPurchase = (date) => {
        const apiDate = parseToApiDate(date, "ddmmyyyy");
        const key = apiDate;
        console.log('key', key,
            'getCapitalMax: ', this.props.userDataWI.maxPurchases[key]);
        return this.props.userDataWI.maxPurchases[key] ? this.props.userDataWI.maxPurchases[key]['max'] : 0;
    }

    calculateCapitalPurchase = (e, idx?) => {
        const val = e.target.value;
        console.log('setCAPITALPURCHASE', e.target, e.currentTarget,
            'val', val,
            'isDate: ', isDate(val),
            'idx', idx
        );

        if (isDate(val)) {
            const apiDate = parseToApiDate(val, "ddmmyyyy");
            console.log('apiDate', apiDate);
            const mergedPayload = {
                "data": {
                    "tenantName": this.props.userDataWI.tenantName || "BVG",
                    "purchaseDate": apiDate,
                    "insurant": {
                        ...this.props.lastCalculatePayload,
                    },
                    "RetirementSteps": (window as any).retirementSteps
                }
            };
            console.log('apiDate', apiDate, 'payload: ', mergedPayload);
            let newCapitalPurchaseDateInvalid = [...this.capitalPurchaseDateInvalid];
            // let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];

            this.props.calculateMaxPurchase(mergedPayload).then((res) => {
                console.log('calculated max purchase res', res, this.props.userDataWI.maxPurchases[apiDate]);
                if (res.data.value === 0) {
                    newCapitalPurchaseDateInvalid[idx] = true;
                    this.setState({
                        capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                    })
                    this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                } else {
                    newCapitalPurchaseDateInvalid[idx] = false;
                    this.setState({
                        capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                    })
                    this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                }
            }).catch(() => {
                newCapitalPurchaseDateInvalid[idx] = true;
                this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                this.setState({
                    capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                })
            });
        }
    }

    render() {
        return (
            <div className={classNames("controls crossFadeOut", {
                "mobileModal": this.props.mobileModal,
                "bridgingImpossible": !(this.props.userDataWI.settings && this.props.userDataWI.settings.isBridingPossible)
            })}
            >
                {this.props.modalIsOpen === true ? this.props.renderModalWithChildren(this.renderModal(this.props.modalContent), true) : null}

                {this.props.wrapInTooltip(
                    <div key="add2" className="w-100 addTeilContainer">
                        <Button
                            className="button text-center strokeGrey font-12 text-uppercase font-600 addTeilButton"
                            onClick={() => this.props.goToRenten()}
                            isDisabled={!this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible}
                            buttonType={EButtonType.InvertedPill}
                        >
                            <span style={{
                                marginRight: 12
                            }}>
                                <IconPlus />
                            </span>
                            <FormattedMessageCustom id={translatableStrings.partialPension.id} text={this.props.intl.formatMessage(translatableStrings.partialPension)} />
                        </Button>
                    </div>,
                    this.props.intl.formatMessage(translatableStrings[this.props.userDataWI.tenantName.toUpperCase() == 'PKAR' ? 'teilpensionNotPossiblePKAR' : 'teilpensionNotPossible']),
                    !this.props.userDataWI.partialPensionLimits.isAdditionalStepPossible, "bottom"
                )}
                <div className="topControls">
                    <FormattedMessageCustom id={translatableStrings.variables.id} text={this.props.intl.formatMessage(translatableStrings.variables)}>
                        <p className="text-left font-16"></p>
                    </FormattedMessageCustom>
                    <Button
                        className="button ml-auto mt-0 w-auto"
                        onClick={() => this.resetControls()}
                        buttonType={EButtonType.BasicLink}
                    >
                        <FormattedMessageCustom id={translatableStrings.goBack.id} text={this.props.intl.formatMessage(translatableStrings.goBack)} />
                    </Button>
                </div>
                <div className="sliders">
                    {
                        // (this.props.userDataWI.settings && this.props.userDataWI.settings.isBridingPossible) ? 
                        <Slider
                            controlled
                            tooltip
                            intl={this.props.intl}
                            tooltipText={this.props.intl.formatMessage(translatableStrings.bridgingPensionTooltip)}
                            label={this.props.intl.formatMessage(translatableStrings.bridgingPension)}
                            translateId={translatableStrings.bridgingPension.id}
                            maxVal={[this.props.bridgingPensionAmountMax || 0]}
                            minVal={this.props.bridgingPensionAmountMin || 0}
                            maxPercentage={this.props.capitalWithdrawalPercentageMax}
                            initialValue={this.props.monthlyOrYearly === "annualIncome" ? this.props.userDataWI.bridgingPension : this.props.userDataWI.bridgingPension / 12}
                            className={classNames('bridgingPensionSlider', {
                                "inactiveBridging": this.props.isEarlyPensionPresent === false
                            })}
                            forcedValue={this.state.sliderValueBridgingPension}
                            domain={[this.props.bridgingPensionAmountMin, this.props.bridgingPensionAmountMax]}
                            sliderValueChanged={(val, initial) => {
                                console.log('idx21', this.state.sliderValueBridgingPension)
                                this.setState({
                                    sliderValueBridgingPension: val,
                                }, () => {
                                    if (this.props.userDataWI.bridgingPension !== this.state.sliderValueBridgingPension) {
                                        const idx = 0;
                                        console.log('idx2', idx, this.state.sliderValueBridgingPension,
                                            'val', val);
                                        clearTimeout(this.updateTimeout);
                                        // if (this.props.calculateCounterTrigger > 0) {
                                        document.body.classList.add('dragStartedFake');
                                        // }
                                        this.updateTimeout = setTimeout(() => {
                                            defined(this.state.sliderValueBridgingPension) && this.props.setBridgingPension(this.props.monthlyOrYearly === "annualIncome" ? this.state.sliderValueBridgingPension : this.state.sliderValueBridgingPension * 12);
                                        }, 300);
                                    }
                                })
                            }}
                            resetCounter={this.state.resetCounter}
                        />
                        //  : null
                    }
                    {this.props.retirementSteps.length > 1 ?
                        <div className="sliderWrapper d-flex flex-row flex-wrap">
                            <div className="d-flex flex-wrap slider_header mb-0 p-0">
                                <FormattedMessageCustom id={translatableStrings.capitalWithdrawal.id} text={this.props.intl.formatMessage(translatableStrings.capitalWithdrawal)} >
                                    <p className="sliderLikeLabel">
                                    </p>
                                </FormattedMessageCustom>
                            </div>
                            {this.props.userDataWI.RetirementSteps.length > 1 ?
                                <Button
                                    className={classNames("button openCapitalWithdrawalButtonChange", {
                                        // "invalidPresent": this.state.capitalPurchaseDateInvalid.includes(true)
                                    })}
                                    onClick={() => this.props.openModal("capitalwithdrawal", true)}
                                    buttonType={EButtonType.BasicLink}
                                >
                                    <FormattedMessageCustom id={translatableStrings.change.id} text={this.props.intl.formatMessage(translatableStrings.change)} />
                                </Button> : null}

                            <div className={classNames("scrollable", {
                                "d-none": Object.keys(this.props.userDataWI.capitalWithdrawals).length === 0
                            })}>
                                {console.log('this.props.userDataWI.capitalWithdrawals',
                                    this.props.userDataWI.capitalWithdrawals,
                                    'this.props.calculations.length',
                                    this.props.calculations,
                                    this.props.calculations.length
                                )}
                                {Object.keys(this.props.userDataWI.capitalWithdrawals).length > 0 && this.props.calculations.length > 0 ?
                                    this.props.calculations.filter((stepObj) => Boolean(stepObj.linkedRetirementStep)).map((stepObj, idx) => {
                                        if (stepObj.linkedRetirementStep && stepObj.linkedRetirementStep.retirementDate) {
                                            if (idx < 3) {
                                                return (idx < 2) ? (
                                                   <div key={`purchaseRow${idx}`} className={classNames("d-flex capitalPurchaseRow", {
                                                        // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                    })}>
                                                        <div>
                                                            {parseDate(stepObj.linkedRetirementStep.retirementDate, "secsToDDMMYYYY") + ' '}
                                                            ({
                                                                stepObj.linkedRetirementStep.retirementPercentage === 100 ? <FormattedMessageCustom key={translatableStrings.fullPensionRetirement.id} id={translatableStrings.fullPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.fullPensionRetirement)} /> :
                                                                    [<FormattedMessageCustom id={translatableStrings.partialPensionRetirement.id} key={translatableStrings.partialPensionRetirement.id} text={this.props.intl.formatMessage(translatableStrings.partialPensionRetirement)} />,
                                                                    ` ${stepObj.linkedRetirementStep.retirementPercentage}%`]
                                                            })
                                                        </div>
                                                        <div>{defined(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) ? "CHF " + formatNumber(stepObj.linkedRetirementStep.capitalWidthdrawalAmount) : null}</div>
                                                    </div>
                                                ) : (
                                                        <div key={`purchaseRow2${idx}`} className={classNames("d-flex capitalPurchaseRow", {
                                                            // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                                        })}>
                                                            <div>{this.props.userDataWI.RetirementSteps.length - 2} <FormattedMessageCustom id={translatableStrings.moreWithdrawals.id} text={this.props.intl.formatMessage(translatableStrings.moreWithdrawals)} /></div>
                                                            <Button
                                                                className="button openCapitalWithdrawalButtonChange"
                                                                onClick={() => {
                                                                    // this.setState({
                                                                    //     showAllWithdrawals: !this.state.showAllWithdrawals
                                                                    // })
                                                                    this.props.openModal('showallwithdrawals', true);
                                                                }}
                                                                buttonType={EButtonType.BasicLink}
                                                            >
                                                                <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />
                                                                {/* {this.state.showAllWithdrawals ? <FormattedMessageCustom id={translatableStrings.hide.id} text={this.props.intl.formatMessage(translatableStrings.hide)} /> : <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />} */}
                                                            </Button>
                                                        </div>
                                                    )
                                            }
                                        } else {
                                            console.log('will return null 1');
                                            return null
                                        }
                                    }) : null}
                            </div>

                            {/* {this.props.userDataWI.RetirementSteps.length > 1 && this.state.sliderValuesWithdrawal.filter((el) => el > 0).length === 0 &&
                                // this.props.userDataWI.capitalWithdrawals[Object.keys(this.props.userDataWI.capitalWithdrawals)[0]]['amount'] && 
                                <div className="mt-0">
                                    <Button
                                        className="button openCapitalWithdrawalButton"
                                        onClick={() => this.props.openModal("capitalwithdrawal", true)}
                                        buttonType={EButtonType.BasicLink}
                                    >
                                        <FormattedMessageCustom id={translatableStrings.addCapitalWithdrawal.id} text={this.props.intl.formatMessage(translatableStrings.addCapitalWithdrawal)} />
                                    </Button>
                                </div>} */}
                        </div>
                        :
                        defined(this.props.capitalWidthdrawalAmountMax) && <Slider
                            controlled
                            tooltip
                            intl={this.props.intl}
                            tooltipText={this.props.intl.formatMessage(translatableStrings.capitalWithdrawalTooltip)}
                            translateId={translatableStrings.capitalWithdrawal.id}
                            label={this.props.intl.formatMessage(translatableStrings.capitalWithdrawal)}
                            showPercInput
                            maxVal={this.props.capitalWidthdrawalAmountMax}
                            maxPercentage={this.props.capitalWithdrawalPercentageMax}
                            // activeSegment={0}
                            minVal={this.props.capitalWidthdrawalAmountMin}
                            initialValue={this.state.sliderValuesWithdrawal[0]}
                            forcedValue={this.state.sliderValuesWithdrawal[0]}
                            className={classNames("middle capitalWithdrawalSlider", {
                                "inactive": this.props.capitalWidthdrawalAmountMax === 0 || !defined(this.props.capitalWithdrawalPercentageMax)
                            })}
                            domain={[this.props.capitalWidthdrawalAmountMin, this.props.capitalWidthdrawalAmountMax]}
                            sliderValueChanged={(val, initial) => {
                                const newSliderValues = [...this.state.sliderValuesWithdrawal];
                                newSliderValues[0] = val;
                                console.log('newSliderValues2', newSliderValues)
                                this.setState({
                                    sliderValuesWithdrawal: newSliderValues,
                                }, () => {
                                    const idx = this.state.sliderValuesWithdrawal.length - 1;
                                    console.log('idx', idx, this.state.sliderValuesWithdrawal,
                                        'val', val);
                                    if (this.props.userDataWI.capitalWithdrawals[idx]['amount'] !== this.state.sliderValuesWithdrawal[idx]) {
                                        clearTimeout(this.updateTimeout);
                                        // if (this.props.calculateCounterTrigger > 0) {
                                        document.body.classList.add('dragStartedFake');
                                        // }
                                        this.updateTimeout = setTimeout(() => {
                                            defined(this.state.sliderValuesWithdrawal[idx]) && this.props.setCapitalWithdrawal({
                                                "amount": this.state.sliderValuesWithdrawal[idx],
                                                "index": idx,
                                                "percentage": (this.state.sliderValuesWithdrawal[idx] / this.props.capitalWidthdrawalAmountMax * 100).toFixed(2)
                                            });
                                        }, 300);
                                        // this.props.calculateDebounced();

                                    }
                                    // addToLSObject("userDataWI", "sliderValuesWithdrawal", this.state.sliderValuesWithdrawal);
                                })
                            }}
                            resetCounter={this.state.resetCounter}
                        />
                    }

                    <div className="sliderWrapperParent d-flex flex-wrap p-md-24">
                        {Object.keys(this.props.userDataWI.capitalPurchases).length === 1 ? <Slider
                            tooltip
                            controlled
                            intl={this.props.intl}
                            tooltipText={this.props.intl.formatMessage(translatableStrings.capitalBuyInTooltip)}
                            label={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}
                            translateId={translatableStrings.capitalBuyIn.id}
                            className={classNames("capitalBuyInSlider", {
                                "inactive": Object.keys(this.props.userDataWI.maxPurchases).length === 0 || this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) === 0,
                                // "notForThisDate": this.capitalPurchaseDateInvalid[1],
                                "notForThisDate": this.state.capitalPurchaseDateInvalid[0],
                                "one": Object.keys(this.props.userDataWI.capitalPurchases).length === 1
                            })}
                            activeSegment={0}
                            maxVal={this.state.capitalPurchaseDates[0] ? this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0]) : 0}
                            minVal={0}
                            domain={[0, this.getCapitalMaxPurchase(this.state.capitalPurchaseDates[0])]}
                            initialValue={defined(this.state.sliderValuesPurchase[0]) ? this.state.sliderValuesPurchase[0] : 0}
                            forcedValue={defined(this.state.sliderValuesPurchase[0]) ? this.state.sliderValuesPurchase[0] : 0}
                            sliderValueChanged={(val, initial) => {
                                let newSliderValuesPurchase = [...this.state.sliderValuesPurchase];
                                newSliderValuesPurchase[0] = val;
                                this.recalculatedMaxPurchasesAt = this.props.calculateCounter + 1;
                                this.setState({
                                    sliderValuesPurchase: newSliderValuesPurchase,
                                }, () => {
                                    const idx = this.state.sliderValuesPurchase.length - 1;
                                    console.log('idx', idx, this.state.sliderValuesPurchase,
                                        'val', val,
                                        "date", this.state.capitalPurchaseDates[idx],
                                        this.state.capitalPurchaseDates
                                    );
                                    clearTimeout(this.updateTimeout);
                                    this.updateTimeout = setTimeout(() => {
                                        defined(this.state.sliderValuesPurchase[idx]) && this.props.setCapitalPurchase({
                                            "amount": this.state.sliderValuesPurchase[idx] || 0,
                                            "index": idx,
                                            "date": this.state.capitalPurchaseDates[idx]
                                        });
                                        addToLSObject("userDataWI", "sliderValuesPurchase", this.state.sliderValuesPurchase);
                                    }, 300);
                                })
                            }}
                            resetCounter={this.state.resetCounter}
                        >
                            <div className="sliderDateInput">
                                {/* <IconCalendar style={{
                                    position: 'absolute',
                                    right: '0px',
                                    bottom: '7px'
                                }} /> */}
                                <input
                                    data-translate={translatableStrings.date.id}
                                    placeholder={this.props.intl.formatMessage(translatableStrings.date)}
                                    type="text"
                                    pattern="(?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}"
                                    onBlur={(e) => this.calculateCapitalPurchase(e, this.state.sliderValuesPurchase.length - 1)}
                                    onChange={(e) => {
                                        const newDate = e.target.value;
                                        let newCapitalPurchaseDates = [...this.state.capitalPurchaseDates];
                                        newCapitalPurchaseDates[0] = newDate;
                                        let newCapitalPurchaseDateInvalid = [...this.state.capitalPurchaseDateInvalid];
                                        const idx = this.state.sliderValuesPurchase.length - 1;
                                        newCapitalPurchaseDateInvalid[idx] = false;
                                        this.capitalPurchaseDateInvalid = [...newCapitalPurchaseDateInvalid];
                                        this.setState({
                                            capitalPurchaseDates: [...newCapitalPurchaseDates],
                                            capitalPurchaseDateInvalid: [...newCapitalPurchaseDateInvalid]
                                        });
                                    }}
                                    value={this.state.capitalPurchaseDates[0] || ""}
                                    className={classNames("slider_val text-left", {
                                        // "invalid": this.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1]
                                        "invalid": this.state.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1]
                                    })}
                                />
                            </div>
                        </Slider> :
                            <div className="d-flex flex-wrap w-100">
                                <div className="slider_header">
                                    <FormattedMessageCustom id={translatableStrings.capitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyIn)}>
                                        <p className="sliderLikeLabel">
                                        </p>
                                    </FormattedMessageCustom>
                                </div>
                                {this.props.userDataWI.capitalPurchases[Object.keys(this.props.userDataWI.capitalPurchases)[0]].date && <Button
                                    className={classNames("button openCapitalWithdrawalButtonChange", {
                                        "invalidPresent": this.state.capitalPurchaseDateInvalid.includes(true)
                                        // "invalidPresent": this.capitalPurchaseDateInvalid.includes(true)
                                    }
                                    )}
                                    onClick={() => this.props.openModal("capitalbuyinedit", true)}
                                    buttonType={EButtonType.BasicLink}
                                >
                                    <FormattedMessageCustom id={translatableStrings.change.id} text={this.props.intl.formatMessage(translatableStrings.change)} />
                                </Button>}
                            </div>
                        }

                        <div className={classNames("scrollable", {
                            "rightPadding": this.state.showAll
                        })}>
                            {Object.keys(this.props.userDataWI.capitalPurchases).length > 1 && Object.keys(this.props.userDataWI.capitalPurchases).map((key, idx) => {
                                // console.log('forEach', key, this.props.userDataWI.capitalPurchases[key]);
                                if (idx < 3 || this.state.showAll) {
                                    return (idx < 2 || this.state.showAll) ? (
                                        <div className={classNames("d-flex capitalPurchaseRow", {
                                            "invalid": this.capitalPurchaseDateInvalid[idx]
                                            // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                        })}>
                                            <div>{parseDate(this.props.userDataWI.capitalPurchases[key]["date"], "secsToDDMMYYYY")}
                                                {/* {this.state.capitalPurchaseDateInvalid[idx] ? <Tooltip */}
                                                {this.capitalPurchaseDateInvalid[idx] ? <Tooltip
                                                    mouseEnterDelay={0}
                                                    mouseLeaveDelay={0}
                                                    destroyTooltipOnHide={true}
                                                    placement={'top'}
                                                    trigger={'click'}
                                                    overlay={
                                                        <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                            <p className="invalidPurchaseDate">
                                                            </p>
                                                        </FormattedMessageCustom>
                                                    }
                                                >
                                                    <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                </Tooltip> : null}
                                            </div>
                                            <div>{this.props.userDataWI.capitalPurchases[key]["amount"] ? "CHF " + formatNumber(this.props.userDataWI.capitalPurchases[key]["amount"]) : null}</div>
                                        </div>
                                    ) : (
                                            <div className={classNames("d-flex capitalPurchaseRow", {
                                                "invalid": this.capitalPurchaseDateInvalid[idx]
                                                // "invalid": this.state.capitalPurchaseDateInvalid[idx]
                                            })}>
                                                <div>{Object.keys(this.props.userDataWI.capitalPurchases).length - 2} <FormattedMessageCustom id={translatableStrings.moreBuyIns.id} text={this.props.intl.formatMessage(translatableStrings.moreBuyIns)} />
                                                    {/* {this.state.capitalPurchaseDateInvalid.slice(2).includes(true) ? <Tooltip */}
                                                    {this.state.capitalPurchaseDateInvalid.slice(2).includes(true) ? <Tooltip
                                                        mouseEnterDelay={0}
                                                        mouseLeaveDelay={0}
                                                        destroyTooltipOnHide={true}
                                                        placement={'top'}
                                                        trigger={'click'}
                                                        overlay={
                                                            <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                            </FormattedMessageCustom>
                                                        }
                                                    >
                                                        <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                                    </Tooltip> : null}
                                                </div>
                                                <Button
                                                    className="button openCapitalWithdrawalButtonChange"
                                                    onClick={() => {
                                                        this.props.openModal("showAllBuyIns", true);
                                                        // this.setState({
                                                        //     showAll: !this.state.showAll
                                                        // })
                                                    }}
                                                    buttonType={EButtonType.BasicLink}
                                                >
                                                    {/* {this.state.showAll ? <FormattedMessageCustom id={translatableStrings.hide.id} text={this.props.intl.formatMessage(translatableStrings.hide)} /> : 
                                                    } */}
                                                    <FormattedMessageCustom id={translatableStrings.showAll.id} text={this.props.intl.formatMessage(translatableStrings.showAll)} />
                                                </Button>
                                            </div>
                                        )
                                } else {
                                    return null
                                }
                            })}
                        </div>
                        <div className={Object.keys(this.props.userDataWI.capitalPurchases).length < 2 && "p-md-24"}>
                            {/* {this.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1] && !this.props.userDataWI.capitalPurchases[1] && */}
                            {this.state.capitalPurchaseDateInvalid[this.state.sliderValuesPurchase.length - 1] && !this.props.userDataWI.capitalPurchases[1] &&
                                <p className="invalidPurchaseDate">
                                    {this.state.capitalPurchaseDateInvalidFromDrag ?
                                        <FormattedMessageCustom id={translatableStrings.wrongDateForCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.wrongDateForCapitalBuyIn)}>
                                        </FormattedMessageCustom>
                                        : <FormattedMessageCustom id={translatableStrings.capitalBuyInNotPossible.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInNotPossible)}>
                                        </FormattedMessageCustom>
                                    }
                                    {<Tooltip
                                        mouseEnterDelay={0}
                                        mouseLeaveDelay={0}
                                        destroyTooltipOnHide={true}
                                        placement={'top'}
                                        trigger={'click'}
                                        overlay={
                                            <FormattedMessageCustom id={translatableStrings.capitalBuyInInvalidExplanation.id} text={this.props.intl.formatMessage(translatableStrings.capitalBuyInInvalidExplanation)}>
                                                <p className="invalidPurchaseDate">
                                                </p>
                                            </FormattedMessageCustom>
                                        }
                                    >
                                        <HelpIcon style={{ zIndex: 9999, width: '18px', marginLeft: '8px' }} className="pointer" />
                                    </Tooltip>}
                                </p>
                            }
                            {this.state.sliderValuesPurchase.length > 0 && this.state.sliderValuesPurchase[0] && this.state.sliderValuesPurchase.length < 6 ? <Button
                                className="button openCapitalWithdrawalButton"
                                onClick={() => {
                                    this.setState({
                                        capitalPurchaseDatesCandidate: [...this.state.capitalPurchaseDates],
                                        sliderValuesPurchaseCandidate: [...this.state.sliderValuesPurchase],
                                    }, () => {
                                    })
                                    this.props.openModal("capitalbuyin", true);
                                }}
                                buttonType={EButtonType.BasicLink}
                            >
                                <FormattedMessageCustom id={translatableStrings.addCapitalBuyIn.id} text={this.props.intl.formatMessage(translatableStrings.addCapitalBuyIn)}>
                                </FormattedMessageCustom>
                            </Button> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
