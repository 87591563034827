import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import Autosuggest from './Autosuggest';
import gsap from 'gsap';
import SelectList from "../../../components/Sidebar/SelectList";
import { animationConfig } from "../../../constants";
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    where: {
        id: 'pageWohnort.where',
        defaultMessage: 'Wo wohnen Sie?'
    }
})
interface State {
}

interface Props {
    actions: any;
    history: any;
    userDataWI: any;
    setUserDataWohnort: any;
    intl: any;
}

class Wohnort extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;


    componentDidMount() {
        setTimeout(() => {
            (document.querySelector('.autosuggestContainer input') as any).focus();
        }, 750);
    }

    async setUserDataWohnort(data) {
        await this.props.setUserDataWohnort(data);
    }

    async next(e) {
        await this.setUserDataWohnort(e);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            // document.querySelector('.routeContainer').classList.add('overflowHidden');
            this.timeline
                .to(document.querySelector('#logo2 ~ .dropdown'), { maxHeight: 50, duration: 0.5 })
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: { ...animationConfig().stagger },
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {

            this.props.history.push({
                pathname: `/ausgestelltam`
            })
        }, 900)

    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                  <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                </div>
                <div className="main wohn-main">
                    <FormattedMessageCustom id={translatableStrings.where.id} text={this.props.intl.formatMessage(translatableStrings.where)}>
                        <div className='ich-mochte mb-40 stagger'></div>
                    </FormattedMessageCustom>
                    <Autosuggest
                        userDataWI={this.props.userDataWI.Wohnort}
                        onClick={this.next}
                    />
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataWohnort: (data) => dispatch(actions.setUserDataWohnort(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Wohnort);
