export const exists = x => Boolean(x);

export const defined = x => typeof x !== 'undefined' && x !== null;

export const isArray = x => x.constructor === Array;

export const isString = x => typeof x === 'string';

export const isNumber = x => typeof x === 'number';

export const isNumeric = (num) => /^-{0,1}\d*\.{0,1}\d+$/.test(num);

export function isNumericOrDot(input, decimals?) {
    if (input === "") {
        return true;
    } else {
        let RE;
        if (decimals) {
            RE = new RegExp("^[0-9]+[.]{0,1}[0-9]{0," + decimals + "}$", 'g');
        } else {
            RE = new RegExp("^[0-9.]+$", 'g');
        }
        return (RE.test(input));
    }
}
