import { getParameterByName } from '../utils/toSearchParams';
import { setTenant } from '../actions';
import { defined } from '../utils/variableEvaluation';
import { updateIntl } from 'react-intl-redux';

export function sideActions({ dispatch, getState }) {
    return next => action => {
        // const state = getState();
        if (action.type === "@@router/LOCATION_CHANGE") {
            // document.body.classList.remove('blurred');
            const tenantParam = getParameterByName('tenantName') || getParameterByName('tenantname');
            const tenantName = getState().app.userDataWI.tenantName;
            const langParam = getParameterByName('lang');
            let langCode = getState().intl.locale;
            console.log(
                'locationChange',
                'getParameterByName', tenantParam,
                'getState', tenantName,
                'langParam', langParam,
                'langCode', langCode
            );

            // Set tenantName on any url change
            if (defined(tenantParam) && tenantParam !== tenantName) {
                dispatch(setTenant(tenantParam));
            }

            // Language change
            if (defined(langParam) && langParam !== langCode) {
                let languages = ["en", "fr", "it", "de"];

                if (languages.indexOf(langCode) >= 0) {
                    // dispatch(setLang(langParam));
                    console.log('getState().locales[langParam]', getState().locales[langParam])
                    dispatch(updateIntl({ locale: langParam, messages: getState().locales[langParam] }));
                } else {
                    // dispatch(setLang("de"));
                    dispatch(updateIntl({ locale: langParam, messages: getState().locales["de"] }));
                    console.error(`${langParam} is not supported language`);
                }

            }

        }
        return next(action);
    };
}
