import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { getParameterByName } from '../../../utils/toSearchParams';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { parseDate } from '../../../utils/react';
import Slider from '../../../components/Slider/index'
import gsap from 'gsap';
import SelectList from "../../../components/Sidebar/SelectList";
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
import { Button, EButtonType } from "../../../components/Button";
import classNames from 'classnames';

const translatableStrings = defineMessages({
    whatRetirementPercentage: {
        id: 'pageRentenanteil.whatRetirementPercentage',
        defaultMessage: 'Wie hoch soll der Anteil der Altersrente zum Zeitpunkt Ihrer Teilpensionierung sein?'
    },
    rentenanteil: {
        id: 'general.rentenanteil',
        defaultMessage: 'Rentenanteil'
    },
    fixedPercentage: {
        id: 'general.fixedPercentage',
        defaultMessage: 'Der Rentenanteil der Teilpensionierung ist auf {percentage}% fixiert.'
    },
    fixedPercentagePKAR: {
        id: 'general.fixedPercentagePKAR',
        defaultMessage: 'Die Höhe  der Teilaltersrente beträgt {percentage}%'
    },
    cancel: {
        id: 'button.cancel',
        defaultMessage: 'ABBRECHEN',
    },
})
interface State {
    percent: string;
}

interface Props {
    actions: any;
    history: any;
    setUserDataRentenanteil: any;
    userDataWI: any;
    calculations: any;
    intl: any;
}

class Rentenanteil extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            percent: this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
                this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].defaultRetirementPercentage :
                this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].defaultRetirementPercentage
        };
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        setTimeout(() => {
            (document.querySelector('.lohnsumme__button button') as any) && (document.querySelector('.lohnsumme__button button') as any).focus();
        }, 1200);
    }

    async setUserDataRentenanteil(data) {
        await this.props.setUserDataRentenanteil(data);
    }

    async next(e) {
        await this.setUserDataRentenanteil(e);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {
            this.props.history.push({
                pathname: this.props.userDataWI.partialPensionLimits.isDerivedFromActivityReduction ? `/fragenbean` : `/arbeitspensum`,
                search: `no=${getParameterByName("no")}`
            })
        }, 700)
    }

    cancel(e) {
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {
            this.props.history.push({
                pathname: `/chart`
            })
        }, 800)
    }

    start = (e) => {
        this.props.history.push({
            pathname: e,
        })
    }

    back = (e) => {
        console.log('back in rentenanteil', e.currentTarget);
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id,
                search: `no=${getParameterByName("no")}`
            })
        }
    }

    setPercent = (e) => {
        console.log('setPercent:', e);
        this.setState({
            percent: e || this.state.percent,
        });
        this.next(e || this.state.percent);
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    getLimitsIdx = () => {
        const chosenDate = this.props.userDataWI.RetirementStepsUnsorted[this.props.userDataWI.RetirementStepsUnsorted.length - 1];
        let index = 0;
        this.props.userDataWI.partialPensionLimits.possibleRetirementStepList.forEach((el, idx) => {
            // console.log('inside for', el.startDate, el.endDate, chosenDate, parseDate(chosenDate, "dateObject"), this.props.userDataWI.RetirementSteps);
            if (parseDate(chosenDate, "dateObject") > parseDate(el.startDate, "dateObject") &&
                parseDate(chosenDate, "dateObject") < parseDate(el.endDate, "dateObject")) {
                index = idx;
            }
        })
        console.log('will return index renten', index);
        return index;
    }

    render() {
        const minVal = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
            this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumRetirementPercentage :
            this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumRetirementPercentage;
        const maxVal = this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()] ?
            this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].maximumRetirementPercentage :
            this.props.userDataWI.lastPartialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].maximumRetirementPercentage;
        const bruttolohns = this.props.userDataWI.bruttolohns;
        const calculatedVal = (parseFloat(this.props.userDataWI.Lohnsumme) - parseFloat(bruttolohns[bruttolohns.length - 1])) / parseFloat(this.props.userDataWI.Lohnsumme) * 100;

        return (
            <div className={classNames("wunschberechnung_container", this.props.userDataWI.tenantName)}>
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                    <div className='flex-grow-1'></div>
                    <div className='selects' style={{ flexGrow: 1.53 }}>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                </div>
                <div className="main rentenant-main">
                    <FormattedMessageCustom id={translatableStrings.whatRetirementPercentage.id} text={this.props.intl.formatMessage(translatableStrings.whatRetirementPercentage)}>
                        <div className='ich-mochte mb-40 stagger'></div>
                    </FormattedMessageCustom>
                    <div className='rentenanteil stagger'>
                        <Slider
                            infoText={(minVal == maxVal || this.props.userDataWI.partialPensionLimits.isDerivedFromActivityReduction) ? this.props.intl.formatMessage(translatableStrings[this.props.userDataWI.tenantName.toUpperCase() === 'PKAR' ? 'fixedPercentagePKAR' : 'fixedPercentage'], {
                                percentage: maxVal || calculatedVal.toFixed(1)
                            }) : null}
                            controlled={false}
                            showButton
                            label={this.props.intl.formatMessage(translatableStrings.rentenanteil)}
                            percent
                            initialValue={this.state.percent}
                            setPercent={(e) => {
                                this.setPercent(this.props.userDataWI.partialPensionLimits.isDerivedFromActivityReduction ? Math.round(calculatedVal * 10) / 10 : e)
                            }}
                            stepSize={5}
                            // stepSize={this.props.userDataWI.partialPensionLimits.possibleRetirementStepList[this.getLimitsIdx()].minimumRetirementPercentageStepSize}
                            minVal={minVal}
                            maxVal={maxVal}
                            domain={[minVal, maxVal]}
                            intl={this.props.intl}
                        />
                    </div>
                    <Button
                        className="stagger"
                        buttonType={EButtonType.BasicLink}
                        onClick={(e) => this.cancel(e)}>
                        <FormattedMessageCustom id={translatableStrings.cancel.id} text={this.props.intl.formatMessage(translatableStrings.cancel)} />
                    </Button>
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataRentenanteil: (data) => dispatch(actions.setUserDataRentenanteil(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rentenanteil);
