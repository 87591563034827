import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import { getParameterByName } from '../../../utils/toSearchParams';

import '../../../containers/Wunschberechnung/Anliegen/Wunschberechnung.scss';
import { DropdownCollapse } from '../../DropdownCollapse';
import classNames from 'classnames';
// import { defined } from '../../../utils/variableEvaluation';
// import four from "../../assets/icons/menu_group/four_unc.svg";
// import fourOpen from "../../assets/icons/menu_group/four_c.svg";
// import complete, { ReactComponent as IconComplete } from "../../assets/icons/menu_group/complete.svg";
// import { ReactComponent as CheckmarkSlim } from "../../../assets/icons/checkmark-slim.svg";
import { ReactComponent as CheckmarkBold } from "../../../assets/icons/checkmark-bold.svg";
// import { parseDate } from '../../../utils/react';
// , ReactComponent as Checkmark
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    rentenberechnung: {
        id: 'navigation.rentenberechnung',
        defaultMessage: 'Rentenberechnung'
    },
    zeitpunkt: {
        id: 'navigation.zeitpunkt',
        defaultMessage: 'Zeitpunkt'
    },
    rentenanteil: {
        id: 'navigation.rentenanteil',
        defaultMessage: 'Rentenanteil'
    },
    arbeitspensum: {
        id: 'navigation.arbeitspensum',
        defaultMessage: 'Arbeitspensum'
    },
})

const stepsRange = [12, 16];
interface State {
    newZeitpunktEntry: number;
    readyToAnimate: boolean;
    visibleOnChart: boolean;
}

interface Props {
    // allowAnimation: boolean;
    back?: any;
    currentPage: string;
    currentStep: number;
    open?: boolean;
    onChart: boolean;
    start?: any;
    stepsOpened: any;
    toggle: any;
    userDataWI: any;
    intl: any;
}

class Four extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            visibleOnChart: false,
            readyToAnimate: false,
            newZeitpunktEntry: parseInt(getParameterByName("no")) || this.props.userDataWI.RetirementSteps.length - 1
        }
    }

    componentDidMount() {
        // const isarbeitspensum = this.props.currentPage === "arbeitspensum";
        // const isrentenanteil = this.props.currentPage === "rentenanteil";

        this.setState({
            readyToAnimate: true,
            newZeitpunktEntry: parseInt(getParameterByName("no")) || this.props.userDataWI.RetirementSteps.length - 1
        });
        document.body.addEventListener('click', this.bodyClick)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.userDataWI.RetirementSteps !== prevProps.userDataWI.RetirementSteps) {
            this.setState({
                newZeitpunktEntry: this.props.userDataWI.RetirementSteps.length
            })
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.bodyClick)
    }

    bodyClick = (event) => {
        if (window.innerWidth < 896) {

            const menuElem = (document.getElementById("step4") as any);
            // console.log("body clicked, in second nav", event.target,
            // 'menuelem', menuElem,
            // 'contains:', menuElem.contains(event.target),
            //  (event.target as any).classList,
            // (event.target as any).parentElement.classList
            // );
            if (!(menuElem).contains(event.target) && this.props.open) {
                console.log('will toggle on mobile 4');
                document.body.classList.remove('blurred');
                this.props.open && this.props.toggle();
            }
        }
    }

    showHide = () => {
        if (window.innerWidth < 896) {
            this.props.toggle();
            if (!this.props.open) {
                document.body.classList.add('blurred');
            } else {
                document.body.classList.remove('blurred');
            }
        }
    }

    handleMouseEnter = () => {
        if (window.innerWidth > 896 && this.props.onChart) {
            this.setState({
                visibleOnChart: true
            })
        }
    }

    handleMouseLeave = () => {
        if (window.innerWidth > 896 && this.props.onChart) {
            this.props.open && this.props.toggle();
            this.setState({
                visibleOnChart: false
            })
        }
    }

    stepNo = () => {
        const stepNo = parseInt(getParameterByName("no")) || this.props.userDataWI.RetirementSteps.length;
        // console.log('stepNo', stepNo, parseInt(getParameterByName("no")), this.props.userDataWI.RetirementSteps.length);
        return stepNo;
    }

    render() {
        const fourthStepCompleted = this.props.userDataWI.RetirementSteps.length === (this.stepNo() + 1) &&
            this.props.userDataWI.retirementPercentage.length > 1 && this.props.userDataWI.retirementPercentage.length === (this.stepNo() + 1) &&
            this.props.userDataWI.arbeitPensums.length > 1 && this.props.userDataWI.arbeitPensums.length === this.stepNo() + 1;
        const insideStep = this.props.currentStep > stepsRange[0] && this.props.currentStep < stepsRange[1];
        return (
            <label id="step4" className={classNames('select', {
                "activated": this.props.currentPage === 'chart',
                "visible": this.state.visibleOnChart,
                "hidden": !this.state.visibleOnChart,
            })}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
            >

                <div onClick={this.showHide}
                    className={classNames('circle', {
                        'open': this.props.open,
                        'insideStep': insideStep,
                        // ' open': (this.props.userDataWI.ahvPensionAmount && this.props.stepsOpened[3]),
                        // '  open': (this.props.userDataWI.VPKonto && this.props.stepsOpened[3]),
                        // 'open': this.props.open || (this.props.userDataWI.ahvPensionAmount && !this.props.userDataWI.RetirementSteps.length),
                        // 'open': this.props.open,
                        'finished': fourthStepCompleted
                    })}
                >
                    {fourthStepCompleted ? (<CheckmarkBold />) : "3"}
                </div>

                <div className={classNames("dropdown_content forMobileOnly", {
                    "opened": this.props.open
                })}
                    id="logo4"
                >
                    <FormattedMessageCustom id={translatableStrings.rentenberechnung.id} text={this.props.intl.formatMessage(translatableStrings.rentenberechnung)}>

                        <div
                            id={(this.props.userDataWI.ahvPensionAmount) ? `zeitpunkt` : null}
                            onClick={this.props.back}
                            // id={this.props.userDataWI.calculationType.length ? `anliegen` : null}
                            style={this.props.open && insideStep ? { color: 'var(--blue-light)' } : null}
                            className={classNames('dropdown_elem point', {
                                // 'completed': this.props.userDataWI.RetirementSteps.length > 1,
                                'current': this.props.currentPage === 'zeitpunkt'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.zeitpunkt.id} text={this.props.intl.formatMessage(translatableStrings.zeitpunkt)}>
                        <div
                            id={this.props.userDataWI.arbeitPensums.length ? `zeitpunkt` : null}
                            // id={this.props.userDataWI.RetirementSteps.length ? `zeitpunkt` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={(this.props.userDataWI.RetirementSteps.length > 1) ? null : (this.props.currentPage === 'zeitpunkt') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem d-none', {
                                'completed': this.props.userDataWI.RetirementSteps.length > 1,
                                'current': this.props.currentPage === 'zeitpunkt'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.rentenanteil.id} text={this.props.intl.formatMessage(translatableStrings.rentenanteil)}>
                        <div
                            id={this.props.userDataWI.RetirementSteps.length ? `rentenanteil` : null}
                            // id={this.props.userDataWI.retirementPercentage.length ? `rentenanteil` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={(this.props.userDataWI.retirementPercentage.length > 1) ? null : (this.props.currentPage === 'rentenanteil') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem d-none', {
                                'completed': this.props.userDataWI.retirementPercentage.length > this.state.newZeitpunktEntry && this.props.userDataWI.retirementPercentage.length > 1,
                                'current': this.props.currentPage === 'rentenanteil'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>
                    <FormattedMessageCustom id={translatableStrings.arbeitspensum.id} text={this.props.intl.formatMessage(translatableStrings.arbeitspensum)}>
                        <div
                            id={this.props.userDataWI.retirementPercentage.length ? `arbeitspensum` : null}
                            // id={this.props.userDataWI.arbeitPensums.length ? `arbeitspensum` : null}
                            onClick={(e) => {
                                this.handleMouseLeave();
                                this.props.back(e);
                            }}
                            style={(this.props.userDataWI.arbeitPensums.length > 1) ? null : (this.props.currentPage === 'arbeitspensum') ? { color: 'var(--main)' } : null}
                            className={classNames('dropdown_elem d-none', {
                                'completed': this.props.userDataWI.arbeitPensums.length > 1 && this.props.userDataWI.arbeitPensums.length > this.state.newZeitpunktEntry,
                                'current': this.props.currentPage === 'arbeitspensum'
                            })}
                        >

                        </div>
                    </FormattedMessageCustom>

                </div>
                <DropdownCollapse
                    className={classNames({
                        // 'open': this.props.open,
                        'open': this.props.open,
                        // ' open': (this.props.userDataWI.ahvPensionAmount && this.props.stepsOpened[3]),
                        // '  open': (this.props.userDataWI.VPKonto && this.props.stepsOpened[3]),

                    })}
                    isOpen={this.props.open}
                    toggle={this.props.toggle}
                    // allowAnimation={this.props.allowAnimation}
                    isComplete={fourthStepCompleted}
                    insideStep={this.props.currentStep > stepsRange[0] && (this.props.currentStep < stepsRange[1])}
                    // isActive={this.props.userDataWI.???}
                    // func={this.props.userDataWI.retirementPercentage.length ? (this.props.start) : null}
                    children={
                        [
                            <FormattedMessageCustom id={translatableStrings.zeitpunkt.id} text={this.props.intl.formatMessage(translatableStrings.zeitpunkt)} key="zeitpunktsMobile">
                                <div
                                    id={(this.props.userDataWI.ahvPensionAmount) ? `zeitpunkt` : null}
                                    // id={this.props.userDataWI.RetirementSteps.length ? `zeitpunkt` : null}
                                    onClick={(e) => {
                                        this.handleMouseLeave();
                                        // this.props.back(e);
                                    }}
                                    className={classNames('menuTopElem menuElem', {
                                        'completed': this.state.newZeitpunktEntry > 0 &&
                                            (this.props.userDataWI.retirementPercentage.length > this.state.newZeitpunktEntry ||
                                                (this.state.newZeitpunktEntry === this.props.userDataWI.RetirementSteps.length - 1 && this.props.currentPage !== 'chart') ||
                                                (this.props.currentPage === 'chart' && this.props.userDataWI.RetirementSteps.length === 5)),
                                        'current': this.props.currentPage === 'zeitpunkt' && this.state.readyToAnimate
                                    })}
                                >
                                </div>
                            </FormattedMessageCustom>
                            ,
                            <br key="break41" />,
                            <FormattedMessageCustom id={translatableStrings.rentenanteil.id} text={this.props.intl.formatMessage(translatableStrings.rentenanteil)} key="ahvrenteMobile4">
                                <div
                                    id={this.state.newZeitpunktEntry === this.props.userDataWI.RetirementSteps.length - 1 ? `rentenanteil` : null}
                                    // id={this.props.userDataWI.retirementPercentage.length ? `rentenanteil` : null}
                                    onClick={(e) => {
                                        this.handleMouseLeave();
                                        // this.props.back(e);
                                    }}
                                    className={classNames('menuElem', {
                                        'completed':
                                            this.state.newZeitpunktEntry > 0 &&
                                            (this.props.userDataWI.retirementPercentage.length > this.state.newZeitpunktEntry ||
                                                (this.state.newZeitpunktEntry === this.props.userDataWI.retirementPercentage.length - 1 && this.props.currentPage !== 'chart') ||
                                                (this.props.currentPage === 'chart' && this.props.userDataWI.RetirementSteps.length === 5)),
                                        'current': this.props.currentPage === 'rentenanteil' && this.state.readyToAnimate
                                    })}
                                >
                                </div>
                            </FormattedMessageCustom>
                            ,
                            <br key="break42" />,
                            <FormattedMessageCustom id={translatableStrings.arbeitspensum.id} text={this.props.intl.formatMessage(translatableStrings.arbeitspensum)} key="rentenanteilsMobile">
                                <div
                                    id={this.props.userDataWI.arbeitPensums[this.props.userDataWI.RetirementSteps.length - 1] ? `arbeitspensum` : null}
                                    // id={this.props.userDataWI.arbeitPensums.length ? `arbeitspensum` : null}
                                    onClick={(e) => {
                                        this.handleMouseLeave();
                                        // this.props.back(e);
                                    }}
                                    className={classNames('menuLastElem menuElem', {
                                        'completed':
                                            this.state.newZeitpunktEntry > 0 &&
                                            (this.props.userDataWI.arbeitPensums.length > this.state.newZeitpunktEntry ||
                                                (this.state.newZeitpunktEntry === this.props.userDataWI.arbeitPensums.length - 1 && this.props.currentPage !== 'chart') ||
                                                (this.props.currentPage === 'chart' && this.props.userDataWI.RetirementSteps.length === 5)),
                                        'current': this.props.currentPage === 'arbeitspensum' && this.state.readyToAnimate
                                    })}
                                >
                                </div>
                            </FormattedMessageCustom>
                            ,
                        ]
                    }
                    label='Rentenberechnung' />
            </label>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Four);
