import * as React from 'react';
import {
  SliderItem,
  GetHandleProps,
  GetTrackProps
} from 'react-compound-slider';
import './Slider.scss'

// *******************************************************
// HANDLE COMPONENT
// *******************************************************
interface IHandleProps {
  domain: ReadonlyArray<number>;
  handle: SliderItem;
  getHandleProps: GetHandleProps;
}

export const Handle: React.SFC<IHandleProps> = (
  {
    domain: [min, max],
    handle: {id, value, percent},
    getHandleProps
  }
) => (
  <div
    role="slider"
    aria-valuemin={min}
    aria-valuemax={max}
    aria-valuenow={value}
    className='slider'
    style={{
      left: `${percent}%`,
    }}
    {...getHandleProps(id)}
  />
);

// *******************************************************
// TRACK COMPONENT
// *******************************************************
interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
}

export const Track: React.SFC<ITrackProps> = ({
                                                source,
                                                target,
                                                getTrackProps
                                              }) => (
  <div
    className='slider_line'
    style={{
      left: `${source.percent}%`,
      width: `${target.percent - source.percent}%`
    }}
    {...getTrackProps()}
  />
);
