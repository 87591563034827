import React from 'react';
import { formatNumber } from '../../../../utils/react';
import classNames from 'classnames';
import './TeilPensionOverview.scss';
import ExpandableRow from '../../../../components/ExpandableRow';

interface State {
    open1: boolean;
    open2: boolean;
    open3: boolean;
}

interface Props {
    capitalInformation: any;
    retirementSteps: any;
}

export default class TeilPensionOverview extends React.Component<Props, State> {

    constructor(props: any) {
        super(props);
        console.log("TeilPensionOverview constructor called");

        this.state = {
            open1: false,
            open2: false,
            open3: false,
        };
    }


    componentDidMount() {
        console.log('TeilPensionOverview app did mount', this.props);

    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("TeilPensionOverview componenentDidUpdate", prevProps, prevState, snapshot);
        // this.animateIn();
        // Object.entries(this.props).forEach(([key, val]) =>
        //     prevProps[key] !== val && console.log(`Prop TeilPensionOverview'${key}' changed`)
        // );
        // if (this.state) {
        //     Object.entries(this.state).forEach(([key, val]) =>
        //         prevState[key] !== val && console.log(`State TeilPensionOverview'${key}' changed`)
        //     );
        // }
    }

    renderMoreContent = (obj) => {
        return obj && obj.items.length ? (
            <>
                {obj.items.map((el, idx) => {
                    return <div key={"more" + idx} className={classNames("d-flex mt-4", {
                        // "mt-4": idx === 0,
                        "my-4": idx === obj.items.length - 1
                    })}>
                        <div className="col-8">
                            {el.label}
                            {/* Altersguthaben (Basiskapital) */}
                        </div>
                        <div className="col-4">
                            CHF {formatNumber(el.value)}
                        </div>
                    </div>

                })}
                {/* <div className="d-flex mt-4">
                    <div className="col-8">
                        Altersguthaben für vorzeitige
                        Pensionierung (VP-Konto)
                </div>
                    <div className="col-4">
                        CHF {formatNumber(obj.value)}
                    </div>
                </div>
                <div className="d-flex my-4">
                    <div className="col-8">
                        Gesperrtes Sparkonto (über Kapital)
                </div>
                    <div className="col-4">
                        CHF {formatNumber(obj.value)}
                    </div>
                </div> */}
            </>
        ) : null
    }

    render() {
        return (
            <div className="teilPensionOverview">
                {this.props.capitalInformation && this.props.capitalInformation.details.items ? this.props.capitalInformation.details.items.map((obj, idx) => {
                    console.log('obj overview', obj)
                    return <>
                        <div className="sliderLikeLabel">
                            {obj.label}
                            {/* Situation Teilpensionierung {parseDate(obj.startDate, "secsToDDMMYYYY")} */}
                        </div>
                        {obj.items.map((item, idx) => {
                            console.log('item', item);
                            return <ExpandableRow
                                expanded={this.state.open1}
                                // index={idx}
                                // length={this.state.filteredInvoices || this.props.invoices}
                                className={"expandableRowCapital"}
                                // key={'invoiceRow' }
                                col1={item.label}
                                col2={item.label ? "CHF " + formatNumber(item.value) : null}
                                moreContent={this.renderMoreContent(item)}
                            />
                        })}

                        {/* {this.props.capitalInformation.summary.capitalWithdrawalDetails[idx] && <ExpandableRow
                            expanded={this.state.open2}
                            // index={idx}
                            // length={this.state.filteredInvoices || this.props.invoices}
                            className={"expandableRowCapital"}
                            // key={'invoiceRow' }
                            col1={"Kapitalbezug"}
                            col2={this.props.capitalInformation.summary.capitalWithdrawalDetails[idx] ? ("CHF " + formatNumber(this.props.capitalInformation.summary.capitalWithdrawalDetails[idx])) : null}
                            moreContent={this.renderMoreContent(obj.items[idx])}
                        />} */}

                        {/* {this.props.retirementSteps.length === 1 && <ExpandableRow
                            expanded={this.state.open3}
                            // index={idx}
                            // length={this.state.filteredInvoices || this.props.invoices}
                            className={"expandableRowCapital"}
                            // key={'invoiceRow' }
                            col1={`Kapital für Rentenberechnung zum Umwandlungssatz von ${this.props.capitalInformation.summary.conversionRate.toFixed(3)}%`}
                            col2={this.props.capitalInformation.summary.totalCapitalForAnnnuity ? ("CHF " + formatNumber(this.props.capitalInformation.summary.totalCapitalForAnnnuity)) : null}
                            moreContent={this.renderMoreContent(obj.items[idx])}
                        />} */}
                    </>
                }) : null}
            </div>
        );
    }
}
