import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../reducers/index';
import {Dispatch} from 'redux';
import classNames from 'classnames';
import * as actions from '../../actions/';
import './ExpandableRow.scss';
import {ReactComponent as IconArrowDown} from '../../assets/icons/arrow-down.svg';

interface State {
    expanded: boolean;
}

interface Props {
    col1: string;
    col2: string;
    moreContent: any;
    rowChanged?: any;
    className?: string;
    expanded?: number;
    index?: number;
    length?: number;
    progress?: boolean;
}

class ExpandableRow extends React.Component<Props, State> {
    constructor(props : any) {
        super(props);
        this.state = {
            expanded: false
        };

        // console.log("ExpandableRow constructor called");
    }

    componentDidMount() {
        // console.log('ExpandableRow app did mount', this.props);
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     console.log("shouldComponentUpdate ExpandableRow", !((nextProps.expanded != this.props.index) && !this.state.expanded));
    //     if((nextProps.expanded != this.props.index) && this.state.expanded) {
    //         this.setState({
    //             expanded: false
    //         })
    //     }
    //     return !((nextProps.expanded != this.props.index) && !this.state.expanded)
    // }

    componentWillUnmount() {}

    // shouldComponentUpdate(nextProps, nextState) {
    //     // console.log("shouldComponentUpdate", nextProps.length, this.props.length);
    //     if((nextProps.expanded != this.props.index) && this.state.expanded) {
    //         this.setState({
    //             expanded: false
    //         })
    //     }
    //     return !((nextProps.expanded != this.props.index) && !this.state.expanded) ||
    //             nextProps.length !== this.props.length || nextProps.progress;
    // }

    componentDidUpdate(prevProps, prevState, snapshot) {
       // console.log("ExpandableRow componenentDidUpdate", prevProps, prevState, snapshot);
    }

    expandRow = () => {
        this.setState({expanded: true});
        this.props.rowChanged && this.props.rowChanged(true);
    }

    collapseRow = () => {
        this.setState({expanded: false});
        this.props.rowChanged && this.props.rowChanged(false);
    }

    rowClicked = (e) => {
        // console.log("row clicked", e.target, e.currentTarget);
        if (e.target.classList.contains('exRow') || e.target.classList.contains('column')) {
            // console.log("contains column");
            this.props.rowChanged && this.props.rowChanged(!this.state.expanded);
            this.setState({
                expanded: !this.state.expanded
            })
        }
    }

    render() {
        // console.log("render ExpandableRow", this.props.moreContent)
        return (<div className={classNames('exRowTop', this.props.className, {'expanded': this.state.expanded})}>
            <div className={classNames('exRow topRow')} onClick={(e) => {this.rowClicked(e)}}>
                <div className={classNames('column', 'col1', 'invoiceNumber')}>
                    <div className="colContent">
                        {this.props.col1}
                    </div>
                </div>

                <div className={classNames('column', 'col2')}>
                    <div className="colContent">
                        {this.props.col2}
                    </div>
                </div>
                {
                    <div className={classNames('column', 'col7 icon', {
                        'invisible': !this.props.moreContent
                    })}>
                            {
                                this.state.expanded
                                    ? (<button className={'moreDetails anchorLike'} style={{
                                            position: 'relative'
                                        }} onClick={this.collapseRow}>
                                        <div className={'arrowE rotated'}>
                                            <IconArrowDown/>
                                        </div>
                                    </button>)
                                    : (<button className={'moreDetails anchorLike'} style={{
                                            position: 'relative'
                                        }} onClick={this.expandRow}>
                                        <div className={'arrowE'}>
                                            <IconArrowDown/>
                                        </div>
                                    </button>)
                            }
                        </div>
                }
            </div>

            {
                this.props.moreContent && <div className={classNames(
                            'exRow', 'moreRow', this.state.expanded
                            ? 'active'
                            : '')}>
                        {this.props.moreContent}
                    </div>
            }

        </div>);
    }
}

function mapStateToProps(state : RootState, ownProps : any) {
    return {
        ...ownProps,
        ...state.app
    }
}

function mapDispatchToProps(dispatch : Dispatch < actions.ACTION >) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpandableRow);
