export const postExample = {
    "data": {
        "tenantName": "",                                    // make this configurable, coming from config
        "calculationType": "Retirement",                        // anliegen 
        "pensionType": "Common",                                // detaillierung
        "RetirementSteps": [
            {
                "ahvPensionAmount": 0,                                  // always show, regardles of VP konto answer, ask Marcus to confirm
                "bridgingPensionAmount": 0,                             // moved to the steps below
                "capitalWithdrawalPercentage": 0,                      // moved to the steps below
                "capitalPurchaseAmount": 0,                             // moved to the steps below
                "retirementDate": "2060-06-30T12:00:00.000Z",   // zeitpunkt
                "retirementPercentage": 50                      // rentenanteil
            },
            {
                "ahvPensionAmount": 0,                                  // always show, regardles of VP konto answer, ask Marcus to confirm
                "bridgingPensionAmount": 0,                             // moved to the steps below
                "capitalWithdrawalPercentage": 0,                      // moved to the steps below
                "capitalPurchaseAmount": 0,                             // moved to the steps below
                "retirementDate": "2062-06-30T12:00:00.000Z",
                "retirementPercentage": 100
            }
        ],
        "insurant": {
            "person": {
                "name": "Peter",
                "firstname": "Müller",
                "birthDate": "1995-06-15T00:00:00.00Z",         // geburtsdatum
                "language": "German",                           // always german for now
                "sexName": "male",
                "sex": "male",                                  // geschlecht
                "salutation": 0,
                "salutationName": "Herr",
                "personNote": "string",
                "divorceDate": "2020-03-09T16:08:37.372Z",
            },
            "addressArray": [
                {
                    "validFrom": "2020-03-09T16:08:37.372Z",
                    "validUntil": "2020-03-09T16:08:37.372Z",
                    "address1": "string",
                    "address2": "string",
                    "address3": "string",
                    "address4": "string",
                    "address5": "string",
                    "phoneNumber": "string",
                    "mobileNumber": "string",
                    "email": "string",
                    "zipCode": "string",                        // wohnort
                    "city": "string",                           // wohnort
                    "countryCode": "string",
                    "countryName": "string"
                }
            ],
            "payroll": [
                {
                    "validFrom": "2020-01-01T12:00:00.000Z",    // first day of the year always 
                    "grossSalary": 50000,                       // we don't ask for it
                    "insuredSalary": 50000,                     // lohnsumme
                    "bonus": 0,                                 // we don't ask for it 
                    "activityRate": 100.0,                       // arbeitspensum, max 100, min even below 0
                    "insuredSalaryConstantAfter58": true        // we don't ask for it
                }
            ],
            "employee": {
                // "entryDate": "2020-03-09T16:08:37.372Z",
                // "insurancePlan": "BVG",                    // always bvg, taken from config 
            },
            "acturialValuesSet": {
                "totals": [
                    {
                        "referenceDate": "2020-01-01T12:00:00.000Z",    // ausgestellt
                        "basicCapital": 0,                          // sparguthaben
                        "earlyRetirementCapital": 0,                // vpkonto nein => 0, 
                        "savingsCapital": 0,                        // not in the interface. possible in some pension funds
                        "bvgCapital": 0                             // not in the interface
                    }
                ],
                "details": [
                    // {
                    //     "referenceDate": "2020-01-01T12:00:00.000Z",
                    //     "totalCapital": 
                    // }
                ]
            }
        }
    },
    "jsonapi": {
        // "version": "1.0.3"
    }
};
