import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import classNames from 'classnames';
import { Button, EButtonType } from "../../../components/Button";
// import HelpIcon from '../../../assets/icons/Help Icon.svg';
import './index.scss';
import { Input } from '../../../components/Input/index';
import doc from "../../../assets/imgs/VP-konto-2.png";
import SelectList from "../../../components/Sidebar/SelectList";
import { ReactComponent as IconNein } from '../../../assets/icons/Combined2.svg';
import { ReactComponent as IconJa } from '../../../assets/icons/Combined.svg';
import gsap from 'gsap';
import { animationConfig } from '../../../constants';
import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';
const translatableStrings = defineMessages({
    enterVpKonto: {
        id: 'pageVpKonto.enterVpKonto',
        defaultMessage: 'VP-Konto Guthaben eingeben'
    },
    howHighVpKonto: {
        id: 'pageVpKonto.howHighVpKonto',
        defaultMessage: 'Wie hoch ist Ihr aktuelles VP-Konto Guthaben?'
    }
});

interface State {
    step: number;
    value: string;
    // login: string;
    // password: string;
    // passwordType: string;
    // rememberMe: boolean;
}

interface Props {
    actions: any;
    history: any;
    setUserDataVPKonto: any;
    setUserDataVPKontoAmount: any;
    userDataWI: any;
    intl: any;
}

class VPKonto extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            step: 1,
            value: this.props.userDataWI.VPKontoAmount ? this.props.userDataWI.VPKontoAmount : null,
        }
        this.next = this.next.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline1 = null;
    private timelineIn = null;



    componentDidMount() {
        // if (window.innerWidth > 896) {
        setTimeout(() => {
            (document.querySelector('.fakeFocusable') as any) && (document.querySelector('.fakeFocusable') as any).focus();
        }, 2000);
        // }
    }

    next(e, skip) {
        if (this.state.step === 1) {
            this.props.setUserDataVPKonto(e.currentTarget.id);
            skip && this.props.setUserDataVPKontoAmount(0);
        } else {
            this.props.setUserDataVPKontoAmount(this.state.value);
        }
        console.log('next', skip, this.state.step);

        if (skip) {
            console.log('skip true');
            this.timeline1 = gsap.timeline({ paused: true });
            this.timeline1 && this.timeline1.pause();

            if (this.state.step === 2) {
                // if (document.querySelectorAll('.crossFade').length) {
                //     this.timeline1
                //         .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                //             autoAlpha: 0
                //         })
                // }

                this.timeline1
                    .fromTo([
                        document.querySelectorAll('.step2 .stagger'),
                        // document.querySelectorAll('.optional.stagger'),
                    ], { autoAlpha: 1, y: 0 }, {
                        y: -900,
                        stagger: {
                            ...animationConfig().stagger,
                        },
                        ease: animationConfig().easingStart,
                        autoAlpha: 0
                    })
            } else {
                if (document.querySelectorAll('.crossFade').length) {
                    this.timeline1
                        .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                            autoAlpha: 0
                        })
                }

                this.timeline1
                    .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1, y: 0 }, {
                        y: -900,
                        stagger: {
                            ...animationConfig().stagger,
                        },
                        ease: animationConfig().easingStart,
                        autoAlpha: 0
                    })

            }
            this.timeline1.play();
            document.querySelector('.routeContainer').classList.add('overflowHidden');

            this.timeline1.eventCallback("onComplete", () => {
                console.log('onComplete 1');
                setTimeout(() => {
                    document.querySelector('.routeContainer').classList.remove('overflowHidden');
                    this.props.history.push({
                        pathname: `/ahvrente`
                    });
                }, 300);
            });
        } else if (this.state.step === 1) {
            console.log('step1');
            this.timeline1 = gsap.timeline({ paused: true });
            this.timeline1 && this.timeline1.pause();

            if (document.querySelectorAll('.crossFade').length) {
                this.timeline1
                    .fromTo(document.querySelectorAll('.crossFade'), { autoAlpha: 1 }, {
                        autoAlpha: 0
                    })
            }

            this.timeline1
                .fromTo(document.querySelectorAll('.step1 .stagger'), {
                    autoAlpha: 1,
                    y: 0
                }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                });

            this.timeline1.play();

            this.timeline1.eventCallback("onComplete", () => {
                console.log('oncomplete step 1');
                setTimeout(() => {
                    // setTimeout((e) => {
                    this.setState({
                        step: 2
                    }, () => {
                        this.timelineIn = gsap.timeline({ paused: true });
                        this.timelineIn && this.timelineIn.pause();

                        if (document.querySelectorAll('.step2 .stagger').length) {
                            this.timelineIn
                                .fromTo(document.querySelectorAll('.step2 .stagger'), {
                                    autoAlpha: 0,
                                    y: 900
                                }, {
                                    y: 0,
                                    stagger: { ...animationConfig().stagger },
                                    autoAlpha: 1
                                })
                        }

                        this.timelineIn.play();
                        this.timelineIn.eventCallback("onComplete", () => {
                            console.log('oncomplete');
                            setTimeout(() => {
                                document.querySelector('.routeContainer').classList.remove('overflowHidden');
                                if (window.innerWidth > 896) {
                                    (document.querySelector('.mainBlock input') as any).focus();
                                }
                            }, 300)
                        });

                        this.timelineIn.eventCallback("onStart", () => {
                            document.querySelector('.routeContainer').classList.add('overflowHidden');
                        });
                    })
                }, 300)
            });
        }
    }

    handleChange(name, value) {
        this.setState({
            value: value
        });
    };


    enter(event) {
        if (event.keyCode === 13) {
            this.next(event.currentTarget.value, true)
        }
    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        document.body.classList.remove('blurred');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                    <div className='flex-grow-1'>
                        
                    </div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques'/> */}
                </div>
                <div className="main vp-main">

                    <div className={classNames("mainBlock step1", {
                        "d-none": this.state.step === 2
                    })}>
                        <div className='ich-mochte stagger'>
                            Verfügen Sie über ein Konto
                            «Vorzeitige Pensionierung»?
                        </div>
                        <div className='subtitle stagger'>
                            Wenn das Konto «Vorzeitige Pensionierung» (VP-Konto)
                            auf Ihrem Versicherungsausweis nicht aufgeführt ist,
                            verfügen Sie nicht über ein solches Konto.
                        </div>
                        {/* <img alt="logo" src={HelpIcon} className='ques_mobile'/> */}
                        <div
                            className='blocksGeschlecht stagger'
                        >
                            <button
                                tabIndex={2}
                                id='Ja'
                                className='blockVP '
                                onClick={(e) => this.next(e, false)}
                            >
                                <div className={this.props.userDataWI.VPKonto === 'Ja' ? 'activeChoice' : 'imgGeschlecht'}>
                                    <div>
                                        <IconJa className="d-sm-inline" />
                                    </div>
                                </div>
                                <p className='text'>Ja</p>
                            </button>
                        </div>
                        <div
                            className='blocksGeschlecht2 stagger'
                        >
                            <button
                                tabIndex={3}
                                id='Nein'
                                className='blockVP '
                                onClick={(e) => this.next(e, true)}
                            >
                                <div className={this.props.userDataWI.VPKonto === 'Nein' ? 'activeChoice' : 'imgGeschlecht'}>
                                    <div>
                                        <IconNein className="d-sm-inline" />
                                    </div>
                                </div>
                                <p className='text'>Nein</p>
                            </button>
                            <a className='fakeFocusable' tabIndex={1} href="/">FOCUSABLE</a>
                        </div>
                    </div>

                    <div className={classNames("mainBlock step2", {
                        "d-none": this.state.step === 1
                    })}>
                        <FormattedMessageCustom id={translatableStrings.howHighVpKonto.id} text={this.props.intl.formatMessage(translatableStrings.howHighVpKonto)}>

                            <div className={classNames(
                                'ich-mochte mb-40', {
                                "stagger": this.state.step === 2
                            }
                            )}
                            ></div>
                        </FormattedMessageCustom>
                        <div className={classNames(
                            'lohnsumme', {
                            "stagger": this.state.step === 2
                        }
                        )}

                        >
                            <div className='lohnsumme__main'>
                                <Input
                                    // onBlur={this.handleBlur}
                                    // onFocus={this.handleFocus}
                                    value={this.state.value}
                                    placeholder={this.props.intl.formatMessage(translatableStrings.enterVpKonto)}
                                    inputWrapperClass="w-100 number_input"
                                    onChange={(name, val) => this.handleChange(name, val)}
                                    onKeyDown={(event) => this.enter(event)}
                                    type='money'
                                    // validationMessageConfig={{}}
                                    inputWrapperStyle={{}}>
                                    CHF
                            </Input>
                            </div>
                            {(this.state) ? this.state.value ?
                                <div className='lohnsumme__button'>
                                    <Button
                                        className="button"
                                        submit={true}
                                        onClick={() => this.next(this.state.value, true)}
                                        buttonType={EButtonType.FullWidth}
                                    >
                                        <div>WEITER</div>
                                    </Button>
                                </div> : null : null
                            }
                        </div>
                    </div>
                </div>

                <div className='optional crossFade' style={{ marginRight: '-13px' }}>
                    <div className='doc'>
                        <img alt="doc" src={doc} />
                        <p>Beispiel</p>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataVPKonto: (data) => dispatch(actions.setUserDataVPKonto(data)),
        setUserDataVPKontoAmount: (data) => dispatch(actions.setUserDataVPKontoAmount(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VPKonto);
