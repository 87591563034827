import { combineReducers } from 'redux';
import {reducer as toastrReducer} from 'react-redux-toastr'
import app, { AppState } from './app';
import { connectRouter } from 'connected-react-router';
import localesReducer from './i18n';
import { intlReducer } from 'react-intl-redux';

export interface RootState {
    app: AppState;
    intl: any;
    locales: any;
}

export default (history) => combineReducers({
    router: connectRouter(history),
    app,
    intl: intlReducer,
    locales: localesReducer,
    toastr: toastrReducer
})

