import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { RootState } from '../../../reducers';
import * as actions from '../../../actions';
import '../../Wunschberechnung/Anliegen/Wunschberechnung.scss';
import Calendar from "../../../components/Calendar";
import SelectList from "../../../components/Sidebar/SelectList";
import gsap from 'gsap';
import { animationConfig } from '../../../constants';

import { defineMessages } from 'react-intl';
import FormattedMessageCustom from '../../../components/FormattedMessageCustom';

const translatableStrings = defineMessages({
    when: {
        id: 'pageGeburtsdatum.when',
        defaultMessage: 'Wann wurden Sie geboren?'
    }
})

interface State {
    dateOfBirth: string;
}

interface Props {
    actions: any;
    history: any;
    setUserDataGeburtsdatum: any;
    userDataWI: any;
    intl: any;
}

class Geburtsdatum extends React.Component<Props, State> {
    constructor(props: any) {

        super(props);
        this.state = {
            dateOfBirth: '',
        };
        this.setDateOfBirth = this.setDateOfBirth.bind(this);
        this.back = this.back.bind(this);
    }
    private timeline = null;

    componentDidMount() {
        document.body.classList.remove('welcome');
        // this.props.setStepsOpened({ 1 true, 2: false, 3: false });
    }

    async setUserDataGeburtsdatum(data) {
        await this.props.setUserDataGeburtsdatum(data);
    }

    async next(e) {
        await this.setUserDataGeburtsdatum(e);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (document.querySelectorAll('.stagger').length) {
            this.timeline
                .fromTo(document.querySelectorAll('.stagger'), { autoAlpha: 1 }, {
                    y: -900,
                    stagger: {
                        ...animationConfig().stagger,
                    },
                    ease: animationConfig().easingStart,
                    autoAlpha: 0
                })
        }

        this.timeline.play();

        setTimeout((e) => {

            this.props.history.push({
                pathname: `/geschlecht`
            })
        }, 600)

    }

    start = (e) => {
        this.props.history.push({
            pathname: e
        })
    }

    back = (e) => {
        if (e.currentTarget.id) {
            this.props.history.push({
                pathname: '/' + e.currentTarget.id
            })
        }
    }

    async setDateOfBirth(e) {
        this.setState({
            dateOfBirth: e,
        });
        this.next(e);
    }

    async showHide(event) {
        event();
    }

    async showHideBack() {
        console.log('showHideBack');
        document.body.classList.remove('blurred');
    }

    render() {
        return (
            <div className="wunschberechnung_container">
                <div id='back' className='mobile_back' onClick={() => this.showHideBack()} />
                <div className='sidebar'>
                  <div className='flex-grow-1'></div>
                    <div className='selects'>
                        <div className='neustart'>
                            <SelectList
                                intl={this.props.intl}
                                back={this.back}
                                start={this.start}
                            />
                        </div>
                    </div>
                    {/* <img alt="logo" src={HelpIcon} className='ques' /> */}
                </div>
                <div className="main calend-main">
                    <FormattedMessageCustom id={translatableStrings.when.id} text={this.props.intl.formatMessage(translatableStrings.when)}>
                        <div className="ich-mochte mb-40 stagger"></div>
                    </FormattedMessageCustom>
                    {/* <img alt="logo" src={HelpIcon} className='ques_mobile' /> */}
                    <Calendar
                        intl={this.props.intl}
                        setDateOfBirth={this.setDateOfBirth}
                    />
                </div>
                <div className='optional' />
            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {
        setUserDataGeburtsdatum: (data) => dispatch(actions.setUserDataGeburtsdatum(data)),
        // setStepsOpened: (data) => dispatch(actions.setStepsOpened(data)),
        // fetchSettings: bindActionCreators(actions.fetchSettings, dispatch),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Geburtsdatum);

