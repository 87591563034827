import React from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../../reducers/index';
import {Dispatch} from 'redux';
import * as actions from '../../../actions/';
import './Day.scss';


interface State {
    monthArr: Array<number>,
    minDay?:  any,
    maxDay?:  any,
}

interface Props {
    numberOfDaysPerMonth: number,
    onClick: any,
}

class Day extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        // Here should be the date in the format ["JJJJ-MM-TTT12:00:00.000Z", "JJJJ-MM-TTT12:00:00.000Z"]
        const date = [];
        if (date.length) {
            this.state = {
                monthArr: [],
                minDay:  date[0].slice(8,10),
                maxDay:  date[1].slice(8,10),
            }
        } else {
            this.state = {
                monthArr: [],
            }
        }
    }

    UNSAFE_componentWillMount() {
        let monthArr = [];
        const startDay = this.state.minDay ? this.state.minDay : 1;
        const numberOfDaysPerMonth = this.state.maxDay ? this.state.maxDay : this.props.numberOfDaysPerMonth || 31;
        for (let i = startDay; i <= numberOfDaysPerMonth; i++) {
            let day: string = i < 10 ? `0${i}` : i.toString();
            monthArr.push(day);
        }
        this.setState({monthArr})
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className="days">
                {this.state.monthArr.map(day => (
                  <div className="day"
                       id={day.toString()}
                       onClick={(e) => {
                           this.props.onClick(e)
                       }}
                       key={day.toString()}
                  >
                      {day}
                  </div>
                ))}

            </div>
        );
    }
}

function mapStateToProps(state: RootState, ownProps: any) {
    return {
        ...ownProps,
        ...state.app,
    }
}

function mapDispatchToProps(dispatch: Dispatch<actions.ACTION>) {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Day);
