import React from 'react';
import gsap from 'gsap';
import { Transition, SwitchTransition } from 'react-transition-group'
import { parseDate, getMonth, monthDiff, getDate } from '../../utils/react';
import FormattedMessageCustom from '../../components/FormattedMessageCustom';
import { defineMessages } from 'react-intl';
import './Calendar.scss';
import classNames from 'classnames';
import Day from './Day/index'
import Month from './Month/index'
import { Year } from './Year'
import { Button, EButtonType } from "../Button";

// const shortening: any = {
//     'TT': 'Tag',
//     'MM': 'Monat',
//     'JJJJ': 'Jahr',
// };

const translatableStrings = defineMessages({
    chooseDay: {
        id: 'calendar.chooseDay',
        defaultMessage: 'Tag wählen'
    },
    chooseMonth: {
        id: 'calendar.chooseMonth',
        defaultMessage: 'Monat wählen'
    },
    chooseYear: {
        id: 'calendar.chooseYear',
        defaultMessage: 'Jahr wählen'
    },
    MM: {
        id: 'calendar.MM',
        defaultMessage: "MM",
    },
    DD: {
        id: 'calendar.TT',
        defaultMessage: "TT"
    },
    YYYY: {
        id: 'calendar.YYYY',
        defaultMessage: "JJJJ"
    },
    next: {
        id: 'button.next',
        defaultMessage: 'WEITER'
    },
})


interface State {
    isOpened: string,
    selectedDate: any,
    activeDate: string,
}

interface Props {
    title?: any;
    second?: boolean;
    day?: boolean;
    dateRange?: any;
    setDateOfBirth: any;
    yearReversed?: boolean;
    intl: any;
}

export default class Calendar extends React.Component<Props, State> {
    private TTref: React.RefObject<HTMLInputElement>;
    private MMref: React.RefObject<HTMLInputElement>;
    private JJJJref: React.RefObject<HTMLInputElement>;
    private nextButtonRef: React.RefObject<React.Component>;
    private timeline: any = null;

    initialState = {
        isOpened: "datePicker",
        selectedDate: {
            TT: this.props.second || this.props.day ? '01' : '',
            MM: '',
            JJJJ: '',
        },
        activeDate: this.props.second || this.props.day ? 'MM' : 'TT',
    };

    constructor(props: any) {
        super(props);
        this.state = this.initialState;
        this.TTref = React.createRef();
        this.MMref = React.createRef();
        this.JJJJref = React.createRef();
        this.nextButtonRef = React.createRef();
    }

    componentDidMount() {
        if (window.innerWidth > 896) {
            setTimeout(() => {
                (document.querySelector('.dateParameter.test') as any) && (document.querySelector('.dateParameter.test') as any).focus();
            }, 1200);
        }
    }

    focusNextButton = () => {
        console.log("FOCUS");
        // setTimeout(() => {
        //     (document.querySelector(".confirmationDialog") as any) && (document.querySelector(".confirmationDialog") as any).firstChild.focus()
        // }, 500)
    }

    handleChange1 = (e) => {
        // console.log('test change1', e.target.textContent, e.currentTarget.innerText);
        // const doc = document as any;
        let value = e.currentTarget.innerText.replace(/\D/g, '');
        const dayInitialValue = 'TT';
        // this.TTref.current.innerText = value;

        if (!(Number.isInteger(e.currentTarget.innerText) || Number(e.currentTarget.innerText) >= 0)) {
            this.setState({
                selectedDate: {
                    ...this.state.selectedDate,
                    'TT': dayInitialValue
                },
            });

            this.TTref.current.innerText = '';
            return;
        };

        if (e.currentTarget.innerText.indexOf('.') === 1) {
            console.log('fixvalue1');
            value = '0' + e.currentTarget.innerText.replace(/\D/g, '');
            this.TTref.current.innerText = (parseInt(value) > 31) ? 31 : value;
        }

        if (e.currentTarget.innerText.length > 2) {
            value = value.slice(0, 2);
            this.TTref.current.innerText = (parseInt(value) > 31) ? 31 : value;
        }

        this.setState({
            selectedDate: {
                ...this.state.selectedDate,
                'TT': (parseInt(value) > 31) ? 31 : ('0' + value).slice(-2)
            },
        });

        if (value.length === 2 || e.currentTarget.textContent.length === 2 || e.currentTarget.innerText.length > 2) {
            this.TTref.current.innerText = (parseInt(value) > 31) ? 31 : value;
            this.setState({
                activeDate: 'MM',
            });
            this.MMref.current.innerText = '';
            this.MMref.current.focus();
        }
    };

    handleChange2 = async (e) => {
        console.log('test change2', e.target, e.currentTarget.innerText, e.currentTarget.textContent);
        let value = e.currentTarget.innerText.replace(/\D/g, '');
        const { selectedDate } = this.state;
        const monthInitialValue = 'MM';

        if (!(Number.isInteger(e.currentTarget.innerText) || Number(e.currentTarget.innerText) >= 0)) {
            this.setState({
                selectedDate: {
                    ...this.state.selectedDate,
                    'MM': monthInitialValue
                },
            });
            console.log('will return')

            this.MMref.current.innerText = '';
            return;
        };

        if (e.currentTarget.textContent.indexOf('.') === 1) {
            console.log('fixvalue2');
            value = '0' + e.currentTarget.innerText.replace(/\D/g, '');
            this.MMref.current.innerText = value;
        }
        // this.MMref.current.innerText = e.currentTarget.innerText.replace(/\D/g, '');
        if (e.currentTarget.innerText.length > 2) {
            value = value.slice(0, 2);
            this.MMref.current.innerText = (parseInt(value) > 12) ? 12 : value;
        };


        if (this.props.dateRange && this.props.dateRange[0] && this.props.dateRange[1] && value > 0) {
            console.log('this.props.dateRange', this.props.dateRange, 'value', value, getMonth(this.props.dateRange[0]), getMonth(this.props.dateRange[1]));
            console.log("MONTH DIFF",);
            const differenceInMonths = monthDiff(new Date(this.props.dateRange[0]), new Date(this.props.dateRange[1]));
            if (differenceInMonths < 12) {
                if (Number(value) < (getMonth(this.props.dateRange[0]) + 1)) {
                    value = ('0' + Number(getMonth(this.props.dateRange[0]) + 1)).slice(-2);
                } else if (selectedDate && selectedDate.TT && (Number(value) >= (getMonth(this.props.dateRange[1]) + 1)) && (Number(selectedDate.TT) > getDate(this.props.dateRange[1]))) {
                    value = ('0' + Number(getMonth(this.props.dateRange[1]))).slice(-2);
                } else if (Number(value) > (getMonth(this.props.dateRange[1]) + 1)) {
                    value = ('0' + Number(getMonth(this.props.dateRange[1]) + 1)).slice(-2);
                }
            }
            console.log('value', value, parseInt(value));
        }

        this.setState({
            selectedDate: {
                ...this.state.selectedDate,
                'MM': (parseInt(value) > 12) ? 12 : ('0' + value).slice(-2),
            },
        });
        if (value.length === 2) {
            this.MMref.current.innerText = (parseInt(value) > 12) ? 12 : value;
            this.setState({
                activeDate: 'JJJJ',
            });
            this.JJJJref.current.innerText = '';
            this.JJJJref.current.focus();
        }
    };

    handleChange3 = async (e) => {
        console.log('test change3', e.target, e.currentTarget, e.currentTarget.innerText, e.currentTarget.textContent, e.currentTarget.innerHTML);
        let value = e.currentTarget.textContent.replace(/\D/g, '');
        // this.JJJJref.current.innerText = value;
        // this.JJJJref.current.textContent = value;

        const yearInitialValue = 'JJJJ';
        if (!(Number.isInteger(e.currentTarget.innerText) || Number(e.currentTarget.innerText) >= 1)) {
            this.setState({
                selectedDate: {
                    ...this.state.selectedDate,
                    'JJJJ': yearInitialValue
                },
            });

            this.JJJJref.current.innerText = '';
            return;
        };

        if (e.currentTarget.innerText.length > 4) {
            return;
        }
        this.setState({
            selectedDate: {
                ...this.state.selectedDate,
                'JJJJ': value,
            },
        });
        if (value.length === 4) {
            if (this.state.selectedDate.MM === "") {
                this.setState({
                    activeDate: 'MM',
                });
                this.MMref.current.focus();
            } else if (this.state.selectedDate.TT === "") {
                this.setState({
                    activeDate: 'TT',
                });
                this.TTref.current.focus();
            } else {
                if (this.props.dateRange) {
                    if (parseDate(this.props.dateRange[0], "toSecs") <= parseDate(`${value}-${this.state.selectedDate.MM}-${this.state.selectedDate.TT}`, "toSecs")) {
                        if (parseDate(this.props.dateRange[1], "toSecs") >= parseDate(`${value}-${this.state.selectedDate.MM}-${this.state.selectedDate.TT}`, "toSecs")) {
                            this.setState({
                                isOpened: 'confirmationDialog',
                                activeDate: '',
                            });
                            this.JJJJref.current.blur();
                            this.focusNextButton()
                        }
                    }
                } else {
                    this.setState({
                        isOpened: 'confirmationDialog',
                        activeDate: '',
                    });
                    this.JJJJref.current.blur();
                    this.focusNextButton()
                }

            }
        }
    };

    async onClickDateParameter(e) {
        console.log('onclickdatepram', e.target.id, this.state, this.MMref.current.innerText);
        // Handle click on JJJJ from month screen
        let month = {};
        if (e.target.id === "JJJJ") {
            month = {
                "MM": this.MMref.current.innerText
            }
        }
        this.setState({
            activeDate: e.target.id,
            selectedDate: {
                ...this.state.selectedDate,
                [e.target.id]: '',
                ...month
            },
            isOpened: "datePicker",
        });
    }

    async onClickChangeDate(e) {
        console.log('onclickchangeadate', e.target.id);
        if (this.state.selectedDate.MM === "") {
            this.setState({
                activeDate: 'MM',
                selectedDate: {
                    ...this.state.selectedDate,
                    'TT': e.target.id,
                },
            });
        } else if (this.state.selectedDate.JJJJ === "") {
            this.setState({
                activeDate: 'JJJJ',
                selectedDate: {
                    ...this.state.selectedDate,
                    'TT': e.target.id,
                },
            });
        } else {
            this.setState({
                activeDate: '',
                selectedDate: {
                    ...this.state.selectedDate,
                    'TT': e.target.id,
                },
                isOpened: 'confirmationDialog',
            });
        }

        this.TTref.current.innerText = e.target.id;
    }

    async onClickChangeMonth(e) {
        console.log('onclickchangemonth', e.target.id);
        if (this.state.selectedDate.TT === "") {
            this.setState({
                activeDate: 'TT',
                selectedDate: {
                    ...this.state.selectedDate,
                    'MM': e.target.id,
                },
            });
        } else if (this.state.selectedDate.JJJJ === "") {
            this.setState({
                activeDate: 'JJJJ',
                selectedDate: {
                    ...this.state.selectedDate,
                    'MM': e.target.id,
                },
            });
        } else {
            this.setState({
                activeDate: '',
                selectedDate: {
                    ...this.state.selectedDate,
                    'MM': e.target.id,
                },
                isOpened: 'confirmationDialog',
            });
        };

        this.MMref.current.innerText = e.target.id;
    }

    async onClickChangeYear(e) {
        if (this.state.selectedDate.MM === "") {
            this.setState({
                activeDate: 'MM',
                selectedDate: {
                    ...this.state.selectedDate,
                    'JJJJ': e.target.id,
                },
            });
        } else if (this.state.selectedDate.TT === "") {
            this.setState({
                activeDate: 'TT',
                selectedDate: {
                    ...this.state.selectedDate,
                    'JJJJ': e.target.id,
                },
            });
        } else {
            this.setState({
                activeDate: '',
                selectedDate: {
                    ...this.state.selectedDate,
                    'JJJJ': e.target.id,
                },
                isOpened: 'confirmationDialog',
            });
        };

        this.JJJJref.current.textContent = e.target.id;
    }

    playIn = (node, appears?) => {
        console.log('playIn', node, appears, this.state.activeDate, appears);
        if (!appears) {
            if (this.state.activeDate === 'MM') {
                this.timeline.to(document.querySelector('.datePicker'), { maxHeight: 468, duration: 0.2 });
                this.timeline.fromTo(node.querySelector('.months'), { x: 500, autoAlpha: 0 }, { x: 0, duration: 0.3, autoAlpha: 1 });
                this.timeline.fromTo(node.querySelector('.clue'), { autoAlpha: 0 }, { duration: 0.3, autoAlpha: 1, delay: 0 });
            } else if (this.state.activeDate === 'DD') {
                this.timeline.to(document.querySelector('.datePicker'), { maxHeight: 468, duration: 0.3 });
                // this.timeline.fromTo(node.querySelector('.days'), { x: 500, autoAlpha: 0 }, { x: 0, duration: 0.3, autoAlpha: 1 });
            } else if (this.state.activeDate === 'JJJJ') {
                this.timeline.to(document.querySelector('.datePicker'), { maxHeight: 468, duration: 0.3 });
                this.timeline.fromTo(node.querySelector('.years'), { x: 500, autoAlpha: 0 }, { x: 0, duration: 0.3, autoAlpha: 1 });
                this.timeline.fromTo(node.querySelector('.clue'), { autoAlpha: 0 }, { duration: 0.3, autoAlpha: 1, delay: 0 });
            } else {
                console.log("FOCUS BUTTONm");
                this.timeline.fromTo(document.querySelector('.confirmationDialog'), { autoAlpha: 0 }, { duration: 0.3, autoAlpha: 1, delay: 0.15 });
                setTimeout(() => {
                    document.querySelector('.focusableButton') && (document.querySelector('.focusableButton') as any).focus();
                }, 200);
            }
            this.timeline.play();
        }
    }

    playOut = (node) => {
        console.log('playOut', node, this.state.activeDate);
        this.timeline = gsap.timeline({ paused: true });
        this.timeline && this.timeline.pause();

        if (this.state.activeDate === 'MM') {
            this.timeline.fromTo(node.querySelector('.days'), { x: 0, autoAlpha: 1 }, { x: -500, duration: 0.3, autoAlpha: 0 }, 0);
            this.timeline.fromTo(node.querySelector('.clue'), { autoAlpha: 1 }, { duration: 0.3, autoAlpha: 0, delay: 0 }, 0);
        } else if (this.state.activeDate === 'JJJJ') {
            this.timeline.fromTo(node.querySelector('.clue'), { autoAlpha: 1 }, { duration: 0.3, autoAlpha: 0, delay: 0 }, 0);
            this.timeline.fromTo(node.querySelector('.months'), { x: 0, autoAlpha: 1 }, { x: -500, duration: 0.3, autoAlpha: 0 }, 0);
        } else if (this.state.activeDate === "") {
            console.log('datepicier height animn out');
            this.timeline.to(document.querySelector('.datePicker'), { maxHeight: 232, duration: 0.3 }, 0);
            this.timeline.fromTo(node.querySelector('.years'), { autoAlpha: 1 }, { duration: 0.3, autoAlpha: 0 }, 0);
            this.timeline.fromTo(node.querySelector('.clue'), { autoAlpha: 1 }, { duration: 0.3, autoAlpha: 0, delay: 0 }, 0);
        } else {
            console.log('datepicier alpha animn out');
            this.timeline.fromTo(document.querySelector('.confirmationDialog'), { autoAlpha: 1 }, { duration: 0.3, autoAlpha: 0, delay: 0 });
        }

        this.timeline.play();
    }

    // enter(event) {
    //     console.log('event click in calenar');
    //     if (event.keyCode === 13) {
    //         this.props.setDateOfBirth(`${this.state.selectedDate.TT}.${this.state.selectedDate.MM}.${this.state.selectedDate.JJJJ}`);
    //     }
    // }

    render() {
        return (
            <div className="calendar stagger">
                {/*<div className="calendarTitle">*/}
                {/*    {this.props.title}*/}
                {/*</div>*/}
                <div className="datePicker">
                    <div className={classNames("date", {
                        "daysHidden": this.props.second || this.props.day
                    })}>
                        {(this.props.second || this.props.day) ?
                            // Number(this.state.selectedDate.JJJJ) ?
                            //     <div className='d-flex'>
                            //         <div className={classNames('dateParameter', {
                            //             activeDateParameter: this.state.activeDate === 'TT'
                            //         })}
                            //             id='TT'
                            //             onClick={this.props.second || this.props.day ? null : (e) => this.onClickDateParameter(e)}
                            //         >
                            //             {this.state.selectedDate.TT}
                            //         </div>
                            //         <div className="dateParameter">
                            //             .
                            //     </div>
                            //     </div>
                            //     : null
                            null
                            :
                            <div className='d-flex'>
                                <div className={classNames('dateParameter', 'test', {
                                    activeDateParameter: this.state.activeDate === 'TT'
                                })}
                                    id='TT'
                                    onClick={(e) => this.onClickDateParameter(e)}
                                    ref={this.TTref}
                                    spellCheck={false}
                                    contentEditable={true}
                                    data-translate={translatableStrings.DD.id}
                                    placeholder={this.props.intl.formatMessage(translatableStrings.DD)}
                                    onInput={this.handleChange1}
                                >
                                    {/* {this.state.selectedDate.TT} */}
                                </div>
                                <div className="dateParameter">
                                    .
                              </div>
                            </div>
                        }
                        <div className={classNames('dateParameter', 'test', {
                            activeDateParameter: this.state.activeDate === 'MM'
                        })}
                            id='MM'
                            onClick={(e) => {
                                this.onClickDateParameter(e)
                            }}
                            ref={this.MMref}
                            contentEditable={true}
                            data-translate={translatableStrings.MM.id}
                            placeholder={this.props.intl.formatMessage(translatableStrings.MM)}
                            spellCheck={false}
                            onInput={this.handleChange2}
                        >
                            {/* {this.state.selectedDate.MM} */}
                        </div>
                        <div className="dateParameter">
                            .
                        </div>
                        <div className={classNames('dateParameter', 'test', {
                            activeDateParameter: this.state.activeDate === 'JJJJ'
                        })}
                            id='JJJJ'
                            ref={this.JJJJref}
                            onClick={(e) => {
                                this.onClickDateParameter(e)
                            }}
                            spellCheck={false}
                            contentEditable={true}
                            data-translate={translatableStrings.YYYY.id}
                            placeholder={this.props.intl.formatMessage(translatableStrings.YYYY)}
                            onInput={this.handleChange3}
                        >
                            {/* {this.state.selectedDate.JJJJ} */}
                        </div>
                    </div>
                    <SwitchTransition mode="out-in">
                        <Transition
                            key={`child${this.state.activeDate}`}
                            timeout={{ enter: 0, exit: 300 }}
                            onEnter={(node?, appears?) => this.playIn(node, appears)}
                            onExit={(node?) => this.playOut(node)}
                        >
                            {this.state.isOpened !== "confirmationDialog" ?
                                <div className="choseDate">
                                    <SwitchTransition mode="out-in">
                                        <Transition
                                            key={`child${this.state.activeDate}`}
                                            timeout={{ enter: 600, exit: 300 }}
                                            onEnter={(node, appears?) => this.playIn(node, appears)}
                                            onExit={(node?) => this.playOut(node)}
                                        >
                                            <span>
                                                {this.state.activeDate === 'TT' ?
                                                    <>
                                                        <FormattedMessageCustom id={translatableStrings.chooseDay.id} text={this.props.intl.formatMessage(translatableStrings.chooseDay)}>
                                                            <div className='clue'>
                                                            </div>
                                                        </FormattedMessageCustom>
                                                        <Day
                                                            onClick={(e) => this.onClickChangeDate(e)}
                                                        />
                                                    </>
                                                    : null
                                                }
                                                {this.state.activeDate === 'MM' ?
                                                    <>
                                                        <FormattedMessageCustom id={translatableStrings.chooseMonth.id} text={this.props.intl.formatMessage(translatableStrings.chooseMonth)}>
                                                            <div className='clue'>
                                                            </div>
                                                        </FormattedMessageCustom>
                                                        <Month
                                                            selectedDate={this.state.selectedDate}
                                                            onClick={(e) => this.onClickChangeMonth(e)}
                                                            dateRange={this.props.dateRange}
                                                            lang={this.props.intl.locale}
                                                        />
                                                    </>
                                                    : null
                                                }
                                                {this.state.activeDate === 'JJJJ' ?
                                                    <>
                                                        <FormattedMessageCustom id={translatableStrings.chooseYear.id} text={this.props.intl.formatMessage(translatableStrings.chooseYear)}>
                                                            <div className='clue'>
                                                            </div>
                                                        </FormattedMessageCustom>
                                                        <Year
                                                            selectedDate={this.state.selectedDate}
                                                            dateRange={this.props.dateRange}
                                                            second={this.props.second ? 2090 : null}
                                                            reversed={this.props.yearReversed}
                                                            onClick={(e) => this.onClickChangeYear(e)}
                                                        />
                                                    </>
                                                    : null
                                                }
                                            </span>
                                        </Transition>
                                    </SwitchTransition>

                                </div>
                                :
                                <div
                                    className="confirmationDialog">
                                    <Button
                                        className="button focusableButton"
                                        // submit={true}
                                        onClick={() => this.props.setDateOfBirth(`${("0" + this.state.selectedDate.TT).slice(-2)}-${this.state.selectedDate.MM}-${this.state.selectedDate.JJJJ}`)}
                                        buttonType={EButtonType.FullWidth}
                                    >
                                        <FormattedMessageCustom id={translatableStrings.next.id} text={this.props.intl.formatMessage(translatableStrings.next)}>
                                            <div></div>
                                        </FormattedMessageCustom>
                                    </Button>
                                </div>
                            }
                        </Transition>
                    </SwitchTransition>
                </div>
            </div>
        );
    }
}
